import * as Api from "../api/api";
import * as SignalR from "@aspnet/signalr";
import * as MngHubStore from "../store/MngHub";
import { ApplicationState } from "../store";
import * as Notifications from 'react-notification-system-redux';
import { WebSocketAction } from "../store/HmiApplication";
import { NeoEventAdded, requestAlarmEvents } from "../store/NeoEvent";
import { ReceiveRobotStateUpdated } from "../store/Robot";
import { ReceiveCashSolvencyChanged, ReceiveCashPaymentCancelled, ReceiveCashStateUpdated, ReceiveSolvencyCashCompleted } from "../store/Cash";
import { ReceiveCardAmountCollected, ReceiveCardPaymentCancelled, ReceiveCardSolvencyChanged, ReceiveCardStateUpdated } from "../store/Card";
import { ReceivePrintStateUpdated } from "../store/Print";
import { readToken } from "../security/tokenManager";
import {requestTodayDayStoreData,requestTodayStoreOrders, requestTodayMoneyStock} from "../store/History";
import {ReceiveLockersStates, requestStockStatus} from "../store/Stock";
import Throttle from "../utils/throttle"
import { requestTheLastPayments } from "../store/Home";

let connection: SignalR.HubConnection = null;

type KnownAction = MngHubStore.KnownAction
    | NeoEventAdded
    | WebSocketAction
    | ReceiveRobotStateUpdated
    | ReceiveCashSolvencyChanged
    | ReceiveCashPaymentCancelled
    | ReceiveCardAmountCollected
    | ReceiveCardPaymentCancelled
    | ReceiveCardSolvencyChanged
    | ReceiveCashStateUpdated
    | ReceiveCardStateUpdated
    | ReceivePrintStateUpdated
    | ReceiveSolvencyCashCompleted
    | ReceiveLockersStates
    ;

export const connectUserHub = (dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<void> => {
    if(connection){
        connection.stop();
    }
    
    connection = new SignalR.HubConnectionBuilder()
        .withUrl("/mngHub")
        .build();

    connection.on("SendConnectedUsers", (usersJson) => {
        dispatch({ type: "RECEIVE_CONNECTED_USERS", payload: { connectedUsers: JSON.parse(usersJson) } });
    });

    connection.on("SendConnectionId", (connectionId) => {
        dispatch({ type: "RECEIVE_CONNECTION_ID", payload: { connectionId: connectionId } });
    });

    connection.on("UserSendNewMessage", (messageJson) => {
        dispatch({ type: "RECEIVE_NEW_USER_MESSAGE", payload: { message: JSON.parse(messageJson) } });
        if (!getState().mngHub.isOpen) {
            dispatch(Notifications.info({
                title: 'Message',
                message: 'Vous avez reçu un nouveau message',
                position: 'tc'
            }));
        }
    });    

    connection.onclose((err) => {
        dispatch({ type: "USERHUB_CONNECTION_CLOSED" });
    });

    connection.on("ApplicationStateUpdated", (model: Api.HmiApplicationStateModel) => {
        dispatch({
            type: "RECEIVE_HMI_STATE",
            payload: { currentTime: new Date().getTime(), hmiState: model }
        });
    });

    connection.on("RobotStateUpdated", (state: Api.RobotHandlerState) => {
        dispatch({
            type: "RECEIVE_ROBOT_STATE_UPDATED",
            payload: { state: state }
        });
    });
  
    connection.on("NeoEventAdded", (event: Api.NeoEventModel) => {
        if(event){
            dispatch({
                type: "NEO_EVENT_ADDED",
                payload: { value: event }
            });
            let eventCode = getState().seed.seed.neoEventCodes[event.neoEventCodeId]?.code;
            if([
                502,
                603,
                606,
                607,
                616,
                702,
                304
            ].some(x => x === eventCode)){
                Throttle.throttle("requestTodayDayStoreData", () => {
                    //Auto update for home page on collections
                    (requestTodayDayStoreData(new Date().getTime(), dispatch as any, getState) as Promise<any>)
                        .then(() =>requestTodayStoreOrders(new Date().getTime(), dispatch as any, getState));
                    requestTodayMoneyStock(new Date().getTime(), dispatch as any, getState);
                    requestTheLastPayments(new Date().getTime(), dispatch as any, getState);
                }, 5000)
            }
            if([
                306,
                702
            ].some(x => x === eventCode)){
                Throttle.throttle("requestStockStatus", () => {
                    //Auto update for stock post delivery
                    requestStockStatus(new Date().getTime(), dispatch as any, getState);
                }, 5000)
            
            }
            if(eventCode >= 800){
                Throttle.throttle("requestAlarmEvents", () => {
                    requestAlarmEvents(new Date().getTime(), dispatch as any, getState);
                }, 5000)
            }
        }
    });

    connection.on("SolvencyCashChanged", (value) => {
        dispatch({
            type: "RECEIVE_CASH_SOLVENCY_CHANGED",
            payload: { value: value }
        });
    });
    connection.on("SolvencyCashCompleted", (value) => {
        dispatch({
            type: "RECEIVE_CASH_SOLVENCY_COMPLETED",
            payload: { value: value }
        });
    });

    connection.on("ProductLockerStatus", (lockersStatus) => {
        dispatch({ 
            type: "RECEIVE_LOCKERS_STATES", 
            payload: { requestTime: new Date().getTime(), lockersStatus: lockersStatus} 
        });
    });

    connection.on("PaymentCashCanceled", () => {
        dispatch({ type: "RECEIVE_CASH_PAYMENT_CANCELLED" });
    });

    connection.on("CollectionCardCompleted", (value) => {
        dispatch({ type: "RECEIVE_CARD_AMOUNT_COLLECTED", payload: { value: value } });
    });
    connection.on("SolvencyCardChanged", (value) => {
        dispatch({ type: "RECEIVE_CARD_SOLVENCY_CHANGED", payload: { value: value } });
    });
    connection.on("PaymentCardCanceled", (value) => {
        dispatch({ type: "RECEIVE_CARD_PAYMENT_CANCELLED" });
    });
    connection.on("CardHandlerStateChanged", (value) => {
        dispatch({ type: "RECEIVE_CARD_STATE_UPDATED", payload: { state: value } });
    });
    connection.on("CashHandlerStateChanged", (value) => {
        dispatch({ type: "RECEIVE_CASH_STATE_UPDATED", payload: { state: value } });
    });
    connection.on("PrintStateUpdated", (value) => {
        dispatch({ type: "RECEIVE_PRINT_STATE_UPDATED", payload: { state: value } });
    });
    
    return connection.start().then(() => {
        let token = window.localStorage.getItem("token");        
        dispatch({ type: "USERHUB_CONNECTION_OPENED" });
        setTimeout(() => {
            connection.send("UserAuthenticate", token);
        }, 5000);
    });
};

export const closeConnection = (): any => {
    if (connection) {
        connection.off("OnDisconnectedAsync");
        connection.stop();
    }
}

export const sentMessage = (message: string): Promise<void> => {
    if (connection)
        return connection.send("UserSendMessage", message);

    return Promise.reject("No connection");
}