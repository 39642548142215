let allTexts = {};

export const loadTexts = (texts: { [key: string]: string }) => {
    allTexts = texts || {};
}

export const getText = (key: LangKey, params?: any): string => {
    let text = allTexts[key] || "XXXXXXXX";
    if (params) {
        Object.keys(params).forEach(param => {
            text = text.replace(new RegExp("%{" + param + "}", "g"), params[param]);
        });
    }
    return text;
}

export const getAllTexts = (): { [key: string]: string } => {
    return allTexts;
}

type LangKey = 
    "NavMenuInterventions" |"NavMenuModalVersions" | "NavMenuVersionModalVersion" | "NavMenuDateModalVersion" | "NavMenuDescriptionModalVersion" |
/* Originals */
"HmiStartSale" | "HmiRetrieveOrder" | "GenExpirityNone" | "GenExpirityNormal" | "GenExpirityDdm" | "GenExpirityEggs" | "GenStabilityStable" | 
"GenStabilityUnstable" | "GenUsername" | "GenPassword" | "GenLogin" | "LgapDenyAlreadyExist" | "LgapDenyBadName" | "LgapDenyBadImage" | 
"LgapDenyNoSupplier" | "LgapDenyInconsistentPriceUnit" | "LgapDenyInconsistentUnit" | "LgapDenyUnitNotWellDefined" | "LgapDenyIndistinct" | 
"LgapDenyOther" | "LgapProductMealVoucher" | "HistoExploitant" |
/* General */
"GenRobot" | "GenCard" | "GenCash" | "GenPrint" | "GenPhone" | "GenTableExport" | "GenTableOf" | "GenTableLoadingText" | "GenTableNext" | "GenTablePrevious" | "GenTableLines" |
"GenTablePage" | "GenLGAP" | "NavMenuHome" | "NavMenuProducts" | "NavMenuMenu" | "NavMenuDLCDDM" | "NavMenuPromotions" |
"NavMenuStock" | "NavMenuPlanogram" | "NavMenuReorder" | "NavMenuOrders" | "NavMenuReception" | "NavMenuFigures" | "NavMenuVoucher" |
"NavMenuCashRegister" | "NavMenuSales" | "NavMenuHistory" | "NavMenuTech" | "NavMenuMaintenance" | "NavMenuSettings" |
"NavMenuEvents" | "NavMenuDarkMode" | "HistoOrdinateur" | "NavMenuLockerStock" |
/* Header */
"HeaderLogOut" | "HeaderLogIn" | "HeaderTitle" | "HeaderCash" | "HeaderTitleStore" |
/* Home */
"HomeStockState" | "HomeCashLevel" | "HomeTicketsLevel" | "HomeProductsNumber" | "HomeEmptyBaysPerc" | "HomeFreeBands" | "HomeCash" | 
"HomeGraphLabel1" | "HomeGraphLabel2" | "HomeGraphLabel3" | "HomeGraphLabel4" | "HomeGraphLabel5" | "HomeGraphLabel6" | "HomeGraphLabel7" |  "HomeSearch" |
"HomeGraphLabel8" | "HomeGraphLabel9" | "HomeDefaults" | "HomeEvents" | "HomeSince" | "HomeUntil" | "HomeCode" | "HomeFilter" | "HomeHour" | "HomeType" | 
"HomeEvent" | "HomeNotRecycled" | "HomeStoreState" | "HomeComboChartTitleClients" | "HomeComboChartHour" | "HomeComboCharSold" | "HomeComboChartTotal" |
"HomeComboChartTitleProducts" | "HomeComboCharProducts" | "HomeCashRegisterValidationTrue" | "HomeCashRegisterValidationFalse" | "HomePeropheralsBusy" |
"HomePeropheralsOff" | "HomePeropheralsKo" | "HomePeropheralsDeactivated" | "HomePeropheralsOk" | "HomeUserHubTooltip" | "HomeUserHubConnected" |
/* Products */
"ProductsTitle" | "ProductsFamily" | "ProductsSubfamily" | "ProductsProduct" | "ProductsInPlanogram" | "ProductsMargin" | "ProductsRate" |
"ProductsSource" | "ProductsUnitPrice" | "ProductsPVTTC" | "ProductsBrandTaxes" | "ProductsTVA" | "ProductsGrossMargin" | "ProductsPrice2" |
"ProductsSales" | "ProductsSubfamilies" | "ProductsCategories" | "ProductsCategory" | "ProductsComments" | "ProductsSourceSupply" |
"ProductsReference" | "ProductsPAHT" | "ProductsQty" | "ProductsDeliveryQty" | "ProductsInfo" | "ProductsCode" | "ProductsType" | "ProductsDayNumber" |
"ProductsStable" | "ProductsInStock" | "ProductsDimensions" | "ProductsDepth" | "ProductsHeight" | "ProductsWidth" | "ProductsWeightVolume" |
"ProductsVolumeUnit" | "ProductsPracticUnit" | "ProductsConversionFactor" |
/* Menu */
"MenuTitle" | "MenuName" | "MenuPrice" | "MenuActive" | "MenuDetails" |
/* DLC/DDM */
"OfferTitle" | "OfferFamily" | "OfferSubfamily" | "OfferName" | "OfferHavePromo" | "OfferYes" | "OfferNo" | "OfferProductDay" | 
"OfferPromotions" | "OfferDLC" | "OfferDDM" | "OfferRate" | "OfferDisable" | "OfferAll" | "OfferActivate" | "OfferUpdateAll" |
"OfferPromoRate" | "OfferUntil" | "OfferDaysBefore" | "OfferDaysAfter" | "OfferNoPromo" | "OfferDialogMsg" | "OfferDialogTitle" |
/* Promotions */
"PricePeriodTitle" | "PricePeriodName" | "PricePeriodPeriod" | "PricePeriodDefaultRate" | "PricePeriodProduct" | "PricePeriodActions" | 
"PricePeriodDefaultRateName" | "PricePeriodProducts" | "PricePeriodCreate" | "PricePeriodModalPeriods" | "PricePeriodModalHours" | 
"PricePeriodModalMonth" | "PricePeriodModalDays" | "PricePeriodNewPeriod" | "PricePeriodAdd" | "PricePeriodRate" | 
"PricePeriodEdit" | "PricePeriodStartHour" | "PricePeriodEndHour" | "PricePeriodStartMonth" | "PricePeriodStartDayOfMonth" | 
"PricePeriodEndMonth" | "PricePeriodEndDayOfMonth" | "PricePeriodMonday" | "PricePeriodTuesday" | "PricePeriodWednesday" | "PricePeriodThursday" |
"PricePeriodFriday" | "PricePeriodSaturday" | "PricePeriodSunday" | "PricePeriodSave" | "PricePeriodFamilies" | "PricePeriodSubfamilies" | 
"PricePeriodAddAll" | "PricePeriodAddFamily" | "PricePeriodAddSubfamily" | "PricePeriodFamily" | "PricePeriodSubfamily" | "PricePeriodTooltip" |
"PricePeriodHoursValidation" | "PricePeriodMonthsValidation" | "PricePeriodByPromo" | "PricePeriodByPeriod" |
/* Planogram */
"PlanogramTitle" | "PlanogramFloor" | "PlanogramMaxQty" | "PlanogramName" | "PlanogramPlanogram" | "PlanogramCreate" | "PlanogramExport" | 
"PlanogramImport" | "PlanogramActivate" | "PlanogramRemove" | "PlanogramLastImport" | "PlanogramImportLGAP" | "PlanogramStartImport" |  "PlanogramPlanosList" |
"PlanogramDepth" | "PlanogramHeight" | "PlanogramWidth" | "PlanogramDirection" | "PlanogramStock" | "PlanogramAdding" | "PlanogramRADF" |
"PlanogramInPlanogram" | "PlanogramHeightFilter" | "PlanogramAddButton" | "PlanogramProductPosition" | "PlanogramFamily" | "PlanogramSubfamily" |
"PlanogramProduct" | "PlanogramModalDLC" | "PlanogramModalQty" | "PlanogramModalAdd" | "PlanogramModalDeleteStock" | "PlanogramModalFill" | 
"PlanogramModalDateCheck" | "PlanogramModalPosition" | "PlanogramModalAddPlate" | "PlanogramModalRayon" | "PlanogramModalFloor" | "PlanogramModalLevel" |
"PlanogramCancel" | "PlanogramActive" | "PlanogramSettings" | "PlanogramSuccess" | "PlanogramFail" | "PlanogramGridDialogMsg" | "PlanogramGridDialogTitle" |
"PlanogramGridDialogMsgCtr8" | "PlanogramLockerIP" | "PlanogramLockerLockNum" | "PlanogramLockerDeleteProd" | "PlanogramShelvings" | "PlanogramLockers" |
"PlanogramLockerDialogMsg" | "PlanogramHmiRunning" | "PlanogramDisponible" | "PlanogramBlocked" | "PlanogramNoDisponible" | "PlanogramRemoveShelving" | "PlanogramUpdateShelving" |
"PlanogramLockerProductAlert" | "PlanogramLockerProdValidation" | "PlanogramLockerLockValidation" | "PlanogramLockerOpen" | "PlanogramLockerClose" | "PlanogramLockerControlButton" |
"PlanogramLockerValidationIP" |
/* Reappro */
"ReorderTabReorder" | "ReorderSource" | "ReorderCode" | "ReorderDaysPast" | "ReorderDaysStock" | "ReorderTabProductsOrders" | "ReorderDaysInPast" |
"ReorderStockWanted" | "ReorderSecurityCoeff" | "ReorderOnlyProposals" | "ReorderApply" | "ReorderOrderCreate" | "ReorderModalName" | "ReorderModalTotal" |
"ReorderModalOrder" | "ReorderModalProductTable" | "ReorderQtyPackTable" | "ReorderWord" | "ReorderProductCode" | "ReorderVMH" | "ReorderVMM" |"ReorderSales" |
"ReorderRemoved" | "ReorderQtyStkProb" | "ReorderDaysStkProb" | "ReorderVolDays" | "ReorderVolQty" | "ReorderQtyToOrder" | "ReorderQty" | "ReorderAlreadyOrdered" | 
"ReorderNumberQty" | "ReorderDLC" | "ReorderPUHT" | "ReorderPAHT" | "ReorderPackCond" | "ReorderPATHPack" | "ReorderModalCreate" | "ReorderstockRob" |
"ReorderstkArr" |
/* Order */
"OrderTitle" | "OrderProducts" | "OrderFilter" | "OrderNom" | "OrderDate" | "OrderNumber" | "OrderCond" | "OrderPAHT" |
"OrderRecive" | "OrderProductsTitle" | "OrderProductTitle" | "OrderSource" | "OrderPack" | "OrderPAHTPack" | "OrderPackNumber" |
"OrderTotal" | 
/* Reception */
"ReceptionTitle" | "ReceptionOrders" | "ReceptionReceiveOrder" | "ReceptionAddOrder" | "ReceptionDownloadCSV" | "ReceptionInventory" | "ReceptionDayOrder" | 
"ReceptionSearchName" | "ReceptionSearchSource" | "ReceptionSearchCode" | "ReceptionNom" | "ReceptionSource" | "ReceptionCode" | "ReceptionStockRobot" | 
"ReceptionBackStock" | "ReceptionTotalStock" | "ReceptionProbableStock" | "ReceptionUnits" | "ReceptionQty" | "ReceptionQtyNumber" |
"ReceptionProduct" | "ReceptionQtyPack" | "ReceptionPAHTPack" | "ReceptionTotal" | "ReceptionPracticUnit" | "ReceptionVolUnit" | "ReceptionInventoryRexel" |
"ReceptionStockRobotRexel" | "ReceptionBackStockRexel" | "ReceptionTotalStockRexel" | "ReceptionProbableStockRexel" | "ReceptionUnitsRexel" |
"ReceptionBackStockUp" | "ReceptionTotalStockUp" | "ReceptionProbableStockUp" | "ReceptionInventoryUp" | "ReceptionUnitsUp" | "ReceptionManual" |
"ReceptionMultOfPack" | "ReceptionAddReception" | "ReceptionAdd" |
/* Stock */
"StockTitle" | "StockSource" | "StockCodeSource" | "StockStock" | "StockBaysState" | "StockPromo" | "StockFull" | "StockBelow" | 
"StockEmptyBay" | "StockNoStock" | "StockInPromo" | "StockPromoOfTheDay" | "StockPercentageLabel" | "StockName" | "StockRADF" | 
"StockDLCClose" | "StockNumber"  | "StockState" | "StockTotal" | "StockBack" | "StockMarkdown" | "StockDays" | "StockDate" | "StockType" |
"StockActions" | "StockAlert" | "StockSearch" | "StockActions" | "StockPracticUnit" | "StockVolUnit" | "StockStockRexel" | "StockNumberRexel" |
"StockBackRexel" | "StockMarkdownRexel" | "StockStockUpRexel" | "StockNumberUpRexel" | "StockBackUpRexel" | "StockDialogErrorMsg" | "StockDialogErrorTitle" |
"StockTimedOffer" | "StockExpirity" | "StockHelpDialogTitle" | "StockHelpDialogAlarm1" | "StockHelpDialogAlarm2" | "StockHelpDialogAlarm3" | "StockCampaign" |
"StockLockerPosition" |
/* Reduction */
"VoucherTitle" | "VoucherCurrent" | "VoucherHisto" | "VoucherNew" | "VoucherAdd" | "VoucherCampaign" | "VoucherCode" | "VoucherAmount" | 
"VoucherMiniBasket" | "VoucherStart" | "VoucherEnd" | "VoucherDateOfUse" | "VoucherModalName" | "VoucherModalCreate" | "VoucherModalManual" | "VoucherModalAuto" |
"VoucherModalMax" | "VoucherStartDate" | "VoucherEndDate" | "VoucherModalFixedAmount" | "VoucherModalPercentageAmount" |
"VoucherModalAmountCashed" | "VoucherTotal" |"VoucherCouponNumber" | "VoucherUsedNumber" | "VoucherTotalDiscount" | "VoucherLoss" | "VoucherMaxUsed" |
"VoucherUsed" | "VoucherType" | "VoucherAmountMin" | "VoucherTimesToUse" | "VoucherQty" | "VoucherCreate2" | "VoucherAmount2" | "VoucherCodesToUse" |
"VoucherType2" | "VoucherModalPercentageAmount2" | "VoucherModalAmount" | "VoucherModalPercent2" | "VoucherModalAmountMin" | "VoucherModalAmountMax" |
"VoucherDeleteDialog" | "VouchersDelete" | "VouchersDeleteDialog" | "VoucherModalCreateTitle" | "VoucherAmountFlat" | "VoucherAmountPercentage" |
/* Caisse */
"CashRegisterTitle" | "CashRegisterOperations" | "CashRegisterInCashRegister" | "CashRegisterType" | "CashRegisterDay" | 
"CashRegisterCoins" | "CashRegisterTickets" | "CashRegisterIntro" | "CashRegisterRendering" | "CashRegisterReset" | "CashRegisterEmptying" | "CashRegisterLoading" | 
"CashRegisterDiscounts" | "CashRegisterResetCR" | "CashRegisterHopperMng" | "CashRegisterLevel" | "CashRegisterReloading" | "CashRegisterPartialEmpty" |
"CashRegisterResetHoppers" | "CashRegisterBanknoteReader" | "CashRegisterHoppers" | "CashRegisterBanknoteReader2" | "CashRegisterCreditCard" |
"CashRegisterSmartphone" | "CashRegisterTotal" | "CashRegisterNFC" | "CashRegisterTotal2" | "CashRegisterReload" | "CashRegisterEmpty" |
/* Analyse des ventes */
"SalesAnalysisTitle" |
/* Help */
"HelpTitle" | "HelpLinksNoFound" |
/* Histo */
"HistoTitle" | "HistoSince" | "HistoUntil" | "HistoAccumulation" | "HistoApply" | "HistoDays" | "HistoWeeks" | "HistoMonths" | "HistoYears" |
"HistoExport" | "HistoTabTurnover" | "HistoTabOrders" | "HistoTabSales" | "HistoTabStock" | "HistoTabReorders" | "HistoTabCashRegister" | "HistoTabEvent" | 
"HistoTabBack" | "HistoTabRemote" | "HistoTabStockOperations" | "HistoCA" | "HistoCashPayments" | "HistoCreditCardPayments" | "HistoNFCPayments" | "HistoTotalPayment" |
"HistoDate" | "HistoNb" | "HistoHT" | "HistoTTC" | "HistoMargin" | "HistoCAHT" | "HistoCATTC" | "HistoType" | "HistoCode" | "HistoSource" | "HistoSmartphonePayments" | 
"HistoTotalBasket" | "HistoTotalCashed" | "HistoProductsQty" | "HistoWord" | "HistoUnit" | "HistoPromo" | "HistoMenu" | "HistoTotalHT" | "HistoTotal" | "HistoPAHT" |
"HistoPVTTC" | "HistoTVA" | "HistoShelv" | "HistoSmart" | "HistoQty" | "HistoPrice" | "HistoChoice" | "HistoInStock" | "HistoInBackStock" | "HistoReorderDate" | 
"HistoMinorEvents" | "HistoEvents" | "HistoClearAll" | "HistoComputer" | "HistoRobot" | "HistoPrinter" | "HistoMajorEvents" | "HistoMoney" | "HistoAuto" | 
"HistoState" | "HistoWarning" | "HistoTransactions" | "HistoInfo" | "HistoTransactionNumber" | "HistoDiscountNumber" | "HistoAmount" | "HistoSolvency" | 
"HistoModule" | "HistoSolvencyDate" | "HistoValidity" | "HistoAccountNumber" | "HistoPaymentType" | "HistoCardType" | "HistoContractNumber" | "HistoCrypto" |
"HistoTeachTitle" | "HistoCashedAmount" | "HistoState" | "HistoLabel" | "HistoCollectionDate" | "HistoOrders" | "HistoData" | "HistoProductName" | "HistoQtyTable" |
"HistoQtyDLC" | "HistoQtyManualRemove" | "HistoLoad" | "HistoUnload" | "HistoStockBackAdd" | "HistoStockBackSubs" | "HistoSales" | "HistoQtyTable2" | "HistoYesterday" | 
"HistoDaysOperations" | "HistoTomorrow" | "HistoTotalPrice" | "HistoFilter" | "HistoTPN" | "HistoTPA" | "HistoActionExp" | "HistoActionClient" | "HistoPhone" |
"HistoMessage" | "HistoStockTotalNoVat" | "HistoStockOpProductName" | "HistoStockOpDay1" | "HistoStockOpDayReception" | "HistoStockOpDayDLC" | "HistoStockOpDayManualRemove" |
"HistoStockOpDayLoad" | "HistoStockOpDayUnload" | "HistoStockOpDayAdd" | "HistoStockOpDaySubs" | "HistoStockOpTotal" | "HistoStockOpSales" | "HistoStockOpDsay2"|
"HistoRemoteCollectionReply" | "HistoRemoteCollectionSequence" | "HistoRemoteCollectionDelivery" | "HistoRemoteCollectionTransaction" | "HistoRemoteCollectionTransactionTotal" |
"HistoRemoteCollectionTransactionName" | "HistoMarque" | "HistoRemoteCollectionDiDate" | "HistoRemoteCollectionDiLabel" | "HistoRemoteCollectionDiContractId" | "HistoRemoteCollectionDiActivity" |
"HistoRemoteCollectionDiSiteType" | "HistoRemoteCollectionDiPaymentType" | "HistoRemoteCollectionDiSiret" | "HistoRemoteCollectionDiAcceptorNum" | "HistoRemoteCollectionDiTitle" |
"HistoRemoteCollectionDiResponse" | "HistoRemoteCollectionDiFooter" | "HistoRemoteCollectionDiAcquirerNum" | "HistoRemoteCollectionDiPart" | "HistoRemoteCollectionDiCurrencyCode" | 
"HistoRemoteCollectionDiRemissionId" | "HistoRemoteCollectionDiNbTrans" | "HistoRemoteCollectionDiAmountT" | "HistoRemoteCollectionDiNbFailedTrans" | "HistoRemoteCollectionDialogTitle" |
"HistoRemoteCollectionDialogTitle2" | "HistoRemoteCollectionAction" | "HistoCollectionCloseButton" | "HistoCardCollectionAction" | "HistoCardCollectionDate" | "HistoCardCollectionValidity" |
"HistoCardCollectionAccountNum" | "HistoCardCollectionPaymentType" | "HistoCardCollectionCardType" | "HistoCardCollectionContractNum" | "HistoCardCollectionCryptogram" | "HistoCardCollectionEnterprise" |
"HistoCardCollectionCollection" | "HistoCardCollectionStatus" | "HistoCardCollectionDialogTitle" | "HistoCardCollectionDiTransType" | "HistoCardCollectionDiTransNum" | "HistoCardCollectionDiSiret" | 
"HistoCardCollectionDiRemissionNum" | "HistoCardCollectionDiCurrencyNum" | "HistoCardCollectionDiTransAmount" | "HistoCardCollectionDiAcceptorNum" | "HistoCardCollectionDiDate" | "HistoCardCollectionDiCvPart" |
"HistoCardCollectionDiCardValidity" | "HistoCardCollectionDiCurrencyCode" | "HistoCardCollectionDiAccountNum" | "HistoCardCollectionDiReadingMode" | "HistoCardCollectionDiActivityType" | "HistoCardCollectionDiCvAmount" |
"HistoCardCollectionDiContractNum" | "HistoCardCollectionDiCurrencyPart" | "HistoCardCollectionDiAID" | "HistoCardCollectionDiCvCode" | "HistoCardCollectionDiLabel" | "HistoCardCollectionDiPaymentType" | 
"HistoCardCollectionDiLocationType" | "HistoCardCollectionDiAppType" | "HistoCardCollectionDiCrypto" | "HistoCardCollectionDiAuthoNum" | "HistoCardCollectionDiForceCode" | "HistoCardCollectionDiEstimatedAmount" |
"HistoCardCollectionDiServiceCode" | "HistoCardCollectionDiAcceptorTitle" | "HistoCardCollectionDiReceiptTitle" | "HistoCardCollectionDiReceiptFooter" | "HistoSmartphoneLvPayments" | 
"HistoSmartphoneCePayments" | "HistoCashRegisterMoney" | "HistoCashRegisterType" | "HistoCashRegisterTotal" | "HistoCashRegisterTotalMoney" | "HistoCashRegisterNoValues" | "HistoClient" |
"HistoNFC" | "HistoDelivery" | "HistoCE" | "HistoUberEats" | "HistoDeliveroo" | "HistoJustEat" | "HistoUnSouldProds" | "HistoTicket" | "HistoTicketCB" | "HistoMealVoucher" | "HistoDisplayTickets" | "HistoTurnoverTotal" |
/* Intervention */ 
"InterventionsTitle" | "InterventionSave" | "InterventionHistory" | "InterventionDate" | "InterventionTypeMessage" | "InterventionMessage" | "InterventionUser" | "InterventionFormTitle" |
"InterventionAction" | "InterventionMoney" | "InterventionRobot" | "InterventionComputer" | "InterventionNewFeature" | "InterventionOther" |
/* Notifications */
"NotificationTitleSuccess" | "NotificationSuccessCard" | "NotificationAlertBigFile" | "NotificationTitleError" | "NotificationErrorToken" | "NotificationErrorImport" | "NotificationRequestFailed" | "NotificationUpdatedSuccess" |
"NotificationUpdatedError" | "NotificationTitleRoborError" | "NotificationSomethingWrong" | "NotificationCreatedSuccess" | "NotificationCreatedError" |
/* Validations */
"ValidationRequired" | "ValidationMaxValue" | "ValidationMinValue" | "ValidationMustBe" | "ValidationMaxLength" | "ValidationMinLength" | "ValidationNoValid" | "ValidationOnlyNumbers" | "ValidationPassword" |
"ValidationPhone" | "ValidationCoef" |
/* Forum */
"ForumTitle" |
/* Support */
"SupportTitle" |
/* SalesTariff */
"SalesTariffTitle" | "SalesTariffButtonSave" | "SalesTariffTabName" | "SalesTariffTabPmp" | "SalesTariffButtonCalculate" | "SalesTariffTabDelta" | "SalesTariffTabMarque" |
/* PurchaseTariff */
"PurchaseTariffTitle" | "PurchaseTariffTabReference" | "PurchaseTariffTabPackCond" | "PurchaseTariffTabBuyPrice" | "PurchaseTariffTabBPUnit" | "PurchaseTariffTabDelCond" | "PurchaseTariffTabDelCondPrice" |
/*ProductSizes*/
"ProdSizesTitle" | "ProdSizesTabWidth" | "ProdSizesTabDepth" | "ProdSizesTabHeight" | "ProdSizesTabPlanoWidth" | "ProdSizesTabPlanoHeight" | "ProdSizesTabPlanoDepth" |
/*ProductMenuCat*/
"ProdMenuCTitle" |
/*MenuCategories*/
"MenuCatTitle" | "MenuCatNavMenuTitle" | "MenuCatSubTitle" | "MenuCatCategory" |
/* Maintenance */
"MaintenanceTitle" | "MaintenanceAbandonment" | "MaintenanceAction" | "MaintenanceAdd" | "MaintenanceAlarm" | "MaintenanceTabAlarms" | "MaintenanceIHMStop" | 
"MaintenanceAny" | "MaintenanceBands" | "MaintenanceCalculate" | "MaintenanceCreditCard" | "MaintenanceCreditCardTest" | "MaintenanceCode" |
"MaintenanceEventCode" |"MaintenanceTabContacts" | "MaintenanceCreate" | "MaintenanceSolvencyRequest" | "MaintenanceStartSimulation" | "MaintenanceDisableStore" | "MaintenanceDisableCreditCard" |
"MaintenanceDisableCashPayment" | "MaintenanceEdit" | "MaintenanceMail" | "MaintenanceCollection" | "MaintenanceToSend" | "MaintenanceCash" |
"MaintenanceRobotStateLabel" | "MaintenanceEvents" | "MaintenanceExtractor" | "MaintenanceExtractors" | "MaintenancePaymentSession" | "MaintenanceClosing" | "MaintenanceHour" |
"MaintenanceTabEvent" | "MaintenancePrinter" | "MaintenancePrinter" | "MaintenanceToPrint" | "MaintenanceIndicators" | "MaintenanceGeneralInit" |
"MaintenanceRobotInit" | "MaintenanceInit" | "MaintenanceDelivery" | "MaintenanceMajor" | "MaintenanceIHMMarket" | "MaintenanceMessage" | "MaintenanceMineur" | 
"MaintenanceAmount" | "MaintenanceAmount2" | "MaintenanceCleaning" | "MaintenanceNewContact" | "MaintenanceNFC" | "MaintenanceLastName" | "MaintenanceOpening" | "MaintenanceAlarms" | "MaintenanceDeliveryPoint" |
"MaintenanceRestPoint" | "MaintenanceDoor" | "MaintenancePosition" | "MaintenancePositionOf" | "MaintenanceFirstName" | "MaintenanceProf" | "MaintenanceQty" | "MaintenanceUnitTestReset" | 
"MaintenanceEmergencyStop" | "MaintenanceRestart" | "MaintenanceEnter" | "MaintenanceSimulationRobot" | "MaintenanceRobotInit" | "MaintenanceTabRobot" | "MaintenanceRobotSAS" | "MaintenanceDirectionAccumulation" | 
"MaintenanceDirectionDLC" | "MaintenanceDirectionDelivery" | "MaintenanceSimulation" | "MaintenanceExit" | "MaintenanceAcucumulation" | "MaintenanceDeliveryMat" | "MaintenancePhone" | "MaintenanceTimeExt" | "MaintenanceUnblock" | 
"MaintenanceTest" | "MaintenanceUnitTest" | "MaintenanceTabTests" | "MaintenanceCreditCard" | "MaintenanceTicketReader" | "MaintenanceCoinTerminal" | "MaintenanceType" | "MaintenanceType3" | "MaintenanceType2" |
"MaintenanceType1" |"MaintenanceLock" | "MaintenanceIHM" | "MaintenanceVersion" | "MaintenanceStatusInit" | "MaintenanceStatus" | "MaintenancePosition2" | "MaintenanceErrorRobotUnlockDialog" |
"MaintenanceDate" | "MaintenancePartial" | "MaintenanceCashTest" | "MaintenanceMovements" | "MaintenanceDeliveryPoint2" | "MaintenanceSave" | "MaintenanceTitleDialog" | "MaintenanceError1" | "MaintenanceError2" | "MaintenanceError3" |
"MaintenanceErrorDefault" | "MaintenanceCorrect" | "MaintenanceHMIState" | "MaintenanceInitMW232" | "MaintenanceConfirm" | "MaintenanceConfirmDeleteTitle" | "MaintenanceContactBodyConfirm" | "MaintenanceConfirmTitle" | 
"MaintenanceRestartAppsBodyConfirm" | "MaintenanceRestartAppsButton" | "MaintenanceBodyConfirmDelete" | "PlanogramAlertDeleteProdRail" | "MaintenancePlanoProdBodyConfirm" | "MaintenancePlanoBodyConfirm" |
"MaintenanceCoordCalDelivery" | "MaintenanceCoordCalProd" | "MaintenanceCoordCalculerButton" | "MaintenanceCoordCalCopyVal" | "MaintenanceRobotBodyConfirm" | "MaintenanceConfirmChangeTitle" | "MaintenanceRobotSimulationLoop" |
"MaintenanceRobotSimulationOnce" | "MaintenanceRobotSimulationNormal" | "MaintenanceRobotSimulationTiming" | "MaintenanceRobotSimulationEmpty" | "MaintenanceDeliveryDoor" | "MaintenanceTrash" | "MaintenanceNone" | "MaintenanceRobotSimulationIdle" |
"MaintenanceRobotSimulationNone" | "MaintenanceShelvingBodyConfirm" | "MaintenanceTrayBodyConfirm" | "MaintenanceTabLockers" |
/* Params */
"SettingsTitle" | "SettingsBayTable" | "SettingsAccepted" | "SettingsNotAccepted" | "SettingsAccumulation" | "SettingsActions" | "SettingsBanknote" | "SettingsCode" | "SettingsTicketLowComment" | "SettingsShelvingValidation" |
"SettingsTicketComment" | "SettingsTitleConfig" | "SettingsCreate" | "SettingsRecoveryTime" | "SettingsDoorPerProduct" | "SettingsDoorOpenDelay" | "SettingsDoorDelay" |  "SettingsDownloadCsvHistoCron" | "SettingsShelvingTrayValidation" |
"SettingsReturnHome" | "SettingsBayTable2" | "SettingsBadge" | "SettingsBagDispenser" | "SettingsDLCDownload" | "SettingsEdit" | "SettingsExportCron" | "SettingsExtractor" | "SettingsCreateAuto" |
"SettingsFace" | "SettingsTabGeneral" | "SettingsTitleHoppers" | "SettingsTabImport" | "SettingsInterShelving" | "SettingsDaysDLC" | "SettingsDelivery" | "SettingsLengthTable" |
"SettingsMaintenance1" | "SettingsMaintenance2" | "SettingsUpdateLGAP" | "SettingsMinCreditCard" | "SettingsModuleCreditCard" | "SettingsModuleCash" | "SettingsModulePrinter" | "SettingsChange" |
"SettingsTabMoney" | "SettingsTitleCurrency" | "SettingsMW" |  "SettingsMW270" | "SettingsMW270Message" | "SettingsMW270Message2" | "SettingsMW272" | "SettingsMW272Message" | "SettingsMW272Message2" |
"SettingsMW274" | "SettingsMW274Message" | "SettingsMW274Message2" | "SettingsMW280" | "SettingsMW280Message" | "SettingsMW280Message2" | "SettingsMW282" | "SettingsMW282Message" | "SettingsMW282Message2" |
"SettingsMW284" | "SettingsMW284Message" | "SettingsMW284Message2" | "SettingsMWLivraison" | "SettingsMWLivraisonMessage" | "SettingsMWLivraisonMessage2" | "SettingsSecond" | 
"SettingsFirst" | "SettingsLowAlert" | "SettingsLowWarning" | "SettingsHighAlert" | "SettingsHighWarning" | "SettingsNameTable" | "SettingsDeclined" | "SettingsNumber" | "SettingsSerial" |
"SettingsX" | "SettingsZ" | "SettingsTitleGeneral" | "SettingsGeneralInit" | "SettingsTitleSacs" | "SettingsTitleTickets" | "SettingsTitle" | "SettingsHeightTable" | "SettingsCoin" | "SettingsRender" | "SettingsTitleTray" | "SettingsTemp" |
"SettingsDeliveryPosition" | "SettingsRestPosition" | "SettingsPercentageExtractor" | "SettingsTrayTable" | "SettingsDepthTray" | "SettingsMaxQty" | "SettingsAutoLogout" | "SettingsExpiration" | "SettingsProductCodeUsage" | "SettingsMinQty" | 
"SettingsTitleShelving" | "SettingsRepos" | "SettingsTitleRobot" | "SettingsSaveCron" | "SettingsSave" | "SettingsPosition" | "SettingsState" | "SettingsTemperatureAlert" | "SettingsTemperatureWarning" | "SettingsTimePaymentMin" | 
"SettingsTimeSalesMin" | "SettingsTimeCreditCard" | "SettingsTimePaymentCash" | "SettingsToken" | "SettingsVersion" | "SettingsSpeed" | "SettingsMinVolume" | "SettingsSacVolume" | "SettingsStateReceipt" | "SettingsTimeReceiptQR" |
"SettingsDeliveryTimes" | "SettingsBaseTime" | "SettingsDifferentTime" | "SettingsSameTime" | "SettingsCalculatePoints" | "SettingsExploitant" | "SettingsUpdateDateCB" | "SettingsExpirationTime"
| "SettingsTextMaintenance" | "SettingsTextStopped" | "SettingsTextExpiration" | "settingsSmartphoneEnabled" | "settingsEnableSellDDM" | "settingsLangs" | "settingsDefaultLang" | "SettingsTranslationHmi" | "SettingsTabTranslationHmi" |
"SettingsTextHmiName" | "SettingsTextHmiDefault" | "SettingsTextTransHmiLanguage" | "SettingsTextTransHmi" | "SettingsTextTransHmiQuestionDel" | "SettingsTextHmiSave" | "SettingsTextTransHmiCreate" | "SettingsTextTransHmiSave" |
"SettingsTranslationMaintainanceState" | "SettingsTranslationStoppedState" | "SettingsTranslationExpirityState" | "SettingsTranslationHomeMessage" | "SettingsPromoIntervalHmi" | "SettingsOrderTimeoutHmi" | "SettingsErrorNotFound" |
"SettingsHasToken" | "SettingsNoToken" | "SettingsSaveToken" | "SettingsUploadCSVStockTitle" | "SettingsUploadCSVStockButton" | "SettingsRobotSensNormal" | "SettingsRobotSensInverted" | "SettingsCalculatePoints" | "SettingsExpirityTypeNone" |
"SettingsExpirityTypeNormal" | "SettingsExpirityTypeDdm" | "SettingsExpirityTypeEggs" | "SettingsTranslationStoppedState" | "SettingsTranslationExpirityState" | "SettingsTranslationHomeMessage" | "SettingsPromoIntervalHmi" | "SettingsOrderTimeoutHmi" |
"SettingsLocker" | "SettingsIPCard" | "SettingsLockerType" | "SettingsTypeTable" | "SettingsNameExist" | "SettingsIPTable" | "SettingsIPExist" | "SettingsLockerWidthTable" |  "SettingsLockerHeightTable" |  "SettingsLockerDepthTable" |
/* HMI Status */
"HMIStatusIsOff" | "HMIStatusMaintainance" | "HMIStatusStopped" | "HMIStatusOff" | "HMIStatusRunning" | "HMIStatusSelling" | "HMIStatusTest"
;