/**
 * Ximiti API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";
import * as assign from "core-js/library/fn/object/assign";
import { BASE_PATH } from "../utils/utils";

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }


export interface FetchArgs {
    url: string;
    options: any;
}

const isomorphicFetch = fetch;

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface Account {
    "accountId"?: number;
    "enabled"?: boolean;
    "username"?: string;
    "password"?: string;
    "user"?: User;
    "passwordTrys"?: number;
    "lastPasswordTry"?: Date;
    "creationDate"?: Date;
    "entityCustomizations"?: Array<EntityCustomization>;
    "accountRoles"?: Array<AccountRole>;
}

export interface AccountRole {
    "accountRoleId"?: number;
    "roleId"?: number;
    "role"?: Role;
    "accountId"?: number;
    "account"?: Account;
}

export interface Address {
    "addressId"?: number;
    "city"?: string;
    "postalCode"?: string;
    "streetName"?: string;
    "streetNumber"?: string;
    "countryId"?: number;
    "country"?: Country;
    "stores"?: Array<Store>;
}

export interface CalculateTurnoverModel {
    "fromDate"?: Date;
    "toDate"?: Date;
    "cardType"?: CalculateTurnoverModelCardTypeEnum;
    "paymentType"?: CalculateTurnoverModelPaymentTypeEnum;
}

export type CalculateTurnoverModelCardTypeEnum = "Normal" | "NoContact";
export type CalculateTurnoverModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export interface CardHandlerStateModel {
    "version"?: string;
    "state"?: CardHandlerStateModelStateEnum;
    "status"?: CardHandlerStateModelStatusEnum;
    "noContactStatus"?: CardHandlerStateModelNoContactStatusEnum;
    "hasCardInserted"?: boolean;
    "serverCallState"?: CardHandlerStateModelServerCallStateEnum;
    "securityDevices"?: CardHandlerStateModelSecurityDevicesEnum;
}

export type CardHandlerStateModelStateEnum = "Idle" | "Paying" | "RetyingCollection";
export type CardHandlerStateModelStatusEnum = "Ok" | "Unavailable" | "Maintenance" | "Deactivated";
export type CardHandlerStateModelNoContactStatusEnum = "Ok" | "Unavailable" | "Maintenance" | "Deactivated";
export type CardHandlerStateModelServerCallStateEnum = "NoCall" | "Calling" | "CallingParameters";
export type CardHandlerStateModelSecurityDevicesEnum = "AllOk" | "PinPadKo" | "ReaderKo" | "PinPadAndReaderKo";
export interface CashHandlerStateModel {
    "version"?: string;
    "status"?: CashHandlerStateModelStatusEnum;
    "state"?: CashHandlerStateModelStateEnum;
    "cashHopperStates"?: Array<CashHopperStateModel>;
    "coinAcceptorEnabled"?: boolean;
    "billAcceptorEnabled"?: boolean;
    "billAcceptorStatus"?: CashHandlerStateModelBillAcceptorStatusEnum;
    "coinAcceptorStatus"?: CashHandlerStateModelCoinAcceptorStatusEnum;
}

export type CashHandlerStateModelStatusEnum = "Ok" | "NotConnected" | "Error" | "Deactivated";
export type CashHandlerStateModelStateEnum = "Idle" | "Paying";
export type CashHandlerStateModelBillAcceptorStatusEnum = "Ok" | "Error" | "Inhibited" | "Fault" | "Dead" | "Disabled" | "NotFound";
export type CashHandlerStateModelCoinAcceptorStatusEnum = "Ok" | "Error" | "Inhibited" | "Fault" | "Dead" | "Disabled" | "NotFound";
export interface CashHopperStateModel {
    "serialNumber"?: number;
    "status"?: CashHopperStateModelStatusEnum;
    "value"?: number;
    "quantity"?: number;
}

export type CashHopperStateModelStatusEnum = "Ok" | "Empty" | "Error" | "Inhibited" | "Jammed" | "Blocked";
export interface CollectionCardCompletedModel {
    "hmiId"?: number;
    "sessionId"?: string;
    "amount"?: number;
    "label"?: string;
    "info"?: string;
}

export interface Contact {
    "contactId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "email"?: string;
    "phone"?: string;
    "creationDate"?: Date;
    "type"?: ContactTypeEnum;
    "neoEventAlarmContacts"?: Array<NeoEventAlarmContact>;
}

export type ContactTypeEnum = "ContactOnError" | "ContactOnlyAlarm";
export interface ContactModel {
    "contactId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "email"?: string;
    "phone"?: string;
    "creationDate"?: Date;
    "type"?: ContactModelTypeEnum;
}

export type ContactModelTypeEnum = "ContactOnError" | "ContactOnlyAlarm";
export interface Country {
    "countryId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "languageId"?: number;
    "language"?: Language;
    "countryVats"?: Array<CountryVat>;
    "addresses"?: Array<Address>;
}

export interface CountryVat {
    "countryVatId"?: number;
    "vatId"?: number;
    "vat"?: Vat;
    "countryId"?: number;
    "country"?: Country;
}

export interface CountryVatModel {
    "countryVatId"?: number;
    "vatId"?: number;
    "countryId"?: number;
}

export interface CreateCommandModel {
    "discountId"?: number;
    "products"?: Array<ProductCommandModel>;
    "menus"?: Array<MenuCommandModel>;
    "platform"?: SmPlatformModel;
    "total"?: number;
    "code"?: string;
    "paymentState"?: CreateCommandModelPaymentStateEnum;
}

export type CreateCommandModelPaymentStateEnum = "Paid" | "PaymentFailed";
export interface CreateNeoEventModel {
    "message"?: string;
    "code"?: number;
    "productRailId"?: number;
    "paramsMessage"?:string;
}

export interface CreateProductReceptionModel {
    "quantity"?: number;
    "productSupplierId"?: number;
    "productId"?: number;
    "supplierOrderId"?: number;
    "externalProductId"?: number;
    "rex9AOrder"?: number;
    "rex9ALine"?: number;
}

export interface CreateProductReceptionsModel {
    "createProductReceptions"?: Array<CreateProductReceptionModel>;
}

export interface CreateProductStockResultModel {
    "productStocks"?: Array<ProductStockModel>;
    "productReceptions"?: Array<ProductReceptionModel>;
    "productReceptionCreated"?: ProductReceptionModel;
}

export interface CreateProductStocksModel {
    "expirity"?: Date;
    "productRailId"?: number;
    "productSupplierId"?: number;
    "quantity"?: number;
}

export interface CreateVouchersModel {
    "voucherCampaignId"?: number;
    "amountType"?: CreateVouchersModelAmountTypeEnum;
    "startDate"?: Date;
    "endDate"?: Date;
    "amount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "maxUse"?: number;
    "number"?: number;
    "codes"?: string;
}

export type CreateVouchersModelAmountTypeEnum = "Flat" | "Percentage";
export interface Currency {
    "currencyId"?: number;
    "name"?: string;
    "code"?: string;
    "symbol"?: string;
    "value"?: number;
    "creationDate"?: Date;
    "stores"?: Array<Store>;
    "moneys"?: Array<Money>;
    "prices"?: Array<Price>;
    "productSupplierBuyPrices"?: Array<ProductSupplier>;
    "submissionCurrencies"?: Array<SubmissionCurrency>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
    "supplierVolumeDiscountUnits"?: Array<SupplierVolumeDiscount>;
}

export interface CurrencyModel {
    "currencyId"?: number;
    "name"?: string;
    "code"?: string;
    "symbol"?: string;
    "value"?: number;
}

export interface CurrentUserManagerModel {
    "userManager"?: UserStoreModel;
    "expiration"?: Date;
}

export interface DayDataSnapshot {
    "dayDataSnapshotId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "dayProductStocks"?: Array<DayProductStock>;
    "dayMoneyStocks"?: Array<DayMoneyStock>;
    "dayProductReceptions"?: Array<DayProductReception>;
}

export interface DayMoneyStock {
    "dayMoneyStockId"?: number;
    "quantity"?: number;
    "creationDate"?: Date;
    "dayDataSnapshotId"?: number;
    "dayDataSnapshot"?: DayDataSnapshot;
    "moneyId"?: number;
    "money"?: Money;
    "hmiMoneyHopperId"?: number;
    "hmiMoneyHopper"?: HmiMoneyHopper;
}

export interface DayProductReception {
    "dayProductReceptionId"?: number;
    "type"?: DayProductReceptionTypeEnum;
    "quantityRemaining"?: number;
    "creationDate"?: Date;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "productId"?: number;
    "product"?: Product;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "dayDataSnapshotId"?: number;
    "dayDataSnapshot"?: DayDataSnapshot;
}

export type DayProductReceptionTypeEnum = "Delivery" | "Unstock" | "Unknown";
export interface DayProductStock {
    "dayProductStockId"?: number;
    "quantity"?: number;
    "expirity"?: Date;
    "creationDate"?: Date;
    "productId"?: number;
    "product"?: Product;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "productRailId"?: number;
    "productRail"?: ProductRail;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "dayDataSnapshotId"?: number;
    "dayDataSnapshot"?: DayDataSnapshot;
}

export interface DayReorderDataModel {
    "dayStoreDataId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "storeId"?: number;
    "storeOrders"?: Array<StoreOrderModel>;
    "storeStockOperations"?: Array<StoreStockOperationModel>;
    "storeProductReceptions"?: Array<StoreProductReceptionModel>;
}

export interface DayStoreData {
    "dayStoreDataId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "storeId"?: number;
    "store"?: Store;
    "storeOrders"?: Array<StoreOrder>;
    "storeStockOperations"?: Array<StoreStockOperation>;
    "storeProductReceptions"?: Array<StoreProductReception>;
    "storeNeoEvents"?: Array<StoreNeoEvent>;
    "storeMoneyTraffics"?: Array<StoreMoneyTraffic>;
}

export interface DayStoreDataModel {
    "dayStoreDataId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "storeId"?: number;
    "storeOrders"?: Array<StoreOrderModel>;
    "storeStockOperations"?: Array<StoreStockOperationModel>;
    "storeProductReceptions"?: Array<StoreProductReceptionModel>;
    "storeNeoEvents"?: Array<StoreNeoEventModel>;
    "storeMoneyTraffics"?: Array<StoreMoneyTrafficModel>;
    "storeHmiMoneyResets"?: Array<StoreHmiMoneyResetModel>;
    "storeRemoteCollections"?: Array<StoreRemoteCollectionModel>;
    "storeDayDataSnapshot"?: StoreDayDataSnapshotModel;
}

export interface DeleteProductStockResultModel {
    "productStocks"?: Array<ProductStockModel>;
    "productReceptions"?: Array<ProductReceptionModel>;
}

export interface DeleteProductStocksModel {
    "productRailId"?: number;
    "quantity"?: number;
}

export interface DeleteQuantityRemainingModel {
    "productId"?: number;
    "quantity"?: number;
}

export interface DeliverProductsModel {
    "destination"?: DeliverProductsModelDestinationEnum;
    "isLastStep"?: boolean;
    "includeBag"?: boolean;
    "productOrders"?: Array<ProductOrderModel>;
}

export type DeliverProductsModelDestinationEnum = "DeliveryDoor" | "Trash" | "None";
export interface Delivery {
    "deliveryId"?: number;
    "type"?: DeliveryTypeEnum;
    "uniqueId"?: string;
    "state"?: DeliveryStateEnum;
    "includeBags"?: boolean;
    "completionDate"?: Date;
    "creationDate"?: Date;
    "orderId"?: number;
    "order"?: Order;
    "deliveryStationId"?: number;
    "deliveryStation"?: DeliveryStation;
    "deliverySteps"?: Array<DeliveryStep>;
}

export type DeliveryTypeEnum = "Order" | "Expirity" | "Test";
export type DeliveryStateEnum = "Initialized" | "Running" | "Stopped";
export interface DeliveryProduct {
    "deliveryProductId"?: number;
    "quantityExpected"?: number;
    "creationDate"?: Date;
    "deliveryStepId"?: number;
    "deliveryStep"?: DeliveryStep;
    "productId"?: number;
    "product"?: Product;
    "productOrderId"?: number;
    "productOrder"?: ProductOrder;
    "productRailId"?: number;
    "productRail"?: ProductRail;
    "deliveryProductStocks"?: Array<DeliveryProductStock>;
}

export interface DeliveryProductResultModel {
    "railCoord"?: RailCoordModel;
    "eventCode"?: number;
    "quantityUnStocked"?: number;
    "railBlocked"?: boolean;
    "status"?: DeliveryProductResultModelStatusEnum;
}

export type DeliveryProductResultModelStatusEnum = "Ok" | "Failed" | "FailedExtraction" | "FailedRamp" | "FailedSas" | "Partial";
export interface DeliveryProductStock {
    "deliveryProductStockId"?: number;
    "status"?: DeliveryProductStockStatusEnum;
    "creationDate"?: Date;
    "neoEventCodeId"?: number;
    "neoEventCode"?: NeoEventCode;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "deliveryProductId"?: number;
    "deliveryProduct"?: DeliveryProduct;
}

export type DeliveryProductStockStatusEnum = "Ok" | "Failed" | "FailedExtraction" | "FailedRamp" | "FailedSas" | "Partial";
export interface DeliveryResultModel {
    "startDate"?: Date;
    "sessionId"?: string;
    "status"?: DeliveryResultModelStatusEnum;
    "deliveryProductResults"?: Array<DeliveryProductResultModel>;
}

export type DeliveryResultModelStatusEnum = "Ok" | "Failed" | "FailedAndCollect" | "FailedAndContinue";
export interface DeliveryStation {
    "deliveryStationId"?: number;
    "name"?: string;
    "code"?: string;
    "enabled"?: boolean;
    "warnTemp"?: number;
    "alertTemp"?: number;
    "bagDispenserEnabled"?: boolean;
    "bagCbcm"?: number;
    "deliveryTimeout"?: number;
    "availabilityStatus"?: DeliveryStationAvailabilityStatusEnum;
    "expirityCheckCron"?: string;
    "creationDate"?: Date;
    "store"?: Store;
    "storeId"?: number;
    "robotId"?: number;
    "robot"?: Robot;
}

export type DeliveryStationAvailabilityStatusEnum = "Available" | "TechnicalIssues" | "TurnedOff";
export interface DeliveryStationModel {
    "deliveryStationId"?: number;
    "name"?: string;
    "code"?: string;
    "enabled"?: boolean;
    "warnTemp"?: number;
    "alertTemp"?: number;
    "deliveryTimeout"?: number;
    "bagDispenserEnabled"?: boolean;
    "bagCbcm"?: number;
    "availabilityStatus"?: DeliveryStationModelAvailabilityStatusEnum;
    "creationDate"?: Date;
    "robotId"?: number;
    "robotDeliveryTimeBase"?: number;
    "robotDeliveryTimeDifferentProducts"?: number;
    "robotDeliveryTimeSameProducts"?: number;
}

export type DeliveryStationModelAvailabilityStatusEnum = "Available" | "TechnicalIssues" | "TurnedOff";
export interface DeliveryStep {
    "deliveryStepId"?: number;
    "state"?: DeliveryStepStateEnum;
    "status"?: DeliveryStepStatusEnum;
    "deliveryId"?: number;
    "delivery"?: Delivery;
    "completionDate"?: Date;
    "startDate"?: Date;
    "creationDate"?: Date;
    "deliveryProducts"?: Array<DeliveryProduct>;
}

export type DeliveryStepStateEnum = "Initialized" | "Running" | "Stopped";
export type DeliveryStepStatusEnum = "Ok" | "Failed" | "FailedAndCollect" | "FailedAndContinue";
export interface EntityCustomization {
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface ExternalProduct {
    "remoteId"?: string;
    "practicUnit"?: number;
    "conversionFactor"?: number;
    "externalProductLanguageNames"?: Array<ExternalProductLanguageName>;
    "productBarcodes"?: Array<ProductBarcode>;
    "productSuppliers"?: Array<ProductSupplier>;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityType"?: ExternalProductExpirityTypeEnum;
    "stability"?: ExternalProductStabilityEnum;
    "volume"?: number;
    "volumeUnitId"?: number;
    "volumeUnit"?: Unit;
    "relativeUnitId"?: number;
    "relativeUnit"?: Unit;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: ExternalProductHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ExternalProductSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ExternalProductSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: Image;
    "defaultPriceNameId"?: number;
    "defaultPriceName"?: PriceName;
    "prices"?: Array<Price>;
    "storeItemStorePayments"?: Array<StoreItemStorePayment>;
    "storeItemTags"?: Array<StoreItemTag>;
}

export type ExternalProductExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ExternalProductStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ExternalProductHmiVisibilityEnum = "Hide" | "Visible";
export type ExternalProductSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ExternalProductSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";

export interface ExternalProductLanguageName {
    "externalProductLanguageNameId"?: number;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "languageId"?: number;
    "language"?: Language;
    "name"?: string;
    "comment"?: string;
}

export interface ExternalProductLanguageNameModel {
    "externalProductLanguageNameId"?: number;
    "externalProductId"?: number;
    "languageId"?: number;
    "name"?: string;
    "comment"?: string;
}

export interface ExternalProductModel {
    "remoteId"?: string;
    "practicUnit"?: number;
    "conversionFactor"?: number;
    "externalProductLanguageNames"?: Array<ExternalProductLanguageNameModel>;
    "productBarcodes"?: Array<ProductBarcodeModel>;
    "productSuppliers"?: Array<ProductSupplier>;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityType"?: ExternalProductModelExpirityTypeEnum;
    "stability"?: ExternalProductModelStabilityEnum;
    "volumeUnitId"?: number;
    "relativeUnitId"?: number;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: ExternalProductModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ExternalProductModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ExternalProductModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
}

export type ExternalProductModelExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ExternalProductModelStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ExternalProductModelHmiVisibilityEnum = "Hide" | "Visible";
export type ExternalProductModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ExternalProductModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";

export interface ExtractorBeltModel {
    "extractorNumber"?: number;
    "selectedBelts"?: Array<number>;
}

export interface ExtractorData {
    "isExtracted"?: boolean;
    "hasItemDetected"?: boolean;
    "sens"?: ExtractorDataSensEnum;
    "isRunning"?: boolean;
    "belts"?: Array<number>;
}

export type ExtractorDataSensEnum = "Normal" | "Inverted";
export interface Family {
    "familyId"?: number;
    "name"?: string;
    "order"?: number;
    "imageId"?: number;
    "image"?: Image;
    "creationDate"?: Date;
    "familyCustoms"?: Array<FamilyCustom>;
    "subFamilies"?: Array<SubFamily>;
    "submissionFamilies"?: Array<SubmissionFamily>;
}

export interface FamilyCustom {
    "familyId"?: number;
    "family"?: Family;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface FamilyCustomModel {
    "familyId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface FamilyModel {
    "familyId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "order"?: number;
    "creationDate"?: Date;
    "familyCustoms"?: Array<FamilyCustomModel>;
}

export interface GetHistoryModel {
    "fromDate"?: Date;
    "toDate"?: Date;
}

export interface HistoCAModel { 
    "histoCAId"?: number;
    "dateCA"?: Date;
    "nbTransac"?: number;
    "tvA1"?: string;
    "tvA2"?: string;
    "tvA3"?: string;
    "tvA4"?: string;
    "tvA5"?: string;
    "cA_Especes_TVA1"?: string;
    "cA_Especes_TVA2"?: string;
    "cA_Especes_TVA3"?: string;
    "cA_Especes_TVA4"?: string;
    "cA_Especes_TVA5"?: string;
    "cA_CB_TVA1"?: string;
    "cA_CB_TVA2"?: string;
    "cA_CB_TVA3"?: string;
    "cA_CB_TVA4"?: string;
    "cA_CB_TVA5"?: string;
    "cA_NFC_TVA1"?: string;
    "cA_NFC_TVA2"?: string;
    "cA_NFC_TVA3"?: string;
    "cA_NFC_TVA4"?: string;
    "cA_NFC_TVA5"?: string;
    "cA_SMART_TVA1"?: string;
    "cA_SMART_TVA2"?: string;
    "cA_SMART_TVA3"?: string;
    "cA_SMART_TVA4"?: string;
    "cA_SMART_TVA5"?: string;
    "cA_EXTERNE_TVA1"?: string;
    "cA_EXTERNE_TVA2"?: string;
    "cA_EXTERNE_TVA3"?: string;
    "cA_EXTERNE_TVA4"?: string;
    "cA_EXTERNE_TVA5"?: string;
    "cA_HT"?: string;
    "cA_TTC"?: string;
    "marge"?: string;
    "cA_HTTVA1"?: string;
    "cA_HTTVA2"?: string;
    "cA_HTTVA3"?: string;
    "cA_HTTVA4"?: string;
    "cA_HTTVA5"?: string;
}

export interface Hmi {
    "hmiId"?: number;
    "name"?: string;
    "authenticationKey"?: string;
    "availabilityStatus"?: HmiAvailabilityStatusEnum;
    "creationDate"?: Date;
    "languageId"?: number;
    "language"?: Language;
    "hmiMoney"?: HmiMoney;
    "orders"?: Array<Order>;
    "sales"?: Array<Sale>;
    "hmiState"?: HmiHmiStateEnum;
}

export type HmiAvailabilityStatusEnum = "Available" | "TechnicalIssues" | "TurnedOff";
export type HmiHmiStateEnum = "Off" | "Running" | "Selling" | "Maintainance" | "Stopped" | "Test" | "Expirity";

export interface HmiApplicationStateModel {
    "hmiId"?: number;
    "version"?: string;
    "applicationState"?: HmiApplicationStateModelApplicationStateEnum;
}

export type HmiApplicationStateModelApplicationStateEnum = "Off" | "Running" | "Selling" | "Maintainance" | "Stopped" | "Test" | "Expirity";
export interface HmiHopperTrafficModel {
    "hmiMoneyHopperId"?: number;
    "quantity"?: number;
    "completeEmptying"?: boolean;
}

export interface HmiModel {
    "hmiId"?: number;
    "name"?: string;
    "availabilityStatus"?: HmiModelAvailabilityStatusEnum;
    "creationDate"?: Date;
    "languageId"?: number;
    "hmiMoney"?: HmiMoneyModel;
}

export type HmiModelAvailabilityStatusEnum = "Available" | "TechnicalIssues" | "TurnedOff";
export interface HmiMoney {
    "hmiMoneyId"?: number;
    "paymentCashTimeout"?: number;
    "paymentCardTimeout"?: number;
    "cardMinAmount"?: number;
    "changeCeiling"?: number;
    "creditCardType"?: HmiMoneyCreditCardTypeEnum;
    "printerType"?: HmiMoneyPrinterTypeEnum;
    "cashEnabled"?: boolean;
    "cashBillEnabled"?: boolean;
    "cardEnabled"?: boolean;
    "noContactEnabled"?: boolean;
    "printEnabled"?: boolean;
    "cashType"?: HmiMoneyCashTypeEnum;
    "hmi"?: Hmi;
    "hmiId"?: number;
    "hmiMoneyHoppers"?: Array<HmiMoneyHopper>;
    "hmiMoneyMoneys"?: Array<HmiMoneyMoney>;
}

export type HmiMoneyCreditCardTypeEnum = "Rs232";
export type HmiMoneyPrinterTypeEnum = "Pmu";
export type HmiMoneyCashTypeEnum = "Paylink";
export interface HmiMoneyHopper {
    "hmiMoneyHopperId"?: number;
    "name"?: string;
    "number"?: number;
    "lowQuantity"?: number;
    "lowQuantityAlert"?: number;
    "highQuantity"?: number;
    "highQuantityAlert"?: number;
    "quantity"?: number;
    "serialNumber"?: string;
    "moneyId"?: number;
    "money"?: Money;
    "hmiMoneyId"?: number;
    "hmiMoney"?: HmiMoney;
    "hmiMoneyHopperMoneys"?: Array<HmiMoneyHopperMoney>;
    "moneyTraffics"?: Array<MoneyTraffic>;
    "dayMoneyStocks"?: Array<DayMoneyStock>;
}

export interface HmiMoneyHopperModel {
    "hmiMoneyHopperId"?: number;
    "name"?: string;
    "number"?: number;
    "lowQuantity"?: number;
    "lowQuantityAlert"?: number;
    "highQuantity"?: number;
    "highQuantityAlert"?: number;
    "quantity"?: number;
    "moneyId"?: number;
    "money"?: MoneyModel;
    "hmiMoneyId"?: number;
    "serialNumber"?: string;
}

export interface HmiMoneyHopperMoney {
    "hmiMoneyHopperMoneyId"?: number;
    "moneyId"?: number;
    "money"?: Money;
    "hmiMoneyHopperId"?: number;
    "hmiMoneyHopper"?: HmiMoneyHopper;
}

export interface HmiMoneyModel {
    "hmiMoneyId"?: number;
    "paymentCashTimeout"?: number;
    "paymentCardTimeout"?: number;
    "cardMinAmount"?: number;
    "changeCeiling"?: number;
    "creditCardType"?: HmiMoneyModelCreditCardTypeEnum;
    "printerType"?: HmiMoneyModelPrinterTypeEnum;
    "cashEnabled"?: boolean;
    "cashBillEnabled"?: boolean;
    "cardEnabled"?: boolean;
    "noContactEnabled"?: boolean;
    "printEnabled"?: boolean;
    "cashType"?: HmiMoneyModelCashTypeEnum;
    "hmiMoneyHoppers"?: Array<HmiMoneyHopperModel>;
    "hmiMoneyMoneys"?: Array<HmiMoneyMoneyModel>;
}

export type HmiMoneyModelCreditCardTypeEnum = "Rs232";
export type HmiMoneyModelPrinterTypeEnum = "Pmu";
export type HmiMoneyModelCashTypeEnum = "Paylink";
export interface HmiMoneyMoney {
    "hmiMoneyMoneyId"?: number;
    "hmiMoneyId"?: number;
    "hmiMoney"?: HmiMoney;
    "moneyId"?: number;
    "money"?: Money;
    "status"?: HmiMoneyMoneyStatusEnum;
}

export type HmiMoneyMoneyStatusEnum = "Unavailable" | "Available";
export interface HmiMoneyMoneyModel {
    "hmiMoneyMoneyId"?: number;
    "hmiMoneyId"?: number;
    "moneyId"?: number;
    "money"?: MoneyModel;
    "status"?: HmiMoneyMoneyModelStatusEnum;
}

export type HmiMoneyMoneyModelStatusEnum = "Unavailable" | "Available";
export interface HmiMoneyReset {
    "hmiMoneyResetId"?: number;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "creationDate"?: Date;
    "hmiMoneyResetMoneys"?: Array<HmiMoneyResetMoney>;
}

export interface HmiMoneyResetModel {
    "hmiMoneyResetId"?: number;
    "storeAccountId"?: number;
    "creationDate"?: Date;
    "hmiMoneyResetMoneys"?: Array<HmiMoneyResetMoneyModel>;
}

export interface HmiMoneyResetMoney {
    "hmiMoneyResetMoneyId"?: number;
    "quantity"?: number;
    "hmiMoneyResetId"?: number;
    "hmiMoneyReset"?: HmiMoneyReset;
    "moneyId"?: number;
    "money"?: Money;
}

export interface HmiMoneyResetMoneyModel {
    "hmiMoneyResetMoneyId"?: number;
    "quantity"?: number;
    "hmiMoneyResetId"?: number;
    "moneyId"?: number;
}

export interface HmiMoneyTrafficModel {
    "moneyInfo"?: MoneyInfo;
    "hmiId"?: number;
    "hopperInfo"?: HopperInfo;
    "quantity"?: number;
    "type"?: HmiMoneyTrafficModelTypeEnum;
}

export type HmiMoneyTrafficModelTypeEnum = "PaymentIn" | "PaymentOut" | "ManagerIn" | "ManagerOut";
export interface HopperInfo {
    "moneyType"?: HopperInfoMoneyTypeEnum;
    "value"?: number;
    "serialNumber"?: string;
}

export type HopperInfoMoneyTypeEnum = "Coin" | "Bill";
export interface Image {
    "imageId"?: number;
    "fileName"?: string;
    "md5"?: string;
    "creationDate"?: Date;
    "itemCustomizations"?: Array<EntityCustomization>;
    "families"?: Array<Family>;
    "subFamilies"?: Array<SubFamily>;
    "storeItems"?: Array<StoreItem>;
    "promotions"?: Array<Promotion>;
}

export interface ImageModel {
    "imageId"?: number;
    "fileName"?: string;
    "md5"?: string;
    "creationDate"?: Date;
}

export interface InterventionModel {
    "interventionId"?: number;
    "userManagerId"?: number;
    "userManager"?: UserStore;
    "messageIntervention"?: string;
    "typeMessage"?: InterventionModelTypeMessageEnum;
    "statusIntervention"?: InterventionModelStatusInterventionEnum;
    "creationDate"?: Date;
}

export type InterventionModelTypeMessageEnum = "Monetic" | "Robot" | "Informatic" | "NewFeature" | "Other";
export type InterventionModelStatusInterventionEnum = "Open" | "Close";
export interface ItemExpirityOfferModel {
    "itemOfferId"?: number;
    "storeItemId"?: number;
    "priceNameId"?: number;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "daysBeforeExpirity"?: number;
    "creationDate"?: Date;
}

export interface ItemOfTheDayModel {
    "itemOfTheDayId"?: number;
    "storeItemId"?: number;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "creationDate"?: Date;
}

export interface ItemOfferResultModel {
    "itemPromotions"?: { [key: string]: Array<ItemPromotionModel>; };
    "itemOfTheDay"?: ItemOfTheDayModel;
    "itemExpirityOffers"?: { [key: string]: Array<ItemExpirityOfferModel>; };
}

export interface ItemPromotionModel {
    "itemOfferId"?: number;
    "storeItemId"?: number;
    "priceNameId"?: number;
    "promotionId"?: number;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "creationDate"?: Date;
}

export interface Language {
    "languageId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "users"?: Array<User>;
    "stores"?: Array<Store>;
    "countries"?: Array<Country>;
    "status"?: LanguageStatusEnum;
    "entityCustomizations"?: Array<EntityCustomization>;
}

export type LanguageStatusEnum = "Unavailable" | "Available";
export interface LanguageModel {
    "languageId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "status"?: LanguageStatusEnum;
}

export interface LastPaymentsModel {
    "cashPayment"?: SalePaymentModel;
    "cardPayment"?: SalePaymentModel;
    "cardNoContactPayment"?: SalePaymentModel;
}

export interface Lock { 
    "lockId"?: number;
    "lockNumber"?: number;
}

export interface Locker { 
    "lockerId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "lockerTypeId"?: number;
    "lockerType"?: LockerType;
    "productLockers"?: Array<ProductLocker>;
}

export interface LockerAddress { 
    "lockerAddressId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "ipAddress"?: string;
    "productLockers"?: Array<ProductLocker>;
}

export interface LockerAddressModel { 
    "lockerAddressId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "ipAddress"?: string;
}

export interface LockerModel { 
    "lockerId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "lockerTypeId"?: number;
}

export interface LockModel { 
    "lockId"?: number;
    "lockNumber"?: number;
}

export interface LockerType { 
    "lockerTypeId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "width"?: number;
    "height"?: number;
    "depth"?: number;
    "lockers"?: Array<Locker>;
}

export interface LockerTypeModel { 
    "lockerTypeId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "name"?: string;
    "width"?: number;
    "height"?: number;
    "depth"?: number;
}

export interface LogModel {
    "content"?: string;
}

export interface LoggedTemp {
    "temperature"?: number;
    "timeStamp"?: Date;
}

export interface LoginModel {
    "username"?: string;
    "password"?: string;
}

export interface Manufacturer {
    "manufacturerId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "products"?: Array<Product>;
}

export interface Menu {
    "hmiEnabled"?: boolean;
    "order"?: number;
    "menuCategoryMenus"?: Array<MenuCategoryMenu>;
    "submissionMenus"?: Array<SubmissionMenu>;
    "storeOrderMenuOrders"?: Array<StoreOrderMenuOrder>;
    "menuStores"?: Array<MenuStore>;
    "menuCustoms"?: Array<MenuCustom>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: MenuHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: MenuSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: Image;
    "defaultPriceNameId"?: number;
    "defaultPriceName"?: PriceName;
    "prices"?: Array<Price>;
    "storeItemStorePayments"?: Array<StoreItemStorePayment>;
    "storeItemTags"?: Array<StoreItemTag>;
}

export type MenuHmiVisibilityEnum = "Hide" | "Visible";
export type MenuSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type MenuSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface MenuCategory {
    "menuCategoryId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "productMenuCategories"?: Array<ProductMenuCategory>;
    "menuCategoryMenus"?: Array<MenuCategoryMenu>;
    "submissionMenuCategories"?: Array<SubmissionMenuCategory>;
    "storeOrderProductOrders"?: Array<StoreOrderProductOrder>;
    "menuCategoryStores"?: Array<MenuCategoryStore>;
    "menuCategoryTranslations"?: Array<MenuCategoryTranslation>;
}

export interface MenuCategoryMenu {
    "menuCategoryMenuId"?: number;
    "order"?: number;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "menuId"?: number;
    "menu"?: Menu;
}

export interface MenuCategoryMenuModel {
    "menuCategoryMenuId"?: number;
    "order"?: number;
    "menuCategoryId"?: number;
    "menuId"?: number;
}

export interface MenuCategoryModel {
    "menuCategoryId"?: number;
    "name"?: string;
    "menuCategoryStores"?: Array<MenuCategoryStoreModel>;
    "menuCategoryTranslations"?: Array<MenuCategoryTranslationModel>;
}

export interface MenuCategoryStore {
    "menuCategoryStoreId"?: number;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "storeId"?: number;
    "store"?: Store;
}

export interface MenuCategoryStoreModel {
    "menuCategoryStoreId"?: number;
    "menuCategoryId"?: number;
    "storeId"?: number;
}

export interface MenuCategoryTranslation {
    "menuCategoryTranslationId"?: number;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "languageId"?: number;
    "language"?: Language;
    "translation"?: string;
}

export interface MenuCategoryTranslationModel {
    "menuCategoryTranslationId"?: number;
    "menuCategoryId"?: number;
    "languageId"?: number;
    "translation"?: string;
}

export interface MenuCommandModel {
    "menuId"?: number;
    "menuUniqueId"?: string;
    "quantity"?: number;
    "price"?: number;
}

export interface MenuCustom {
    "menuId"?: number;
    "menu"?: Menu;
    "comment"?: string;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface MenuCustomModel {
    "menuId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface MenuModel {
    "hmiEnabled"?: boolean;
    "order"?: number;
    "menuCategoryMenus"?: Array<MenuCategoryMenuModel>;
    "menuStores"?: Array<MenuStoreModel>;
    "menuCustoms"?: Array<MenuCustomModel>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: MenuModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: MenuModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
}

export type MenuModelHmiVisibilityEnum = "Hide" | "Visible";
export type MenuModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type MenuModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface MenuOrder {
    "menuId"?: number;
    "menu"?: Menu;
    "productOrders"?: Array<ProductOrder>;
    "storeItemOrderId"?: number;
    "source"?: MenuOrderSourceEnum;
    "quantity"?: number;
    "creationDate"?: Date;
    "priceId"?: number;
    "price"?: Price;
    "orderId"?: number;
    "order"?: Order;
}

export type MenuOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface MenuStore {
    "menuStoreId"?: number;
    "hmiVisibility"?: MenuStoreHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuStoreSmartPhoneClientVisibilityEnum;
    "menu"?: Menu;
    "menuId"?: number;
    "store"?: Store;
    "storeId"?: number;
}

export type MenuStoreHmiVisibilityEnum = "Hide" | "Visible";
export type MenuStoreSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export interface MenuStoreModel {
    "menuStoreId"?: number;
    "hmiVisibility"?: MenuStoreModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuStoreModelSmartPhoneClientVisibilityEnum;
    "menuId"?: number;
    "storeId"?: number;
}

export type MenuStoreModelHmiVisibilityEnum = "Hide" | "Visible";
export type MenuStoreModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export interface Money {
    "moneyId"?: number;
    "name"?: string;
    "value"?: number;
    "type"?: MoneyTypeEnum;
    "currencyId"?: number;
    "currency"?: Currency;
    "storeMonies"?: Array<StoreMoney>;
    "storeMoneyTraffics"?: Array<StoreMoneyTraffic>;
}

export type MoneyTypeEnum = "Coin" | "Bill";
export interface MoneyInfo {
    "value"?: number;
    "type"?: MoneyInfoTypeEnum;
}

export type MoneyInfoTypeEnum = "Coin" | "Bill";
export interface MoneyModel {
    "moneyId"?: number;
    "name"?: string;
    "value"?: number;
    "type"?: MoneyModelTypeEnum;
    "currencyId"?: number;
}

export type MoneyModelTypeEnum = "Coin" | "Bill";
export interface MoneyTraffic {
    "moneyTrafficId"?: number;
    "type"?: MoneyTrafficTypeEnum;
    "quantity"?: number;
    "salePaymentCashId"?: number;
    "salePaymentCash"?: SalePaymentCash;
    "moneyId"?: number;
    "money"?: Money;
    "hmiMoneyHopperId"?: number;
    "hmiMoneyHopper"?: HmiMoneyHopper;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "creationDate"?: Date;
}

export type MoneyTrafficTypeEnum = "PaymentIn" | "PaymentOut" | "ManagerIn" | "ManagerOut";
export interface NeoEvent {
    "neoEventId"?: number;
    "message"?: string;
    "creationDate"?: Date;
    "neoEventCodeId"?: number;
    "neoEventCode"?: NeoEventCode;
    "neoEventAlarmId"?: number;
    "neoEventAlarm"?: NeoEventAlarm;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "productRailId"?: number;
    "productRail"?: ProductRail;
    "acquitted"?: Date;
}

export interface NeoEventAlarm {
    "neoEventAlarmId"?: number;
    "type"?: NeoEventAlarmTypeEnum;
    "action"?: NeoEventAlarmActionEnum;
    "source"?: NeoEventAlarmSourceEnum;
    "creationDate"?: Date;
    "neoEventCodeId"?: number;
    "neoEventCode"?: NeoEventCode;
    "neoEventAlarmContacts"?: Array<NeoEventAlarmContact>;
    "neoEventAlarmSents"?: Array<NeoEventAlarmSent>;
    "neoEventAlarms"?: Array<NeoEventAlarm>;
    "neoEvents"?: Array<NeoEvent>;
}

export type NeoEventAlarmTypeEnum = "Minor" | "Major";
export type NeoEventAlarmActionEnum = "None" | "DisableHmis" | "DisableCash" | "DisableCc" | "DisablePrinter" | "DisableCashBill";
export type NeoEventAlarmSourceEnum = "User" | "Application";
export interface NeoEventAlarmContact {
    "neoEventAlarmContactId"?: number;
    "creationDate"?: Date;
    "contactId"?: number;
    "contact"?: Contact;
    "neoEventAlarmId"?: number;
    "neoEventAlarm"?: NeoEventAlarm;
    "neoEventAlarmSents"?: Array<NeoEventAlarmSent>;
}

export interface NeoEventAlarmContactModel {
    "neoEventAlarmContactId"?: number;
    "creationDate"?: Date;
    "contactId"?: number;
    "neoEventAlarmId"?: number;
}

export interface NeoEventAlarmModel {
    "neoEventAlarmId"?: number;
    "type"?: NeoEventAlarmModelTypeEnum;
    "action"?: NeoEventAlarmModelActionEnum;
    "source"?: NeoEventAlarmModelSourceEnum;
    "neoEventCodeId"?: number;
    "creationDate"?: Date;
    "neoEventAlarmContacts"?: Array<NeoEventAlarmContactModel>;
    "neoEventAlarmSents"?: Array<NeoEventAlarmSentModel>;
}

export type NeoEventAlarmModelTypeEnum = "Minor" | "Major";
export type NeoEventAlarmModelActionEnum = "None" | "DisableHmis" | "DisableCash" | "DisableCc" | "DisablePrinter" | "DisableCashBill";
export type NeoEventAlarmModelSourceEnum = "User" | "Application";
export interface NeoEventAlarmSent {
    "neoEventAlarmSentId"?: number;
    "creationDate"?: Date;
    "neoEventAlarmId"?: number;
    "neoEventAlarm"?: NeoEventAlarm;
    "neoEventAlarmContactId"?: number;
    "neoEventAlarmContact"?: NeoEventAlarmContact;
}

export interface NeoEventAlarmSentModel {
    "neoEventAlarmSentId"?: number;
    "neoEventAlarmId"?: number;
    "neoEventAlarmContactId"?: number;
}

export interface NeoEventCode {
    "neoEventCodeId"?: number;
    "name"?: string;
    "description"?: string;
    "code"?: number;
    "source"?: NeoEventCodeSourceEnum;
    "type"?: NeoEventCodeTypeEnum;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDelivery>;
    "storeNeoEvents"?: Array<StoreNeoEvent>;
}

export type NeoEventCodeSourceEnum = "Status" | "Management" | "Client" | "Robot" | "Computer" | "Cash" | "Card" | "Printer" | "SmartPhone";
export type NeoEventCodeTypeEnum = "Normal" | "Error" | "FatalError";
export interface NeoEventCodeModel {
    "neoEventCodeId"?: number;
    "name"?: string;
    "code"?: number;
    "type"?: NeoEventCodeModelTypeEnum;
    "source"?: NeoEventCodeModelSourceEnum;
}

export type NeoEventCodeModelTypeEnum = "Normal" | "Error" | "FatalError";
export type NeoEventCodeModelSourceEnum = "Status" | "Management" | "Client" | "Robot" | "Computer" | "Cash" | "Card" | "Printer" | "SmartPhone";
export interface NeoEventFilter {
    "fromDate"?: Date;
    "toDate"?: Date;
    "code"?: number;
    "codes"?: string;
    "searchText"?: string;
    "source"?: NeoEventFilterSourceEnum;
}

export type NeoEventFilterSourceEnum = "Status" | "Management" | "Client" | "Robot" | "Computer" | "Cash" | "Card" | "Printer" | "SmartPhone";
export interface NeoEventModel {
    "neoEventId"?: number;
    "message"?: string;
    "creationDate"?: Date;
    "neoEventCodeId"?: number;
    "neoEventCode"?: string;
    "neoEventAlarmId"?: number;
    "productRailId"?: number;
    "paramsMessage"?:string;
}

export interface Order {
    "orderId"?: number;
    "status"?: OrderStatusEnum;
    "receipt"?: string;
    "creationDate"?: Date;
    "hmiId"?: number;
    "hmi"?: Hmi;
    "storeItemOrders"?: Array<StoreItemOrder>;
    "deliveries"?: Array<Delivery>;
    "voucherOrders"?: Array<VoucherOrder>;
    "voucherSources"?: Array<Voucher>;
}

export type OrderStatusEnum = "Created" | "Delivered" | "Canceled" | "Failed";
export interface PeriodModel {
    "periodId"?: number;
    "name"?: string;
    "hourStart"?: number;
    "hourEnd"?: number;
    "monthStart"?: number;
    "monthEnd"?: number;
    "monthDayStart"?: number;
    "monthDayEnd"?: number;
    "sunday"?: boolean;
    "monday"?: boolean;
    "tuesday"?: boolean;
    "wednesday"?: boolean;
    "thursday"?: boolean;
    "friday"?: boolean;
    "saturday"?: boolean;
    "creationDate"?: Date;
}

export interface Planogram {
    "planogramId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "active"?: boolean;
    "creationDate"?: Date;
    "shelvingTrayPlacements"?: Array<ShelvingTrayPlacement>;
    "productRails"?: Array<ProductRail>;
}

export interface PlanogramModel {
    "planogramId"?: number;
    "name"?: string;
    "active"?: boolean;
    "creationDate"?: Date;
}

export interface PlanogramSettingsModel {
    "showDeleteShelving"?: boolean;
}

export interface PlatformModel {
    "platformId"?: number;
    "remoteId"?: string;
    "name"?: string;
}

export interface Price {
    "priceId"?: number;
    "enabled"?: boolean;
    "value"?: number;
    "type"?: PriceTypeEnum;
    "creationDate"?: Date;
    "storeItem"?: StoreItem;
    "storeItemId"?: number;
    "currencyId"?: number;
    "currency"?: Currency;
    "priceNameId"?: number;
    "priceName"?: PriceName;
    "priceStores"?: Array<PriceStore>;
    "storeOrderItemOrders"?: Array<StoreOrderItemOrder>;
}

export type PriceTypeEnum = "Normal";
export interface PriceModel {
    "priceId"?: number;
    "enabled"?: boolean;
    "value"?: number;
    "creationDate"?: Date;
    "storeItemId"?: number;
    "currencyId"?: number;
    "priceNameId"?: number;
    "priceStores"?: Array<PriceStoreModel>;
}

export interface PriceName {
    "priceNameId"?: number;
    "name"?: string;
    "code"?: string;
    "type"?: PriceNameTypeEnum;
    "creationDate"?: Date;
    "prices"?: Array<Price>;
    "storeItems"?: Array<StoreItem>;
    "submissionPriceNames"?: Array<SubmissionPriceName>;
}

export type PriceNameTypeEnum = "Normal" | "Offer";
export interface PriceNameModel {
    "priceNameId"?: number;
    "name"?: string;
    "code"?: string;
    "type"?: PriceNameModelTypeEnum;
    "creationDate"?: Date;
}

export type PriceNameModelTypeEnum = "Normal" | "Offer";
export interface PricePeriodModel {
    "pricePeriodId"?: number;
    "name"?: string;
    "periodId"?: number;
    "priceNameId"?: number;
    "creationDate"?: Date;
    "pricePeriodStoreItems"?: Array<PricePeriodStoreItemModel>;
}

export interface PricePeriodStoreItemModel {
    "pricePeriodStoreItemId"?: number;
    "storeItemId"?: number;
    "priceNameId"?: number;
}

export interface PriceStore {
    "priceStoreId"?: number;
    "priceId"?: number;
    "price"?: Price;
    "storeId"?: number;
    "store"?: Store;
}

export interface PriceStoreModel {
    "priceStoreId"?: number;
    "priceId"?: number;
    "storeId"?: number;
}

export interface PrintContentModel {
    "content"?: string;
    "includeImage"?: boolean;
}

export interface PrintHandlerState {
    "version"?: string;
    "name"?: string;
    "status"?: PrintHandlerStateStatusEnum;
    "paperStatus"?: PrintHandlerStatePaperStatusEnum;
}

export type PrintHandlerStateStatusEnum = "Ok" | "Unavailable" | "Deactivated";
export type PrintHandlerStatePaperStatusEnum = "Ok" | "LowPaper" | "NoPaper";
export interface ProcessResult {
    "isSuccess"?: boolean;
    "message"?: string;
}

export interface Product {
    "shortName"?: string;
    "manufacturerId"?: number;
    "manufacturer"?: Manufacturer;
    "stockDaysProjection"?: number;
    "code"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityOfferDays"?: number;
    "expirityType"?: ProductExpirityTypeEnum;
    "stability"?: ProductStabilityEnum;
    "volume"?: number;
    "volumeUnitId"?: number;
    "volumeUnit"?: Unit;
    "relativeUnitId"?: number;
    "relativeUnit"?: Unit;
    "defaultSupplierId"?: number;
    "defaultSupplier"?: Supplier;
    "pmp"?: number;
    "productSubFamilies"?: Array<ProductSubFamily>;
    "productSuppliers"?: Array<ProductSupplier>;
    "productMenuCategories"?: Array<ProductMenuCategory>;
    "productVats"?: Array<ProductVat>;
    "productBarcodes"?: Array<ProductBarcode>;
    "submissionProducts"?: Array<SubmissionProduct>;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDelivery>;
    "storeStockOperations"?: Array<StoreStockOperation>;
    "storeOrderProductOrders"?: Array<StoreOrderProductOrder>;
    "storeProductReceptions"?: Array<StoreProductReception>;
    "productCustoms"?: Array<ProductCustom>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: ProductHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ProductSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ProductSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: Image;
    "defaultPriceNameId"?: number;
    "defaultPriceName"?: PriceName;
    "prices"?: Array<Price>;
    "storeItemStorePayments"?: Array<StoreItemStorePayment>;
    "storeItemTags"?: Array<StoreItemTag>;
}

export type ProductExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ProductStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ProductHmiVisibilityEnum = "Hide" | "Visible";
export type ProductSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ProductSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface ProductBarcode {
    "productBarcodeId"?: number;
    "productId"?: number;
    "product"?: Product;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "value"?: string;
}

export interface ProductBarcodeModel {
    "productBarcodeId"?: number;
    "productId"?: number;
    "externalProductId"?: number;
    "value"?: string;
}

export interface ProductCommandModel {
    "productId"?: number;
    "price"?: number;
    "menuUniqueId"?: string;
    "quantity"?: number;
    "menuId"?: number;
}

export interface ProductCustom {
    "productId"?: number;
    "product"?: Product;
    "comment"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "volume"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface ProductCustomModel {
    "productId"?: number;
    "comment"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "volume"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface ProductDetailsFilterModel {
    "familyId"?: number;
    "filterByProducts"?: boolean;
}

export interface ProductLocker { 
    "productLockerId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "startRow"?: number;
    "endRow"?: number;
    "startCol"?: number;
    "endCol"?: number;
    "sens"?: ProductLockerSensEnum;
    "lockerId"?: number;
    "locker"?: Locker;
    "isLocked"?: boolean;
    "lockerAddressId"?: number;
    "lockerAddress"?: LockerAddress;
    "lockId"?: number;
    "lock"?: Lock;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "deliveryProducts"?: Array<DeliveryProduct>;
    "productStockOperations"?: Array<ProductStockOperation>;
    "productStocks"?: Array<ProductStock>;
    "savedProductStocks"?: Array<DayProductStock>;
}

export type ProductLockerSensEnum = "Vertical" | "Horizontal";
export interface ProductLockerModel { 
    "productLockerId"?: number;
    "creationDate"?: Date;
    "enabled"?: boolean;
    "startRow"?: number;
    "endRow"?: number;
    "startCol"?: number;
    "endCol"?: number;
    "sens"?: ProductLockerModelSensEnum;
    "lockerId"?: number;
    "isLocked"?: boolean;
    "lockerAddressId"?: number;
    "lockId"?: number;
    "externalProductId"?: number;
    "productStocks"?: Array<ProductStockModel>;
}

export type ProductLockerModelSensEnum = "Vertical" | "Horizontal";
export interface ProductLockerStockModel { 
    "productLockerModel"?: ProductLockerModel;
    "productReceptions"?:  Array<ProductReceptionModel>;
    "productReceptionsRemove"?:  Array<ProductReceptionModel>;
}

export interface ProductMenuCategory {
    "productMenuCategoryId"?: number;
    "productId"?: number;
    "product"?: Product;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "productMenuCategoryStores"?: Array<ProductMenuCategoryStore>;
}

export interface ProductMenuCategoryModel {
    "productMenuCategoryId"?: number;
    "productId"?: number;
    "menuCategoryId"?: number;
    "productMenuCategoryStores"?: Array<ProductMenuCategoryStoreModel>;
}

export interface ProductMenuCategoryStore {
    "productMenuCategoryStoreId"?: number;
    "storeId"?: number;
    "store"?: Store;
    "productMenuCategoryId"?: number;
    "productMenuCategory"?: ProductMenuCategory;
}

export interface ProductMenuCategoryStoreModel {
    "productMenuCategoryStoreId"?: number;
    "storeId"?: number;
    "productMenuCategoryId"?: number;
}

export interface ProductModel {
    "shortName"?: string;
    "stockDaysProjection"?: number;
    "code"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityOfferDays"?: number;
    "expirityType"?: ProductModelExpirityTypeEnum;
    "stability"?: ProductModelStabilityEnum;
    "volumeUnitId"?: number;
    "volume"?: number;
    "relativeUnitId"?: number;
    "defaultSupplierId"?: number;
    "pmp"?: number;
    "productSubFamilies"?: Array<ProductSubFamilyModel>;
    "productSuppliers"?: Array<ProductSupplierModel>;
    "productMenuCategories"?: Array<ProductMenuCategoryModel>;
    "productVats"?: Array<ProductVatModel>;
    "productBarcodes"?: Array<ProductBarcodeModel>;
    "productCustoms"?: Array<ProductCustomModel>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: ProductModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ProductModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ProductModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
}

export type ProductModelExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ProductModelStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ProductModelHmiVisibilityEnum = "Hide" | "Visible";
export type ProductModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ProductModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface ProductOrder {
    "productId"?: number;
    "product"?: Product;
    "vatId"?: number;
    "vat"?: Vat;
    "menuOrderId"?: number;
    "menuOrder"?: MenuOrder;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "deliveryProducts"?: Array<DeliveryProduct>;
    "storeItemOrderId"?: number;
    "source"?: ProductOrderSourceEnum;
    "quantity"?: number;
    "creationDate"?: Date;
    "priceId"?: number;
    "price"?: Price;
    "orderId"?: number;
    "order"?: Order;
}

export type ProductOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface ProductOrderModel {
    "quantity"?: number;
    "productRailDepth"?: number;
    "productId"?: number;
    "name"?: string;
    "expirity"?: Date;
    "coord"?: RailCoordModel;
}

export interface ProductRail {
    "productRailId"?: number;
    "r"?: number;
    "a"?: number;
    "d"?: number;
    "f"?: number;
    "enabled"?: boolean;
    "sens"?: ProductRailSensEnum;
    "creationDate"?: Date;
    "productId"?: number;
    "product"?: Product;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "shelvingTrayId"?: number;
    "shelvingTray"?: ShelvingTray;
    "planogramId"?: number;
    "status"?: ProductRailStatusEnum;
    "planogram"?: Planogram;
    "deliveryProducts"?: Array<DeliveryProduct>;
    "productStockOperations"?: Array<ProductStockOperation>;
    "productStocks"?: Array<ProductStock>;
    "savedProductStocks"?: Array<DayProductStock>;
    "neoEvents"?: Array<NeoEvent>;
}

export type ProductRailSensEnum = "Y1" | "X1" | "Z1" | "Z2" | "Y2" | "X2";
export type ProductRailStatusEnum = "Active" | "Blocked";
export interface ProductRailModel {
    "productRailId"?: number;
    "r"?: number;
    "a"?: number;
    "d"?: number;
    "f"?: number;
    "sens"?: ProductRailModelSensEnum;
    "enabled"?: boolean;
    "creationDate"?: Date;
    "productId"?: number;
    "externalProductId"?: number;
    "shelvingTrayId"?: number;
    "planogramId"?: number;
    "status"?: ProductRailModelStatusEnum;
    "productStocks"?: Array<ProductStockModel>;
}

export type ProductRailModelSensEnum = "Y1" | "X1" | "Z1" | "Z2" | "Y2" | "X2";
export type ProductRailModelStatusEnum = "Active" | "Blocked";
export interface ProductReception {
    "productReceptionId"?: number;
    "type"?: ProductReceptionTypeEnum;
    "quantity"?: number;
    "quantityRemaining"?: number;
    "creationDate"?: Date;
    "userStoreId"?: number;
    "userStore"?: UserStore;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "productId"?: number;
    "product"?: Product;
    "supplierOrderId"?: number;
    "supplierOrder"?: SupplierOrder;
    "productStockOperations"?: Array<ProductStockOperation>;
}

export type ProductReceptionTypeEnum = "Delivery" | "Unstock" | "Unknown";
export interface ProductReceptionModel {
    "productReceptionId"?: number;
    "quantity"?: number;
    "quantityRemaining"?: number;
    "creationDate"?: Date;
    "userManagerId"?: number;
    "productSupplierId"?: number;
    "productId"?: number;
    "supplierOrderId"?: number;
    "externalProductId"?: number;
}

export interface ProductReceptionsModel {
    "productReceptions"?: Array<ProductReceptionModel>;
}

export interface ProductStock {
    "productStockId"?: number;
    "expirity"?: Date;
    "creationDate"?: Date;
    "productRailId"?: number;
    "productRail"?: ProductRail;
    "productLockerId"?: number;
    "productLocker"?: ProductLocker;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "productReceptionId"?: number;
    "productReception"?: ProductReception;
}

export interface ProductStockModel {
    "productStockId"?: number;
    "expirity"?: Date;
    "creationDate"?: Date;
    "productRailId"?: number;
    "productSupplierId"?: number;
    "productLockerId"?: number;
    "productReceptionId"?: number;
}

export interface ProductStockOperation {
    "productStockOperationId"?: number;
    "type"?: ProductStockOperationTypeEnum;
    "quantityStock"?: number;
    "quantityStored"?: number;
    "creationDate"?: Date;
    "productReceptionId"?: number;
    "productReception"?: ProductReception;
    "productRailId"?: number;
    "productRail"?: ProductRail;
    "productId"?: number;
    "product"?: Product;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
}

export type ProductStockOperationTypeEnum = "ManagerStockOut" | "ManagerStockIn" | "ManagerStoredIn" | "ManagerStoredOut" | "ExpirityOut" | "ManualMarkdown";
export interface ProductSubFamily {
    "productSubFamilyId"?: number;
    "productId"?: number;
    "product"?: Product;
    "subFamilyId"?: number;
    "subFamily"?: SubFamily;
    "order"?: number;
    "productSubFamilyStores"?: Array<ProductSubFamilyStore>;
}

export interface ProductSubFamilyModel {
    "productSubFamilyId"?: number;
    "productId"?: number;
    "subFamilyId"?: number;
    "order"?: number;
    "productSubFamilyStores"?: Array<ProductSubFamilyStoreModel>;
}

export interface ProductSubFamilyStore {
    "productSubFamilyStoreId"?: number;
    "productSubFamily"?: ProductSubFamily;
    "productSubFamilyId"?: number;
    "store"?: Store;
    "storeId"?: number;
}

export interface ProductSubFamilyStoreModel {
    "productSubFamilyStoreId"?: number;
    "productSubFamilyId"?: number;
    "storeId"?: number;
}

export interface ProductSupplier {
    "productSupplierId"?: number;
    "enabled"?: boolean;
    "packCondition"?: number;
    "deliveryCondition"?: number;
    "reference"?: string;
    "buyPriceNoVat"?: number;
    "packWeight"?: number;
    "packVolume"?: number;
    "creationDate"?: Date;
    "buyPriceCurrencyId"?: number;
    "buyPriceCurrency"?: Currency;
    "productId"?: number;
    "product"?: Product;
    "externalProductId"?: number;
    "externalProduct"?: ExternalProduct;
    "supplierMainId"?: number;
    "supplierMain"?: SupplierMain;
    "supplierSubId"?: number;
    "supplierSub"?: SupplierSub;
    "vatId"?: number;
    "vat"?: Vat;
    "productSupplierStores"?: Array<ProductSupplierStore>;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDelivery>;
    "storeOrderItemOrders"?: Array<StoreOrderItemOrder>;
    "storeStockOperations"?: Array<StoreStockOperation>;
    "storeProductReceptions"?: Array<StoreProductReception>;
}

export interface ProductSupplierModel {
    "productSupplierId"?: number;
    "enabled"?: boolean;
    "packCondition"?: number;
    "deliveryCondition"?: number;
    "reference"?: string;
    "packWeight"?: number;
    "packVolume"?: number;
    "buyPriceNoVat"?: number;
    "buyPriceCurrencyId"?: number;
    "productId"?: number;
    "externalProductId"?: number;
    "supplierMainId"?: number;
    "supplierSubId"?: number;
    "vatId"?: number;
    "creationDate"?: Date;
    "productSupplierStores"?: Array<ProductSupplierStoreModel>;
}

export interface ProductSupplierStore {
    "productSupplierStoreId"?: number;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "storeId"?: number;
    "store"?: Store;
}

export interface ProductSupplierStoreModel {
    "productSupplierStoreId"?: number;
    "productSupplierId"?: number;
    "storeId"?: number;
}

export interface ProductVat {
    "productVatId"?: number;
    "productId"?: number;
    "product"?: Product;
    "vatId"?: number;
    "vat"?: Vat;
    "countryId"?: number;
    "country"?: Country;
}

export interface ProductVatModel {
    "productVatId"?: number;
    "productId"?: number;
    "vatId"?: number;
    "countryId"?: number;
}

export interface Promotion {
    "promotionId"?: number;
    "enabled"?: boolean;
    "type"?: PromotionTypeEnum;
    "name"?: string;
    "order"?: number;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: Image;
    "promotionCustoms"?: Array<PromotionCustom>;
    "submissionPromotions"?: Array<SubmissionPromotion>;
}

export type PromotionTypeEnum = "Displayed" | "NotDisplayed";
export interface PromotionCustom {
    "promotionId"?: number;
    "promotion"?: Promotion;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface PromotionCustomModel {
    "promotionId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface PromotionModel {
    "promotionId"?: number;
    "enabled"?: boolean;
    "type"?: PromotionModelTypeEnum;
    "name"?: string;
    "order"?: number;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: ImageModel;
    "promotionCustoms"?: Array<PromotionCustomModel>;
}

export type PromotionModelTypeEnum = "Displayed" | "NotDisplayed";
export interface RailCoordModel {
    "r"?: number;
    "a"?: number;
    "d"?: number;
    "f"?: number;
}

export interface ReceiptResult {
    "result"?: string;
}

export interface RemoteCollectionData {
    "label"?: string;
    "status"?: RemoteCollectionDataStatusEnum;
    "data"?: string;
}

export type RemoteCollectionDataStatusEnum = "Ok" | "NotCompleted" | "CallFailed" | "NoCall";
export interface RemoteCollectionsDataModel {
    "sessionId"?: string;
    "remoteCollectionDatas"?: Array<RemoteCollectionData>;
}

export interface Robot {
    "robotId"?: number;
    "name"?: string;
    "version"?: number;
    "robotConfigurationId"?: number;
    "robotConfiguration"?: RobotConfiguration;
    "storeId"?: number;
    "store"?: Store;
    "creationDate"?: Date;
    "deliveryStations"?: Array<DeliveryStation>;
    "robotExtractors"?: Array<RobotExtractor>;
}

export interface RobotConfiguration {
    "robotConfigurationId"?: number;
    "maxVolume"?: number;
    "version"?: number;
    "shelveGap"?: number;
    "railSpeed"?: number;
    "extractionPerc"?: number;
    "idleX"?: number;
    "idleZ"?: number;
    "deliveryX"?: number;
    "deliveryZ"?: number;
    "maintenanceX1"?: number;
    "maintenanceZ1"?: number;
    "maintenanceX2"?: number;
    "maintenanceZ2"?: number;
    "offSetX1"?: number;
    "offSetX2"?: number;
    "offSetZ1"?: number;
    "offSetZ2"?: number;
    "mW270"?: number;
    "mW272"?: number;
    "mW274"?: number;
    "mW280"?: number;
    "mW282"?: number;
    "mW284"?: number;
    "reopenDoorDelay"?: number;
    "collectionDelay"?: number;
    "doorOpeningTimeBase"?: number;
    "doorOpeningTimePerProduct"?: number;
    "deliveryTimeBase"?: number;
    "deliveryTimeDifferentProducts"?: number;
    "deliveryTimeSameProducts"?: number;
    "robots"?: Array<Robot>;
}

export interface RobotConfigurationModel {
    "robotConfigurationId"?: number;
    "maxVolume"?: number;
    "shelveGap"?: number;
    "railSpeed"?: number;
    "extractionPerc"?: number;
    "idleX"?: number;
    "idleZ"?: number;
    "deliveryX"?: number;
    "deliveryZ"?: number;
    "offSetX1"?: number;
    "offSetX2"?: number;
    "offSetZ1"?: number;
    "offSetZ2"?: number;
    "maintenanceX1"?: number;
    "maintenanceZ1"?: number;
    "maintenanceX2"?: number;
    "maintenanceZ2"?: number;
    "mW270"?: number;
    "mW272"?: number;
    "mW274"?: number;
    "mW280"?: number;
    "mW282"?: number;
    "mW284"?: number;
    "reopenDoorDelay"?: number;
    "collectionDelay"?: number;
    "doorOpeningTimeBase"?: number;
    "doorOpeningTimePerProduct"?: number;
    "deliveryTimeBase"?: number;
    "deliveryTimeDifferentProducts"?: number;
    "deliveryTimeSameProducts"?: number;
    "options"?: number;
}

export interface RobotCoordModel {
    "x"?: number;
    "z"?: number;
    "lane"?: number;
    "belts"?: Array<number>;
}

export interface RobotData {
    "coord"?: RobotCoordModel;
    "isDeliveryDoorOpen"?: boolean;
    "isSasDoorOpen"?: boolean;
    "isDeliveryDoorClosed"?: boolean;
    "isSasDoorClosed"?: boolean;
    "isDeliveryDoorLocked"?: boolean;
    "isSasDoorLocked"?: boolean;
    "deliveryBeltState"?: RobotDataDeliveryBeltStateEnum;
    "accumulatorState"?: RobotDataAccumulatorStateEnum;
    "isPanicButtonPressed"?: boolean;
    "hasItemInAccu"?: boolean;
    "hasItemToTrash"?: boolean;
    "hasItemToSas"?: boolean;
    "extractor1"?: ExtractorData;
    "extractor2"?: ExtractorData;
}

export type RobotDataDeliveryBeltStateEnum = "DeliveryDoor" | "Trash" | "None";
export type RobotDataAccumulatorStateEnum = "DeliveryDoor" | "Accumulator";
export interface RobotExtractor {
    "robotExtractorId"?: number;
    "sens"?: RobotExtractorSensEnum;
    "offsetX"?: number;
    "offsetZ"?: number;
    "robotId"?: number;
    "robot"?: Robot;
}

export type RobotExtractorSensEnum = "Normal" | "Inverted";
export interface RobotExtractorModel {
    "robotExtractorId"?: number;
    "sens"?: RobotExtractorModelSensEnum;
    "offsetX"?: number;
    "offsetZ"?: number;
    "robotId"?: number;
}

export type RobotExtractorModelSensEnum = "Normal" | "Inverted";
export interface RobotHandlerState {
    "version"?: string;
    "status"?: RobotHandlerStateStatusEnum;
    "initStatus"?: RobotHandlerStateInitStatusEnum;
    "position"?: RobotHandlerStatePositionEnum;
    "currentSession"?: SessionModel;
    "robotData"?: RobotData;
}

export type RobotHandlerStateStatusEnum = "Unknown" | "Error" | "Initializing" | "Running" | "Ready";
export type RobotHandlerStateInitStatusEnum = "Unknown" | "InitTotalAcknowledged" | "RequestingInitTotal" | "PositionToMaintenanceAcknowledged" | "PositionToMaintenance" | "InitPartialAcknowledged";
export type RobotHandlerStatePositionEnum = "Unknown" | "Idle" | "Moving" | "Extraction" | "Delivery" | "Maintenance" | "Origin";
export interface RobotModel {
    "robotId"?: number;
    "name"?: string;
    "version"?: number;
    "robotConfiguration"?: RobotConfigurationModel;
    "creationDate"?: Date;
    "robotExtractors"?: Array<RobotExtractorModel>;
    "deliveryStations"?: Array<DeliveryStationModel>;
}

export interface RobotSimulationModel {
    "type"?: RobotSimulationModelTypeEnum;
    "extractionTimer"?: number;
    "extractionType"?: RobotSimulationModelExtractionTypeEnum;
    "simulationReturn"?: RobotSimulationModelSimulationReturnEnum;
    "deliverProducts"?: DeliverProductsModel;
}

export type RobotSimulationModelTypeEnum = "Once" | "Loop";
export type RobotSimulationModelExtractionTypeEnum = "Normal" | "Empty" | "Timing";
export type RobotSimulationModelSimulationReturnEnum = "Idle" | "None";
export interface Role {
    "roleId"?: number;
    "name"?: string;
    "accountRoles"?: Array<AccountRole>;
}

export interface Sale {
    "saleId"?: number;
    "step"?: SaleStepEnum;
    "status"?: SaleStatusEnum;
    "endDate"?: Date;
    "creationDate"?: Date;
    "hmiId"?: number;
    "hmi"?: Hmi;
    "salePayments"?: Array<SalePayment>;
    "saleOrders"?: Array<SaleOrder>;
}

export type SaleStepEnum = "Initiated" | "Validated" | "Paid" | "Delivered";
export type SaleStatusEnum = "Open" | "Closed";
export interface SaleOrder {
    "saleId"?: number;
    "sale"?: Sale;
    "bagAmount"?: number;
    "orderId"?: number;
    "status"?: SaleOrderStatusEnum;
    "receipt"?: string;
    "creationDate"?: Date;
    "hmiId"?: number;
    "hmi"?: Hmi;
    "storeItemOrders"?: Array<StoreItemOrder>;
    "deliveries"?: Array<Delivery>;
    "voucherOrders"?: Array<VoucherOrder>;
    "voucherSources"?: Array<Voucher>;
}

export type SaleOrderStatusEnum = "Created" | "Delivered" | "Canceled" | "Failed";
export interface SalePayment {
    "salePaymentId"?: number;
    "paymentType"?: SalePaymentPaymentTypeEnum;
    "uniqueId"?: string;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: SalePaymentStatusEnum;
    "state"?: SalePaymentStateEnum;
    "saleId"?: number;
    "sale"?: Sale;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "creationDate"?: Date;
    "receiptWanted"?: boolean;
    "receiptCcWanted"?: boolean;
    "receiptByQRWanted"?: boolean;
}

export type SalePaymentPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type SalePaymentStatusEnum = "Success" | "PartialSuccess" | "Failed";
export type SalePaymentStateEnum = "Running" | "Completed" | "Canceled";
export interface SalePaymentCash {
    "moneyTraffics"?: Array<MoneyTraffic>;
    "salePaymentId"?: number;
    "paymentType"?: SalePaymentCashPaymentTypeEnum;
    "uniqueId"?: string;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: SalePaymentCashStatusEnum;
    "state"?: SalePaymentCashStateEnum;
    "saleId"?: number;
    "sale"?: Sale;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "creationDate"?: Date;
}

export type SalePaymentCashPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type SalePaymentCashStatusEnum = "Success" | "PartialSuccess" | "Failed";
export type SalePaymentCashStateEnum = "Running" | "Completed" | "Canceled";
export interface SalePaymentModel {
    "salePaymentId"?: number;
    "paymentType"?: SalePaymentModelPaymentTypeEnum;
    "uniqueId"?: string;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: SalePaymentModelStatusEnum;
    "state"?: SalePaymentModelStateEnum;
    "saleId"?: number;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "creationDate"?: Date;
    "cardType"?: SalePaymentModelCardTypeEnum;
    "salePaymentMonies"?: Array<MoneyTraffic>;
    "collectionInfo"?: string;
    "receiptWanted"?: boolean;
    "receiptCcWanted"?: boolean;
    "receiptByQRWanted"?: boolean;
}

export type SalePaymentModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type SalePaymentModelStatusEnum = "Success" | "PartialSuccess" | "Failed";
export type SalePaymentModelStateEnum = "Running" | "Completed" | "Canceled";
export type SalePaymentModelCardTypeEnum = "Normal" | "NoContact";
export interface SearchReceptionModel {
    "dateFrom"?: Date;
    "dateTo"?: Date;
}

export interface SeedMngModel {
    "products"?: { [key: string]: ProductModel; };
    "externalProducts"?: { [key: string]: ExternalProductModel; };
    "menus"?: { [key: string]: MenuModel; };
    "families"?: { [key: string]: FamilyModel; };
    "subFamilies"?: { [key: string]: SubFamilyModel; };
    "promotions"?: { [key: string]: PromotionModel; };
    "suppliers"?: { [key: string]: SupplierModel; };
    "languages"?: { [key: string]: LanguageModel; };
    "priceNames"?: { [key: string]: PriceNameModel; };
    "menuCategories"?: { [key: string]: MenuCategoryModel; };
    "moneys"?: { [key: string]: MoneyModel; };
    "vats"?: { [key: string]: VatModel; };
    "units"?: { [key: string]: UnitModel; };
    "currencies"?: { [key: string]: CurrencyModel; };
    "neoEventCodes"?: { [key: string]: NeoEventCodeModel; };
    "countryVats"?: Array<CountryVatModel>;
    "storeAppSettings"?: StoreAppSettingsModel;
    "storeAppVersions"?: Array<StoreAppVersionsModel>;
}

export interface ServiceResultString {
    "result"?: string;
    "isSuccess"?: boolean;
    "statusCode"?: number;
    "message"?: string;
}

export interface SessionModel {
    "uniqueId"?: string;
    "creationDate"?: Date;
}

export interface Shelving {
    "shelvingId"?: number;
    "lane"?: number;
    "position"?: number;
    "width"?: number;
    "railHeight"?: number;
    "name"?: string;
    "code"?: string;
    "enabled"?: boolean;
    "creationDate"?: Date;
    "offsetX"?: number;
    "offsetZ"?: number;
    "deliveryStationId"?: number;
    "deliveryStation"?: DeliveryStation;
    "shelvingTrayPlacements"?: Array<ShelvingTrayPlacement>;
}

export interface ShelvingModel {
    "shelvingId"?: number;
    "lane"?: number;
    "position"?: number;
    "width"?: number;
    "railHeight"?: number;
    "name"?: string;
    "code"?: string;
    "enabled"?: boolean;
    "creationDate"?: Date;
    "offsetX"?: number;
    "offsetZ"?: number;
    "deliveryStationId"?: number;
}

export interface ShelvingTray {
    "shelvingTrayId"?: number;
    "enabled"?: boolean;
    "depth"?: number;
    "railStart"?: number;
    "railEnd"?: number;
    "railWidth"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "productRails"?: Array<ProductRail>;
    "shelvingTrayPlacements"?: Array<ShelvingTrayPlacement>;
}

export interface ShelvingTrayModel {
    "shelvingTrayId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "code"?: string;
    "depth"?: number;
    "railStart"?: number;
    "railEnd"?: number;
    "railWidth"?: number;
    "creationDate"?: Date;
}

export interface ShelvingTrayPlacement {
    "shelvingTrayPlacementId"?: number;
    "level"?: number;
    "shelvingId"?: number;
    "shelving"?: Shelving;
    "shelvingTrayId"?: number;
    "shelvingTray"?: ShelvingTray;
    "planogramId"?: number;
    "planogram"?: Planogram;
}

export interface ShelvingTrayPlacementModel {
    "shelvingTrayPlacementId"?: number;
    "level"?: number;
    "shelvingId"?: number;
    "shelvingTrayId"?: number;
    "planogramId"?: number;
}

export interface SmPlatformModel {
    "id"?: string;
    "name"?: string;
}

export interface SmartPhoneStatusModel {
    "level"?: SmartPhoneStatusModelLevelEnum;
    "status"?: SmartPhoneStatusModelStatusEnum;
}

export type SmartPhoneStatusModelLevelEnum = "Important" | "Normal";
export type SmartPhoneStatusModelStatusEnum = "Ok" | "NotStable" | "Ko";
export interface StartTestPaymentModel {
    "value"?: number;
    "test"?: boolean;
}

export interface StockStatusModel {
    "planograms"?: Array<PlanogramModel>;
    "productRails"?: Array<ProductRailModel>;
    "productReceptions"?: Array<ProductReceptionModel>;
    "shelvingTrayPlacements"?: Array<ShelvingTrayPlacementModel>;
    "shelvingTrays"?: { [key: string]: ShelvingTrayModel; };
    "shelvings"?: { [key: string]: ShelvingModel; };
    "productSuppliers"?: { [key: string]: ProductSupplierModel; };
    "lockers"?: { [key: string]: LockerModel; };
    "lockerAddresses"?: { [key: string]: LockerAddressModel; };
    "lockerTypes"?: { [key: string]: LockerTypeModel; };
    "productLockers"?: Array<ProductLockerModel>;
    "locks"?: { [key: string]: LockModel; };
}

export interface Store {
    "storeId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "utcOffSet"?: number;
    "currencyId"?: number;
    "currency"?: Currency;
    "languageId"?: number;
    "language"?: Language;
    "address"?: Address;
    "addressId"?: number;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "subscriptions"?: Array<Subscription>;
    "storeMonies"?: Array<StoreMoney>;
    "dayStoreDatas"?: Array<DayStoreData>;
    "productSupplierStores"?: Array<ProductSupplierStore>;
    "priceStores"?: Array<PriceStore>;
    "robots"?: Array<Robot>;
    "productSubFamilyStores"?: Array<ProductSubFamilyStore>;
    "storeItemStorePayments"?: Array<StoreItemStorePayment>;
    "productMenuCategoryStores"?: Array<ProductMenuCategoryStore>;
    "menuStores"?: Array<MenuStore>;
    "menuCategoryStores"?: Array<MenuCategoryStore>;
    "storeImports"?: Array<StoreImport>;
    "deliveryStations"?: Array<DeliveryStation>;
}

export interface StoreAccount {
    "storeAccountId"?: number;
    "enabled"?: boolean;
    "username"?: string;
    "password"?: string;
    "userStore"?: UserStore;
    "passwordTrys"?: number;
    "lastPasswordTry"?: Date;
    "creationDate"?: Date;
    "storeAccountRoles"?: Array<StoreAccountRole>;
    "hmiMoneyResets"?: Array<HmiMoneyReset>;
    "supplierOrders"?: Array<SupplierOrder>;
}

export interface StoreAccountModel {
    "storeAccountId"?: number;
    "username"?: string;
    "creationDate"?: Date;
    "roles"?: Array<string>;
}

export interface StoreAccountRole {
    "storeAccountRoleId"?: number;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "roleId"?: number;
    "role"?: Role;
}

export interface StoreAppSettingsModel {
    "storeName"?: string;
    "storeAddress"?: string;
    "storeZipCode"?: string;
    "storeCity"?: string;
    "storeId"?: number;
    "storeCountry"?: string;
    "lgapImportApiUrl"?: string;
    "hmiScreenHeight"?: number;
    "hmiScreenWidth"?: number;
    "typeStore"?:string;
    "baseColor"?:string;
    "ticketQRUri"?:string;
    "displayCAv6"?:boolean;
}

export interface StoreAppVersionsModel {
    "version"?: string;
    "dateVersion"?: string;
    "description"?: string;
}

export interface StoreDayDataSnapshotModel {
    "dayDataSnapshotId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "dayProductStocks"?: Array<StoreDayProductStockModel>;
    "dayMoneyStocks"?: Array<StoreDayMoneyStockModel>;
    "dayProductReceptions"?: Array<StoreDayProductReceptionModel>;
}

export interface StoreDayMoneyStockModel {
    "dayMoneyStockId"?: number;
    "quantity"?: number;
    "creationDate"?: Date;
    "dayDataSnapshotId"?: number;
    "moneyId"?: number;
    "hmiMoneyHopperId"?: number;
}

export interface StoreDayProductReceptionModel {
    "dayProductReceptionId"?: number;
    "type"?: StoreDayProductReceptionModelTypeEnum;
    "quantityRemaining"?: number;
    "creationDate"?: Date;
    "productSupplierId"?: number;
    "productId"?: number;
    "externalProductId"?: number;
    "dayDataSnapshotId"?: number;
}

export type StoreDayProductReceptionModelTypeEnum = "Delivery" | "Unstock" | "Unknown";
export interface StoreDayProductStockModel {
    "dayProductStockId"?: number;
    "expirity"?: Date;
    "creationDate"?: Date;
    "productId"?: number;
    "externalProductId"?: number;
    "productRailId"?: number;
    "productSupplierId"?: number;
    "dayDataSnapshotId"?: number;
    "quantity"?: number;
}

export interface StoreGroup {
    "storeGroupId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "prices"?: Array<Price>;
    "productSuppliers"?: Array<ProductSupplier>;
    "entityCustomizations"?: Array<EntityCustomization>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
    "supplierDiscounts"?: Array<SupplierDiscount>;
}

export interface StoreHmiMoneyResetModel {
    "hmiMoneyResetId"?: number;
    "storeAccountId"?: number;
    "creationDate"?: Date;
    "hmiMoneyResetMoneys"?: Array<StoreHmiMoneyResetMoneyModel>;
}

export interface StoreHmiMoneyResetMoneyModel {
    "hmiMoneyResetMoneyId"?: number;
    "quantity"?: number;
    "hmiMoneyResetId"?: number;
    "moneyId"?: number;
}

export interface StoreImport {
    "storeImportId"?: number;
    "uniqueId"?: string;
    "status"?: StoreImportStatusEnum;
    "type"?: StoreImportTypeEnum;
    "creationDate"?: Date;
    "errorMessage"?: string;
    "storeId"?: number;
    "store"?: Store;
}

export type StoreImportStatusEnum = "Success" | "Failure";
export type StoreImportTypeEnum = "LgapEntities" | "RobotConfig" | "MoneyConfig" | "Images";
export interface StoreImportModel {
    "storeImportId"?: number;
    "uniqueId"?: string;
    "status"?: StoreImportModelStatusEnum;
    "type"?: StoreImportModelTypeEnum;
    "creationDate"?: Date;
    "errorMessage"?: string;
    "storeId"?: number;
}

export type StoreImportModelStatusEnum = "Success" | "Failure";
export type StoreImportModelTypeEnum = "LgapEntities" | "RobotConfig" | "MoneyConfig" | "Images";
export interface StoreItem {
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: StoreItemHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: StoreItemSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: StoreItemSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: Image;
    "defaultPriceNameId"?: number;
    "defaultPriceName"?: PriceName;
    "prices"?: Array<Price>;
    "storeItemStorePayments"?: Array<StoreItemStorePayment>;
    "storeItemTags"?: Array<StoreItemTag>;
}

export type StoreItemHmiVisibilityEnum = "Hide" | "Visible";
export type StoreItemSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type StoreItemSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface StoreItemOrder {
    "storeItemOrderId"?: number;
    "source"?: StoreItemOrderSourceEnum;
    "quantity"?: number;
    "creationDate"?: Date;
    "priceId"?: number;
    "price"?: Price;
    "orderId"?: number;
    "order"?: Order;
}

export type StoreItemOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface StoreItemStorePayment {
    "storeItemStorePaymentId"?: number;
    "storeItemId"?: number;
    "storeItem"?: StoreItem;
    "store"?: Store;
    "storeId"?: number;
    "paymentType"?: StoreItemStorePaymentPaymentTypeEnum;
    "availability"?: StoreItemStorePaymentAvailabilityEnum;
}

export type StoreItemStorePaymentPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreItemStorePaymentAvailabilityEnum = "Available" | "Unavailable";
export interface StoreItemStorePaymentModel {
    "storeItemStorePaymentId"?: number;
    "storeId"?: number;
    "paymentType"?: StoreItemStorePaymentModelPaymentTypeEnum;
    "availability"?: StoreItemStorePaymentModelAvailabilityEnum;
    "storeItemId"?: number;
}

export type StoreItemStorePaymentModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreItemStorePaymentModelAvailabilityEnum = "Available" | "Unavailable";
export interface StoreItemTag {
    "storeItemTagId"?: number;
    "tagId"?: number;
    "tag"?: Tag;
    "storeItemId"?: number;
    "storeItem"?: StoreItem;
}

export interface StoreItemTagModel {
    "storeItemTagId"?: number;
    "tagId"?: number;
    "storeItemId"?: number;
}

export interface StoreMoney {
    "storeMoneyId"?: number;
    "storeId"?: number;
    "store"?: Store;
    "moneyId"?: number;
    "money"?: Money;
}

export interface StoreMoneyTraffic {
    "storeMoneyTrafficId"?: number;
    "type"?: StoreMoneyTrafficTypeEnum;
    "quantity"?: number;
    "creationDate"?: Date;
    "moneyId"?: number;
    "money"?: Money;
    "hmiMoneyHopperId"?: number;
    "storeOrderPaymentId"?: number;
    "storeOrderPayment"?: StoreOrderPayment;
    "dayStoreDataId"?: number;
    "dayStoreData"?: DayStoreData;
}

export type StoreMoneyTrafficTypeEnum = "PaymentIn" | "PaymentOut" | "ManagerIn" | "ManagerOut";
export interface StoreMoneyTrafficModel {
    "storeMoneyTrafficId"?: number;
    "type"?: StoreMoneyTrafficModelTypeEnum;
    "quantity"?: number;
    "moneyId"?: number;
    "hmiMoneyHopperId"?: number;
    "storeOrderPaymentId"?: number;
    "dayStoreDataId"?: number;
}

export type StoreMoneyTrafficModelTypeEnum = "PaymentIn" | "PaymentOut" | "ManagerIn" | "ManagerOut";
export interface StoreNeoEvent {
    "storeNeoEventId"?: number;
    "message"?: string;
    "eventDate"?: Date;
    "neoEventCodeId"?: number;
    "neoEventCode"?: NeoEventCode;
    "dayStoreDataId"?: number;
    "dayStoreData"?: DayStoreData;
}

export interface StoreNeoEventModel {
    "storeNeoEventId"?: number;
    "message"?: string;
    "eventDate"?: Date;
    "neoEventCodeId"?: number;
    "dayStoreDataId"?: number;
    "paramsMessage"?: string;
}

export interface StoreOrder {
    "storeOrderId"?: number;
    "orderType"?: StoreOrderOrderTypeEnum;
    "orderStatus"?: StoreOrderOrderStatusEnum;
    "orderDate"?: Date;
    "storeOrderPayment"?: StoreOrderPayment;
    "dayStoreDataId"?: number;
    "dayStoreData"?: DayStoreData;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDelivery>;
    "storeOrderItemOrders"?: Array<StoreOrderItemOrder>;
}

export type StoreOrderOrderTypeEnum = "SaleOrder" | "PreOrder";
export type StoreOrderOrderStatusEnum = "Created" | "Delivered" | "Canceled" | "Failed";
export interface StoreOrderItemOrder {
    "storeOrderItemOrderId"?: number;
    "source"?: StoreOrderItemOrderSourceEnum;
    "quantity"?: number;
    "orderDate"?: Date;
    "storeOrderId"?: number;
    "storeOrder"?: StoreOrder;
    "priceId"?: number;
    "price"?: Price;
    "productPmp"?: number;
}

export type StoreOrderItemOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface StoreOrderItemOrderModel {
    "storeOrderItemOrderId"?: number;
    "storeOrderItemOrderType"?: StoreOrderItemOrderModelStoreOrderItemOrderTypeEnum;
    "source"?: StoreOrderItemOrderModelSourceEnum;
    "orderDate"?: Date;
    "quantity"?: number;
    "storeOrderId"?: number;
    "vatId"?: number;
    "priceId"?: number;
    "price"?: PriceModel;
    "productId"?: number;
    "storeOrderMenuOrderId"?: number;
    "menuCategoryId"?: number;
    "menuId"?: number;
    "externalProductId"?: number;
    "productPmp"?: number;
    "unitPriceWoTaxes"?: number;
    "appliedTaxes"?: number;
}

export type StoreOrderItemOrderModelStoreOrderItemOrderTypeEnum = "Product" | "Menu" | "ExternalProduct";
export type StoreOrderItemOrderModelSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface StoreOrderMenuOrder {
    "menuId"?: number;
    "menu"?: Menu;
    "storeOrderProductOrders"?: Array<StoreOrderProductOrder>;
    "storeOrderItemOrderId"?: number;
    "source"?: StoreOrderMenuOrderSourceEnum;
    "quantity"?: number;
    "orderDate"?: Date;
    "storeOrderId"?: number;
    "storeOrder"?: StoreOrder;
    "priceId"?: number;
    "price"?: Price;
}

export type StoreOrderMenuOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface StoreOrderModel {
    "storeOrderId"?: number;
    "type"?: StoreOrderModelTypeEnum;
    "code"?: string;
    "status"?: StoreOrderModelStatusEnum;
    "receipt"?: string;
    "orderDate"?: Date;
    "deliveredDate"?: Date;
    "dayStoreDataId"?: number;
    "platformId"?: number;
    "platform"?: PlatformModel;
    "storeOrderPayments"?: Array<StoreOrderPaymentModel>;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDeliveryModel>;
    "storeOrderItemOrders"?: Array<StoreOrderItemOrderModel>;
    "storeVouchersOrder"?: Array<StoreVoucherOrderModel>;
}

export type StoreOrderModelTypeEnum = "SaleOrder" | "PreOrder";
export type StoreOrderModelStatusEnum = "Created" | "Delivered" | "Canceled" | "Failed";
export interface StoreOrderPayment {
    "storeOrderPaymentId"?: number;
    "paymentType"?: StoreOrderPaymentPaymentTypeEnum;
    "cardType"?: StoreOrderPaymentCardTypeEnum;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: StoreOrderPaymentStatusEnum;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "storeOrder"?: StoreOrder;
    "storeOrderId"?: number;
    "storeMoneyTraffics"?: Array<StoreMoneyTraffic>;
}

export type StoreOrderPaymentPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreOrderPaymentCardTypeEnum = "Normal" | "NoContact";
export type StoreOrderPaymentStatusEnum = "Success" | "PartialSuccess" | "Failed";
export interface StoreOrderPaymentModel {
    "storeOrderPaymentId"?: number;
    "paymentType"?: StoreOrderPaymentModelPaymentTypeEnum;
    "cardType"?: StoreOrderPaymentModelCardTypeEnum;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: StoreOrderPaymentModelStatusEnum;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "collectionModule"?: StoreOrderPaymentModelCollectionModuleEnum;
    "collectionLabel"?: string;
    "collectionInfo"?: string;
    "storeOrderId"?: number;
    "storeMoneyTraffics"?: Array<StoreMoneyTrafficModel>;
}

export type StoreOrderPaymentModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreOrderPaymentModelCardTypeEnum = "Normal" | "NoContact";
export type StoreOrderPaymentModelStatusEnum = "Success" | "PartialSuccess" | "Failed";
export type StoreOrderPaymentModelCollectionModuleEnum = "IngenicoIup";
export interface StoreOrderProductDelivery {
    "storeOrderProductDeliveryId"?: number;
    "status"?: StoreOrderProductDeliveryStatusEnum;
    "deliveryDate"?: Date;
    "storeOrderId"?: number;
    "storeOrder"?: StoreOrder;
    "neoEventCodeId"?: number;
    "neoEventCode"?: NeoEventCode;
    "productId"?: number;
    "product"?: Product;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "storeOrderProductOrderId"?: number;
    "storeOrderProductOrder"?: StoreOrderProductOrder;
}

export type StoreOrderProductDeliveryStatusEnum = "Ok" | "Failed" | "FailedExtraction" | "FailedRamp" | "FailedSas" | "Partial";
export interface StoreOrderProductDeliveryModel {
    "storeOrderProductDeliveryId"?: number;
    "status"?: StoreOrderProductDeliveryModelStatusEnum;
    "stepStatus"?: StoreOrderProductDeliveryModelStepStatusEnum;
    "deliveryDate"?: Date;
    "storeOrderId"?: number;
    "neoEventCodeId"?: number;
    "productId"?: number;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "storeOrderProductOrderId"?: number;
}

export type StoreOrderProductDeliveryModelStatusEnum = "Ok" | "Failed" | "FailedExtraction" | "FailedRamp" | "FailedSas" | "Partial";
export type StoreOrderProductDeliveryModelStepStatusEnum = "Ok" | "Failed" | "FailedAndCollect" | "FailedAndContinue";
export interface StoreOrderProductOrder {
    "productId"?: number;
    "product"?: Product;
    "vatId"?: number;
    "vat"?: Vat;
    "storeOrderMenuOrderId"?: number;
    "storeOrderMenuOrder"?: StoreOrderMenuOrder;
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDelivery>;
    "storeOrderItemOrderId"?: number;
    "source"?: StoreOrderProductOrderSourceEnum;
    "quantity"?: number;
    "orderDate"?: Date;
    "storeOrderId"?: number;
    "storeOrder"?: StoreOrder;
    "priceId"?: number;
    "price"?: Price;
}

export type StoreOrderProductOrderSourceEnum = "Sale" | "Smartphone" | "Contextual";
export interface StoreProductReception {
    "storeProductReceptionId"?: number;
    "type"?: StoreProductReceptionTypeEnum;
    "quantity"?: number;
    "quantityRemaining"?: number;
    "receptionDate"?: Date;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "productId"?: number;
    "product"?: Product;
    "dayStoreDataId"?: number;
    "dayStoreData"?: DayStoreData;
}

export type StoreProductReceptionTypeEnum = "Delivery" | "Unstock" | "Unknown";
export interface StoreProductReceptionModel {
    "storeProductReceptionId"?: number;
    "quantity"?: number;
    "quantityRemaining"?: number;
    "receptionDate"?: Date;
    "productSupplierId"?: number;
    "productId"?: number;
    "dayStoreDataId"?: number;
    "supplierOrderId"?: number;
}

export interface StoreRemoteCollectionModel {
    "remoteCollectionId"?: number;
    "collectionModule"?: StoreRemoteCollectionModelCollectionModuleEnum;
    "collectionStatus"?: StoreRemoteCollectionModelCollectionStatusEnum;
    "label"?: string;
    "data"?: string;
    "creationDate"?: Date;
}

export type StoreRemoteCollectionModelCollectionModuleEnum = "IngenicoIup";
export type StoreRemoteCollectionModelCollectionStatusEnum = "Ok" | "NotCompleted" | "CallFailed" | "NoCall";
export interface StoreSettings {
    "storeSettingsId"?: number;
    "maxOrderQuantity"?: number;
    "saleTimeout"?: number;
    "smartPhoneEnabled"?: boolean;
    "bagMinProducts"?: number;
    "bagMinVolume"?: number;
    "receiptTop"?: string;
    "receiptBottom"?: string;
    "lgapUpdateCron"?: string;
    "exportHistoryCron"?: string;
    "saveHistoryCron"?: string;
    "expirityCron"?: string;
    "expirityDaysBeforeThrow"?: number;
    "noSellTimeout"?: number;
    "noPaymentTimeout"?: number;
    "creationDate"?: Date;
    "userStoreId"?: number;
    "userStore"?: UserStore;
    "currencyId"?: number;
    "currency"?: Currency;
    "automaticLogout"?: boolean;
    "mngAfkExpiration"?: number;
    "productCodeUsage"?: boolean;
    "orderExpirationTime?": number;
    "enableSellDDM"?: boolean;
    "initRobotAuto"?: boolean;
    "receiptValDefault"?: boolean;
    "receiptQRTimeout"?: number;
    "downloadCsvHistos"?: string;
    "promoIntervalHmi"?: number;
    "orderTimeoutHmi"?: number;
}

export interface StoreSettingsModel {
    "storeSettingsId"?: number;
    "maxOrderQuantity"?: number;
    "saleTimeout"?: number;
    "smartPhoneEnabled"?: boolean;
    "bagMinProducts"?: number;
    "bagMinVolume"?: number;
    "receiptTop"?: string;
    "receiptBottom"?: string;
    "lgapUpdateCron"?: string;
    "exportHistoryCron"?: string;
    "saveHistoryCron"?: string;
    "expirityCron"?: string;
    "expirityDaysBeforeThrow"?: number;
    "noSellTimeout"?: number;
    "noPaymentTimeout"?: number;
    "creationDate"?: Date;
    "currencyId"?: number;
    "automaticLogout"?: boolean;
    "mngAfkExpiration"?: number;
    "productCodeUsage"?: boolean;
    "orderExpirationTime?": number;
    "initRobotAuto"?: boolean;
    "enableSellDDM"?: boolean;
    "receiptValDefault"?: boolean;
    "receiptQRTimeout"?: number;
    "downloadCsvHistos"?: string;
    "promoIntervalHmi"?: number;
    "orderTimeoutHmi"?: number;
}

export interface StoreStockOperation {
    "storeStockOperationId"?: number;
    "type"?: StoreStockOperationTypeEnum;
    "quantityStock"?: number;
    "quantityStored"?: number;
    "operationDate"?: Date;
    "dayStoreDataId"?: number;
    "dayStoreData"?: DayStoreData;
    "productId"?: number;
    "product"?: Product;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
}

export type StoreStockOperationTypeEnum = "ManagerStockOut" | "ManagerStockIn" | "ManagerStoredIn" | "ManagerStoredOut" | "ExpirityOut" | "ManualMarkdown";
export interface StoreStockOperationModel {
    "storeStockOperationId"?: number;
    "type"?: StoreStockOperationModelTypeEnum;
    "quantityStock"?: number;
    "quantityStored"?: number;
    "operationDate"?: Date;
    "dayStoreDataId"?: number;
    "productId"?: number;
    "productSupplierId"?: number;
}

export interface StoreVoucherOrderModel {
    "voucherOrderId"?: number;
    "voucherId"?: number;
    "amountType"?: VoucherAmountTypeEnum;
    "amount"?: number;
    "orderId"?: number;
    "creationDate"?: Date;
    "maxAmount"?: number;
}

export type StoreStockOperationModelTypeEnum = "ManagerStockOut" | "ManagerStockIn" | "ManagerStoredIn" | "ManagerStoredOut" | "ExpirityOut" | "ManualMarkdown";
export interface SubFamily {
    "subFamilyId"?: number;
    "name"?: string;
    "order"?: number;
    "family"?: Family;
    "familyId"?: number;
    "imageId"?: number;
    "image"?: Image;
    "creationDate"?: Date;
    "subFamilyCustoms"?: Array<SubFamilyCustom>;
    "productSubFamilies"?: Array<ProductSubFamily>;
    "submissionSubFamilies"?: Array<SubmissionSubFamily>;
}

export interface SubFamilyCustom {
    "subFamilyId"?: number;
    "subFamily"?: SubFamily;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: Image;
    "languageId"?: number;
    "language"?: Language;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
    "accountId"?: number;
    "account"?: Account;
}

export interface SubFamilyCustomModel {
    "subFamilyId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface SubFamilyModel {
    "subFamilyId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "order"?: number;
    "familyId"?: number;
    "creationDate"?: Date;
    "subFamilyCustoms"?: Array<SubFamilyCustomModel>;
}

export interface SubmissionCurrency {
    "currencyId"?: number;
    "currency"?: Currency;
    "submissionId"?: number;
    "type"?: SubmissionCurrencyTypeEnum;
    "content"?: string;
    "status"?: SubmissionCurrencyStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionCurrencyTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionCurrencyStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionFamily {
    "familyId"?: number;
    "family"?: Family;
    "submissionId"?: number;
    "type"?: SubmissionFamilyTypeEnum;
    "content"?: string;
    "status"?: SubmissionFamilyStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionFamilyTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionFamilyStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionMenu {
    "menuId"?: number;
    "menu"?: Menu;
    "submissionId"?: number;
    "type"?: SubmissionMenuTypeEnum;
    "content"?: string;
    "status"?: SubmissionMenuStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionMenuTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionMenuStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionMenuCategory {
    "menuCategoryId"?: number;
    "menuCategory"?: MenuCategory;
    "submissionId"?: number;
    "type"?: SubmissionMenuCategoryTypeEnum;
    "content"?: string;
    "status"?: SubmissionMenuCategoryStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionMenuCategoryTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionMenuCategoryStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionPriceName {
    "priceNameId"?: number;
    "priceName"?: PriceName;
    "submissionId"?: number;
    "type"?: SubmissionPriceNameTypeEnum;
    "content"?: string;
    "status"?: SubmissionPriceNameStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionPriceNameTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionPriceNameStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionProduct {
    "productId"?: number;
    "product"?: Product;
    "submissionId"?: number;
    "type"?: SubmissionProductTypeEnum;
    "content"?: string;
    "status"?: SubmissionProductStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionProductTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionProductStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionPromotion {
    "promotionId"?: number;
    "promotion"?: Promotion;
    "submissionId"?: number;
    "type"?: SubmissionPromotionTypeEnum;
    "content"?: string;
    "status"?: SubmissionPromotionStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionPromotionTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionPromotionStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSubFamily {
    "subFamilyId"?: number;
    "subFamily"?: SubFamily;
    "submissionId"?: number;
    "type"?: SubmissionSubFamilyTypeEnum;
    "content"?: string;
    "status"?: SubmissionSubFamilyStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSubFamilyTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSubFamilyStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSupplierAdvert {
    "supplierAdvertId"?: number;
    "supplierAdvert"?: SupplierAdvert;
    "submissionId"?: number;
    "type"?: SubmissionSupplierAdvertTypeEnum;
    "content"?: string;
    "status"?: SubmissionSupplierAdvertStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSupplierAdvertTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSupplierAdvertStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSupplierDelivery {
    "supplierDeliveryId"?: number;
    "supplierDelivery"?: SupplierDelivery;
    "submissionId"?: number;
    "type"?: SubmissionSupplierDeliveryTypeEnum;
    "content"?: string;
    "status"?: SubmissionSupplierDeliveryStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSupplierDeliveryTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSupplierDeliveryStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSupplierMain {
    "supplierMainId"?: number;
    "supplierMain"?: SupplierMain;
    "submissionId"?: number;
    "type"?: SubmissionSupplierMainTypeEnum;
    "content"?: string;
    "status"?: SubmissionSupplierMainStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSupplierMainTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSupplierMainStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSupplierPayment {
    "supplierPaymentId"?: number;
    "supplierPayment"?: SupplierPayment;
    "submissionId"?: number;
    "type"?: SubmissionSupplierPaymentTypeEnum;
    "content"?: string;
    "status"?: SubmissionSupplierPaymentStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSupplierPaymentTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSupplierPaymentStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionSupplierSub {
    "supplierSubId"?: number;
    "supplierSub"?: SupplierSub;
    "submissionId"?: number;
    "type"?: SubmissionSupplierSubTypeEnum;
    "content"?: string;
    "status"?: SubmissionSupplierSubStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionSupplierSubTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionSupplierSubStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionUnit {
    "unitId"?: number;
    "unit"?: Unit;
    "submissionId"?: number;
    "type"?: SubmissionUnitTypeEnum;
    "content"?: string;
    "status"?: SubmissionUnitStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionUnitTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionUnitStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubmissionVat {
    "vatId"?: number;
    "vat"?: Vat;
    "submissionId"?: number;
    "type"?: SubmissionVatTypeEnum;
    "content"?: string;
    "status"?: SubmissionVatStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "user"?: User;
    "moderatorId"?: number;
    "moderator"?: Account;
}

export type SubmissionVatTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionVatStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface Subscription {
    "subscriptionId"?: number;
    "enabled"?: boolean;
    "userId"?: number;
    "user"?: User;
    "storeId"?: number;
    "store"?: Store;
    "creationDate"?: Date;
}

export interface Supplier {
    "supplierId"?: number;
    "name"?: string;
    "code"?: string;
    "deliveryFeeFix"?: number;
    "deliveryFeeVar"?: number;
    "deliveryFeeVarUnitId"?: number;
    "deliveryFeeVarUnit"?: Unit;
    "creationDate"?: Date;
    "productDefaults"?: Array<Product>;
    "supplierDiscounts"?: Array<SupplierDiscount>;
    "supplierFamilyRotations"?: Array<SupplierFamilyRotation>;
    "supplierPaymentDeadlines"?: Array<SupplierPaymentDeadline>;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplier>;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplier>;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplier>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
}

export interface SupplierAdvert {
    "supplierAdvertId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplier>;
    "submissionSupplierAdverts"?: Array<SubmissionSupplierAdvert>;
}

export interface SupplierAdvertSupplier {
    "supplierAdvertSupplierId"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
    "supplierAdvertId"?: number;
    "supplierAdvert"?: SupplierAdvert;
}

export interface SupplierAdvertSupplierModel {
    "supplierAdvertSupplierId"?: number;
    "supplierId"?: number;
    "supplierAdvertId"?: number;
}

export interface SupplierDelivery {
    "supplierDeliveryId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplier>;
    "submissionSupplierDeliveries"?: Array<SubmissionSupplierDelivery>;
}

export interface SupplierDeliverySupplier {
    "supplierDeliverySupplierId"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
    "supplierDeliveryId"?: number;
    "supplierDelivery"?: SupplierDelivery;
}

export interface SupplierDeliverySupplierModel {
    "supplierDeliverySupplierId"?: number;
    "supplierId"?: number;
    "supplierDeliveryId"?: number;
}

export interface SupplierDiscount {
    "supplierDiscountId"?: number;
    "value"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
}

export interface SupplierDiscountModel {
    "supplierDiscountId"?: number;
    "value"?: number;
    "supplierId"?: number;
    "storeGroupId"?: number;
}

export interface SupplierFamilyRotation {
    "supplierFamilyRotationId"?: number;
    "family"?: string;
    "dayOfWeek"?: SupplierFamilyRotationDayOfWeekEnum;
    "period"?: number;
    "priorNotice"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
}

export type SupplierFamilyRotationDayOfWeekEnum = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" | "FiveAWeek" | "SixAWeek" | "Everyday";
export interface SupplierFamilyRotationModel {
    "supplierFamilyRotationId"?: number;
    "family"?: string;
    "dayOfWeek"?: SupplierFamilyRotationModelDayOfWeekEnum;
    "period"?: number;
    "priorNotice"?: number;
    "supplierId"?: number;
}

export type SupplierFamilyRotationModelDayOfWeekEnum = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" | "FiveAWeek" | "SixAWeek" | "Everyday";
export interface SupplierMain {
    "supplierSubs"?: Array<SupplierSub>;
    "productSuppliers"?: Array<ProductSupplier>;
    "submissionSupplierMains"?: Array<SubmissionSupplierMain>;
    "supplierId"?: number;
    "name"?: string;
    "code"?: string;
    "deliveryFeeFix"?: number;
    "deliveryFeeVar"?: number;
    "deliveryFeeVarUnitId"?: number;
    "deliveryFeeVarUnit"?: Unit;
    "creationDate"?: Date;
    "productDefaults"?: Array<Product>;
    "supplierDiscounts"?: Array<SupplierDiscount>;
    "supplierFamilyRotations"?: Array<SupplierFamilyRotation>;
    "supplierPaymentDeadlines"?: Array<SupplierPaymentDeadline>;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplier>;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplier>;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplier>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
}

export interface SupplierModel {
    "supplierId"?: number;
    "supplierType"?: SupplierModelSupplierTypeEnum;
    "name"?: string;
    "code"?: string;
    "supplierMainId"?: number;
    "deliveryFeeFix"?: number;
    "deliveryFeeVar"?: number;
    "deliveryFeeVarUnitId"?: number;
    "creationDate"?: Date;
    "supplierDiscounts"?: Array<SupplierDiscountModel>;
    "supplierPaymentDeadlines"?: Array<SupplierPaymentDeadlineModel>;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplierModel>;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplierModel>;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplierModel>;
    "supplierFamilyRotations"?: Array<SupplierFamilyRotationModel>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscountModel>;
}

export type SupplierModelSupplierTypeEnum = "SupplierMain" | "SupplierSub";
export interface SupplierOrder {
    "supplierOrderId"?: number;
    "name"?: string;
    "status"?: SupplierOrderStatusEnum;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "creationDate"?: Date;
    "receptionDate"?: Date;
    "supplierOrderProductSuppliers"?: Array<SupplierOrderProductSupplier>;
    "platformId"?: number;
}

export type SupplierOrderStatusEnum = "NotReceived" | "PartiallyReceived" | "Received";
export interface SupplierOrderModel {
    "supplierOrderId"?: number;
    "name"?: string;
    "status"?: string;
    "storeAccountId"?: number;
    "creationDate"?: Date;
    "supplierOrderProductSuppliers"?: Array<SupplierOrderProductSupplierModel>;
    "platformId"?: number;
}

export interface SupplierOrderProductSupplier {
    "supplierOrderProductSupplierId"?: number;
    "packQuantity"?: number;
    "quantityReceived"?: number;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplier;
    "supplierOrderId"?: number;
    "supplierOrder"?: SupplierOrder;
}

export interface SupplierOrderProductSupplierModel {
    "supplierOrderProductSupplierId"?: number;
    "packQuantity"?: number;
    "productSupplierId"?: number;
    "productSupplier"?: ProductSupplierModel;
    "productId"?: number;
    "externalProductId"?: number;
    "supplierOrderId"?: number;
}

export interface SupplierPayment {
    "supplierPaymentId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplier>;
    "submissionSupplierPayments"?: Array<SubmissionSupplierPayment>;
}

export interface SupplierPaymentDeadline {
    "supplierPaymentDeadlineId"?: number;
    "days"?: number;
    "type"?: SupplierPaymentDeadlineTypeEnum;
    "dayOfTheMonth"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
}

export type SupplierPaymentDeadlineTypeEnum = "Exact" | "EndOfMonth";
export interface SupplierPaymentDeadlineModel {
    "supplierPaymentDeadlineId"?: number;
    "days"?: number;
    "type"?: SupplierPaymentDeadlineModelTypeEnum;
    "dayOfTheMonth"?: number;
    "supplierId"?: number;
}

export type SupplierPaymentDeadlineModelTypeEnum = "Exact" | "EndOfMonth";
export interface SupplierPaymentSupplier {
    "supplierPaymentSupplierId"?: number;
    "supplierId"?: number;
    "supplier"?: Supplier;
    "supplierPaymentId"?: number;
    "supplierPayment"?: SupplierPayment;
}

export interface SupplierPaymentSupplierModel {
    "supplierPaymentSupplierId"?: number;
    "supplierId"?: number;
    "supplierPaymentId"?: number;
}

export interface SupplierSub {
    "supplierMainId"?: number;
    "supplierMain"?: SupplierMain;
    "productSuppliers"?: Array<ProductSupplier>;
    "submissionSupplierSubs"?: Array<SubmissionSupplierSub>;
    "supplierId"?: number;
    "name"?: string;
    "code"?: string;
    "deliveryFeeFix"?: number;
    "deliveryFeeVar"?: number;
    "deliveryFeeVarUnitId"?: number;
    "deliveryFeeVarUnit"?: Unit;
    "creationDate"?: Date;
    "productDefaults"?: Array<Product>;
    "supplierDiscounts"?: Array<SupplierDiscount>;
    "supplierFamilyRotations"?: Array<SupplierFamilyRotation>;
    "supplierPaymentDeadlines"?: Array<SupplierPaymentDeadline>;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplier>;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplier>;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplier>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
}

export interface SupplierVolumeDiscount {
    "supplierVolumeDiscountId"?: number;
    "volume"?: number;
    "amount"?: number;
    "currencyId"?: number;
    "currency"?: Currency;
    "supplierId"?: number;
    "supplier"?: Supplier;
    "unitId"?: number;
    "unit"?: Unit;
    "currencyUnitId"?: number;
    "currencyUnit"?: Currency;
    "storeGroupId"?: number;
    "storeGroup"?: StoreGroup;
}

export interface SupplierVolumeDiscountModel {
    "supplierVolumeDiscountId"?: number;
    "volume"?: number;
    "amount"?: number;
    "currencyId"?: number;
    "supplierId"?: number;
    "unitId"?: number;
    "currencyUnitId"?: number;
    "storeGroupId"?: number;
}

export interface Tag {
    "tagId"?: number;
    "value"?: string;
    "creationDate"?: Date;
    "storeItemTags"?: Array<StoreItemTag>;
}

export interface TempHandlerState {
    "version"?: string;
    "status"?: TempHandlerStateStatusEnum;
}

export type TempHandlerStateStatusEnum = "Ok" | "Ko";
export interface TextHmi { 
    "textHmiId"?: number;
    "textName"?: string;
    "defaultText"?: string;
    "textsTranslations"?: Array<TextTranslationHmi>;
}

export interface TextHmiModel { 
    "textHmiId"?: number;
    "textName"?: string;
    "defaultText"?: string;
    "textsTranslations"?: Array<TextTranslationHmiModel>;
}

export interface TextTranslationHmi { 
    "textTranslationHmiId"?: number;
    "textHmiId"?: number;
    "textHmi"?: TextHmi;
    "languageId"?: number;
    "language"?: Language;
    "translation"?: string;
}

export interface TextTranslationHmiModel { 
    "textTranslationHmiId"?: number;
    "textHmiId"?: number;
    "languageId"?: number;
    "translation"?: string;
}

export interface TokenModel {
    "token"?: string;
    "username"?: string;
    "roles"?: Array<string>;
    "expirationDate"?: Date;
    "isValid"?: boolean;
}

export interface TurnoverModel {
    "amount"?: number;
}

export interface Unit {
    "unitId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "type"?: UnitTypeEnum;
    "productVolumeUnits"?: Array<Product>;
    "productRelativeUnits"?: Array<Product>;
    "unitConversionsFrom"?: Array<UnitConversion>;
    "unitConversionsTo"?: Array<UnitConversion>;
    "supplierDeliveryFeeVars"?: Array<Supplier>;
    "submissionUnits"?: Array<SubmissionUnit>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscount>;
}

export type UnitTypeEnum = "Product" | "ExternalProduct";
export interface UnitConversion {
    "unitConversionId"?: number;
    "value"?: number;
    "unitFromId"?: number;
    "unitFrom"?: Unit;
    "unitToId"?: number;
    "unitTo"?: Unit;
}

export interface UnitConversionModel {
    "unitConversionId"?: number;
    "value"?: number;
    "unitFromId"?: number;
    "unitToId"?: number;
}

export interface UnitModel {
    "unitId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "type"?: UnitModelTypeEnum;
    "unitConversionsFrom"?: Array<UnitConversionModel>;
    "unitConversionsTo"?: Array<UnitConversionModel>;
}

export type UnitModelTypeEnum = "Product" | "ExternalProduct";
export interface UpdateDaysToProjectModel {
    "productId"?: number;
    "daysToProject"?: number;
}

export interface UpdatePricesResultModel {
    "pricesUp"?: { [key: string]: PriceModel; };
    "productVatsUp"?: { [key: string]: ProductVatModel; };
}

export interface UpdateProdSupplierResultModel {
    "prodSuppliersUp"?: { [key: string]: ProductSupplierModel; };
}

export interface UpdateProductSizeResultModel {
    "prodSizesUp"?: { [key: string]: ProductModel; };
}

export interface UpdateQuantityRemainingModel {
    "productId"?: number;
    "quantity"?: number;
    "externalProductId"?: number;
}

export interface User {
    "userId"?: number;
    "email"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "creationDate"?: Date;
    "accountId"?: number;
    "account"?: Account;
    "languageId"?: number;
    "language"?: Language;
    "userLgapSettings"?: UserLgapSettings;
    "subscriptions"?: Array<Subscription>;
}

export interface UserLgapSettings {
    "userLgapSettingsId"?: number;
    "dataGridsState"?: string;
    "userId"?: number;
    "user"?: User;
}

export interface UserStore {
    "userStoreId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "remoteAccountId"?: number;
    "email"?: string;
    "phone"?: string;
    "firstname"?: string;
    "lastname"?: string;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccount;
    "productReceptions"?: Array<ProductReception>;
    "storeSettingses"?: Array<StoreSettings>;
}

export interface UserStoreModel {
    "userStoreId"?: number;
    "name"?: string;
    "creationDate"?: Date;
    "storeAccountId"?: number;
    "storeAccount"?: StoreAccountModel;
}

export interface ValidateSupplierOrderModel {
    "supplierOrderId"?: number;
    "supplierOrderProducts"?: Array<ValidateSupplierOrderProductModel>;
}

export interface ValidateSupplierOrderProductModel {
    "supplierOrderProductSupplierId"?: number;
    "quantity"?: number;
}

export interface ValidateSupplierOrderResultModel {
    "productReceptions"?: Array<ProductReceptionModel>;
    "supplierOrder"?: SupplierOrderModel;
}

export interface Vat {
    "vatId"?: number;
    "name"?: string;
    "value"?: number;
    "creationDate"?: Date;
    "countryVats"?: Array<CountryVat>;
    "productSuppliers"?: Array<ProductSupplier>;
    "productVats"?: Array<ProductVat>;
    "submissionVats"?: Array<SubmissionVat>;
    "storeOrderProductOrders"?: Array<StoreOrderProductOrder>;
}

export interface VatModel {
    "vatId"?: number;
    "name"?: string;
    "value"?: number;
    "creationDate"?: Date;
    "countryVats"?: Array<CountryVatModel>;
}

export interface Voucher {
    "voucherId"?: number;
    "code"?: string;
    "amountType"?: VoucherAmountTypeEnum;
    "amount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "maxUse"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "creationDate"?: Date;
    "sourceOrderId"?: number;
    "sourceOrder"?: Order;
    "voucherCampaignId"?: number;
    "voucherCampaign"?: VoucherCampaign;
    "voucherOrders"?: Array<VoucherOrder>;
}

export type VoucherAmountTypeEnum = "Flat" | "Percentage";
export interface VoucherCampaign {
    "voucherCampaignId"?: number;
    "enabled"?: boolean;
    "type"?: VoucherCampaignTypeEnum;
    "name"?: string;
    "creationDate"?: Date;
    "startDate"?: Date;
    "endDate"?: Date;
    "amount"?: number;
    "minAmount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "percentageOfOrder"?: number;
    "number"?: number;
    "vouchers"?: Array<Voucher>;
}

export type VoucherCampaignTypeEnum = "Normal" | "Automatic";
export interface VoucherCampaignModel {
    "voucherCampaignId"?: number;
    "type"?: VoucherCampaignModelTypeEnum;
    "name"?: string;
    "creationDate"?: Date;
    "startDate"?: Date;
    "endDate"?: Date;
    "amount"?: number;
    "minAmount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "percentageOfOrder"?: number;
}

export type VoucherCampaignModelTypeEnum = "Normal" | "Automatic";
export interface VoucherModel {
    "voucherId"?: number;
    "code"?: string;
    "amountType"?: VoucherModelAmountTypeEnum;
    "amount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "enabled"?: boolean;
    "maxUse"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "creationDate"?: Date;
    "sourceOrderId"?: number;
    "voucherCampaignId"?: number;
    "voucherOrders"?: Array<VoucherOrderModel>;
}

export type VoucherModelAmountTypeEnum = "Flat" | "Percentage";
export interface VoucherOrder {
    "voucherOrderId"?: number;
    "voucherId"?: number;
    "voucher"?: Voucher;
    "orderId"?: number;
    "order"?: Order;
    "creationDate"?: Date;
}

export interface VoucherOrderModel {
    "voucherOrderId"?: number;
    "voucherId"?: number;
    "voucher"?: VoucherModel;
    "orderId"?: number;
    "creationDate"?: Date;
}

export interface VouchersHistoryResultModel {
    "vouchers"?: Array<VoucherModel>;
    "salePayments"?: { [key: string]: SalePaymentModel; };
    "voucherCampaigns"?: { [key: string]: VoucherCampaignModel; };
}

export interface VouchersResultModel {
    "vouchers"?: Array<VoucherModel>;
    "voucherCampaigns"?: { [key: string]: VoucherCampaignModel; };
}



/**
 * AccountApi - fetch parameter creator
 */
export const AccountApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/CreateToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getCurrentUser(options?: any): FetchArgs {
        const baseUrl = `/api/Account/GetCurrentUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    logout(options?: any): FetchArgs {
        const baseUrl = `/api/Account/Logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AccountApi - functional programming interface
 */
export const AccountApiFp = {
    /**
     *
     * @param model
     */
    createToken(params: { "model"?: LoginModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenModel> {
        const fetchArgs = AccountApiFetchParamCreator.createToken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getCurrentUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrentUserManagerModel> {
        const fetchArgs = AccountApiFetchParamCreator.getCurrentUser(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.logout(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AccountApi - object-oriented interface
 */
export class AccountApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any) {
        return AccountApiFp.createToken(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getCurrentUser(options?: any) {
        return AccountApiFp.getCurrentUser(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    logout(options?: any) {
        return AccountApiFp.logout(options)(this.fetch, this.basePath);
    }
};

/**
 * AccountApi - factory interface
 */
export const AccountApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createToken(params: {  "model"?: LoginModel; }, options?: any) {
            return AccountApiFp.createToken(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getCurrentUser(options?: any) {
            return AccountApiFp.getCurrentUser(options)(fetch, basePath);
        },
        /**
         *
         */
        logout(options?: any) {
            return AccountApiFp.logout(options)(fetch, basePath);
        },
    };
};


/**
 * ApplicationApi - fetch parameter creator
 */
export const ApplicationApiFetchParamCreator = {
    /**
     *
     */
    restart(options?: any): FetchArgs {
        const baseUrl = `/api/Application/Restart`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    smartPhoneStatus(options?: any): FetchArgs {
        const baseUrl = `/api/Application/SmartPhoneStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApplicationApi - functional programming interface
 */
export const ApplicationApiFp = {
    /**
     *
     */
    restart(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ApplicationApiFetchParamCreator.restart(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    smartPhoneStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SmartPhoneStatusModel> {
        const fetchArgs = ApplicationApiFetchParamCreator.smartPhoneStatus(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApplicationApi - object-oriented interface
 */
export class ApplicationApi extends BaseAPI {
    /**
     *
     */
    restart(options?: any) {
        return ApplicationApiFp.restart(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    smartPhoneStatus(options?: any) {
        return ApplicationApiFp.smartPhoneStatus(options)(this.fetch, this.basePath);
    }
};

/**
 * ApplicationApi - factory interface
 */
export const ApplicationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        restart(options?: any) {
            return ApplicationApiFp.restart(options)(fetch, basePath);
        },
        /**
         *
         */
        smartPhoneStatus(options?: any) {
            return ApplicationApiFp.smartPhoneStatus(options)(fetch, basePath);
        },
    };
};


/**
 * CardApi - fetch parameter creator
 */
export const CardApiFetchParamCreator = {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: {  "value"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Card/AskCollectTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    cancelTestPayment(options?: any): FetchArgs {
        const baseUrl = `/api/Card/CancelTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getState(options?: any): FetchArgs {
        const baseUrl = `/api/Card/GetState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    requestUpdateDateTime(options?: any): FetchArgs {
        const baseUrl = `/api/Card/RequestUpdateDateTime`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Card/StartTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    stopPaymentSession(options?: any): FetchArgs {
        const baseUrl = `/api/Card/StopPaymentSession`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     */
    updateCardEnabled(params: {  "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Card/UpdateCardEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     */
    updateNoContactEnabled(params: {  "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Card/UpdateNoContactEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CardApi - functional programming interface
 */
export const CardApiFp = {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: { "value"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.askCollectTestPayment(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    cancelTestPayment(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.cancelTestPayment(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CardHandlerStateModel> {
        const fetchArgs = CardApiFetchParamCreator.getState(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    requestUpdateDateTime(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.requestUpdateDateTime(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    startTestPayment(params: { "model"?: StartTestPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.startTestPayment(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    stopPaymentSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.stopPaymentSession(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     */
    updateCardEnabled(params: { "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.updateCardEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     */
    updateNoContactEnabled(params: { "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CardApiFetchParamCreator.updateNoContactEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CardApi - object-oriented interface
 */
export class CardApi extends BaseAPI {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: {  "value"?: number; }, options?: any) {
        return CardApiFp.askCollectTestPayment(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    cancelTestPayment(options?: any) {
        return CardApiFp.cancelTestPayment(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getState(options?: any) {
        return CardApiFp.getState(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    requestUpdateDateTime(options?: any) {
        return CardApiFp.requestUpdateDateTime(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any) {
        return CardApiFp.startTestPayment(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    stopPaymentSession(options?: any) {
        return CardApiFp.stopPaymentSession(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     */
    updateCardEnabled(params: {  "value"?: boolean; }, options?: any) {
        return CardApiFp.updateCardEnabled(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     */
    updateNoContactEnabled(params: {  "value"?: boolean; }, options?: any) {
        return CardApiFp.updateNoContactEnabled(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CardApi - factory interface
 */
export const CardApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param value
         */
        askCollectTestPayment(params: {  "value"?: number; }, options?: any) {
            return CardApiFp.askCollectTestPayment(params, options)(fetch, basePath);
        },
        /**
         *
         */
        cancelTestPayment(options?: any) {
            return CardApiFp.cancelTestPayment(options)(fetch, basePath);
        },
        /**
         *
         */
        getState(options?: any) {
            return CardApiFp.getState(options)(fetch, basePath);
        },
        /**
         *
         */
        requestUpdateDateTime(options?: any) {
            return CardApiFp.requestUpdateDateTime(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any) {
            return CardApiFp.startTestPayment(params, options)(fetch, basePath);
        },
        /**
         *
         */
        stopPaymentSession(options?: any) {
            return CardApiFp.stopPaymentSession(options)(fetch, basePath);
        },
        /**
         *
         * @param value
         */
        updateCardEnabled(params: {  "value"?: boolean; }, options?: any) {
            return CardApiFp.updateCardEnabled(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         */
        updateNoContactEnabled(params: {  "value"?: boolean; }, options?: any) {
            return CardApiFp.updateNoContactEnabled(params, options)(fetch, basePath);
        },
    };
};


/**
 * CashApi - fetch parameter creator
 */
export const CashApiFetchParamCreator = {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: {  "value"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/AskCollectTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    cancelTestPayment(options?: any): FetchArgs {
        const baseUrl = `/api/Cash/CancelTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    emptyHoppers(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/EmptyHoppers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getState(options?: any): FetchArgs {
        const baseUrl = `/api/Cash/GetState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    rechargeHopper(params: {  "model"?: HmiHopperTrafficModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/RechargeHopper`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    resetCashRegistry(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/ResetCashRegistry`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    resetStoredMoney(options?: any): FetchArgs {
        const baseUrl = `/api/Cash/ResetStoredMoney`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/StartTestPayment`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    stopPaymentSession(options?: any): FetchArgs {
        const baseUrl = `/api/Cash/StopPaymentSession`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     */
    updateCashBillEnabled(params: {  "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/UpdateCashBillEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     */
    updateCashEnabled(params: {  "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Cash/UpdateCashEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CashApi - functional programming interface
 */
export const CashApiFp = {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: { "value"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.askCollectTestPayment(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    cancelTestPayment(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.cancelTestPayment(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    emptyHoppers(params: { "model"?: Array<HmiHopperTrafficModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HmiHopperTrafficModel>> {
        const fetchArgs = CashApiFetchParamCreator.emptyHoppers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CashHandlerStateModel> {
        const fetchArgs = CashApiFetchParamCreator.getState(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    rechargeHopper(params: { "model"?: HmiHopperTrafficModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HmiHopperTrafficModel> {
        const fetchArgs = CashApiFetchParamCreator.rechargeHopper(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    resetCashRegistry(params: { "model"?: Array<HmiHopperTrafficModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HmiHopperTrafficModel>> {
        const fetchArgs = CashApiFetchParamCreator.resetCashRegistry(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    resetStoredMoney(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HmiMoneyResetModel> {
        const fetchArgs = CashApiFetchParamCreator.resetStoredMoney(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    startTestPayment(params: { "model"?: StartTestPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.startTestPayment(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    stopPaymentSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.stopPaymentSession(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     */
    updateCashBillEnabled(params: { "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.updateCashBillEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     */
    updateCashEnabled(params: { "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CashApiFetchParamCreator.updateCashEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CashApi - object-oriented interface
 */
export class CashApi extends BaseAPI {
    /**
     *
     * @param value
     */
    askCollectTestPayment(params: {  "value"?: number; }, options?: any) {
        return CashApiFp.askCollectTestPayment(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    cancelTestPayment(options?: any) {
        return CashApiFp.cancelTestPayment(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    emptyHoppers(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any) {
        return CashApiFp.emptyHoppers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getState(options?: any) {
        return CashApiFp.getState(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    rechargeHopper(params: {  "model"?: HmiHopperTrafficModel; }, options?: any) {
        return CashApiFp.rechargeHopper(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    resetCashRegistry(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any) {
        return CashApiFp.resetCashRegistry(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    resetStoredMoney(options?: any) {
        return CashApiFp.resetStoredMoney(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any) {
        return CashApiFp.startTestPayment(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    stopPaymentSession(options?: any) {
        return CashApiFp.stopPaymentSession(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     */
    updateCashBillEnabled(params: {  "value"?: boolean; }, options?: any) {
        return CashApiFp.updateCashBillEnabled(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     */
    updateCashEnabled(params: {  "value"?: boolean; }, options?: any) {
        return CashApiFp.updateCashEnabled(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CashApi - factory interface
 */
export const CashApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param value
         */
        askCollectTestPayment(params: {  "value"?: number; }, options?: any) {
            return CashApiFp.askCollectTestPayment(params, options)(fetch, basePath);
        },
        /**
         *
         */
        cancelTestPayment(options?: any) {
            return CashApiFp.cancelTestPayment(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        emptyHoppers(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any) {
            return CashApiFp.emptyHoppers(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getState(options?: any) {
            return CashApiFp.getState(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        rechargeHopper(params: {  "model"?: HmiHopperTrafficModel; }, options?: any) {
            return CashApiFp.rechargeHopper(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        resetCashRegistry(params: {  "model"?: Array<HmiHopperTrafficModel>; }, options?: any) {
            return CashApiFp.resetCashRegistry(params, options)(fetch, basePath);
        },
        /**
         *
         */
        resetStoredMoney(options?: any) {
            return CashApiFp.resetStoredMoney(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        startTestPayment(params: {  "model"?: StartTestPaymentModel; }, options?: any) {
            return CashApiFp.startTestPayment(params, options)(fetch, basePath);
        },
        /**
         *
         */
        stopPaymentSession(options?: any) {
            return CashApiFp.stopPaymentSession(options)(fetch, basePath);
        },
        /**
         *
         * @param value
         */
        updateCashBillEnabled(params: {  "value"?: boolean; }, options?: any) {
            return CashApiFp.updateCashBillEnabled(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         */
        updateCashEnabled(params: {  "value"?: boolean; }, options?: any) {
            return CashApiFp.updateCashEnabled(params, options)(fetch, basePath);
        },
    };
};


/**
 * ContactApi - fetch parameter creator
 */
export const ContactApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Contact/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: ContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Contact/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/Contact/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: ContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Contact/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ContactApi - functional programming interface
 */
export const ContactApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ContactApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: ContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactModel> {
        const fetchArgs = ContactApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ContactModel; }> {
        const fetchArgs = ContactApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: ContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactModel> {
        const fetchArgs = ContactApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ContactApi - object-oriented interface
 */
export class ContactApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return ContactApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: ContactModel; }, options?: any) {
        return ContactApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return ContactApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: ContactModel; }, options?: any) {
        return ContactApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ContactApi - factory interface
 */
export const ContactApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return ContactApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: ContactModel; }, options?: any) {
            return ContactApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return ContactApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: ContactModel; }, options?: any) {
            return ContactApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * ExportApi - fetch parameter creator
 */
export const ExportApiFetchParamCreator = {
    /**
     *
     */
    productReception(options?: any): FetchArgs {
        const baseUrl = `/api/Export/ProductReception`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ExportApi - functional programming interface
 */
export const ExportApiFp = {
    /**
     *
     */
    productReception(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ExportApiFetchParamCreator.productReception(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ExportApi - object-oriented interface
 */
export class ExportApi extends BaseAPI {
    /**
     *
     */
    productReception(options?: any) {
        return ExportApiFp.productReception(options)(this.fetch, this.basePath);
    }
};

/**
 * ExportApi - factory interface
 */
export const ExportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        productReception(options?: any) {
            return ExportApiFp.productReception(options)(fetch, basePath);
        },
    };
};


/**
 * FamilyApi - fetch parameter creator
 */
export const FamilyApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    getEntities(params: {  "model"?: ProductDetailsFilterModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getSubFamiliesByFamily(params: {  "model"?: ProductDetailsFilterModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/GetSubFamiliesByFamily`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * FamilyApi - functional programming interface
 */
export const FamilyApiFp = {
    /**
     *
     * @param model
     */
    getEntities(params: { "model"?: ProductDetailsFilterModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: FamilyModel; }> {
        const fetchArgs = FamilyApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getSubFamiliesByFamily(params: { "model"?: ProductDetailsFilterModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: SubFamilyModel; }> {
        const fetchArgs = FamilyApiFetchParamCreator.getSubFamiliesByFamily(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * FamilyApi - object-oriented interface
 */
export class FamilyApi extends BaseAPI {
    /**
     *
     * @param model
     */
    getEntities(params: {  "model"?: ProductDetailsFilterModel; }, options?: any) {
        return FamilyApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getSubFamiliesByFamily(params: {  "model"?: ProductDetailsFilterModel; }, options?: any) {
        return FamilyApiFp.getSubFamiliesByFamily(params, options)(this.fetch, this.basePath);
    }
};

/**
 * FamilyApi - factory interface
 */
export const FamilyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        getEntities(params: {  "model"?: ProductDetailsFilterModel; }, options?: any) {
            return FamilyApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getSubFamiliesByFamily(params: {  "model"?: ProductDetailsFilterModel; }, options?: any) {
            return FamilyApiFp.getSubFamiliesByFamily(params, options)(fetch, basePath);
        },
    };
};


/**
 * HistoryApi - fetch parameter creator
 */
export const HistoryApiFetchParamCreator = {
    /**
     *
     * @param salePaymentCardId
     */
    createProductReception(params: {  "salePaymentCardId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/CreateProductReception`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "salePaymentCardId": params["salePaymentCardId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getDayStoreDatas(params: {  "model"?: GetHistoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/GetDayStoreDatas`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getHistosCAv6(params: {  "model"?: GetHistoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/GetHistosCAv6`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getTodayMoneyStock(options?: any): FetchArgs {
        const baseUrl = `/api/History/GetTodayMoneyStock`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getTodayStoreOrders(options?: any): FetchArgs {
        const baseUrl = `/api/History/GetTodayStoreOrders`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HistoryApi - functional programming interface
 */
export const HistoryApiFp = {
    /**
     *
     * @param salePaymentCardId
     */
    createProductReception(params: { "salePaymentCardId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceResultString> {
        const fetchArgs = HistoryApiFetchParamCreator.createProductReception(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getDayStoreDatas(params: { "model"?: GetHistoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DayStoreDataModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.getDayStoreDatas(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getHistosCAv6(params: { "model"?: GetHistoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HistoCAModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.getHistosCAv6(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getTodayMoneyStock(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StoreDayMoneyStockModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.getTodayMoneyStock(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getTodayStoreOrders(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StoreOrderModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.getTodayStoreOrders(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HistoryApi - object-oriented interface
 */
export class HistoryApi extends BaseAPI {
    /**
     *
     * @param salePaymentCardId
     */
    createProductReception(params: {  "salePaymentCardId"?: number; }, options?: any) {
        return HistoryApiFp.createProductReception(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getDayStoreDatas(params: {  "model"?: GetHistoryModel; }, options?: any) {
        return HistoryApiFp.getDayStoreDatas(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getHistosCAv6(params: {  "model"?: GetHistoryModel; }, options?: any) {
        return HistoryApiFp.getHistosCAv6(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getTodayMoneyStock(options?: any) {
        return HistoryApiFp.getTodayMoneyStock(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getTodayStoreOrders(options?: any) {
        return HistoryApiFp.getTodayStoreOrders(options)(this.fetch, this.basePath);
    }
};

/**
 * HistoryApi - factory interface
 */
export const HistoryApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param salePaymentCardId
         */
        createProductReception(params: {  "salePaymentCardId"?: number; }, options?: any) {
            return HistoryApiFp.createProductReception(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getDayStoreDatas(params: {  "model"?: GetHistoryModel; }, options?: any) {
            return HistoryApiFp.getDayStoreDatas(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getHistosCAv6(params: {  "model"?: GetHistoryModel; }, options?: any) {
            return HistoryApiFp.getHistosCAv6(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getTodayMoneyStock(options?: any) {
            return HistoryApiFp.getTodayMoneyStock(options)(fetch, basePath);
        },
        /**
         *
         */
         getTodayStoreOrders(options?: any) {
            return HistoryApiFp.getTodayStoreOrders(options)(fetch, basePath);
        },
    };
};


/**
 * HmiApi - fetch parameter creator
 */
export const HmiApiFetchParamCreator = {
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/Hmi/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param models
     */
    updateHmisLanguage(params: {  "models"?: Array<HmiModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Hmi/UpdateHmisLanguage`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HmiApi - functional programming interface
 */
export const HmiApiFp = {
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HmiModel>> {
        const fetchArgs = HmiApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param models
     */
    updateHmisLanguage(params: { "models"?: Array<HmiModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: HmiModel; }> {
        const fetchArgs = HmiApiFetchParamCreator.updateHmisLanguage(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HmiApi - object-oriented interface
 */
export class HmiApi extends BaseAPI {
    /**
     *
     */
    getEntities(options?: any) {
        return HmiApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param models
     */
    updateHmisLanguage(params: {  "models"?: Array<HmiModel>; }, options?: any) {
        return HmiApiFp.updateHmisLanguage(params, options)(this.fetch, this.basePath);
    }
};

/**
 * HmiApi - factory interface
 */
export const HmiApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getEntities(options?: any) {
            return HmiApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param models
         */
        updateHmisLanguage(params: {  "models"?: Array<HmiModel>; }, options?: any) {
            return HmiApiFp.updateHmisLanguage(params, options)(fetch, basePath);
        },
    };
};


/**
 * HmiMoneyApi - fetch parameter creator
 */
export const HmiMoneyApiFetchParamCreator = {
    /**
     *
     */
    getHmiMonies(options?: any): FetchArgs {
        const baseUrl = `/api/HmiMoney/GetHmiMonies`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateHmiMoney(params: {  "model"?: HmiMoneyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/HmiMoney/UpdateHmiMoney`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HmiMoneyApi - functional programming interface
 */
export const HmiMoneyApiFp = {
    /**
     *
     */
    getHmiMonies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HmiMoneyModel>> {
        const fetchArgs = HmiMoneyApiFetchParamCreator.getHmiMonies(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateHmiMoney(params: { "model"?: HmiMoneyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HmiMoneyModel> {
        const fetchArgs = HmiMoneyApiFetchParamCreator.updateHmiMoney(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HmiMoneyApi - object-oriented interface
 */
export class HmiMoneyApi extends BaseAPI {
    /**
     *
     */
    getHmiMonies(options?: any) {
        return HmiMoneyApiFp.getHmiMonies(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateHmiMoney(params: {  "model"?: HmiMoneyModel; }, options?: any) {
        return HmiMoneyApiFp.updateHmiMoney(params, options)(this.fetch, this.basePath);
    }
};

/**
 * HmiMoneyApi - factory interface
 */
export const HmiMoneyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getHmiMonies(options?: any) {
            return HmiMoneyApiFp.getHmiMonies(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateHmiMoney(params: {  "model"?: HmiMoneyModel; }, options?: any) {
            return HmiMoneyApiFp.updateHmiMoney(params, options)(fetch, basePath);
        },
    };
};


/**
 * HmiNotificatorApi - fetch parameter creator
 */
export const HmiNotificatorApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    applicationState(params: {  "model"?: HmiApplicationStateModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/HmiNotificator/ApplicationState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    eventCreated(params: {  "model"?: NeoEventModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/HmiNotificator/EventCreated`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    ping(options?: any): FetchArgs {
        const baseUrl = `/api/HmiNotificator/Ping`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param hmiId
     */
    requestApplicationState(params: {  "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/HmiNotificator/RequestApplicationState`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param hmiId
     * @param state
     */
    setApplicationState(params: {  "hmiId"?: number; "state"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/HmiNotificator/SetApplicationState`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "hmiId": params["hmiId"],
            "state": params["state"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HmiNotificatorApi - functional programming interface
 */
export const HmiNotificatorApiFp = {
    /**
     *
     * @param model
     */
    applicationState(params: { "model"?: HmiApplicationStateModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HmiNotificatorApiFetchParamCreator.applicationState(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    eventCreated(params: { "model"?: NeoEventModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HmiNotificatorApiFetchParamCreator.eventCreated(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    ping(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HmiNotificatorApiFetchParamCreator.ping(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param hmiId
     */
    requestApplicationState(params: { "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HmiNotificatorApiFetchParamCreator.requestApplicationState(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param hmiId
     * @param state
     */
    setApplicationState(params: { "hmiId"?: number; "state"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HmiNotificatorApiFetchParamCreator.setApplicationState(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HmiNotificatorApi - object-oriented interface
 */
export class HmiNotificatorApi extends BaseAPI {
    /**
     *
     * @param model
     */
    applicationState(params: {  "model"?: HmiApplicationStateModel; }, options?: any) {
        return HmiNotificatorApiFp.applicationState(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    eventCreated(params: {  "model"?: NeoEventModel; }, options?: any) {
        return HmiNotificatorApiFp.eventCreated(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    ping(options?: any) {
        return HmiNotificatorApiFp.ping(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param hmiId
     */
    requestApplicationState(params: {  "hmiId"?: number; }, options?: any) {
        return HmiNotificatorApiFp.requestApplicationState(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param hmiId
     * @param state
     */
    setApplicationState(params: {  "hmiId"?: number; "state"?: string; }, options?: any) {
        return HmiNotificatorApiFp.setApplicationState(params, options)(this.fetch, this.basePath);
    }
};

/**
 * HmiNotificatorApi - factory interface
 */
export const HmiNotificatorApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        applicationState(params: {  "model"?: HmiApplicationStateModel; }, options?: any) {
            return HmiNotificatorApiFp.applicationState(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        eventCreated(params: {  "model"?: NeoEventModel; }, options?: any) {
            return HmiNotificatorApiFp.eventCreated(params, options)(fetch, basePath);
        },
        /**
         *
         */
        ping(options?: any) {
            return HmiNotificatorApiFp.ping(options)(fetch, basePath);
        },
        /**
         *
         * @param hmiId
         */
        requestApplicationState(params: {  "hmiId"?: number; }, options?: any) {
            return HmiNotificatorApiFp.requestApplicationState(params, options)(fetch, basePath);
        },
        /**
         *
         * @param hmiId
         * @param state
         */
        setApplicationState(params: {  "hmiId"?: number; "state"?: string; }, options?: any) {
            return HmiNotificatorApiFp.setApplicationState(params, options)(fetch, basePath);
        },
    };
};


/**
 * ImageApi - fetch parameter creator
 */
export const ImageApiFetchParamCreator = {
    /**
     *
     * @param imageName
     */
    getImage(params: {  "imageName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Image/GetImage`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "imageName": params["imageName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ImageApi - functional programming interface
 */
export const ImageApiFp = {
    /**
     *
     * @param imageName
     */
    getImage(params: { "imageName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImageApiFetchParamCreator.getImage(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ImageApi - object-oriented interface
 */
export class ImageApi extends BaseAPI {
    /**
     *
     * @param imageName
     */
    getImage(params: {  "imageName"?: string; }, options?: any) {
        return ImageApiFp.getImage(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ImageApi - factory interface
 */
export const ImageApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param imageName
         */
        getImage(params: {  "imageName"?: string; }, options?: any) {
            return ImageApiFp.getImage(params, options)(fetch, basePath);
        },
    };
};


/**
 * ImportApi - fetch parameter creator
 */
export const ImportApiFetchParamCreator = {
    /**
     *
     */
    getLastLgapImport(options?: any): FetchArgs {
        const baseUrl = `/api/Import/GetLastLgapImport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    hasStoreToken(options?: any): FetchArgs {
        const baseUrl = `/api/Import/HasStoreToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    runLgapImport(options?: any): FetchArgs {
        const baseUrl = `/api/Import/RunLgapImport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param storeToken
     */
    setStoreToken(params: {  "storeToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Import/SetStoreToken`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "storeToken": params["storeToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    startLgapImport(options?: any): FetchArgs {
        const baseUrl = `/api/Import/StartLgapImport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ImportApi - functional programming interface
 */
export const ImportApiFp = {
    /**
     *
     */
    getLastLgapImport(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoreImportModel> {
        const fetchArgs = ImportApiFetchParamCreator.getLastLgapImport(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    hasStoreToken(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = ImportApiFetchParamCreator.hasStoreToken(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    runLgapImport(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoreImportModel> {
        const fetchArgs = ImportApiFetchParamCreator.runLgapImport(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param storeToken
     */
    setStoreToken(params: { "storeToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImportApiFetchParamCreator.setStoreToken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    startLgapImport(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImportApiFetchParamCreator.startLgapImport(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ImportApi - object-oriented interface
 */
export class ImportApi extends BaseAPI {
    /**
     *
     */
    getLastLgapImport(options?: any) {
        return ImportApiFp.getLastLgapImport(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    hasStoreToken(options?: any) {
        return ImportApiFp.hasStoreToken(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    runLgapImport(options?: any) {
        return ImportApiFp.runLgapImport(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param storeToken
     */
    setStoreToken(params: {  "storeToken"?: string; }, options?: any) {
        return ImportApiFp.setStoreToken(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    startLgapImport(options?: any) {
        return ImportApiFp.startLgapImport(options)(this.fetch, this.basePath);
    }
};

/**
 * ImportApi - factory interface
 */
export const ImportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getLastLgapImport(options?: any) {
            return ImportApiFp.getLastLgapImport(options)(fetch, basePath);
        },
        /**
         *
         */
        hasStoreToken(options?: any) {
            return ImportApiFp.hasStoreToken(options)(fetch, basePath);
        },
        /**
         *
         */
        runLgapImport(options?: any) {
            return ImportApiFp.runLgapImport(options)(fetch, basePath);
        },
        /**
         *
         * @param storeToken
         */
        setStoreToken(params: {  "storeToken"?: string; }, options?: any) {
            return ImportApiFp.setStoreToken(params, options)(fetch, basePath);
        },
        /**
         *
         */
        startLgapImport(options?: any) {
            return ImportApiFp.startLgapImport(options)(fetch, basePath);
        },
    };
};


/**
 * InterventionsApi - fetch parameter creator
 */
export const InterventionsApiFetchParamCreator = {
    /**
     *
     * @param idIntervention
     */
    closeIntervention(params: {  "idIntervention"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Interventions/CloseIntervention`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["idIntervention"]) {
            fetchOptions.body = JSON.stringify(params["idIntervention"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createStoreInterventions(params: {  "model"?: InterventionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Interventions/CreateStoreInterventions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getStoreInterventions(options?: any): FetchArgs {
        const baseUrl = `/api/Interventions/GetStoreInterventions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * InterventionsApi - functional programming interface
 */
export const InterventionsApiFp = {
    /**
     *
     * @param idIntervention
     */
    closeIntervention(params: { "idIntervention"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = InterventionsApiFetchParamCreator.closeIntervention(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createStoreInterventions(params: { "model"?: InterventionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoreSettingsModel> {
        const fetchArgs = InterventionsApiFetchParamCreator.createStoreInterventions(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getStoreInterventions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InterventionModel>> {
        const fetchArgs = InterventionsApiFetchParamCreator.getStoreInterventions(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * InterventionsApi - object-oriented interface
 */
export class InterventionsApi extends BaseAPI {
    /**
     *
     * @param idIntervention
     */
    closeIntervention(params: {  "idIntervention"?: number; }, options?: any) {
        return InterventionsApiFp.closeIntervention(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createStoreInterventions(params: {  "model"?: InterventionModel; }, options?: any) {
        return InterventionsApiFp.createStoreInterventions(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getStoreInterventions(options?: any) {
        return InterventionsApiFp.getStoreInterventions(options)(this.fetch, this.basePath);
    }
};

/**
 * InterventionsApi - factory interface
 */
export const InterventionsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param idIntervention
         */
        closeIntervention(params: {  "idIntervention"?: number; }, options?: any) {
            return InterventionsApiFp.closeIntervention(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createStoreInterventions(params: {  "model"?: InterventionModel; }, options?: any) {
            return InterventionsApiFp.createStoreInterventions(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getStoreInterventions(options?: any) {
            return InterventionsApiFp.getStoreInterventions(options)(fetch, basePath);
        },
    };
};


/**
 * LogApi - fetch parameter creator
 */
export const LogApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    create(params: {  "model"?: LogModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Log/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LogApi - functional programming interface
 */
export const LogApiFp = {
    /**
     *
     * @param model
     */
    create(params: { "model"?: LogModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LogApi - object-oriented interface
 */
export class LogApi extends BaseAPI {
    /**
     *
     * @param model
     */
    create(params: {  "model"?: LogModel; }, options?: any) {
        return LogApiFp.create(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LogApi - factory interface
 */
export const LogApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        create(params: {  "model"?: LogModel; }, options?: any) {
            return LogApiFp.create(params, options)(fetch, basePath);
        },
    };
};


/**
 * MenuApi - fetch parameter creator
 */
export const MenuApiFetchParamCreator = {
    /**
     *
     * @param menuId
     * @param value
     */
    updateMenuEnabled(params: {  "menuId"?: number; "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/UpdateMenuEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "menuId": params["menuId"],
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MenuApi - functional programming interface
 */
export const MenuApiFp = {
    /**
     *
     * @param menuId
     * @param value
     */
    updateMenuEnabled(params: { "menuId"?: number; "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MenuApiFetchParamCreator.updateMenuEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MenuApi - object-oriented interface
 */
export class MenuApi extends BaseAPI {
    /**
     *
     * @param menuId
     * @param value
     */
    updateMenuEnabled(params: {  "menuId"?: number; "value"?: boolean; }, options?: any) {
        return MenuApiFp.updateMenuEnabled(params, options)(this.fetch, this.basePath);
    }
};

/**
 * MenuApi - factory interface
 */
export const MenuApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param menuId
         * @param value
         */
        updateMenuEnabled(params: {  "menuId"?: number; "value"?: boolean; }, options?: any) {
            return MenuApiFp.updateMenuEnabled(params, options)(fetch, basePath);
        },
    };
};


/**
 * MenuCategoriesApi - fetch parameter creator
 */
export const MenuCategoriesApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    deleteMenuCategory(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/DeleteMenuCategory`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteMenuCategoryTranslation(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/DeleteMenuCategoryTranslation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/CreateMenuCategory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/CreateMenuCategoryTranslation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/UpdateMenuCategory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategories/UpdateMenuCategoryTranslation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MenuCategoriesApi - functional programming interface
 */
export const MenuCategoriesApiFp = {
    /**
     *
     * @param id
     */
    deleteMenuCategory(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.deleteMenuCategory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteMenuCategoryTranslation(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.deleteMenuCategoryTranslation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createMenuCategory(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryModel> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.createMenuCategory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createMenuCategoryTranslation(params: { "model"?: MenuCategoryTranslationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryTranslationModel> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.createMenuCategoryTranslation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateMenuCategory(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryModel> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.updateMenuCategory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateMenuCategoryTranslation(params: { "model"?: MenuCategoryTranslationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryTranslationModel> {
        const fetchArgs = MenuCategoriesApiFetchParamCreator.updateMenuCategoryTranslation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MenuCategoriesApi - object-oriented interface
 */
export class MenuCategoriesApi extends BaseAPI {
    /**
     *
     * @param id
     */
    deleteMenuCategory(params: {  "id"?: number; }, options?: any) {
        return MenuCategoriesApiFp.deleteMenuCategory(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteMenuCategoryTranslation(params: {  "id"?: number; }, options?: any) {
        return MenuCategoriesApiFp.deleteMenuCategoryTranslation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoriesApiFp.createMenuCategory(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any) {
        return MenuCategoriesApiFp.createMenuCategoryTranslation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoriesApiFp.updateMenuCategory(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any) {
        return MenuCategoriesApiFp.updateMenuCategoryTranslation(params, options)(this.fetch, this.basePath);
    }
};

/**
 * MenuCategoriesApi - factory interface
 */
export const MenuCategoriesApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        deleteMenuCategory(params: {  "id"?: number; }, options?: any) {
            return MenuCategoriesApiFp.deleteMenuCategory(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteMenuCategoryTranslation(params: {  "id"?: number; }, options?: any) {
            return MenuCategoriesApiFp.deleteMenuCategoryTranslation(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoriesApiFp.createMenuCategory(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any) {
            return MenuCategoriesApiFp.createMenuCategoryTranslation(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateMenuCategory(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoriesApiFp.updateMenuCategory(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateMenuCategoryTranslation(params: {  "model"?: MenuCategoryTranslationModel; }, options?: any) {
            return MenuCategoriesApiFp.updateMenuCategoryTranslation(params, options)(fetch, basePath);
        },
    };
};


/**
 * NeoEventApi - fetch parameter creator
 */
export const NeoEventApiFetchParamCreator = {
    /**
     *
     * @param neoEventId
     */
    acquitAlarmEvent(params: {  "neoEventId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEvent/AcquitAlarmEvent`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "neoEventId": params["neoEventId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CreateNeoEventModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEvent/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    downloadEvents(params: {  "model"?: NeoEventFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEvent/DownloadEvents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getAlarmEvents(options?: any): FetchArgs {
        const baseUrl = `/api/NeoEvent/GetAlarmEvents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchEvents(params: {  "model"?: NeoEventFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEvent/SearchEvents`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * NeoEventApi - functional programming interface
 */
export const NeoEventApiFp = {
    /**
     *
     * @param neoEventId
     */
    acquitAlarmEvent(params: { "neoEventId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = NeoEventApiFetchParamCreator.acquitAlarmEvent(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: CreateNeoEventModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NeoEventModel> {
        const fetchArgs = NeoEventApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    downloadEvents(params: { "model"?: NeoEventFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = NeoEventApiFetchParamCreator.downloadEvents(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getAlarmEvents(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NeoEventModel>> {
        const fetchArgs = NeoEventApiFetchParamCreator.getAlarmEvents(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchEvents(params: { "model"?: NeoEventFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NeoEventModel>> {
        const fetchArgs = NeoEventApiFetchParamCreator.searchEvents(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * NeoEventApi - object-oriented interface
 */
export class NeoEventApi extends BaseAPI {
    /**
     *
     * @param neoEventId
     */
    acquitAlarmEvent(params: {  "neoEventId"?: number; }, options?: any) {
        return NeoEventApiFp.acquitAlarmEvent(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CreateNeoEventModel; }, options?: any) {
        return NeoEventApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    downloadEvents(params: {  "model"?: NeoEventFilter; }, options?: any) {
        return NeoEventApiFp.downloadEvents(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getAlarmEvents(options?: any) {
        return NeoEventApiFp.getAlarmEvents(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchEvents(params: {  "model"?: NeoEventFilter; }, options?: any) {
        return NeoEventApiFp.searchEvents(params, options)(this.fetch, this.basePath);
    }
};

/**
 * NeoEventApi - factory interface
 */
export const NeoEventApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param neoEventId
         */
        acquitAlarmEvent(params: {  "neoEventId"?: number; }, options?: any) {
            return NeoEventApiFp.acquitAlarmEvent(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: CreateNeoEventModel; }, options?: any) {
            return NeoEventApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        downloadEvents(params: {  "model"?: NeoEventFilter; }, options?: any) {
            return NeoEventApiFp.downloadEvents(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getAlarmEvents(options?: any) {
            return NeoEventApiFp.getAlarmEvents(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchEvents(params: {  "model"?: NeoEventFilter; }, options?: any) {
            return NeoEventApiFp.searchEvents(params, options)(fetch, basePath);
        },
    };
};


/**
 * NeoEventAlarmApi - fetch parameter creator
 */
export const NeoEventAlarmApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEventAlarm/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: NeoEventAlarmModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEventAlarm/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/NeoEventAlarm/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: NeoEventAlarmModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/NeoEventAlarm/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * NeoEventAlarmApi - functional programming interface
 */
export const NeoEventAlarmApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = NeoEventAlarmApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: NeoEventAlarmModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NeoEventAlarmModel> {
        const fetchArgs = NeoEventAlarmApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: NeoEventAlarmModel; }> {
        const fetchArgs = NeoEventAlarmApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: NeoEventAlarmModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NeoEventAlarmModel> {
        const fetchArgs = NeoEventAlarmApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * NeoEventAlarmApi - object-oriented interface
 */
export class NeoEventAlarmApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return NeoEventAlarmApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: NeoEventAlarmModel; }, options?: any) {
        return NeoEventAlarmApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return NeoEventAlarmApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: NeoEventAlarmModel; }, options?: any) {
        return NeoEventAlarmApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * NeoEventAlarmApi - factory interface
 */
export const NeoEventAlarmApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return NeoEventAlarmApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: NeoEventAlarmModel; }, options?: any) {
            return NeoEventAlarmApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return NeoEventAlarmApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: NeoEventAlarmModel; }, options?: any) {
            return NeoEventAlarmApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * OfferApi - fetch parameter creator
 */
export const OfferApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/CreateItemExpirityOffer`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/CreateItemPromotion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteItemOfTheDay(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/DeleteItemOfTheDay`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteItemOffer(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/DeleteItemOffer`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getItemOffers(options?: any): FetchArgs {
        const baseUrl = `/api/Offer/GetItemOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/UpdateItemExpirityOffer`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateItemOfTheDay(params: {  "model"?: ItemOfTheDayModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/UpdateItemOfTheDay`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Offer/UpdateItemPromotion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * OfferApi - functional programming interface
 */
export const OfferApiFp = {
    /**
     *
     * @param model
     */
    createItemExpirityOffer(params: { "model"?: ItemExpirityOfferModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemExpirityOfferModel> {
        const fetchArgs = OfferApiFetchParamCreator.createItemExpirityOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createItemPromotion(params: { "model"?: ItemPromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemPromotionModel> {
        const fetchArgs = OfferApiFetchParamCreator.createItemPromotion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteItemOfTheDay(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = OfferApiFetchParamCreator.deleteItemOfTheDay(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteItemOffer(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = OfferApiFetchParamCreator.deleteItemOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getItemOffers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemOfferResultModel> {
        const fetchArgs = OfferApiFetchParamCreator.getItemOffers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateItemExpirityOffer(params: { "model"?: ItemExpirityOfferModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemExpirityOfferModel> {
        const fetchArgs = OfferApiFetchParamCreator.updateItemExpirityOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateItemOfTheDay(params: { "model"?: ItemOfTheDayModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemOfTheDayModel> {
        const fetchArgs = OfferApiFetchParamCreator.updateItemOfTheDay(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateItemPromotion(params: { "model"?: ItemPromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemPromotionModel> {
        const fetchArgs = OfferApiFetchParamCreator.updateItemPromotion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * OfferApi - object-oriented interface
 */
export class OfferApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any) {
        return OfferApiFp.createItemExpirityOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any) {
        return OfferApiFp.createItemPromotion(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteItemOfTheDay(params: {  "id"?: number; }, options?: any) {
        return OfferApiFp.deleteItemOfTheDay(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteItemOffer(params: {  "id"?: number; }, options?: any) {
        return OfferApiFp.deleteItemOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getItemOffers(options?: any) {
        return OfferApiFp.getItemOffers(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any) {
        return OfferApiFp.updateItemExpirityOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateItemOfTheDay(params: {  "model"?: ItemOfTheDayModel; }, options?: any) {
        return OfferApiFp.updateItemOfTheDay(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any) {
        return OfferApiFp.updateItemPromotion(params, options)(this.fetch, this.basePath);
    }
};

/**
 * OfferApi - factory interface
 */
export const OfferApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any) {
            return OfferApiFp.createItemExpirityOffer(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any) {
            return OfferApiFp.createItemPromotion(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteItemOfTheDay(params: {  "id"?: number; }, options?: any) {
            return OfferApiFp.deleteItemOfTheDay(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteItemOffer(params: {  "id"?: number; }, options?: any) {
            return OfferApiFp.deleteItemOffer(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getItemOffers(options?: any) {
            return OfferApiFp.getItemOffers(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateItemExpirityOffer(params: {  "model"?: ItemExpirityOfferModel; }, options?: any) {
            return OfferApiFp.updateItemExpirityOffer(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateItemOfTheDay(params: {  "model"?: ItemOfTheDayModel; }, options?: any) {
            return OfferApiFp.updateItemOfTheDay(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateItemPromotion(params: {  "model"?: ItemPromotionModel; }, options?: any) {
            return OfferApiFp.updateItemPromotion(params, options)(fetch, basePath);
        },
    };
};


/**
 * PaymentApi - fetch parameter creator
 */
export const PaymentApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    calculateTurnover(params: {  "model"?: CalculateTurnoverModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Payment/CalculateTurnover`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param hmiId
     */
    checkAviablePaymentMethods(params: {  "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Payment/CheckAviablePaymentMethods`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getHoppers(options?: any): FetchArgs {
        const baseUrl = `/api/Payment/GetHoppers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getLastPayments(options?: any): FetchArgs {
        const baseUrl = `/api/Payment/GetLastPayments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PaymentApi - functional programming interface
 */
export const PaymentApiFp = {
    /**
     *
     * @param model
     */
    calculateTurnover(params: { "model"?: CalculateTurnoverModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TurnoverModel> {
        const fetchArgs = PaymentApiFetchParamCreator.calculateTurnover(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param hmiId
     */
    checkAviablePaymentMethods(params: { "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = PaymentApiFetchParamCreator.checkAviablePaymentMethods(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getHoppers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<HmiMoneyHopperModel>> {
        const fetchArgs = PaymentApiFetchParamCreator.getHoppers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getLastPayments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LastPaymentsModel> {
        const fetchArgs = PaymentApiFetchParamCreator.getLastPayments(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PaymentApi - object-oriented interface
 */
export class PaymentApi extends BaseAPI {
    /**
     *
     * @param model
     */
    calculateTurnover(params: {  "model"?: CalculateTurnoverModel; }, options?: any) {
        return PaymentApiFp.calculateTurnover(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param hmiId
     */
    checkAviablePaymentMethods(params: {  "hmiId"?: number; }, options?: any) {
        return PaymentApiFp.checkAviablePaymentMethods(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getHoppers(options?: any) {
        return PaymentApiFp.getHoppers(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getLastPayments(options?: any) {
        return PaymentApiFp.getLastPayments(options)(this.fetch, this.basePath);
    }
};

/**
 * PaymentApi - factory interface
 */
export const PaymentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        calculateTurnover(params: {  "model"?: CalculateTurnoverModel; }, options?: any) {
            return PaymentApiFp.calculateTurnover(params, options)(fetch, basePath);
        },
        /**
         *
         * @param hmiId
         */
        checkAviablePaymentMethods(params: {  "hmiId"?: number; }, options?: any) {
            return PaymentApiFp.checkAviablePaymentMethods(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getHoppers(options?: any) {
            return PaymentApiFp.getHoppers(options)(fetch, basePath);
        },
        /**
         *
         */
        getLastPayments(options?: any) {
            return PaymentApiFp.getLastPayments(options)(fetch, basePath);
        },
    };
};


/**
 * PaymentNotificatorApi - fetch parameter creator
 */
export const PaymentNotificatorApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    cardHandlerStateChanged(params: {  "model"?: CardHandlerStateModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/CardHandlerStateChanged`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    cashHandlerStateChanged(params: {  "model"?: CashHandlerStateModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/CashHandlerStateChanged`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    cashHopperUpdated(params: {  "model"?: HopperInfo; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/CashHopperUpdated`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    collectionCardCompleted(params: {  "model"?: CollectionCardCompletedModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/CollectionCardCompleted`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    moneyGiven(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/MoneyGiven`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    moneyTaken(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/MoneyTaken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param hmiId
     */
    paymentCardCanceled(params: {  "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/PaymentCardCanceled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param hmiId
     */
    paymentCashCanceled(params: {  "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/PaymentCashCanceled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    remoteCollectionCardCompleted(params: {  "model"?: RemoteCollectionsDataModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/RemoteCollectionCardCompleted`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCardChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/SolvencyCardChanged`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     * @param cardType
     * @param hmiId
     */
    solvencyCardCompleted(params: {  "value"?: number; "cardType"?: string; "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/SolvencyCardCompleted`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
            "cardType": params["cardType"],
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/SolvencyCashChanged`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashCompleted(params: {  "value"?: number; "hmiId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PaymentNotificator/SolvencyCashCompleted`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
            "hmiId": params["hmiId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PaymentNotificatorApi - functional programming interface
 */
export const PaymentNotificatorApiFp = {
    /**
     *
     * @param model
     */
    cardHandlerStateChanged(params: { "model"?: CardHandlerStateModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.cardHandlerStateChanged(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    cashHandlerStateChanged(params: { "model"?: CashHandlerStateModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.cashHandlerStateChanged(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    cashHopperUpdated(params: { "model"?: HopperInfo;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.cashHopperUpdated(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    collectionCardCompleted(params: { "model"?: CollectionCardCompletedModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.collectionCardCompleted(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    moneyGiven(params: { "model"?: HmiMoneyTrafficModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.moneyGiven(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    moneyTaken(params: { "model"?: HmiMoneyTrafficModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.moneyTaken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param hmiId
     */
    paymentCardCanceled(params: { "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.paymentCardCanceled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param hmiId
     */
    paymentCashCanceled(params: { "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.paymentCashCanceled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    remoteCollectionCardCompleted(params: { "model"?: RemoteCollectionsDataModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.remoteCollectionCardCompleted(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCardChanged(params: { "value"?: number; "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.solvencyCardChanged(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     * @param cardType
     * @param hmiId
     */
    solvencyCardCompleted(params: { "value"?: number; "cardType"?: string; "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.solvencyCardCompleted(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashChanged(params: { "value"?: number; "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.solvencyCashChanged(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashCompleted(params: { "value"?: number; "hmiId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PaymentNotificatorApiFetchParamCreator.solvencyCashCompleted(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PaymentNotificatorApi - object-oriented interface
 */
export class PaymentNotificatorApi extends BaseAPI {
    /**
     *
     * @param model
     */
    cardHandlerStateChanged(params: {  "model"?: CardHandlerStateModel; }, options?: any) {
        return PaymentNotificatorApiFp.cardHandlerStateChanged(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    cashHandlerStateChanged(params: {  "model"?: CashHandlerStateModel; }, options?: any) {
        return PaymentNotificatorApiFp.cashHandlerStateChanged(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    cashHopperUpdated(params: {  "model"?: HopperInfo; }, options?: any) {
        return PaymentNotificatorApiFp.cashHopperUpdated(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    collectionCardCompleted(params: {  "model"?: CollectionCardCompletedModel; }, options?: any) {
        return PaymentNotificatorApiFp.collectionCardCompleted(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    moneyGiven(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any) {
        return PaymentNotificatorApiFp.moneyGiven(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    moneyTaken(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any) {
        return PaymentNotificatorApiFp.moneyTaken(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param hmiId
     */
    paymentCardCanceled(params: {  "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.paymentCardCanceled(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param hmiId
     */
    paymentCashCanceled(params: {  "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.paymentCashCanceled(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    remoteCollectionCardCompleted(params: {  "model"?: RemoteCollectionsDataModel; }, options?: any) {
        return PaymentNotificatorApiFp.remoteCollectionCardCompleted(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCardChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.solvencyCardChanged(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     * @param cardType
     * @param hmiId
     */
    solvencyCardCompleted(params: {  "value"?: number; "cardType"?: string; "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.solvencyCardCompleted(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.solvencyCashChanged(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     * @param hmiId
     */
    solvencyCashCompleted(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
        return PaymentNotificatorApiFp.solvencyCashCompleted(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PaymentNotificatorApi - factory interface
 */
export const PaymentNotificatorApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        cardHandlerStateChanged(params: {  "model"?: CardHandlerStateModel; }, options?: any) {
            return PaymentNotificatorApiFp.cardHandlerStateChanged(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        cashHandlerStateChanged(params: {  "model"?: CashHandlerStateModel; }, options?: any) {
            return PaymentNotificatorApiFp.cashHandlerStateChanged(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        cashHopperUpdated(params: {  "model"?: HopperInfo; }, options?: any) {
            return PaymentNotificatorApiFp.cashHopperUpdated(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        collectionCardCompleted(params: {  "model"?: CollectionCardCompletedModel; }, options?: any) {
            return PaymentNotificatorApiFp.collectionCardCompleted(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        moneyGiven(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any) {
            return PaymentNotificatorApiFp.moneyGiven(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        moneyTaken(params: {  "model"?: HmiMoneyTrafficModel; }, options?: any) {
            return PaymentNotificatorApiFp.moneyTaken(params, options)(fetch, basePath);
        },
        /**
         *
         * @param hmiId
         */
        paymentCardCanceled(params: {  "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.paymentCardCanceled(params, options)(fetch, basePath);
        },
        /**
         *
         * @param hmiId
         */
        paymentCashCanceled(params: {  "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.paymentCashCanceled(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        remoteCollectionCardCompleted(params: {  "model"?: RemoteCollectionsDataModel; }, options?: any) {
            return PaymentNotificatorApiFp.remoteCollectionCardCompleted(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         * @param hmiId
         */
        solvencyCardChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.solvencyCardChanged(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         * @param cardType
         * @param hmiId
         */
        solvencyCardCompleted(params: {  "value"?: number; "cardType"?: string; "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.solvencyCardCompleted(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         * @param hmiId
         */
        solvencyCashChanged(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.solvencyCashChanged(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         * @param hmiId
         */
        solvencyCashCompleted(params: {  "value"?: number; "hmiId"?: number; }, options?: any) {
            return PaymentNotificatorApiFp.solvencyCashCompleted(params, options)(fetch, basePath);
        },
    };
};


/**
 * PeriodApi - fetch parameter creator
 */
export const PeriodApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Period/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: PeriodModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Period/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getPeriods(options?: any): FetchArgs {
        const baseUrl = `/api/Period/GetPeriods`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: PeriodModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Period/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PeriodApi - functional programming interface
 */
export const PeriodApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PeriodApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: PeriodModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PeriodModel> {
        const fetchArgs = PeriodApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getPeriods(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PeriodModel>> {
        const fetchArgs = PeriodApiFetchParamCreator.getPeriods(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: PeriodModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PeriodModel> {
        const fetchArgs = PeriodApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PeriodApi - object-oriented interface
 */
export class PeriodApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return PeriodApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: PeriodModel; }, options?: any) {
        return PeriodApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getPeriods(options?: any) {
        return PeriodApiFp.getPeriods(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: PeriodModel; }, options?: any) {
        return PeriodApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PeriodApi - factory interface
 */
export const PeriodApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return PeriodApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: PeriodModel; }, options?: any) {
            return PeriodApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getPeriods(options?: any) {
            return PeriodApiFp.getPeriods(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: PeriodModel; }, options?: any) {
            return PeriodApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * PricePeriodApi - fetch parameter creator
 */
export const PricePeriodApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PricePeriod/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: PricePeriodModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PricePeriod/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getPricePeriods(options?: any): FetchArgs {
        const baseUrl = `/api/PricePeriod/GetPricePeriods`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: PricePeriodModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PricePeriod/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PricePeriodApi - functional programming interface
 */
export const PricePeriodApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PricePeriodApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: PricePeriodModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PricePeriodModel> {
        const fetchArgs = PricePeriodApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getPricePeriods(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PricePeriodModel>> {
        const fetchArgs = PricePeriodApiFetchParamCreator.getPricePeriods(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: PricePeriodModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PricePeriodModel> {
        const fetchArgs = PricePeriodApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PricePeriodApi - object-oriented interface
 */
export class PricePeriodApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return PricePeriodApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: PricePeriodModel; }, options?: any) {
        return PricePeriodApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getPricePeriods(options?: any) {
        return PricePeriodApiFp.getPricePeriods(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: PricePeriodModel; }, options?: any) {
        return PricePeriodApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PricePeriodApi - factory interface
 */
export const PricePeriodApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return PricePeriodApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: PricePeriodModel; }, options?: any) {
            return PricePeriodApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getPricePeriods(options?: any) {
            return PricePeriodApiFp.getPricePeriods(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: PricePeriodModel; }, options?: any) {
            return PricePeriodApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * PrintApi - fetch parameter creator
 */
export const PrintApiFetchParamCreator = {
    /**
     *
     */
    getState(options?: any): FetchArgs {
        const baseUrl = `/api/Print/GetState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param salePaymentId
     */
    printCardReceiptFromSalePaymentId(params: {  "salePaymentId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Print/PrintCardReceiptFromSalePaymentId`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "salePaymentId": params["salePaymentId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param orderId
     */
    printReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Print/PrintReceiptFromOrderId`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "orderId": params["orderId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    printText(params: {  "model"?: PrintContentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Print/PrintText`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param value
     */
    updatePrintEnabled(params: {  "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Print/UpdatePrintEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PrintApi - functional programming interface
 */
export const PrintApiFp = {
    /**
     *
     */
    getState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PrintHandlerState> {
        const fetchArgs = PrintApiFetchParamCreator.getState(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param salePaymentId
     */
    printCardReceiptFromSalePaymentId(params: { "salePaymentId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintApiFetchParamCreator.printCardReceiptFromSalePaymentId(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param orderId
     */
    printReceiptFromOrderId(params: { "orderId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintApiFetchParamCreator.printReceiptFromOrderId(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    printText(params: { "model"?: PrintContentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintApiFetchParamCreator.printText(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param value
     */
    updatePrintEnabled(params: { "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintApiFetchParamCreator.updatePrintEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PrintApi - object-oriented interface
 */
export class PrintApi extends BaseAPI {
    /**
     *
     */
    getState(options?: any) {
        return PrintApiFp.getState(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param salePaymentId
     */
    printCardReceiptFromSalePaymentId(params: {  "salePaymentId"?: number; }, options?: any) {
        return PrintApiFp.printCardReceiptFromSalePaymentId(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param orderId
     */
    printReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any) {
        return PrintApiFp.printReceiptFromOrderId(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    printText(params: {  "model"?: PrintContentModel; }, options?: any) {
        return PrintApiFp.printText(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param value
     */
    updatePrintEnabled(params: {  "value"?: boolean; }, options?: any) {
        return PrintApiFp.updatePrintEnabled(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PrintApi - factory interface
 */
export const PrintApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getState(options?: any) {
            return PrintApiFp.getState(options)(fetch, basePath);
        },
        /**
         *
         * @param salePaymentId
         */
        printCardReceiptFromSalePaymentId(params: {  "salePaymentId"?: number; }, options?: any) {
            return PrintApiFp.printCardReceiptFromSalePaymentId(params, options)(fetch, basePath);
        },
        /**
         *
         * @param orderId
         */
        printReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any) {
            return PrintApiFp.printReceiptFromOrderId(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        printText(params: {  "model"?: PrintContentModel; }, options?: any) {
            return PrintApiFp.printText(params, options)(fetch, basePath);
        },
        /**
         *
         * @param value
         */
        updatePrintEnabled(params: {  "value"?: boolean; }, options?: any) {
            return PrintApiFp.updatePrintEnabled(params, options)(fetch, basePath);
        },
    };
};


/**
 * PrintNotificatorApi - fetch parameter creator
 */
export const PrintNotificatorApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    printEnded(params: {  "model"?: ProcessResult; }, options?: any): FetchArgs {
        const baseUrl = `/api/PrintNotificator/PrintEnded`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    printStateUpdated(params: {  "model"?: PrintHandlerState; }, options?: any): FetchArgs {
        const baseUrl = `/api/PrintNotificator/PrintStateUpdated`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PrintNotificatorApi - functional programming interface
 */
export const PrintNotificatorApiFp = {
    /**
     *
     * @param model
     */
    printEnded(params: { "model"?: ProcessResult;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintNotificatorApiFetchParamCreator.printEnded(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    printStateUpdated(params: { "model"?: PrintHandlerState;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PrintNotificatorApiFetchParamCreator.printStateUpdated(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PrintNotificatorApi - object-oriented interface
 */
export class PrintNotificatorApi extends BaseAPI {
    /**
     *
     * @param model
     */
    printEnded(params: {  "model"?: ProcessResult; }, options?: any) {
        return PrintNotificatorApiFp.printEnded(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    printStateUpdated(params: {  "model"?: PrintHandlerState; }, options?: any) {
        return PrintNotificatorApiFp.printStateUpdated(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PrintNotificatorApi - factory interface
 */
export const PrintNotificatorApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        printEnded(params: {  "model"?: ProcessResult; }, options?: any) {
            return PrintNotificatorApiFp.printEnded(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        printStateUpdated(params: {  "model"?: PrintHandlerState; }, options?: any) {
            return PrintNotificatorApiFp.printStateUpdated(params, options)(fetch, basePath);
        },
    };
};


/**
 * ProductsLgapApi - fetch parameter creator
 */
export const ProductsLgapApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    updateSalesTariff(params: {  "model"?: UpdatePricesResultModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProductsLgap/UpdateSalesTariff`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updatePurchaseTariff(params: {  "model"?: UpdateProdSupplierResultModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProductsLgap/UpdatePurchaseTariff`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateProductSizes(params: {  "model"?: UpdateProductSizeResultModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProductsLgap/UpdateProductSizes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ProductsLgapApi - functional programming interface
 */
export const ProductsLgapApiFp = {
    /**
     *
     * @param model
     */
    updateSalesTariff(params: { "model"?: UpdatePricesResultModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProductsLgapApiFetchParamCreator.updateSalesTariff(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updatePurchaseTariff(params: { "model"?: UpdateProdSupplierResultModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProductsLgapApiFetchParamCreator.updatePurchaseTariff(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateProductSizes(params: { "model"?: UpdateProductSizeResultModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProductsLgapApiFetchParamCreator.updateProductSizes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ProductsLgapApi - object-oriented interface
 */
export class ProductsLgapApi extends BaseAPI {
    /**
     *
     * @param model
     */
    updateSalesTariff(params: {  "model"?: UpdatePricesResultModel; }, options?: any) {
        return ProductsLgapApiFp.updateSalesTariff(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updatePurchaseTariff(params: {  "model"?: UpdateProdSupplierResultModel; }, options?: any) {
        return ProductsLgapApiFp.updatePurchaseTariff(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateProductSizes(params: {  "model"?: UpdateProductSizeResultModel; }, options?: any) {
        return ProductsLgapApiFp.updateProductSizes(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ProductsLgapApi - factory interface
 */
export const ProductsLgapApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        updateSalesTariff(params: {  "model"?: UpdatePricesResultModel; }, options?: any) {
            return ProductsLgapApiFp.updateSalesTariff(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updatePurchaseTariff(params: {  "model"?: UpdateProdSupplierResultModel; }, options?: any) {
            return ProductsLgapApiFp.updatePurchaseTariff(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateProductSizes(params: {  "model"?: UpdateProductSizeResultModel; }, options?: any) {
            return ProductsLgapApiFp.updateProductSizes(params, options)(fetch, basePath);
        },
    }
};


/**
 * ReceiptApi - fetch parameter creator
 */
export const ReceiptApiFetchParamCreator = {
    /**
     *
     * @param salePaymentId
     */
    getCardReceiptFromPaymentId(params: {  "salePaymentId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Receipt/GetCardReceiptFromPaymentId`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "salePaymentId": params["salePaymentId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param orderId
     */
    getOrderReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Receipt/GetOrderReceiptFromOrderId`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "orderId": params["orderId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReceiptApi - functional programming interface
 */
export const ReceiptApiFp = {
    /**
     *
     * @param salePaymentId
     */
    getCardReceiptFromPaymentId(params: { "salePaymentId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReceiptResult> {
        const fetchArgs = ReceiptApiFetchParamCreator.getCardReceiptFromPaymentId(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param orderId
     */
    getOrderReceiptFromOrderId(params: { "orderId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReceiptResult> {
        const fetchArgs = ReceiptApiFetchParamCreator.getOrderReceiptFromOrderId(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReceiptApi - object-oriented interface
 */
export class ReceiptApi extends BaseAPI {
    /**
     *
     * @param salePaymentId
     */
    getCardReceiptFromPaymentId(params: {  "salePaymentId"?: number; }, options?: any) {
        return ReceiptApiFp.getCardReceiptFromPaymentId(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param orderId
     */
    getOrderReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any) {
        return ReceiptApiFp.getOrderReceiptFromOrderId(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReceiptApi - factory interface
 */
export const ReceiptApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param salePaymentId
         */
        getCardReceiptFromPaymentId(params: {  "salePaymentId"?: number; }, options?: any) {
            return ReceiptApiFp.getCardReceiptFromPaymentId(params, options)(fetch, basePath);
        },
        /**
         *
         * @param orderId
         */
        getOrderReceiptFromOrderId(params: {  "orderId"?: number; }, options?: any) {
            return ReceiptApiFp.getOrderReceiptFromOrderId(params, options)(fetch, basePath);
        },
    };
};


/**
 * ReceptionApi - fetch parameter creator
 */
export const ReceptionApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createProductReception(params: {  "model"?: CreateProductReceptionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reception/CreateProductReception`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createProductReceptions(params: {  "model"?: CreateProductReceptionsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reception/CreateProductReceptions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    deleteQuantityRemaining(params: {  "model"?: DeleteQuantityRemainingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reception/DeleteQuantityRemaining`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchEntities(params: {  "model"?: SearchReceptionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reception/SearchEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateQuantityRemaining(params: {  "model"?: UpdateQuantityRemainingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reception/UpdateQuantityRemaining`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReceptionApi - functional programming interface
 */
export const ReceptionApiFp = {
    /**
     *
     * @param model
     */
    createProductReception(params: { "model"?: CreateProductReceptionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductReceptionModel> {
        const fetchArgs = ReceptionApiFetchParamCreator.createProductReception(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createProductReceptions(params: { "model"?: CreateProductReceptionsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductReceptionModel>> {
        const fetchArgs = ReceptionApiFetchParamCreator.createProductReceptions(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    deleteQuantityRemaining(params: { "model"?: DeleteQuantityRemainingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductReceptionModel>> {
        const fetchArgs = ReceptionApiFetchParamCreator.deleteQuantityRemaining(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchEntities(params: { "model"?: SearchReceptionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductReceptionsModel> {
        const fetchArgs = ReceptionApiFetchParamCreator.searchEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateQuantityRemaining(params: { "model"?: UpdateQuantityRemainingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductReceptionModel>> {
        const fetchArgs = ReceptionApiFetchParamCreator.updateQuantityRemaining(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReceptionApi - object-oriented interface
 */
export class ReceptionApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createProductReception(params: {  "model"?: CreateProductReceptionModel; }, options?: any) {
        return ReceptionApiFp.createProductReception(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createProductReceptions(params: {  "model"?: CreateProductReceptionsModel; }, options?: any) {
        return ReceptionApiFp.createProductReceptions(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    deleteQuantityRemaining(params: {  "model"?: DeleteQuantityRemainingModel; }, options?: any) {
        return ReceptionApiFp.deleteQuantityRemaining(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchEntities(params: {  "model"?: SearchReceptionModel; }, options?: any) {
        return ReceptionApiFp.searchEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateQuantityRemaining(params: {  "model"?: UpdateQuantityRemainingModel; }, options?: any) {
        return ReceptionApiFp.updateQuantityRemaining(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReceptionApi - factory interface
 */
export const ReceptionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createProductReception(params: {  "model"?: CreateProductReceptionModel; }, options?: any) {
            return ReceptionApiFp.createProductReception(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createProductReceptions(params: {  "model"?: CreateProductReceptionsModel; }, options?: any) {
            return ReceptionApiFp.createProductReceptions(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        deleteQuantityRemaining(params: {  "model"?: DeleteQuantityRemainingModel; }, options?: any) {
            return ReceptionApiFp.deleteQuantityRemaining(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchEntities(params: {  "model"?: SearchReceptionModel; }, options?: any) {
            return ReceptionApiFp.searchEntities(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateQuantityRemaining(params: {  "model"?: UpdateQuantityRemainingModel; }, options?: any) {
            return ReceptionApiFp.updateQuantityRemaining(params, options)(fetch, basePath);
        },
    };
};


/**
 * ReorderApi - fetch parameter creator
 */
export const ReorderApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    getReorderData(params: {  "model"?: GetHistoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reorder/GetReorderData`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateDaysToProject(params: {  "model"?: UpdateDaysToProjectModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Reorder/UpdateDaysToProject`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReorderApi - functional programming interface
 */
export const ReorderApiFp = {
    /**
     *
     * @param model
     */
    getReorderData(params: { "model"?: GetHistoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DayReorderDataModel>> {
        const fetchArgs = ReorderApiFetchParamCreator.getReorderData(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateDaysToProject(params: { "model"?: UpdateDaysToProjectModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ReorderApiFetchParamCreator.updateDaysToProject(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReorderApi - object-oriented interface
 */
export class ReorderApi extends BaseAPI {
    /**
     *
     * @param model
     */
    getReorderData(params: {  "model"?: GetHistoryModel; }, options?: any) {
        return ReorderApiFp.getReorderData(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateDaysToProject(params: {  "model"?: UpdateDaysToProjectModel; }, options?: any) {
        return ReorderApiFp.updateDaysToProject(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReorderApi - factory interface
 */
export const ReorderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        getReorderData(params: {  "model"?: GetHistoryModel; }, options?: any) {
            return ReorderApiFp.getReorderData(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateDaysToProject(params: {  "model"?: UpdateDaysToProjectModel; }, options?: any) {
            return ReorderApiFp.updateDaysToProject(params, options)(fetch, basePath);
        },
    };
};


/**
 * RobotApi - fetch parameter creator
 */
export const RobotApiFetchParamCreator = {
    /**
     *
     */
    closeDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/CloseDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    closeInsideDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/CloseInsideDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    dispenseBags(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/DispenseBags`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param extractorNumber
     */
    extendExtractor(params: {  "extractorNumber"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/ExtendExtractor`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "extractorNumber": params["extractorNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getRobots(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/GetRobots`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getState(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/GetState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    initializeMW232(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/InitializeMW232`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    initializeRobotPartial(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/InitializeRobotPartial`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    initializeRobotTotal(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/InitializeRobotTotal`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    lockDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/LockDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    lockInsideDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/LockInsideDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    openDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/OpenDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    openInsideDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/OpenInsideDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    railCoordToRobotCoord(params: {  "model"?: RailCoordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/RailCoordToRobotCoord`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param extractorNumber
     */
    retractExtractor(params: {  "extractorNumber"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/RetractExtractor`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "extractorNumber": params["extractorNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param destination
     */
    runAccumulatorBelt(params: {  "destination"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/RunAccumulatorBelt`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "destination": params["destination"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param destination
     */
    runDeliveryBelt(params: {  "destination"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/RunDeliveryBelt`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "destination": params["destination"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    runExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/RunExtractorBelt`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    startSimulation(params: {  "model"?: RobotSimulationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/StartSimulation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    stopAccumulatorBelt(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/StopAccumulatorBelt`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    stopDeliveryBelt(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/StopDeliveryBelt`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    stopExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/StopExtractorBelt`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    stopSimulation(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/StopSimulation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    toCoord(params: {  "model"?: RobotCoordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/ToCoord`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    toRailCoord(params: {  "model"?: RailCoordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/ToRailCoord`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    unlockDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/UnlockDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    unlockInsideDoor(options?: any): FetchArgs {
        const baseUrl = `/api/Robot/UnlockInsideDoor`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateRobot(params: {  "model"?: RobotModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Robot/UpdateRobot`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RobotApi - functional programming interface
 */
export const RobotApiFp = {
    /**
     *
     */
    closeDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.closeDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    closeInsideDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.closeInsideDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    dispenseBags(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.dispenseBags(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param extractorNumber
     */
    extendExtractor(params: { "extractorNumber"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.extendExtractor(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getRobots(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RobotModel>> {
        const fetchArgs = RobotApiFetchParamCreator.getRobots(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RobotHandlerState> {
        const fetchArgs = RobotApiFetchParamCreator.getState(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    initializeMW232(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.initializeMW232(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    initializeRobotPartial(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.initializeRobotPartial(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    initializeRobotTotal(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.initializeRobotTotal(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    lockDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.lockDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    lockInsideDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.lockInsideDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    openDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.openDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    openInsideDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.openInsideDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    railCoordToRobotCoord(params: { "model"?: RailCoordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RobotCoordModel> {
        const fetchArgs = RobotApiFetchParamCreator.railCoordToRobotCoord(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param extractorNumber
     */
    retractExtractor(params: { "extractorNumber"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.retractExtractor(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param destination
     */
    runAccumulatorBelt(params: { "destination"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.runAccumulatorBelt(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param destination
     */
    runDeliveryBelt(params: { "destination"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.runDeliveryBelt(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    runExtractorBelt(params: { "model"?: ExtractorBeltModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.runExtractorBelt(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    startSimulation(params: { "model"?: RobotSimulationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.startSimulation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    stopAccumulatorBelt(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.stopAccumulatorBelt(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    stopDeliveryBelt(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.stopDeliveryBelt(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    stopExtractorBelt(params: { "model"?: ExtractorBeltModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.stopExtractorBelt(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    stopSimulation(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.stopSimulation(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    toCoord(params: { "model"?: RobotCoordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.toCoord(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    toRailCoord(params: { "model"?: RailCoordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.toRailCoord(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    unlockDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.unlockDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    unlockInsideDoor(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProcessResult> {
        const fetchArgs = RobotApiFetchParamCreator.unlockInsideDoor(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateRobot(params: { "model"?: RobotModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RobotModel> {
        const fetchArgs = RobotApiFetchParamCreator.updateRobot(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RobotApi - object-oriented interface
 */
export class RobotApi extends BaseAPI {
    /**
     *
     */
    closeDoor(options?: any) {
        return RobotApiFp.closeDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    closeInsideDoor(options?: any) {
        return RobotApiFp.closeInsideDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    dispenseBags(options?: any) {
        return RobotApiFp.dispenseBags(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param extractorNumber
     */
    extendExtractor(params: {  "extractorNumber"?: number; }, options?: any) {
        return RobotApiFp.extendExtractor(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getRobots(options?: any) {
        return RobotApiFp.getRobots(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getState(options?: any) {
        return RobotApiFp.getState(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    initializeMW232(options?: any) {
        return RobotApiFp.initializeMW232(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    initializeRobotPartial(options?: any) {
        return RobotApiFp.initializeRobotPartial(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    initializeRobotTotal(options?: any) {
        return RobotApiFp.initializeRobotTotal(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    lockDoor(options?: any) {
        return RobotApiFp.lockDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    lockInsideDoor(options?: any) {
        return RobotApiFp.lockInsideDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    openDoor(options?: any) {
        return RobotApiFp.openDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    openInsideDoor(options?: any) {
        return RobotApiFp.openInsideDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    railCoordToRobotCoord(params: {  "model"?: RailCoordModel; }, options?: any) {
        return RobotApiFp.railCoordToRobotCoord(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param extractorNumber
     */
    retractExtractor(params: {  "extractorNumber"?: number; }, options?: any) {
        return RobotApiFp.retractExtractor(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param destination
     */
    runAccumulatorBelt(params: {  "destination"?: string; }, options?: any) {
        return RobotApiFp.runAccumulatorBelt(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param destination
     */
    runDeliveryBelt(params: {  "destination"?: string; }, options?: any) {
        return RobotApiFp.runDeliveryBelt(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    runExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any) {
        return RobotApiFp.runExtractorBelt(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    startSimulation(params: {  "model"?: RobotSimulationModel; }, options?: any) {
        return RobotApiFp.startSimulation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    stopAccumulatorBelt(options?: any) {
        return RobotApiFp.stopAccumulatorBelt(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    stopDeliveryBelt(options?: any) {
        return RobotApiFp.stopDeliveryBelt(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    stopExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any) {
        return RobotApiFp.stopExtractorBelt(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    stopSimulation(options?: any) {
        return RobotApiFp.stopSimulation(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    toCoord(params: {  "model"?: RobotCoordModel; }, options?: any) {
        return RobotApiFp.toCoord(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    toRailCoord(params: {  "model"?: RailCoordModel; }, options?: any) {
        return RobotApiFp.toRailCoord(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    unlockDoor(options?: any) {
        return RobotApiFp.unlockDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    unlockInsideDoor(options?: any) {
        return RobotApiFp.unlockInsideDoor(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateRobot(params: {  "model"?: RobotModel; }, options?: any) {
        return RobotApiFp.updateRobot(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RobotApi - factory interface
 */
export const RobotApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        closeDoor(options?: any) {
            return RobotApiFp.closeDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        closeInsideDoor(options?: any) {
            return RobotApiFp.closeInsideDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        dispenseBags(options?: any) {
            return RobotApiFp.dispenseBags(options)(fetch, basePath);
        },
        /**
         *
         * @param extractorNumber
         */
        extendExtractor(params: {  "extractorNumber"?: number; }, options?: any) {
            return RobotApiFp.extendExtractor(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getRobots(options?: any) {
            return RobotApiFp.getRobots(options)(fetch, basePath);
        },
        /**
         *
         */
        getState(options?: any) {
            return RobotApiFp.getState(options)(fetch, basePath);
        },
        /**
         *
         */
        initializeMW232(options?: any) {
            return RobotApiFp.initializeMW232(options)(fetch, basePath);
        },
        /**
         *
         */
        initializeRobotPartial(options?: any) {
            return RobotApiFp.initializeRobotPartial(options)(fetch, basePath);
        },
        /**
         *
         */
        initializeRobotTotal(options?: any) {
            return RobotApiFp.initializeRobotTotal(options)(fetch, basePath);
        },
        /**
         *
         */
        lockDoor(options?: any) {
            return RobotApiFp.lockDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        lockInsideDoor(options?: any) {
            return RobotApiFp.lockInsideDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        openDoor(options?: any) {
            return RobotApiFp.openDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        openInsideDoor(options?: any) {
            return RobotApiFp.openInsideDoor(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        railCoordToRobotCoord(params: {  "model"?: RailCoordModel; }, options?: any) {
            return RobotApiFp.railCoordToRobotCoord(params, options)(fetch, basePath);
        },
        /**
         *
         * @param extractorNumber
         */
        retractExtractor(params: {  "extractorNumber"?: number; }, options?: any) {
            return RobotApiFp.retractExtractor(params, options)(fetch, basePath);
        },
        /**
         *
         * @param destination
         */
        runAccumulatorBelt(params: {  "destination"?: string; }, options?: any) {
            return RobotApiFp.runAccumulatorBelt(params, options)(fetch, basePath);
        },
        /**
         *
         * @param destination
         */
        runDeliveryBelt(params: {  "destination"?: string; }, options?: any) {
            return RobotApiFp.runDeliveryBelt(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        runExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any) {
            return RobotApiFp.runExtractorBelt(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        startSimulation(params: {  "model"?: RobotSimulationModel; }, options?: any) {
            return RobotApiFp.startSimulation(params, options)(fetch, basePath);
        },
        /**
         *
         */
        stopAccumulatorBelt(options?: any) {
            return RobotApiFp.stopAccumulatorBelt(options)(fetch, basePath);
        },
        /**
         *
         */
        stopDeliveryBelt(options?: any) {
            return RobotApiFp.stopDeliveryBelt(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        stopExtractorBelt(params: {  "model"?: ExtractorBeltModel; }, options?: any) {
            return RobotApiFp.stopExtractorBelt(params, options)(fetch, basePath);
        },
        /**
         *
         */
        stopSimulation(options?: any) {
            return RobotApiFp.stopSimulation(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        toCoord(params: {  "model"?: RobotCoordModel; }, options?: any) {
            return RobotApiFp.toCoord(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        toRailCoord(params: {  "model"?: RailCoordModel; }, options?: any) {
            return RobotApiFp.toRailCoord(params, options)(fetch, basePath);
        },
        /**
         *
         */
        unlockDoor(options?: any) {
            return RobotApiFp.unlockDoor(options)(fetch, basePath);
        },
        /**
         *
         */
        unlockInsideDoor(options?: any) {
            return RobotApiFp.unlockInsideDoor(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateRobot(params: {  "model"?: RobotModel; }, options?: any) {
            return RobotApiFp.updateRobot(params, options)(fetch, basePath);
        },
    };
};


/**
 * RobotNotificatorApi - fetch parameter creator
 */
export const RobotNotificatorApiFetchParamCreator = {
    /**
     *
     * @param step
     * @param sessionId
     */
    deliveryStepStarted(params: {  "step"?: string; "sessionId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/RobotNotificator/DeliveryStepStarted`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "step": params["step"],
            "sessionId": params["sessionId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param state
     */
    robotStateChanged(params: {  "state"?: RobotHandlerState; }, options?: any): FetchArgs {
        const baseUrl = `/api/RobotNotificator/RobotStateChanged`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["state"]) {
            fetchOptions.body = JSON.stringify(params["state"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    simulationDeliveryStepEnded(params: {  "model"?: DeliveryResultModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RobotNotificator/SimulationDeliveryStepEnded`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param sessionId
     */
    waitingForRobot(params: {  "sessionId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/RobotNotificator/WaitingForRobot`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "sessionId": params["sessionId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RobotNotificatorApi - functional programming interface
 */
export const RobotNotificatorApiFp = {
    /**
     *
     * @param step
     * @param sessionId
     */
    deliveryStepStarted(params: { "step"?: string; "sessionId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RobotNotificatorApiFetchParamCreator.deliveryStepStarted(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param state
     */
    robotStateChanged(params: { "state"?: RobotHandlerState;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RobotNotificatorApiFetchParamCreator.robotStateChanged(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    simulationDeliveryStepEnded(params: { "model"?: DeliveryResultModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RobotNotificatorApiFetchParamCreator.simulationDeliveryStepEnded(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param sessionId
     */
    waitingForRobot(params: { "sessionId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RobotNotificatorApiFetchParamCreator.waitingForRobot(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RobotNotificatorApi - object-oriented interface
 */
export class RobotNotificatorApi extends BaseAPI {
    /**
     *
     * @param step
     * @param sessionId
     */
    deliveryStepStarted(params: {  "step"?: string; "sessionId"?: string; }, options?: any) {
        return RobotNotificatorApiFp.deliveryStepStarted(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param state
     */
    robotStateChanged(params: {  "state"?: RobotHandlerState; }, options?: any) {
        return RobotNotificatorApiFp.robotStateChanged(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    simulationDeliveryStepEnded(params: {  "model"?: DeliveryResultModel; }, options?: any) {
        return RobotNotificatorApiFp.simulationDeliveryStepEnded(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param sessionId
     */
    waitingForRobot(params: {  "sessionId"?: string; }, options?: any) {
        return RobotNotificatorApiFp.waitingForRobot(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RobotNotificatorApi - factory interface
 */
export const RobotNotificatorApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param step
         * @param sessionId
         */
        deliveryStepStarted(params: {  "step"?: string; "sessionId"?: string; }, options?: any) {
            return RobotNotificatorApiFp.deliveryStepStarted(params, options)(fetch, basePath);
        },
        /**
         *
         * @param state
         */
        robotStateChanged(params: {  "state"?: RobotHandlerState; }, options?: any) {
            return RobotNotificatorApiFp.robotStateChanged(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        simulationDeliveryStepEnded(params: {  "model"?: DeliveryResultModel; }, options?: any) {
            return RobotNotificatorApiFp.simulationDeliveryStepEnded(params, options)(fetch, basePath);
        },
        /**
         *
         * @param sessionId
         */
        waitingForRobot(params: {  "sessionId"?: string; }, options?: any) {
            return RobotNotificatorApiFp.waitingForRobot(params, options)(fetch, basePath);
        },
    };
};


/**
 * SeedApi - fetch parameter creator
 */
export const SeedApiFetchParamCreator = {
    /**
     *
     * @param name
     * @param lang
     */
    getHelp(params: {  "name"?: string; "lang"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetHelp`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "name": params["name"],
            "lang": params["lang"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getSeed(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetSeed`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param langCode
     */
    getTexts(params: {  "langCode"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetTexts`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "langCode": params["langCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SeedApi - functional programming interface
 */
export const SeedApiFp = {
    /**
     *
     * @param name
     * @param lang
     */
    getHelp(params: { "name"?: string; "lang"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = SeedApiFetchParamCreator.getHelp(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getSeed(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SeedMngModel> {
        const fetchArgs = SeedApiFetchParamCreator.getSeed(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param langCode
     */
    getTexts(params: { "langCode"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: string; }> {
        const fetchArgs = SeedApiFetchParamCreator.getTexts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SeedApi - object-oriented interface
 */
export class SeedApi extends BaseAPI {
    /**
     *
     * @param name
     * @param lang
     */
    getHelp(params: {  "name"?: string; "lang"?: string; }, options?: any) {
        return SeedApiFp.getHelp(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getSeed(options?: any) {
        return SeedApiFp.getSeed(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param langCode
     */
    getTexts(params: {  "langCode"?: string; }, options?: any) {
        return SeedApiFp.getTexts(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SeedApi - factory interface
 */
export const SeedApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param name
         * @param lang
         */
        getHelp(params: {  "name"?: string; "lang"?: string; }, options?: any) {
            return SeedApiFp.getHelp(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getSeed(options?: any) {
            return SeedApiFp.getSeed(options)(fetch, basePath);
        },
        /**
         *
         * @param langCode
         */
        getTexts(params: {  "langCode"?: string; }, options?: any) {
            return SeedApiFp.getTexts(params, options)(fetch, basePath);
        },
    };
};


/**
 * SettingsApi - fetch parameter creator
 */
export const SettingsApiFetchParamCreator = {
    /**
     *
     */
    getStoreSettings(options?: any): FetchArgs {
        const baseUrl = `/api/Settings/GetStoreSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param models
     */
    updateStatusLanguage(params: {  "models"?: Array<LanguageModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Settings/UpdateStatusLanguage`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateStoreSettings(params: {  "model"?: StoreSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Settings/UpdateStoreSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SettingsApi - functional programming interface
 */
export const SettingsApiFp = {
    /**
     *
     */
    getStoreSettings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoreSettingsModel> {
        const fetchArgs = SettingsApiFetchParamCreator.getStoreSettings(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param models
     */
    updateStatusLanguage(params: { "models"?: Array<LanguageModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: LanguageModel; }> {
        const fetchArgs = SettingsApiFetchParamCreator.updateStatusLanguage(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateStoreSettings(params: { "model"?: StoreSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StoreSettingsModel> {
        const fetchArgs = SettingsApiFetchParamCreator.updateStoreSettings(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SettingsApi - object-oriented interface
 */
export class SettingsApi extends BaseAPI {
    /**
     *
     */
    getStoreSettings(options?: any) {
        return SettingsApiFp.getStoreSettings(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param models
     */
    updateStatusLanguage(params: {  "models"?: Array<LanguageModel>; }, options?: any) {
        return SettingsApiFp.updateStatusLanguage(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateStoreSettings(params: {  "model"?: StoreSettingsModel; }, options?: any) {
        return SettingsApiFp.updateStoreSettings(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SettingsApi - factory interface
 */
export const SettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getStoreSettings(options?: any) {
            return SettingsApiFp.getStoreSettings(options)(fetch, basePath);
        },
        /**
         *
         * @param models
         */
        updateStatusLanguage(params: {  "models"?: Array<LanguageModel>; }, options?: any) {
            return SettingsApiFp.updateStatusLanguage(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateStoreSettings(params: {  "model"?: StoreSettingsModel; }, options?: any) {
            return SettingsApiFp.updateStoreSettings(params, options)(fetch, basePath);
        },
    };
};


/**
 * SmartPhoneApi - fetch parameter creator
 */
export const SmartPhoneApiFetchParamCreator = {
    /**
     *
     * @param code
     */
    cancelCommand(params: {  "code"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/CancelCommand`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createCommand(params: {  "model"?: CreateCommandModel; }, options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/CreateCommand`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any): FetchArgs {
        const baseUrl = `/sm/api/account/CreateToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    generateCommandCode(options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/GenerateCommandCode`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getCommands(options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/GetCommands`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEverything(options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/GetEverything`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getStoreInfo(options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/GetStoreInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param username
     * @param password
     */
    token(params: {  "username"?: string; "password"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/sm/api/account/Token`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "Username": params["username"],
            "Password": params["password"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param discountId
     */
    useDiscount(params: {  "discountId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/sm/api/shop/UseDiscount`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "discountId": params["discountId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SmartPhoneApi - functional programming interface
 */
export const SmartPhoneApiFp = {
    /**
     *
     * @param code
     */
    cancelCommand(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.cancelCommand(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createCommand(params: { "model"?: CreateCommandModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.createCommand(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createToken(params: { "model"?: LoginModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenModel> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.createToken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    generateCommandCode(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.generateCommandCode(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getCommands(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.getCommands(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEverything(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.getEverything(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getStoreInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.getStoreInfo(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param username
     * @param password
     */
    token(params: { "username"?: string; "password"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenModel> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.token(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param discountId
     */
    useDiscount(params: { "discountId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SmartPhoneApiFetchParamCreator.useDiscount(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SmartPhoneApi - object-oriented interface
 */
export class SmartPhoneApi extends BaseAPI {
    /**
     *
     * @param code
     */
    cancelCommand(params: {  "code"?: string; }, options?: any) {
        return SmartPhoneApiFp.cancelCommand(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createCommand(params: {  "model"?: CreateCommandModel; }, options?: any) {
        return SmartPhoneApiFp.createCommand(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any) {
        return SmartPhoneApiFp.createToken(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    generateCommandCode(options?: any) {
        return SmartPhoneApiFp.generateCommandCode(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getCommands(options?: any) {
        return SmartPhoneApiFp.getCommands(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEverything(options?: any) {
        return SmartPhoneApiFp.getEverything(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getStoreInfo(options?: any) {
        return SmartPhoneApiFp.getStoreInfo(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param username
     * @param password
     */
    token(params: {  "username"?: string; "password"?: string; }, options?: any) {
        return SmartPhoneApiFp.token(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param discountId
     */
    useDiscount(params: {  "discountId"?: number; }, options?: any) {
        return SmartPhoneApiFp.useDiscount(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SmartPhoneApi - factory interface
 */
export const SmartPhoneApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param code
         */
        cancelCommand(params: {  "code"?: string; }, options?: any) {
            return SmartPhoneApiFp.cancelCommand(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createCommand(params: {  "model"?: CreateCommandModel; }, options?: any) {
            return SmartPhoneApiFp.createCommand(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createToken(params: {  "model"?: LoginModel; }, options?: any) {
            return SmartPhoneApiFp.createToken(params, options)(fetch, basePath);
        },
        /**
         *
         */
        generateCommandCode(options?: any) {
            return SmartPhoneApiFp.generateCommandCode(options)(fetch, basePath);
        },
        /**
         *
         */
        getCommands(options?: any) {
            return SmartPhoneApiFp.getCommands(options)(fetch, basePath);
        },
        /**
         *
         */
        getEverything(options?: any) {
            return SmartPhoneApiFp.getEverything(options)(fetch, basePath);
        },
        /**
         *
         */
        getStoreInfo(options?: any) {
            return SmartPhoneApiFp.getStoreInfo(options)(fetch, basePath);
        },
        /**
         *
         * @param username
         * @param password
         */
        token(params: {  "username"?: string; "password"?: string; }, options?: any) {
            return SmartPhoneApiFp.token(params, options)(fetch, basePath);
        },
        /**
         *
         * @param discountId
         */
        useDiscount(params: {  "discountId"?: number; }, options?: any) {
            return SmartPhoneApiFp.useDiscount(params, options)(fetch, basePath);
        },
    };
};


/**
 * StockApi - fetch parameter creator
 */
export const StockApiFetchParamCreator = {
    /**
     *
     *
     */
    calculateOffsets(options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CalculateOffsets`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateLockerAddress`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createLocker(params: {  "model"?: LockerModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateLocker`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createLockerType(params: {  "model"?: LockerTypeModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateLockerType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quantity
     * @param lockerTypeId
     */
    createLockersAuto(params: {  "quantity"?: number; "lockerTypeId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateLockersAuto`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quantity": params["quantity"],
            "lockerTypeId": params["lockerTypeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param name
     */
    createPlanogram(params: {  "name"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreatePlanogram`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "name": params["name"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateProductLocker`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createProductRail(params: {  "model"?: ProductRailModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateProductRail`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createProductStocks(params: {  "model"?: CreateProductStocksModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateProductStocks`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createShelving(params: {  "model"?: ShelvingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateShelving`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quantity
     */
    createShelvingsAuto(params: {  "quantity"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateShelvingsAuto`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quantity": params["quantity"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateShelvingTray`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quantity
     */
    createShelvingsTrayAuto(params: {  "quantity"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateShelvingsTrayAuto`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quantity": params["quantity"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createShelvingTrayPlacement(params: {  "model"?: ShelvingTrayPlacementModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/CreateShelvingTrayPlacement`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteLockerAddress(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteLockerAddress`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteLocker(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteLocker`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteLockerType(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteLockerType`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deletePlanogram(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeletePlanogram`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param productLockerId
     */
    deleteProductLocker(params: {  "productLockerId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteProductLocker`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "productLockerId": params["productLockerId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param productRailId
     */
    deleteProductRail(params: {  "productRailId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteProductRail`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "productRailId": params["productRailId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    deleteProductStocks(params: {  "model"?: DeleteProductStocksModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteProductStocks`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteShelving(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteShelving`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteShelvingTray(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteShelvingTray`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteShelvingTrayPlacement(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DeleteShelvingTrayPlacement`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param planogramId
     */
    downloadPlanogramExport(params: {  "planogramId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/DownloadPlanogramExport`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "planogramId": params["planogramId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getStockStatus(options?: any): FetchArgs {
        const baseUrl = `/api/Stock/GetStockStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param contentType
     * @param contentDisposition
     * @param headers
     * @param length
     * @param name
     * @param fileName
     * @param planogramId
     */
    importPlanogram(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; "planogramId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/ImportPlanogram`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "planogramId": params["planogramId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "ContentType": params["contentType"],
            "ContentDisposition": params["contentDisposition"],
            "Headers": params["headers"],
            "Length": params["length"],
            "Name": params["name"],
            "FileName": params["fileName"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param productLockerId
     */
    openProductLocker(params: {  "productLockerId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/OpenProductLocker`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "productLockerId": params["productLockerId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param planogramId
     */
    updateActivePlanogram(params: {  "planogramId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateActivePlanogram`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "planogramId": params["planogramId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateLockerAddress`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateLocker(params: {  "model"?: LockerModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateLocker`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateLockerType(params: {  "model"?: LockerTypeModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateLockerType`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    updatePlanogramSettings(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdatePlanogramSettings`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateProductLocker`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateShelving(params: {  "model"?: ShelvingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateShelving`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateShelvings(params: {  "model"?: Array<ShelvingModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateShelvings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stock/UpdateShelvingTray`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * StockApi - functional programming interface
 */
export const StockApiFp = {
    /**
     *
     *
     */
    calculateOffsets(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ShelvingModel; }> {
        const fetchArgs = StockApiFetchParamCreator.calculateOffsets(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createLockerAddress(params: { "model"?: LockerAddressModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerAddressModel> {
        const fetchArgs = StockApiFetchParamCreator.createLockerAddress(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createLocker(params: { "model"?: LockerModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerModel> {
        const fetchArgs = StockApiFetchParamCreator.createLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createLockerType(params: { "model"?: LockerTypeModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerTypeModel> {
        const fetchArgs = StockApiFetchParamCreator.createLockerType(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quantity
     * @param lockerTypeId
     */
    createLockersAuto(params: { "quantity"?: number; "lockerTypeId"?: number; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: LockerModel; }> {
        const fetchArgs = StockApiFetchParamCreator.createLockersAuto(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param name
     */
    createPlanogram(params: { "name"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PlanogramModel> {
        const fetchArgs = StockApiFetchParamCreator.createPlanogram(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createProductLocker(params: { "model"?: ProductLockerModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductLockerModel> {
        const fetchArgs = StockApiFetchParamCreator.createProductLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createProductRail(params: { "model"?: ProductRailModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductRailModel> {
        const fetchArgs = StockApiFetchParamCreator.createProductRail(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createProductStocks(params: { "model"?: CreateProductStocksModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateProductStockResultModel> {
        const fetchArgs = StockApiFetchParamCreator.createProductStocks(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createShelving(params: { "model"?: ShelvingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShelvingModel> {
        const fetchArgs = StockApiFetchParamCreator.createShelving(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quantity
     */
    createShelvingsAuto(params: { "quantity"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ShelvingModel; }> {
        const fetchArgs = StockApiFetchParamCreator.createShelvingsAuto(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createShelvingTray(params: { "model"?: ShelvingTrayModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShelvingTrayModel> {
        const fetchArgs = StockApiFetchParamCreator.createShelvingTray(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quantity
     */
    createShelvingsTrayAuto(params: { "quantity"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ShelvingTrayModel; }> {
        const fetchArgs = StockApiFetchParamCreator.createShelvingsTrayAuto(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createShelvingTrayPlacement(params: { "model"?: ShelvingTrayPlacementModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShelvingTrayPlacementModel> {
        const fetchArgs = StockApiFetchParamCreator.createShelvingTrayPlacement(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteLockerAddress(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteLockerAddress(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteLocker(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteLockerType(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteLockerType(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deletePlanogram(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deletePlanogram(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param productLockerId
     */
    deleteProductLocker(params: { "productLockerId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductLockerModel>> {
        const fetchArgs = StockApiFetchParamCreator.deleteProductLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param productRailId
     */
    deleteProductRail(params: { "productRailId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductReceptionModel>> {
        const fetchArgs = StockApiFetchParamCreator.deleteProductRail(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    deleteProductStocks(params: { "model"?: DeleteProductStocksModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeleteProductStockResultModel> {
        const fetchArgs = StockApiFetchParamCreator.deleteProductStocks(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteShelving(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteShelving(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteShelvingTray(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteShelvingTray(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteShelvingTrayPlacement(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.deleteShelvingTrayPlacement(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param planogramId
     */
    downloadPlanogramExport(params: { "planogramId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.downloadPlanogramExport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getStockStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockStatusModel> {
        const fetchArgs = StockApiFetchParamCreator.getStockStatus(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param contentType
     * @param contentDisposition
     * @param headers
     * @param length
     * @param name
     * @param fileName
     * @param planogramId
     */
    importPlanogram(params: { "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; "planogramId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.importPlanogram(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param productLockerId
     */
    openProductLocker(params: { "productLockerId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.openProductLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param planogramId
     */
    updateActivePlanogram(params: { "planogramId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = StockApiFetchParamCreator.updateActivePlanogram(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateLockerAddress(params: { "model"?: LockerAddressModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerAddressModel> {
        const fetchArgs = StockApiFetchParamCreator.updateLockerAddress(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateLocker(params: { "model"?: LockerModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerModel> {
        const fetchArgs = StockApiFetchParamCreator.updateLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateLockerType(params: { "model"?: LockerTypeModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LockerTypeModel> {
        const fetchArgs = StockApiFetchParamCreator.updateLockerType(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    updatePlanogramSettings(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PlanogramSettingsModel> {
        const fetchArgs = StockApiFetchParamCreator.updatePlanogramSettings(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateProductLocker(params: { "model"?: ProductLockerModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductLockerStockModel> {
        const fetchArgs = StockApiFetchParamCreator.updateProductLocker(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateShelving(params: { "model"?: ShelvingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShelvingModel> {
        const fetchArgs = StockApiFetchParamCreator.updateShelving(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateShelvings(params: { "model"?: Array<ShelvingModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ShelvingModel; }> {
        const fetchArgs = StockApiFetchParamCreator.updateShelvings(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateShelvingTray(params: { "model"?: ShelvingTrayModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShelvingModel> {
        const fetchArgs = StockApiFetchParamCreator.updateShelvingTray(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * StockApi - object-oriented interface
 */
export class StockApi extends BaseAPI {
    /**
     *
     *
     */
    calculateOffsets( options?: any) {
        return StockApiFp.calculateOffsets(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any) {
        return StockApiFp.createLockerAddress(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createLocker(params: {  "model"?: LockerModel; }, options?: any) {
        return StockApiFp.createLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createLockerType(params: {  "model"?: LockerTypeModel; }, options?: any) {
        return StockApiFp.createLockerType(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quantity
     * @param lockerTypeId
     */
    createLockersAuto(params: {  "quantity"?: number; "lockerTypeId"?: number; }, options?: any) {
        return StockApiFp.createLockersAuto(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param name
     */
    createPlanogram(params: {  "name"?: string; }, options?: any) {
        return StockApiFp.createPlanogram(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any) {
        return StockApiFp.createProductLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createProductRail(params: {  "model"?: ProductRailModel; }, options?: any) {
        return StockApiFp.createProductRail(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createProductStocks(params: {  "model"?: CreateProductStocksModel; }, options?: any) {
        return StockApiFp.createProductStocks(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createShelving(params: {  "model"?: ShelvingModel; }, options?: any) {
        return StockApiFp.createShelving(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quantity
     */
    createShelvingsAuto(params: {  "quantity"?: number; }, options?: any) {
        return StockApiFp.createShelvingsAuto(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any) {
        return StockApiFp.createShelvingTray(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quantity
     */
    createShelvingsTrayAuto(params: {  "quantity"?: number; }, options?: any) {
        return StockApiFp.createShelvingsTrayAuto(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createShelvingTrayPlacement(params: {  "model"?: ShelvingTrayPlacementModel; }, options?: any) {
        return StockApiFp.createShelvingTrayPlacement(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteLockerAddress(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteLockerAddress(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteLocker(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteLockerType(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteLockerType(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deletePlanogram(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deletePlanogram(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param productLockerId
     */
    deleteProductLocker(params: {  "productLockerId"?: number; }, options?: any) {
        return StockApiFp.deleteProductLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param productRailId
     */
    deleteProductRail(params: {  "productRailId"?: number; }, options?: any) {
        return StockApiFp.deleteProductRail(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    deleteProductStocks(params: {  "model"?: DeleteProductStocksModel; }, options?: any) {
        return StockApiFp.deleteProductStocks(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteShelving(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteShelving(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteShelvingTray(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteShelvingTray(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteShelvingTrayPlacement(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.deleteShelvingTrayPlacement(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param planogramId
     */
    downloadPlanogramExport(params: {  "planogramId"?: number; }, options?: any) {
        return StockApiFp.downloadPlanogramExport(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getStockStatus(options?: any) {
        return StockApiFp.getStockStatus(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param contentType
     * @param contentDisposition
     * @param headers
     * @param length
     * @param name
     * @param fileName
     * @param planogramId
     */
    importPlanogram(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; "planogramId"?: number; }, options?: any) {
        return StockApiFp.importPlanogram(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param productLockerId
     */
    openProductLocker(params: {  "productLockerId"?: number; }, options?: any) {
        return StockApiFp.openProductLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param planogramId
     */
    updateActivePlanogram(params: {  "planogramId"?: number; }, options?: any) {
        return StockApiFp.updateActivePlanogram(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any) {
        return StockApiFp.updateLockerAddress(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateLocker(params: {  "model"?: LockerModel; }, options?: any) {
        return StockApiFp.updateLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateLockerType(params: {  "model"?: LockerTypeModel; }, options?: any) {
        return StockApiFp.updateLockerType(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    updatePlanogramSettings(params: {  "id"?: number; }, options?: any) {
        return StockApiFp.updatePlanogramSettings(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any) {
        return StockApiFp.updateProductLocker(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateShelving(params: {  "model"?: ShelvingModel; }, options?: any) {
        return StockApiFp.updateShelving(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateShelvings(params: {  "model"?: Array<ShelvingModel>; }, options?: any) {
        return StockApiFp.updateShelvings(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any) {
        return StockApiFp.updateShelvingTray(params, options)(this.fetch, this.basePath);
    }
};

/**
 * StockApi - factory interface
 */
export const StockApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         *
         */
        calculateOffsets( options?: any) {
            return StockApiFp.calculateOffsets(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any) {
            return StockApiFp.createLockerAddress(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createLocker(params: {  "model"?: LockerModel; }, options?: any) {
            return StockApiFp.createLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createLockerType(params: {  "model"?: LockerTypeModel; }, options?: any) {
            return StockApiFp.createLockerType(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quantity
         * @param lockerTypeId
         */
        createLockersAuto(params: {  "quantity"?: number; "lockerTypeId"?: number; }, options?: any) {
            return StockApiFp.createLockersAuto(params, options)(fetch, basePath);
        },
        /**
         *
         * @param name
         */
        createPlanogram(params: {  "name"?: string; }, options?: any) {
            return StockApiFp.createPlanogram(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any) {
            return StockApiFp.createProductLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createProductRail(params: {  "model"?: ProductRailModel; }, options?: any) {
            return StockApiFp.createProductRail(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createProductStocks(params: {  "model"?: CreateProductStocksModel; }, options?: any) {
            return StockApiFp.createProductStocks(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createShelving(params: {  "model"?: ShelvingModel; }, options?: any) {
            return StockApiFp.createShelving(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quantity
         */
        createShelvingsAuto(params: {  "quantity"?: number; }, options?: any) {
            return StockApiFp.createShelvingsAuto(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any) {
            return StockApiFp.createShelvingTray(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quantity
         */
        createShelvingsTrayAuto(params: {  "quantity"?: number; }, options?: any) {
            return StockApiFp.createShelvingsTrayAuto(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createShelvingTrayPlacement(params: {  "model"?: ShelvingTrayPlacementModel; }, options?: any) {
            return StockApiFp.createShelvingTrayPlacement(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteLockerAddress(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteLockerAddress(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteLocker(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteLockerType(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteLockerType(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deletePlanogram(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deletePlanogram(params, options)(fetch, basePath);
        },
        /**
         *
         * @param productLockerId
         */
        deleteProductLocker(params: {  "productLockerId"?: number; }, options?: any) {
            return StockApiFp.deleteProductLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param productRailId
         */
        deleteProductRail(params: {  "productRailId"?: number; }, options?: any) {
            return StockApiFp.deleteProductRail(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        deleteProductStocks(params: {  "model"?: DeleteProductStocksModel; }, options?: any) {
            return StockApiFp.deleteProductStocks(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteShelving(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteShelving(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteShelvingTray(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteShelvingTray(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteShelvingTrayPlacement(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.deleteShelvingTrayPlacement(params, options)(fetch, basePath);
        },
        /**
         *
         * @param planogramId
         */
        downloadPlanogramExport(params: {  "planogramId"?: number; }, options?: any) {
            return StockApiFp.downloadPlanogramExport(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getStockStatus(options?: any) {
            return StockApiFp.getStockStatus(options)(fetch, basePath);
        },
        /**
         *
         * @param contentType
         * @param contentDisposition
         * @param headers
         * @param length
         * @param name
         * @param fileName
         * @param planogramId
         */
        importPlanogram(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; "planogramId"?: number; }, options?: any) {
            return StockApiFp.importPlanogram(params, options)(fetch, basePath);
        },
        /**
         *
         * @param productLockerId
         */
        openProductLocker(params: {  "productLockerId"?: number; }, options?: any) {
            return StockApiFp.openProductLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param planogramId
         */
        updateActivePlanogram(params: {  "planogramId"?: number; }, options?: any) {
            return StockApiFp.updateActivePlanogram(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateLockerAddress(params: {  "model"?: LockerAddressModel; }, options?: any) {
            return StockApiFp.updateLockerAddress(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateLocker(params: {  "model"?: LockerModel; }, options?: any) {
            return StockApiFp.updateLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateLockerType(params: {  "model"?: LockerTypeModel; }, options?: any) {
            return StockApiFp.updateLockerType(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        updatePlanogramSettings(params: {  "id"?: number; }, options?: any) {
            return StockApiFp.updatePlanogramSettings(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateProductLocker(params: {  "model"?: ProductLockerModel; }, options?: any) {
            return StockApiFp.updateProductLocker(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateShelving(params: {  "model"?: ShelvingModel; }, options?: any) {
            return StockApiFp.updateShelving(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateShelvings(params: {  "model"?: Array<ShelvingModel>; }, options?: any) {
            return StockApiFp.updateShelvings(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateShelvingTray(params: {  "model"?: ShelvingTrayModel; }, options?: any) {
            return StockApiFp.updateShelvingTray(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupplierOrderApi - fetch parameter creator
 */
export const SupplierOrderApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createSupplierOrder(params: {  "model"?: SupplierOrderModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierOrder/CreateSupplierOrder`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteSupplierOrder(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierOrder/DeleteSupplierOrder`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    supplierOrders(options?: any): FetchArgs {
        const baseUrl = `/api/SupplierOrder/SupplierOrders`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    updateSupplierOrderStatus(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierOrder/UpdateSupplierOrderStatus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    validateReception(params: {  "model"?: ValidateSupplierOrderModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierOrder/ValidateReception`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierOrderApi - functional programming interface
 */
export const SupplierOrderApiFp = {
    /**
     *
     * @param model
     */
    createSupplierOrder(params: { "model"?: SupplierOrderModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierOrderModel> {
        const fetchArgs = SupplierOrderApiFetchParamCreator.createSupplierOrder(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteSupplierOrder(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierOrderApiFetchParamCreator.deleteSupplierOrder(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    supplierOrders(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierOrderModel>> {
        const fetchArgs = SupplierOrderApiFetchParamCreator.supplierOrders(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    updateSupplierOrderStatus(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierOrderApiFetchParamCreator.updateSupplierOrderStatus(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    validateReception(params: { "model"?: ValidateSupplierOrderModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ValidateSupplierOrderResultModel> {
        const fetchArgs = SupplierOrderApiFetchParamCreator.validateReception(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierOrderApi - object-oriented interface
 */
export class SupplierOrderApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createSupplierOrder(params: {  "model"?: SupplierOrderModel; }, options?: any) {
        return SupplierOrderApiFp.createSupplierOrder(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteSupplierOrder(params: {  "id"?: number; }, options?: any) {
        return SupplierOrderApiFp.deleteSupplierOrder(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    supplierOrders(options?: any) {
        return SupplierOrderApiFp.supplierOrders(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    updateSupplierOrderStatus(params: {  "id"?: number; }, options?: any) {
        return SupplierOrderApiFp.updateSupplierOrderStatus(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    validateReception(params: {  "model"?: ValidateSupplierOrderModel; }, options?: any) {
        return SupplierOrderApiFp.validateReception(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierOrderApi - factory interface
 */
export const SupplierOrderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createSupplierOrder(params: {  "model"?: SupplierOrderModel; }, options?: any) {
            return SupplierOrderApiFp.createSupplierOrder(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteSupplierOrder(params: {  "id"?: number; }, options?: any) {
            return SupplierOrderApiFp.deleteSupplierOrder(params, options)(fetch, basePath);
        },
        /**
         *
         */
        supplierOrders(options?: any) {
            return SupplierOrderApiFp.supplierOrders(options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        updateSupplierOrderStatus(params: {  "id"?: number; }, options?: any) {
            return SupplierOrderApiFp.updateSupplierOrderStatus(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        validateReception(params: {  "model"?: ValidateSupplierOrderModel; }, options?: any) {
            return SupplierOrderApiFp.validateReception(params, options)(fetch, basePath);
        },
    };
};


/**
 * TempApi - fetch parameter creator
 */
export const TempApiFetchParamCreator = {
    /**
     *
     */
    getLoggedTemperatures(options?: any): FetchArgs {
        const baseUrl = `/api/Temp/GetLoggedTemperatures`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getState(options?: any): FetchArgs {
        const baseUrl = `/api/Temp/GetState`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getTemperature(options?: any): FetchArgs {
        const baseUrl = `/api/Temp/GetTemperature`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TempApi - functional programming interface
 */
export const TempApiFp = {
    /**
     *
     */
    getLoggedTemperatures(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LoggedTemp>> {
        const fetchArgs = TempApiFetchParamCreator.getLoggedTemperatures(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TempHandlerState> {
        const fetchArgs = TempApiFetchParamCreator.getState(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getTemperature(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = TempApiFetchParamCreator.getTemperature(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TempApi - object-oriented interface
 */
export class TempApi extends BaseAPI {
    /**
     *
     */
    getLoggedTemperatures(options?: any) {
        return TempApiFp.getLoggedTemperatures(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getState(options?: any) {
        return TempApiFp.getState(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getTemperature(options?: any) {
        return TempApiFp.getTemperature(options)(this.fetch, this.basePath);
    }
};

/**
 * TempApi - factory interface
 */
export const TempApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getLoggedTemperatures(options?: any) {
            return TempApiFp.getLoggedTemperatures(options)(fetch, basePath);
        },
        /**
         *
         */
        getState(options?: any) {
            return TempApiFp.getState(options)(fetch, basePath);
        },
        /**
         *
         */
        getTemperature(options?: any) {
            return TempApiFp.getTemperature(options)(fetch, basePath);
        },
    };
};


/**
 * TextTranslationHmiApi - fetch parameter creator
 */
 export const TextTranslationHmiApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/TextTranslationHmi/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: TextTranslationHmiModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/TextTranslationHmi/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/TextTranslationHmi/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: TextTranslationHmiModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/TextTranslationHmi/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateDefaultText(params: {  "model"?: TextHmiModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/TextTranslationHmi/UpdateDefaultText`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TextTranslationHmiApi - functional programming interface
 */
export const TextTranslationHmiApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = TextTranslationHmiApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: TextTranslationHmiModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TextTranslationHmiModel> {
        const fetchArgs = TextTranslationHmiApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: TextHmiModel; }> {
        const fetchArgs = TextTranslationHmiApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: TextTranslationHmiModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TextTranslationHmiModel> {
        const fetchArgs = TextTranslationHmiApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateDefaultText(params: { "model"?: TextHmiModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TextHmiModel> {
        const fetchArgs = TextTranslationHmiApiFetchParamCreator.updateDefaultText(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TextTranslationHmiApi - object-oriented interface
 */
export class TextTranslationHmiApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return TextTranslationHmiApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: TextTranslationHmiModel; }, options?: any) {
        return TextTranslationHmiApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return TextTranslationHmiApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: TextTranslationHmiModel; }, options?: any) {
        return TextTranslationHmiApiFp.update(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateDefaultText(params: {  "model"?: TextHmiModel; }, options?: any) {
        return TextTranslationHmiApiFp.updateDefaultText(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TextTranslationHmiApi - factory interface
 */
export const TextTranslationHmiApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return TextTranslationHmiApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: TextTranslationHmiModel; }, options?: any) {
            return TextTranslationHmiApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return TextTranslationHmiApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: TextTranslationHmiModel; }, options?: any) {
            return TextTranslationHmiApiFp.update(params, options)(fetch, basePath);
        },
         /**
         *
         * @param model
         */
        updateDefaultText(params: {  "model"?: TextHmiModel; }, options?: any) {
            return TextTranslationHmiApiFp.updateDefaultText(params, options)(fetch, basePath);
        },
    };
};


/**
 * VoucherApi - fetch parameter creator
 */
export const VoucherApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createVoucherCampaign(params: {  "model"?: VoucherCampaignModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/CreateVoucherCampaign`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createVouchers(params: {  "model"?: CreateVouchersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/CreateVouchers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getVouchers(options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/GetVouchers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteVoucher(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/DeleteVoucher`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    deleteVouchersByCampaign(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/DeleteVouchersByCampaign`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param fromDate
     */
    getVouchersHistory(params: {  "fromDate"?: Date; }, options?: any): FetchArgs {
        const baseUrl = `/api/Voucher/GetVouchersHistory`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fromDate": params["fromDate"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * VoucherApi - functional programming interface
 */
export const VoucherApiFp = {
    /**
     *
     * @param model
     */
    createVoucherCampaign(params: { "model"?: VoucherCampaignModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherCampaignModel> {
        const fetchArgs = VoucherApiFetchParamCreator.createVoucherCampaign(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createVouchers(params: { "model"?: CreateVouchersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VoucherModel>> {
        const fetchArgs = VoucherApiFetchParamCreator.createVouchers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getVouchers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VouchersResultModel> {
        const fetchArgs = VoucherApiFetchParamCreator.getVouchers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteVoucher(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) =>Promise<any> {
        const fetchArgs = VoucherApiFetchParamCreator.deleteVoucher(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    deleteVouchersByCampaign(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) =>Promise<any> {
        const fetchArgs = VoucherApiFetchParamCreator.deleteVouchersByCampaign(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param fromDate
     */
    getVouchersHistory(params: { "fromDate"?: Date;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VouchersHistoryResultModel> {
        const fetchArgs = VoucherApiFetchParamCreator.getVouchersHistory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * VoucherApi - object-oriented interface
 */
export class VoucherApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createVoucherCampaign(params: {  "model"?: VoucherCampaignModel; }, options?: any) {
        return VoucherApiFp.createVoucherCampaign(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createVouchers(params: {  "model"?: CreateVouchersModel; }, options?: any) {
        return VoucherApiFp.createVouchers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getVouchers(options?: any) {
        return VoucherApiFp.getVouchers(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteVoucher(params: {  "id"?: number; }, options?: any) {
        return VoucherApiFp.deleteVoucher(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    deleteVouchersByCampaign(params: {  "id"?: number; }, options?: any) {
        return VoucherApiFp.deleteVouchersByCampaign(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param fromDate
     */
    getVouchersHistory(params: {  "fromDate"?: Date; }, options?: any) {
        return VoucherApiFp.getVouchersHistory(params, options)(this.fetch, this.basePath);
    }
};

/**
 * VoucherApi - factory interface
 */
export const VoucherApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createVoucherCampaign(params: {  "model"?: VoucherCampaignModel; }, options?: any) {
            return VoucherApiFp.createVoucherCampaign(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createVouchers(params: {  "model"?: CreateVouchersModel; }, options?: any) {
            return VoucherApiFp.createVouchers(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getVouchers(options?: any) {
            return VoucherApiFp.getVouchers(options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        deleteVoucher(params: {  "id"?: number; }, options?: any) {
            return VoucherApiFp.deleteVoucher(params, options)(this.fetch, this.basePath);
        },
        /**
         *
         * @param id
         */
        deleteVouchersByCampaign(params: {  "id"?: number; }, options?: any) {
            return VoucherApiFp.deleteVouchersByCampaign(params, options)(this.fetch, this.basePath);
        },
        /**
         *
         * @param fromDate
         */
        getVouchersHistory(params: {  "fromDate"?: Date; }, options?: any) {
            return VoucherApiFp.getVouchersHistory(params, options)(fetch, basePath);
        },
    };
};

