import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';
import * as PlanogramStore from "../store/Planogram";
import * as StockStore from "../store/Stock";
import * as Styles from '../styles/baseStyle';
import LockerPlanogramGrid from './LockerPlanogramGrid';

type LockerControlProps = PlanogramStore.PlanogramState
    & LockerControlOwnProps
    & typeof PlanogramStore.actionCreators;

interface LockerControlOwnProps {
    stock: StockStore.StockState;
    disabled: boolean;
    productLocker: Api.ProductLockerModel;
}

const PLANO_HEIGHT = 24;
const PLANO_WIDTH = 102;
const PLANO_SQUARE_SIZE_MM = 100;

const sensList: Array<Api.ProductLockerModelSensEnum> = [
    "Vertical", "Horizontal"
];

interface LockerControlState {
    openDialogMsg: boolean;
    msgDialog: string;
}

class LockerControl extends React.Component<LockerControlProps, {}> {
    interval = null;

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }


    public render() {
        if(!this.props.stock.stockStatus)
            return <div></div>;

        return ( 
        <div className="gridContainerLockerPlano">
            <div className="lockerPlanoItem1 card" style={{
                ...Styles.cardStyle,
                padding: "3px 13px",
                marginBottom: 10,
                height: "100%",
                display: "flex",
                overflowX: "auto"
            }}>
                <div>
                    <LockerPlanogramGrid 
                        planoType={"Control"}
                        planoHeight={PLANO_HEIGHT}
                        planoWidth={PLANO_WIDTH}
                        productLockerId={this.props.productLockerControlId}
                        lockerGrids = {_.sortBy(this.props.stock.stockStatus.productLockers
                            .map(x => {
                                return {
                                    startRow: x.startRow,
                                    endRow: x.endRow,
                                    startCol: x.startCol,
                                    endCol: x.endCol,
                                    productLocker: x
                                };
                            }).filter(x => x), x => `${x.startRow}|${x.startCol}`)}
                        onSelectProductLocker={(productLocker) => this.props.updateProductLockerControlId(productLocker.productLockerId)} />
                </div>
            </div>
            <div className="lockerPlanoItem2"> 
                <div className="card" style={{
                    ...Styles.cardStyle,
                    padding: 15,
                    marginBottom: 10,
                    height: "100%"
                }}> 
                   <button className="btn btn-primary btn-sm"
                            type={"button"}
                            disabled={!this.props.stock.stockStatus || !this.props.productLockerControlId 
                                || !this.props.stock.stockStatus.productLockers.find(x => x.productLockerId == this.props.productLockerControlId).isLocked}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => {
                                this.props.requestOpenLocker(new Date().getTime(), this.props.productLockerControlId);
                            }}> 
                                {`${getText("PlanogramLockerControlButton")} ${this.props.productLockerControlId ? 
                                    this.props.stock.stockStatus.lockers[this.props.stock.stockStatus.productLockers.find(x => x.productLockerId == this.props.productLockerControlId).lockerId].name
                                         : ""}`} </button>
                </div>
            </div>
        </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.planogram,
    stock: state.stock,
    disabled: !canUpdate(state),
    productLocker: PlanogramStore.productLockerSelector(state)
} as LockerControlOwnProps), {
        ...PlanogramStore.actionCreators,
    })(LockerControl as any);