import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { CSSProperties } from 'react';
import Tooltip from "./Tooltip";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { LockerGrid, LockerPlanoType } from '../store/Planogram';

interface LockerPlanogramGridProps {
    planoType: LockerPlanoType;
    style?: any;
    planoHeight: number;
    planoWidth: number;
    lockerGrids: Array<LockerGrid>;
    lockerPreview?: LockerGrid;
    productLockerId?: number;
    externalProds?: { [key: string]: Api.ExternalProductModel; };
    ipAddresses?: { [key: string]: Api.LockerAddressModel; };
    locks?: { [key: string]: Api.LockModel; };
    onMouseEnterRail?: (row: number, col: number) => void;
    onSelectCreatePosition?: (lockerPrev: LockerGrid) => void;
    onSelectProductLocker: (productLocker: Api.ProductLockerModel) => void;
    updateCreateLockerId?: (id: number, productLocker: Api.ProductLockerModel) => any;
}

export default class LockerPlanogramGrid extends React.Component<LockerPlanogramGridProps, {}> {
    getRow(row: number) {
        return (this.props.planoHeight + 1 - row);
    }

    get previewIsValid(): boolean {
        return this.props.lockerPreview
            && 0 < this.props.lockerPreview.startRow
            && 0 < this.props.lockerPreview.startCol
            && this.props.lockerPreview.endRow <= this.props.planoHeight
            && this.props.lockerPreview.endCol <= this.props.planoWidth
            && !this.props.lockerGrids.some(x =>
                ((this.props.lockerPreview.startRow >= x.startRow && this.props.lockerPreview.startRow <= x.endRow) 
                    || (this.props.lockerPreview.endRow >= x.startRow && this.props.lockerPreview.endRow <= x.endRow))
                    && ((this.props.lockerPreview.startCol >= x.startCol && this.props.lockerPreview.startCol <= x.endCol) 
                    || (this.props.lockerPreview.endCol >= x.startCol && this.props.lockerPreview.endCol <= x.endCol))
            || ((x.startRow >= this.props.lockerPreview.startRow && x.startRow <= this.props.lockerPreview.endRow) 
                    || (x.endRow >= this.props.lockerPreview.startRow && x.endRow <= this.props.lockerPreview.endRow))
                    && ((x.startCol >= this.props.lockerPreview.startCol && x.startCol <= this.props.lockerPreview.endCol) 
                    || (x.endCol >= this.props.lockerPreview.startCol && x.endCol <= this.props.lockerPreview.endCol))
            || (this.props.lockerPreview.startRow >= x.startRow && this.props.lockerPreview.endRow <= x.endRow 
                    && this.props.lockerPreview.startCol <= x.startCol && this.props.lockerPreview.endCol >= x.endCol)
            || (this.props.lockerPreview.startRow <= x.startRow && this.props.lockerPreview.endRow >= x.endRow 
                && this.props.lockerPreview.startCol >= x.startCol && this.props.lockerPreview.endCol <= x.endCol));
    }

    handleSelectPreview(): void {
        if (this.previewIsValid) {
            this.props.onSelectCreatePosition(this.props.lockerPreview);
        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{
                height: "100%",
                minWidth: 671,
                display: "grid",
                fontSize: 12,
                gridTemplateRows: "repeat(" + (this.props.planoHeight + 1) + ", 1fr)",
                gridTemplateColumns: "repeat(" + (this.props.planoWidth + 1) + ", 1fr)",
                ...this.props.style
            }}>
                {Array.apply(null, { length: this.props.planoHeight + 1 })
                    .map(Number.call, Number)
                    .map(row => Array.apply(null, { length: this.props.planoWidth + 1 })
                        .map(Number.call, Number)
                        .map(col => <div key={`${row}-${col}`}
                            style={{
                            position: "relative",
                            whiteSpace: "nowrap",
                            maxWidth: 40,
                            minWidth: 25,
                            gridRow: (row + 1) + " / " + (row + 2),
                            gridColumn: (col + 1) + " / " + (col + 2),
                            border: this.isDarkMode() ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(59, 67, 112, 0.15)"
                        }}
                        onMouseEnter={(e) => {
                            this.props.planoType == "Normal" && this.props.onMouseEnterRail(row, col);
                        }}
                            onClick={(e) => {
                                this.props.planoType == "Normal" && this.handleSelectPreview();
                            }}>
                            {col === 0
                                && row !== 0
                                && this.getRow(row)
                            }
                            {row === 0
                                && col !== 0
                                && <div style={{
                                    display: "flex", flexDirection: "row",
                                    alignItems: "center", height: "100%"
                                }}>                            
                                    <div style={{ textAlign: "center", width: "100%"}}>{col}</div>                                  
                                </div>}
                        </div>))}
                {_.sortBy(this.props.lockerGrids, x => (x.startCol * 1000) + x.startRow).map((lockerGrid, index) =>
                    <Tooltip key={lockerGrid.productLocker.productLockerId} placement={"topRight"} overlay={
                    <div>
                        {this.props.planoType == "Normal" ?
                        <div><span>{getText("PlanogramProduct")}: </span> { lockerGrid.productLocker.externalProductId != 0 && this.props.externalProds[lockerGrid.productLocker.externalProductId].name} <br />
                            <span>{getText("PlanogramLockerIP")}: </span><span >{lockerGrid.productLocker.lockerAddressId != 0 && this.props.ipAddresses[lockerGrid.productLocker.lockerAddressId].name}</span> <br />
                            <span>{getText("PlanogramLockerLockNum")}: </span><span >{lockerGrid.productLocker.lockId != 0 && this.props.locks[lockerGrid.productLocker.lockId].lockNumber}</span> <br />
                        </div>    
                        : <div>{lockerGrid.productLocker.isLocked ? getText("PlanogramLockerClose") : getText("PlanogramLockerOpen") }</div>
                        }              
                    </div>}>
                        <div key={lockerGrid.productLocker.productLockerId}
                            style={{
                                gridRow: (lockerGrid.startRow + 1) + " / " + (lockerGrid.endRow + 2),
                                gridColumn: (lockerGrid.startCol + 1) + " / " + (lockerGrid.endCol + 2),
                                backgroundColor: this.props.planoType == "Normal" ? Styles.colorsPalette[index % Styles.colorsPalette.length]
                                                    : lockerGrid.productLocker.isLocked ? '#6DDC7F ' : '#FF423B',
                                opacity: 0.75,
                                cursor: "pointer",
                                border: this.props.productLockerId === lockerGrid.productLocker.productLockerId
                                    ? "4px solid #3B4370" : this.props.planoType == "Normal" 
                                        ? (lockerGrid.productLocker.externalProductId != 0 ? '2px solid #a00606' : "") 
                                        : lockerGrid.productLocker.isLocked ? '2px solid #55AB64' : '2px solid #992723'
                            }}
                            onClick={(e) => {
                                this.props.onSelectProductLocker(lockerGrid.productLocker);
                                this.props.planoType == "Normal" && this.props.updateCreateLockerId(lockerGrid.productLocker.lockerId, lockerGrid.productLocker)
                                e.preventDefault();
                            }}>
                        </div>
                </Tooltip>)}
                {this.props.planoType == "Normal" && this.props.lockerPreview
                    && <div style={{
                        gridRow: (this.props.lockerPreview.startRow + 1) + " / " + (this.props.lockerPreview.endRow + 2),
                        gridColumn: (this.props.lockerPreview.startCol + 1) + " / " + (this.props.lockerPreview.endCol + 2),
                        backgroundColor: this.previewIsValid
                            ? "#000000"
                            : "red",
                        opacity: 0.9,
                        pointerEvents: "none"
                    }}>
                    </div>}
            </div>
        );
    };
}