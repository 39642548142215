import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as ReduxForm from "redux-form";
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type LockerAddressFormProps = InjectedFormProps<{}, {}, {}>
    & LockerAddressFormOwnProps
    & LockerAddressFormExternalProps;

interface LockerAddressFormOwnProps {
    text: string;
    disabled: boolean;
    lockerAddresses: { [key: string]: Api.LockerAddressModel; };
    ipUpdated?: string;
}

interface LockerAddressFormExternalProps {
    onSubmit: (values: Api.LockerAddressModel) => Promise<any>
}

class LockerAddressForm extends React.Component<LockerAddressFormProps, {}> {
    constructor(props: LockerAddressFormProps) {
        super(props);
        this.validationLockerAddress = this.validationLockerAddress.bind(this);
    }

    validationLockerAddress(value) {
        return _.values(this.props.lockerAddresses)
             .some(x =>(this.props.ipUpdated == undefined && x.ipAddress === value) || 
               (this.props.ipUpdated != value && x.ipAddress === value))
                ? getText( "SettingsIPExist")
                : undefined
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical borderSelectors" style={{ maxWidth: '50%' }}>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsIPTable")}</label>
                    <Field name="ipAddress"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required, this.validationLockerAddress]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsNameTable")}</label>
                    <Field name="name"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-12 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createLockerAddress'));

let createForm = reduxForm({
    form: 'createLockerAddress',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(LockerAddressForm as any) as any;
const createSelector = formValueSelector('createLockerAddress');

export const CreateLockerAddressForm = connect((state: ApplicationState) => {
    return {
        text: getText("SettingsCreate"),
        initialValues: null,
        disabled: !canUpdate(state),
        lockerAddresses: state.stock.stockStatus.lockerAddresses
    } as LockerAddressFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<LockerAddressFormExternalProps>


let form = reduxForm({
    form: 'updateLockerAddress',
    enableReinitialize: true,
    destroyOnUnmount: true
})(LockerAddressForm as any) as any;
const updateSelector = formValueSelector('updateLockerAddress');

export const UpdateLockerAddressForm = connect((state: ApplicationState) => {
    return {
        text: getText( "SettingsSave"),
        initialValues: state.stock.stockStatus.lockerAddresses[state.stock.selectedLockerAddressId],
        disabled: !canUpdate(state),
        lockerAddresses: state.stock.stockStatus.lockerAddresses,
        ipUpdated: state.stock.stockStatus.lockerAddresses[state.stock.selectedLockerAddressId].ipAddress
    } as LockerAddressFormOwnProps
}, null)(form as any) as any as React.ComponentClass<LockerAddressFormExternalProps>

