import * as Api from "../api/api";
import * as _ from 'lodash';
import { Action, Reducer } from 'redux';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders, calculateProductWidth } from '../utils/utils';
import { ReceiveStockStatus } from "./Stock";
import { createSelector } from 'reselect';
import * as Download from "downloadjs";
import * as MimeTypes from "mime-types";
import * as Notification from "react-notification-system-redux";
import { getText } from "../utils/texts";

export interface PlanogramState {
    shelvingId?: number;
    productRailId?: number;
    familyId?: number;
    subFamilyId?: number;
    planogramId?: number;
    selectedPlanogramId?: number;
    productLockerId?: number;
    productLockerControlId?: number;
    productRailStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    createProductRailState: {
        isLoading: boolean;
        isCreating: boolean;
        sens: Api.ProductRailModelSensEnum;
        productId?: number;
        requestTime?: number;
        position: {
            row: number;
            col: number;
        }
    };
    activePlanogramState: {
        isLoading: boolean;
        requestTime?: number;
    };
    createPlanogramState: {
        name: string;
        isLoading: boolean;
        requestTime?: number;
    },
    importPlanogramState: {
        isLoading: boolean;
        requestTime?: number;
    },
    exportPlanogramState: {
        isLoading: boolean;
        requestTime?: number;
    },
    deletePlanogram: {
        isLoading: boolean;
        requestTime?: number;
    },
    addTray: {
        isOpen: boolean;
        isLoading: boolean;
        requestTime?: number;
    },
    deleteTray: {
        isLoading: boolean;
        requestTime?: number;
    },
    planogramSettings: {
        isOpen: boolean;
        isLoading: boolean;
        requestTime?: number;
    },
    settings: {
        showDeleteShelving:boolean
    },
    createProductLockerState: {
        lockerId?: number;
        lockerTypeId?: number;
        isLoading: boolean;
        isCreating: boolean;
        sens: Api.ProductLockerModelSensEnum;
        requestTime?: number;
        position: {
            row: number;
            col: number;
        }
    };
    productLockerStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
            productLockerStateError: boolean;
        }
    };
}

export interface LockerGrid {
    productLocker?: Api.ProductLockerModel;
    startRow: number;
    endRow: number;
    startCol: number;
    endCol: number;
}

export type LockerPlanoType = "Normal" | "Control";

interface PlanogramUpdateShelvingId {
    type: "PLANOGRAM_UPDATE_SHELVINGID";
    payload: { shelvingId: number };
}

interface PlanogramUpdateFamilyId {
    type: "PLANOGRAM_UPDATE_FAMILYID";
    payload: { familyId: number };
}

interface PlanogramUpdateSubFamilyId {
    type: "PLANOGRAM_UPDATE_SUBFAMILYID";
    payload: { subFamilyId: number };
}

interface PlanogramUpdateProductRailId {
    type: "PLANOGRAM_UPDATE_PRODUCTRAILID";
    payload: { productRailId: number };
}

interface PlanogramUpdateProductLockerId {
    type: "PLANOGRAM_UPDATE_PRODUCTLOCKERID";
    payload: { productLockerId: number };
}

interface PlanogramUpdateProductLockerControlId {
    type: "PLANOGRAM_UPDATE_PRODUCTLOCKER_CONTROLID";
    payload: { productLockerControlId: number };
}

interface PlanogramUpdateCreateProductId {
    type: "PLANOGRAM_UPDATE_CREATE_PRODUCTID";
    payload: { productId: number };
}

interface PlanogramUpdateLockerPlanoId {
    type: "PLANOGRAM_UPDATE_LOCKERPLANOID";
    payload: { lockerId: number };
}

interface PlanogramUpdateLockerTypePlanoId {
    type: "PLANOGRAM_UPDATE_LOCKERTYPEPLANOID";
    payload: { lockerTypeId: number };
}

interface PlanogramUpdateCreatePosition {
    type: "PLANOGRAM_UPDATE_CREATE_POSITION";
    payload: { row: number; col: number; };
}

interface PlanogramUpdateCreatePositionLockerPlano {
    type: "PLANOGRAM_UPDATE_CREATE_POSITION_LOCKERPLANO";
    payload: { row: number; col: number; };
}

interface PlanogramUpdateCreateSens {
    type: "PLANOGRAM_UPDATE_CREATE_SENS";
    payload: { value: Api.ProductRailModelSensEnum };
}

interface PlanogramUpdateCreateLockerSens {
    type: "PLANOGRAM_UPDATE_CREATE_LOCKER_SENS";
    payload: { value: Api.ProductLockerModelSensEnum };
}

interface PlanogramUpdateCreating {
    type: "PLANOGRAM_UPDATE_CREATING";
    payload: { value: boolean };
}

interface PlanogramUpdateCreatingLockersPlano {
    type: "PLANOGRAM_UPDATE_CREATING_LOCKERSPLANO";
    payload: { value: boolean };
}

interface PlanogramUpdateSelectPlanogramId {
    type: "PLANOGRAM_UPDATE_SELECT_PLANOGRAMID";
    payload: { planogramId: number };
}

interface PlanogramRequestUpdateActivePlanogram {
    type: "PLANOGRAM_REQUEST_ACTIVATE_PLANOGRAM";
    payload: { requestTime: number;  };
}

export interface PlanogramReceiveUpdateActivePlanogram {
    type: "PLANOGRAM_RECEIVE_ACTIVATE_PLANOGRAM";
    payload: { requestTime: number; planogramId: number; };
    error?: any;
}

interface PlanogramRequestDeleteProductRail {
    type: "PLANOGRAM_REQUEST_DELETE_PRODUCTRAIL";
    payload: { productRailId: number; requestTime: number };
}
//Action on store is made in store.ts
export interface PlanogramReceiveDeleteProductRail {
    type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTRAIL";
    payload: {
        productRailId: number;
        productReceptions: Array<Api.ProductReceptionModel>;
        requestTime: number;
    };
    error?: any;
}

interface PlanogramRequestCreateProductRail {
    type: "PLANOGRAM_REQUEST_CREATE_PRODUCTRAIL";
    payload: { requestTime: number };
}
//Action on store is made in store.ts
export interface PlanogramReceiveCreateProductRail {
    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTRAIL";
    payload: { requestTime: number; productRail: Api.ProductRailModel };
    error?: any;
}

interface PlanogramRequestCreatePlanogram {
    type: "PLANOGRAM_REQUEST_CREATE_PLANOGRAM";
    payload: { requestTime: number; }
}
export interface PlanogramReceiveCreatePlanogram {
    type: "PLANOGRAM_RECEIVE_CREATE_PLANOGRAM";
    payload: { requestTime: number; planogram?: Api.PlanogramModel; };
    error?: any;
}
interface PlanogramUpdateName {
    type: "PLANOGRAM_UPDATE_NAME";
    payload: { value: string; };
}

interface PlanogramRequestImport {
    type: "PLANOGRAM_REQUEST_IMPORT";
    payload: { requestTime: number; }
}
export interface PlanogramReceiveImport {
    type: "PLANOGRAM_RECEIVE_IMPORT";
    payload: { requestTime: number; };
    error?: any;
}

interface PlanogramRequestExport {
    type: "PLANOGRAM_REQUEST_EXPORT";
    payload: { requestTime: number; }
}
export interface PlanogramReceiveExport {
    type: "PLANOGRAM_RECEIVE_EXPORT";
    payload: { requestTime: number; };
    error?: any;
}

interface PlanogramRequestDeletePlanogram {
    type: "PLANOGRAM_REQUEST_DELETE_PLANOGRAM";
    payload: { requestTime: number; }
}
export interface PlanogramReceiveDeletePlanogram {
    type: "PLANOGRAM_RECEIVE_DELETE_PLANOGRAM";
    payload: { requestTime: number; id: number; };
    error?: any;
}

interface PlanogramRequestCreateShelvingTrayPlacement {
    type: "PLANOGRAM_REQUEST_CREATE_SHELVINGTRAYPLACEMENT";
    payload: { requestTime: number; }
}
//Is used in stock reducer to apply modification
export interface PlanogramReceiveCreateShelvingTrayPlacement {
    type: "PLANOGRAM_RECEIVE_CREATE_SHELVINGTRAYPLACEMENT";
    payload: { requestTime: number; value?: Api.ShelvingTrayPlacementModel; };
    error?: any;
}

interface PlanogramRequestDeleteShelvingTrayPlacement {
    type: "PLANOGRAM_REQUEST_DELETE_SHELVINGTRAYPLACEMENT";
    payload: { requestTime: number; shelvingTrayPlacementId: number; }
}
export interface PlanogramReceiveDeleteShelvingTrayPlacement {
    type: "PLANOGRAM_RECEIVE_DELETE_SHELVINGTRAYPLACEMENT";
    payload: { requestTime: number; shelvingTrayPlacementId: number; };
    error?: any;
}
export interface PlanogramRequestSaveSettings {
    type: "PLANOGRAM_SETTINGS_UPDATE";
    payload: { requestTime: number; value: Api.PlanogramSettingsModel; };
    error?: any;
}

interface PlanogramRequestCreateProductLocker{
    type: "PLANOGRAM_REQUEST_CREATE_PRODUCTLOCKER";
    payload: { requestTime: number };
}

export interface PlanogramReceiveCreateProductLocker {
    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTLOCKER";
    payload: { requestTime: number; productLocker: Api.ProductLockerModel };
    error?: any;
}

interface PlanogramRequestUpdateProductLocker{
    type: "PLANOGRAM_REQUEST_UPDATE_PRODUCTLOCKER";
    payload: { requestTime: number; productLockerId: number; };
}

export interface PlanogramReceiveUpdateProductLocker {
    type: "PLANOGRAM_RECEIVE_UPDATE_PRODUCTLOCKER";
    payload: { requestTime: number; productLockerId: number; lockerStock: Api.ProductLockerStockModel };
    error?: any;
}

interface PlanogramRequestDeleteProductLocker {
    type: "PLANOGRAM_REQUEST_DELETE_PRODUCTLOCKER";
    payload: { productLockerId: number; requestTime: number };
}

export interface PlanogramReceiveDeleteProductLocker {
    type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTLOCKER";
    payload: {
        productLockerId: number;
        productReceptions: Array<Api.ProductReceptionModel>;
        requestTime: number;
    };
    error?: any;
}

interface PlanogramOpenAddTray {
    type: "PLANOGRAM_OPEN_ADD_TRAY";
}
interface PlanogramCloseAddTray {
    type: "PLANOGRAM_CLOSE_ADD_TRAY";
}

interface PlanogramSettingsOpen{
    type: "PLANOGRAM_SETTINGS_OPEN";
}
interface PlanogramSettingsClose{
    type: "PLANOGRAM_SETTINGS_CLOSE";
}

interface PlanogramRequestOpenLocker {
    type: "PLANOGRAM_REQUEST_OPEN_LOCKER";
    payload: { requestTime: number;  productLockerId: number;};
}
export interface PlanogramReceiveOpenLocker {
    type: "PLANOGRAM_RECEIVE_OPEN_LOCKER";
    payload: { requestTime: number; productLockerId: number; };
    error?: any;
}

//placeholder
type KnownAction = PlanogramUpdateFamilyId
    | PlanogramUpdateProductRailId
    | PlanogramUpdateShelvingId
    | ReceiveStockStatus
    | PlanogramUpdateSelectPlanogramId
    | PlanogramUpdateCreating
    | PlanogramUpdateCreateSens
    | PlanogramUpdateCreateLockerSens
    | PlanogramUpdateSubFamilyId
    | PlanogramUpdateCreatePosition
    | PlanogramUpdateCreateProductId
    | PlanogramRequestDeleteProductRail
    | PlanogramReceiveDeleteProductRail
    | PlanogramRequestCreateProductRail
    | PlanogramReceiveCreateProductRail
    | PlanogramRequestUpdateActivePlanogram
    | PlanogramReceiveUpdateActivePlanogram
    | PlanogramRequestCreatePlanogram
    | PlanogramReceiveCreatePlanogram
    | PlanogramUpdateName
    | PlanogramRequestImport
    | PlanogramReceiveImport
    | PlanogramRequestExport
    | PlanogramReceiveExport
    | PlanogramRequestDeletePlanogram
    | PlanogramReceiveDeletePlanogram
    | PlanogramOpenAddTray
    | PlanogramCloseAddTray
    | PlanogramSettingsOpen
    | PlanogramSettingsClose
    | PlanogramRequestCreateShelvingTrayPlacement
    | PlanogramReceiveCreateShelvingTrayPlacement
    | PlanogramRequestDeleteShelvingTrayPlacement
    | PlanogramReceiveDeleteShelvingTrayPlacement
    | PlanogramRequestSaveSettings
    | PlanogramUpdateLockerPlanoId
    | PlanogramUpdateCreatingLockersPlano
    | PlanogramUpdateLockerTypePlanoId
    | PlanogramUpdateCreatePositionLockerPlano
    | PlanogramUpdateProductLockerId
    | PlanogramUpdateProductLockerControlId
    | PlanogramRequestCreateProductLocker
    | PlanogramReceiveCreateProductLocker
    | PlanogramRequestDeleteProductLocker
    | PlanogramReceiveDeleteProductLocker
    | PlanogramRequestUpdateProductLocker
    | PlanogramReceiveUpdateProductLocker
    | PlanogramRequestOpenLocker
    | PlanogramReceiveOpenLocker
    ;

export const actionCreators = {
    updateProductRailId: (productRailId: number) => <PlanogramUpdateProductRailId>{
        type: "PLANOGRAM_UPDATE_PRODUCTRAILID",
        payload: { productRailId: productRailId }
    },
    updateProductLockerId: (productLockerId: number) => <PlanogramUpdateProductLockerId>{
        type: "PLANOGRAM_UPDATE_PRODUCTLOCKERID",
        payload: { productLockerId: productLockerId }
    },
    updateProductLockerControlId: (productLockerControlId: number) => <PlanogramUpdateProductLockerControlId>{
        type: "PLANOGRAM_UPDATE_PRODUCTLOCKER_CONTROLID",
        payload: { productLockerControlId: productLockerControlId }
    },
    updateFamilyId: (familyId: number) => <PlanogramUpdateFamilyId>{
        type: "PLANOGRAM_UPDATE_FAMILYID",
        payload: { familyId: familyId }
    },
    updateSubFamilyId: (subFamilyId: number) => <PlanogramUpdateSubFamilyId>{
        type: "PLANOGRAM_UPDATE_SUBFAMILYID",
        payload: { subFamilyId: subFamilyId }
    },
    updateShelvingId: (shelvingId: number) => <PlanogramUpdateShelvingId>{
        type: "PLANOGRAM_UPDATE_SHELVINGID",
        payload: { shelvingId: shelvingId }
    },
    updateCreateProductId: (productId: number) => <PlanogramUpdateCreateProductId>{
        type: "PLANOGRAM_UPDATE_CREATE_PRODUCTID",
        payload: { productId: productId }
    },
    updateLockerId: (lockerId: number) => <PlanogramUpdateLockerPlanoId>{
        type: "PLANOGRAM_UPDATE_LOCKERPLANOID",
        payload: { lockerId: lockerId }
    },
    updateLockerTypeId: (lockerTypeId: number) => <PlanogramUpdateLockerTypePlanoId>{
        type: "PLANOGRAM_UPDATE_LOCKERTYPEPLANOID",
        payload: { lockerTypeId: lockerTypeId }
    },
    updateCreateSens: (value: Api.ProductRailModelSensEnum) => <PlanogramUpdateCreateSens>{
        type: "PLANOGRAM_UPDATE_CREATE_SENS",
        payload: { value: value }
    },
    updateCreateLockerSens: (value: Api.ProductLockerModelSensEnum) => <PlanogramUpdateCreateLockerSens>{
        type: "PLANOGRAM_UPDATE_CREATE_LOCKER_SENS",
        payload: { value: value }
    },
    updateCreatePosition: (row: number, col: number) => <PlanogramUpdateCreatePosition>{
        type: "PLANOGRAM_UPDATE_CREATE_POSITION",
        payload: { row: row, col: col }
    },
    updateCreatePositionLockerPlano: (row: number, col: number) => <PlanogramUpdateCreatePositionLockerPlano>{
        type: "PLANOGRAM_UPDATE_CREATE_POSITION_LOCKERPLANO",
        payload: { row: row, col: col }
    },
    updateCreating: (value: boolean) => <PlanogramUpdateCreating>{
        type: "PLANOGRAM_UPDATE_CREATING",
        payload: { value: value }
    },
    updateCreatingLockersPlano: (value: boolean) => <PlanogramUpdateCreatingLockersPlano>{
        type: "PLANOGRAM_UPDATE_CREATING_LOCKERSPLANO",
        payload: { value: value }
    },
    updateSelectPlanogramId: (planogramId: number) => <PlanogramUpdateSelectPlanogramId>{
        type: "PLANOGRAM_UPDATE_SELECT_PLANOGRAMID",
        payload: { planogramId: planogramId }
    },
    requestCreatePorductRail: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let stockStatus = getState().stock.stockStatus;

        let createState = getState().planogram.createProductRailState;
        let shelving = stockStatus.shelvings[getState().planogram.shelvingId];
        let shelvingTrayPlacement = stockStatus.shelvingTrayPlacements
            .find(x => x.shelvingId === shelving.shelvingId
                && x.planogramId === getState().planogram.selectedPlanogramId
                && x.level === createState.position.row - 1);
        let shelvingTray = stockStatus.shelvingTrays[shelvingTrayPlacement.shelvingTrayId];

        let endColValue = 1;
        let bandsDistance = 5;
        let prodWidth = calculateProductWidth(
            (getState().seed.seed.storeAppSettings.typeStore == "Ximiti" ?
                 getState().seed.seed.products[createState.productId]: getState().seed.seed.externalProducts[createState.productId] ),
            createState.sens);
        if(prodWidth > (shelvingTray.railWidth - bandsDistance)) {
            endColValue = (Math.ceil((prodWidth - (shelvingTray.railWidth - bandsDistance)) / shelvingTray.railWidth) + 1);
        }

        let fecthTask = api.createProductRail({
            model: {
                r: parseInt(shelving.name),
                a: createState.position.row - 1,
                d: createState.position.col,
                f: createState.position.col + endColValue - 1, //Because is inclusive
                planogramId: getState().planogram.selectedPlanogramId,
                productId:  getState().seed.seed.storeAppSettings.typeStore == "Ximiti" ? createState.productId : undefined,
                externalProductId: getState().seed.seed.storeAppSettings.typeStore != "Ximiti" ? createState.productId : undefined,
                sens: createState.sens,
                shelvingTrayId: shelvingTray.shelvingTrayId
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productRail => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTRAIL",
                    payload: { requestTime: requestTime, productRail: productRail }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTRAIL",
                    payload: { requestTime: requestTime, productRail: undefined },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_CREATE_PRODUCTRAIL",
            payload: { requestTime: requestTime }
        });
        return fecthTask;
    },
    requestDeleteProductRail: (requestTime: number, productRailId : number, robotStock: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        if (robotStock > 0) {
            return null;
        }
        else {
            let fetchTask = api.deleteProductRail({
                productRailId: getState().planogram.productRailId
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(productReceptions => {
                    dispatch({
                        type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTRAIL",
                        payload: {
                            requestTime: requestTime,
                            productRailId: productRailId,
                            productReceptions: productReceptions
                        }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTRAIL",
                        payload: {
                            requestTime: requestTime,
                            productRailId: productRailId,
                            productReceptions: []
                        },
                        error: err
                    });
                });

            dispatch({
                type: "PLANOGRAM_REQUEST_DELETE_PRODUCTRAIL",
                payload: { requestTime: requestTime, productRailId: productRailId }
            });
            return fetchTask;
        }        
    },
    requestUpdateActivePlanogram: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let planogramId = getState().planogram.selectedPlanogramId;
        let fetchTask = api.updateActivePlanogram({
            planogramId: planogramId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_ACTIVATE_PLANOGRAM",
                    payload: { requestTime: requestTime, planogramId: planogramId  }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_ACTIVATE_PLANOGRAM",
                    payload: { requestTime: requestTime, planogramId: planogramId },
                    error: err
                });
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_ACTIVATE_PLANOGRAM",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestCreatePlanogram: (requestTime: number, name: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.createPlanogram({
            name: name
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(planogram => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PLANOGRAM",
                    payload: { requestTime: requestTime, planogram: planogram }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PLANOGRAM",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_CREATE_PLANOGRAM",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestDeletePlanogram: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.deletePlanogram({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_DELETE_PLANOGRAM",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_DELETE_PLANOGRAM",
                    payload: { requestTime: requestTime, id: id  },
                    error: err
                });
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_DELETE_PLANOGRAM",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    updatePlanogramName: (value: string) => <PlanogramUpdateName>{
        type: "PLANOGRAM_UPDATE_NAME",
        payload: { value: value }
    },
    requestPlanogramExport: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let panogramName = getState().stock.stockStatus
            ? getState().stock.stockStatus.planograms.find(x => x.planogramId === getState().planogram.planogramId).name
            : "NA";
        let fetchTask = api.downloadPlanogramExport({
            planogramId: getState().planogram.selectedPlanogramId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "PLANOGRAM_RECEIVE_EXPORT", payload: { requestTime: requestTime } });
                let fileName = `${panogramName}.json`;
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            }).catch(err => {
                dispatch({ type: "PLANOGRAM_RECEIVE_EXPORT", payload: { requestTime: requestTime }, error: err });
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_EXPORT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestPlanogramImport: (requestTime: number, file: File): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let formData = new FormData();
        formData.append("file", file);

        let fetchTask = fetch(`/api/Stock/ImportPlanogram?planogramId=${getState().planogram.selectedPlanogramId}`, {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        })
            .then(() => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_IMPORT",
                    payload: { requestTime: requestTime }
                });
            }).catch(error => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_IMPORT",
                    payload: { requestTime: requestTime },
                    error: error
                });
            });

        dispatch({ type: "PLANOGRAM_REQUEST_IMPORT", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    planogramOpenTray: () => <PlanogramOpenAddTray>{
        type: "PLANOGRAM_OPEN_ADD_TRAY"
    },
    planogramCloseTray: () => <PlanogramCloseAddTray>{
        type: "PLANOGRAM_CLOSE_ADD_TRAY"
    },
    planogramSettingsOpen: () => <PlanogramSettingsOpen>{
        type: "PLANOGRAM_SETTINGS_OPEN"
    },
    planogramSettingsClose: () => <PlanogramSettingsClose>{
        type: "PLANOGRAM_SETTINGS_CLOSE"
    },
    requestCreateShelvingTrayPlacement: (requestTime: number, model: Api.ShelvingTrayPlacementModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.createShelvingTrayPlacement({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(shelvingTrayPlacement => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_SHELVINGTRAYPLACEMENT",
                    payload: { requestTime: requestTime, value: shelvingTrayPlacement }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_SHELVINGTRAYPLACEMENT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                } else {
                    dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationRequestFailed"), position: "tc", level: "error" }) as any);
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_CREATE_SHELVINGTRAYPLACEMENT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestDeleteShelvingTrayPlacement: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.deleteShelvingTrayPlacement({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_DELETE_SHELVINGTRAYPLACEMENT",
                    payload: { requestTime: requestTime, shelvingTrayPlacementId: id }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_DELETE_SHELVINGTRAYPLACEMENT",
                    payload: { requestTime: requestTime, shelvingTrayPlacementId: id },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                } else {
                    dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationRequestFailed"), position: "tc", level: "error" }) as any);
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_DELETE_SHELVINGTRAYPLACEMENT",
            payload: { requestTime: requestTime, shelvingTrayPlacementId: id }
        });
        return fetchTask;
    },
    requestSaveSettings: (requestTime: number, model: Api.PlanogramSettingsModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "PLANOGRAM_SETTINGS_UPDATE",
                payload: {
                    requestTime: requestTime, value: { ...model }
                }
            });
            resolve(model)
        });
    },
    requestCreateProductLocker: (requestTime: number, lockerGrid: LockerGrid): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();

        let createState = getState().planogram.createProductLockerState;

        let fecthTask = api.createProductLocker({
            model: {
                startRow: lockerGrid.startRow,
                endRow: lockerGrid.endRow,
                startCol: lockerGrid.startCol,
                endCol: lockerGrid.endCol,
                sens: createState.sens,
                lockerId: createState.lockerId
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productLocker => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTLOCKER",
                    payload: { requestTime: requestTime, productLocker: productLocker }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_CREATE_PRODUCTLOCKER",
                    payload: { requestTime: requestTime, productLocker: undefined },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: "Erreur", message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_CREATE_PRODUCTLOCKER",
            payload: { requestTime: requestTime }
        });
        return fecthTask;
    },
    requestUpdateProductLocker: (requestTime: number, model: Api.ProductLockerModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fecthTask = api.updateProductLocker({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productLocker => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_UPDATE_PRODUCTLOCKER",
                    payload: { requestTime: requestTime, productLockerId: model.productLockerId, lockerStock: productLocker }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_UPDATE_PRODUCTLOCKER",
                    payload: { requestTime: requestTime, productLockerId: model.productLockerId, lockerStock: undefined },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: "Erreur", message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_UPDATE_PRODUCTLOCKER",
            payload: { requestTime: requestTime, productLockerId: model.productLockerId  }
        });
        return fecthTask;
    },
    requestDeleteProductLocker: (requestTime: number, productLockerId : number, lockerStock: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        if (lockerStock > 0) {
            return null;
        }
        else {
            let fetchTask = api.deleteProductLocker({
                productLockerId: getState().planogram.productLockerId
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(productReceptions => {
                    dispatch({
                        type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTLOCKER",
                        payload: {
                            requestTime: requestTime,
                            productLockerId: productLockerId,
                            productReceptions: productReceptions
                        }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: "PLANOGRAM_RECEIVE_DELETE_PRODUCTLOCKER",
                        payload: {
                            requestTime: requestTime,
                            productLockerId: productLockerId,
                            productReceptions: []
                        },
                        error: err
                    });
                });

            dispatch({
                type: "PLANOGRAM_REQUEST_DELETE_PRODUCTLOCKER",
                payload: { requestTime: requestTime, productLockerId: productLockerId }
            });
            return fetchTask;
        }        
    },
    requestOpenLocker: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.openProductLocker({
            productLockerId: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_OPEN_LOCKER",
                    payload: { requestTime: requestTime, productLockerId: id }
                });
            })
            .catch(err => {
                dispatch({
                    type: "PLANOGRAM_RECEIVE_OPEN_LOCKER",
                    payload: { requestTime: requestTime, productLockerId: id  },
                    error: err
                });
                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: "Erreur", message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({
            type: "PLANOGRAM_REQUEST_OPEN_LOCKER",
            payload: { requestTime: requestTime, productLockerId: id }
        });
        return fetchTask;
    }
};

const unloadedState: PlanogramState = {
    createProductRailState: {
        isCreating: false,
        isLoading: false,
        sens: "Y1",
        position: {  row: 0, col: 0 }
    },
    createProductLockerState: {
        isCreating: false,
        isLoading: false,
        sens: "Vertical",
        position: {  row: 0, col: 0 }
    },
    productRailStates: {},
    productLockerStates: {},
    activePlanogramState: {
        isLoading: false
    },
    createPlanogramState: {
        isLoading: false,
        name: ""
    },
    importPlanogramState: {
        isLoading: false
    },
    exportPlanogramState: {
        isLoading: false
    },
    deletePlanogram: {
        isLoading: false
    },
    addTray: {
        isLoading: false,
        isOpen: false
    },
    deleteTray: {
        isLoading: false
    },
    planogramSettings: {
        isLoading: false,
        isOpen:false
    },
    settings: {
        showDeleteShelving: false
    }
};

const productRailsSelector = (state: ApplicationState) => state.stock.stockStatus ? state.stock.stockStatus.productRails : [];
const productRailIdSelector = (state: ApplicationState) => state.planogram.productRailId;

const productLockersSelector = (state: ApplicationState) => state.stock.stockStatus ? state.stock.stockStatus.productLockers : [];
const productLockerIdSelector = (state: ApplicationState) => state.planogram.productLockerId;
const externalProductsSelector = (state: ApplicationState) => state.seed.seed.externalProducts;

export const productRailSelector = createSelector(productRailsSelector,
    productRailIdSelector,
    (productRails, productRailId) => productRails.find(x => x.productRailId === productRailId));

export const productLockerSelector = createSelector(productLockersSelector,
    productLockerIdSelector,
    (productLockers, productLockerId) => productLockers.find(x => x.productLockerId === productLockerId));

export const locksAvailable = (lockerAddressId: number, productLockers: Array<Api.ProductLockerModel>, locks: { [key: string]: Api.LockModel; }): Array<number> => {
    let availables = _.values(locks).map(x => x.lockId);
    productLockers.filter(x => x.lockerAddressId == lockerAddressId)
        .map(x => {
        if(availables.findIndex(y => y == x.lockId) != -1) {
            availables = availables.filter(z => z != x.lockId)
        }
    });

    return availables;
}

export const productsLockersPlanoAvailable = createSelector(productRailsSelector,
    externalProductsSelector,
    (productRails, externalProducts) => _.values(externalProducts).filter(x => {
        if (x.enabled && !productRails.some(y => y.externalProductId == x.storeItemId))
            return x
    }));

export const reducer: Reducer<PlanogramState> = (state: PlanogramState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "PLANOGRAM_UPDATE_FAMILYID":
            return {
                ...state,
                familyId: action.payload.familyId,
                subFamilyId: undefined
            };
        case "PLANOGRAM_UPDATE_SUBFAMILYID":
            return {
                ...state,
                subFamilyId: action.payload.subFamilyId
            };
        case "PLANOGRAM_UPDATE_CREATE_SENS":
            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    sens: action.payload.value
                }
            };
        case "PLANOGRAM_UPDATE_CREATE_LOCKER_SENS":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    sens: action.payload.value
                }
            };    
        case "PLANOGRAM_UPDATE_CREATE_POSITION":
            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    position: {
                        row: action.payload.row,
                        col: action.payload.col
                    }
                }
            };
        case "PLANOGRAM_UPDATE_CREATE_POSITION_LOCKERPLANO":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    position: {
                        row: action.payload.row,
                        col: action.payload.col
                    }
                }
            };
        case "PLANOGRAM_UPDATE_CREATE_PRODUCTID":
            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    productId: action.payload.productId
                }
            };
        case "PLANOGRAM_UPDATE_PRODUCTRAILID":
            return {
                ...state,
                productRailId: action.payload.productRailId
            };
        case "PLANOGRAM_UPDATE_PRODUCTLOCKERID":
            return {
                ...state,
                productLockerId: action.payload.productLockerId
            };
         case "PLANOGRAM_UPDATE_PRODUCTLOCKER_CONTROLID":
            return {
                ...state,
                productLockerControlId: action.payload.productLockerControlId
            };
        case "PLANOGRAM_UPDATE_LOCKERPLANOID":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    lockerId: action.payload.lockerId
                }
            };
         case "PLANOGRAM_UPDATE_LOCKERTYPEPLANOID":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    lockerTypeId: action.payload.lockerTypeId
                }
            };
        case "PLANOGRAM_UPDATE_SHELVINGID":
            return {
                ...state,
                shelvingId: action.payload.shelvingId
            };
        case "PLANOGRAM_UPDATE_CREATING":
            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    isCreating: action.payload.value
                }
            };
        case "PLANOGRAM_UPDATE_CREATING_LOCKERSPLANO":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    isCreating: action.payload.value
                }
            };    
        case "RECEIVE_STOCKSTATUS":
            let actPln = _.sortBy(action.payload.stockStatus.planograms, x => !x.active)[0];

            return {
                ...state,
                selectedPlanogramId: action.error || !actPln
                    ? undefined
                    : actPln.planogramId,
                planogramId: action.error || !actPln
                    ? undefined
                    : actPln.planogramId,
                shelvingId: action.error || _.values(action.payload.stockStatus.shelvings).length === 0
                    ? undefined
                    : (state.shelvingId === undefined) ? _.values(action.payload.stockStatus.shelvings)[0].shelvingId : state.shelvingId
            };
        case "PLANOGRAM_REQUEST_CREATE_PRODUCTRAIL":
            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_PRODUCTRAIL":
            if (state.createProductRailState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createProductRailState: {
                    ...state.createProductRailState,
                    isLoading: false,
                    isCreating: false
                }
            };
        case "PLANOGRAM_REQUEST_DELETE_PLANOGRAM":
            return {
                ...state,
                deletePlanogram: {
                    ...state.deletePlanogram,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_PLANOGRAM":
            if (state.deletePlanogram.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                selectedPlanogramId: state.planogramId,
                deletePlanogram: {
                    ...state.deletePlanogram,
                    isLoading: false,
                }
            };
        case "PLANOGRAM_REQUEST_DELETE_PRODUCTRAIL":
            return {
                ...state,
                productRailStates: {
                    ...state.productRailStates,
                    [action.payload.productRailId]: {
                        ...state.productRailStates[action.payload.productRailId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "PLANOGRAM_REQUEST_DELETE_PRODUCTRAIL":
            return {
                ...state,
                productRailStates: {
                    ...state.productRailStates,
                    [action.payload.productRailId]: {
                        ...state.productRailStates[action.payload.productRailId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_PRODUCTRAIL":
            return {
                ...state,
                productRailStates: {
                    ...state.productRailStates,
                    [action.payload.productRailId]: {
                        ...state.productRailStates[action.payload.productRailId],
                        isLoading: !state.productRailStates[action.payload.productRailId]
                            || state.productRailStates[action.payload.productRailId].requestTime === action.payload.requestTime
                            ? false : (state.productRailStates[action.payload.productRailId]
                                ? state.productRailStates[action.payload.productRailId].isLoading : false),
                    }
                },
                productRailId: action.error
                    ? state.productRailId
                    : (state.productRailId === action.payload.productRailId ? undefined : state.productRailId)
            };
        case "PLANOGRAM_UPDATE_SELECT_PLANOGRAMID":
            return {
                ...state,
                selectedPlanogramId: action.payload.planogramId
            };
        case "PLANOGRAM_REQUEST_ACTIVATE_PLANOGRAM":
            return {
                ...state,
                activePlanogramState: {
                    ...state.activePlanogramState,
                    isLoading: true,
                    requestTime: action.payload.requestTime,
                },
                productRailId: undefined,
                createProductRailState: {
                    ...state.createProductRailState,
                    isCreating: false,
                    productId: undefined
                }
            };
        case "PLANOGRAM_RECEIVE_ACTIVATE_PLANOGRAM":
            if (state.activePlanogramState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                activePlanogramState: {
                    ...state.activePlanogramState,
                    isLoading: false,
                },
                planogramId: action.error
                    ? state.planogramId
                    : action.payload.planogramId
            };
        case "PLANOGRAM_UPDATE_NAME":
            return {
                ...state,
                createPlanogramState: {
                    ...state.createPlanogramState,
                    name: action.payload.value
                }
            };
        case "PLANOGRAM_REQUEST_CREATE_PLANOGRAM":
            return {
                ...state,
                createPlanogramState: {
                    ...state.createPlanogramState,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_PLANOGRAM":
            if (action.payload.requestTime !== state.createPlanogramState.requestTime)
                return state;

            return {
                ...state,
                createPlanogramState: {
                    ...state.createPlanogramState,
                    isLoading: false,
                    name: ""
                },
            };
        case "PLANOGRAM_REQUEST_EXPORT":
            return {
                ...state,
                exportPlanogramState: {
                    ...state.exportPlanogramState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_EXPORT":
            if (state.exportPlanogramState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                exportPlanogramState: {
                    ...state.exportPlanogramState,
                    isLoading: false,
                }
            };
        case "PLANOGRAM_REQUEST_IMPORT":
            return {
                ...state,
                importPlanogramState: {
                    ...state.importPlanogramState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_IMPORT":
            if (state.importPlanogramState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                importPlanogramState: {
                    ...state.importPlanogramState,
                    isLoading: false,
                }
            };
        case "PLANOGRAM_OPEN_ADD_TRAY":
            return {
                ...state,
                addTray: {
                    ...state.addTray,
                    isOpen: true
                }
            };
        case "PLANOGRAM_CLOSE_ADD_TRAY":
            return {
                ...state,
                addTray: {
                    ...state.addTray,
                    isOpen: false
                }
            };
        case "PLANOGRAM_SETTINGS_OPEN":
            return {
                ...state,
                planogramSettings: {
                    ...state.planogramSettings,
                    isOpen: true
                }
            };
        case "PLANOGRAM_SETTINGS_CLOSE":
            return {
                ...state,
                planogramSettings: {
                    ...state.planogramSettings,
                    isOpen: false
                }
            };
        case "PLANOGRAM_REQUEST_CREATE_SHELVINGTRAYPLACEMENT":
            return {
                ...state,
                addTray: {
                    ...state.addTray,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_SHELVINGTRAYPLACEMENT":
            if (state.addTray.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                addTray: {
                    ...state.addTray,
                    isLoading: false
                }
            };
        case "PLANOGRAM_REQUEST_DELETE_SHELVINGTRAYPLACEMENT":
            return {
                ...state,
                deleteTray: {
                    ...state.deleteTray,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_SHELVINGTRAYPLACEMENT":
            return {
                ...state,
                deleteTray: {
                    ...state.deleteTray,
                    isLoading: state.deleteTray.requestTime !== action.payload.requestTime
                        ? state.deleteTray.isLoading
                        : false
                }
            };
        case "PLANOGRAM_SETTINGS_UPDATE":
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload.value
                },
                planogramSettings: {
                    ...state.planogramSettings,
                    isLoading: false,
                    isOpen: false
                }
            };
        case "PLANOGRAM_REQUEST_CREATE_PRODUCTLOCKER":
            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_PRODUCTLOCKER":
            if (state.createProductLockerState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createProductLockerState: {
                    ...state.createProductLockerState,
                    isLoading: false,
                    isCreating: false
                }
            };
        case "PLANOGRAM_REQUEST_UPDATE_PRODUCTLOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        productLockerStateError: false
                    }
                }
            };
        case "PLANOGRAM_RECEIVE_UPDATE_PRODUCTLOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: false
                    }
                }
            };
        case "PLANOGRAM_REQUEST_DELETE_PRODUCTLOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        productLockerStateError: false
                    }
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_PRODUCTLOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: !state.productLockerStates[action.payload.productLockerId]
                            || state.productLockerStates[action.payload.productLockerId].requestTime === action.payload.requestTime
                            ? false : (state.productLockerStates[action.payload.productLockerId]
                                ? state.productLockerStates[action.payload.productLockerId].isLoading : false),
                    }
                },
                productLockerId: action.error
                    ? state.productLockerId
                    : (state.productLockerId === action.payload.productLockerId ? undefined : state.productLockerId)
            };
        case "PLANOGRAM_REQUEST_OPEN_LOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        productLockerStateError: false
                    }
                }
            };
        case "PLANOGRAM_RECEIVE_OPEN_LOCKER":
            return {
                ...state,
                productLockerStates: {
                    ...state.productLockerStates,
                    [action.payload.productLockerId]: {
                        ...state.productLockerStates[action.payload.productLockerId],
                        isLoading: false
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};