import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as StockStore from "../store/Stock";
import { productsLockersPlanoAvailable } from '../store/Planogram';
import * as Api from '../api/api';
import Table from "./Table";
import * as ReactTable from "react-table";
import DialogModal from './DialogModal';
import { UpdateProductLockerForm } from './ProductLockerForm';
import * as PlanogramStore from "../store/Planogram";
import * as ReceptionStore from "../store/Reception";
import _ from 'lodash';

const PLANO_HEIGHT = 24;

type LockerStockProps = LockerStockOwnProps
    & StockStore.StockState
    & typeof StockStore.actionCreators
    & typeof PlanogramStore.actionCreators
    & typeof ReceptionStore.actionCreators
    ;

type LockerStockColumn = ReactTable.Column
& {
    accessor: (d: Api.ProductLockerModel) => any;
    columns?: Array<LockerStockColumn>
}    

interface LockerStockOwnProps {
    externalProducts : { [id: number]: Api.ExternalProductModel};
    productLockerStates: any;
    unites: { [key: string]: Api.UnitModel; };
    receptionisLoading: boolean;
}

const headerColumns: React.CSSProperties = { overflow: 'visible' , whiteSpace: 'normal' }
const columnBGBlueStyle: React.CSSProperties = { backgroundColor: 'rgba(167, 212, 252, 0.4)' }
const columnBGYellowStyle: React.CSSProperties = { backgroundColor: 'rgba(245, 243, 146, 0.4)' }

class LockerStock extends React.Component<LockerStockProps, {}> {
    stockRef = {};
    auxIndexRef = undefined;

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: Readonly<LockerStockProps>): void {
        if(this.props.stockStatus != prevProps.stockStatus && this.auxIndexRef != undefined
            && this.stockRef[this.auxIndexRef] != null){
            this.stockRef[this.auxIndexRef].focus();
        }
    }

    getProductName(externalProductId) {
        return this.props.externalProducts[externalProductId]?.name ?? "";
    }

    getProductVolumeUnit(externalProductId) {        
        return externalProductId === 0 ? null : `${this.props.unites[this.props.externalProducts[externalProductId]?.volumeUnitId]?.name} (${this.props.externalProducts[externalProductId]?.conversionFactor})`
    }

    getProductPracticUnit(externalProductId) {        
        return externalProductId === 0 ? null : this.props.unites[this.props.externalProducts[externalProductId].practicUnit].name
    }

    getProductBackStock(externalProductId) {
        return _.sum(this.props.stockStatus.productReceptions
            .filter(y => y.externalProductId == externalProductId)
            .map(x => x.quantityRemaining));
    }

    getStoredLockerStockArr(productLockerId: number): number | undefined {
        return this.props.storeLockerStockState[productLockerId]
            || this.props.storeLockerStockState[productLockerId] === 0
            ? this.props.storeLockerStockState[productLockerId] 
            : undefined
    }

    getProductStockUp(externalProductId) {
        if(externalProductId === 0) {
            return null;
        }
        let stock = this.props.stockStatus.productLockers.filter(x => x.externalProductId === externalProductId).length;
        return (stock * this.props.externalProducts[externalProductId]?.conversionFactor);
    }

    getProductBackStockUp(externalProductId) {
        if(externalProductId === 0) {
            return null;
        }
        let backStock = this.getProductBackStock(externalProductId);
        return (backStock * this.props.externalProducts[externalProductId]?.conversionFactor);
    }

    public render() {
        return (
            <div>
                <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                    <span style={{paddingLeft: 30}}>
                        {getText( "StockTitle")}
                    </span>
                </div>
                <div className="card" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
                    <Table tableKey={"lockerstock"}
                            className="stock-table"
                            loading={this.props.isLoading}
                            style={{ height: '80vh' }}
                            getTheadFilterThProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        borderRight: column.filterable && (this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)')
                                    }
                                };
                            }}
                            columns={[
                                {
                                    id:"id",
                                    columns: [
                                        {
                                            id: "lockerid",
                                            accessor: (x) => this.props.stockStatus?.lockers[x.lockerId].name ,
                                            Header: <div>{getText("SettingsLocker")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 120,
                                            filterable: false,
                                        },   
                                        {
                                            id: "position",
                                            accessor: (x) => `(${x.startCol}, ${PLANO_HEIGHT - x.startRow + 1})`,
                                            Header: <div style={headerColumns}>{getText("StockLockerPosition")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            width: 135,
                                            filterable: false,
                                        },      
                                        {
                                            id: "lockerAddressId",
                                            accessor: (x) => this.props.stockStatus?.lockerAddresses[x.lockerAddressId]?.name ?? "",
                                            Header: <div>{getText( "PlanogramLockerIP")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 100,
                                            filterable: false,
                                        },                      
                                        {
                                            id: "lockId",
                                            accessor: (x) => this.props.stockStatus?.locks[x.lockId]?.lockNumber ?? "",
                                            Header: <div>{getText( "PlanogramLockerLockNum")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 100,
                                            filterable: false,
                                        }, 
                                        {
                                            id: "externalProductId",
                                            accessor: (x) => this.getProductName(x.externalProductId),
                                            Header: <div>{getText( "PlanogramProduct")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            filterable: false,
                                        },
                                        {
                                            id: "volumeUnit",
                                            accessor:  (x) => this.getProductVolumeUnit(x.externalProductId),
                                            Header: <div style={headerColumns}>{getText("StockVolUnit")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            Cell: row => { return (
                                                <div style={{...columnBGYellowStyle, padding: 7}}>
                                                    {row.value !== null ? row.value : "-"}
                                                </div>
                                            )},
                                            maxWidth: 100,
                                            filterable: false,
                                        },
                                        {
                                            id: "practicUnit",
                                            accessor: (x) => this.getProductPracticUnit(x.externalProductId),
                                            Header: <div style={headerColumns}>{getText("StockPracticUnit")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            Cell: row => { return ( <div style={{...columnBGBlueStyle, padding: 7}}>
                                                    {row.value !== null ? row.value : "-"}
                                                </div> 
                                            )},
                                            maxWidth: 100,
                                            filterable: false
                                        },
                                        {
                                            id: "productReceptions",
                                            Header: <div style={headerColumns}>{getText( "StockBackRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 90,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (d) => d,
                                            Cell: row => {
                                                let element = row.value;
                                                if(element.externalProductId == 0) {
                                                    return (<div style={{...columnBGYellowStyle, padding: 7}}> {"-"} </div>)
                                                }

                                                let newStoredStock = this.getStoredLockerStockArr(element.productLockerId);
                                                let backStock = newStoredStock === undefined ? this.getProductBackStock(element.externalProductId) : newStoredStock;
                                                return (
                                                    //for stock porpuse the dictionary will be control by productLockerId as Key.
                                                    
                                                    <div style={{...columnBGYellowStyle, height: 35}}>
                                                        <input
                                                            ref={(r) => this.stockRef[row.index] = r}
                                                            tabIndex={0}
                                                            className="form-control"
                                                            style={{ width: 70, textAlign: 'center' }}
                                                            type={"number"}
                                                            min="0"
                                                            disabled={this.props.receptionisLoading}
                                                            value={backStock}
                                                            onChange={(e) => this.props.productLockerStockUpdateStoredStock(element.productLockerId, parseInt(e.target.value) || 0)}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    let value = parseInt(e.currentTarget.value);
                                                                    if ((value || value === 0) && value >= 0) {
                                                                        this.props.requestUpdateQuantityRemaining(new Date().getTime(), {
                                                                            productId: element.externalProductId,
                                                                            quantity: value
                                                                        });
                                                                    }
                                                                }
                                                                if(e.keyCode === 40 && this.stockRef[row.index+1]){
                                                                    this.stockRef[row.index+1].focus();
                                                                    this.auxIndexRef = row.index+1;
                                                                }
                                                                if(e.keyCode === 38 && this.stockRef[row.index-1]){
                                                                    this.stockRef[row.index-1].focus();
                                                                    this.auxIndexRef = row.index-1;
                                                                }
                                                            }}
                                                            onKeyDown={(e => {
                                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                                    e.preventDefault();
                                                                }
                                                            })}
                                                            onClick={() => this.auxIndexRef = row.index}
                                                        />
                                                    </div>
                                                )
                                            },
                                           
                                        },
                                        {
                                            id: "productManualToTrash",
                                            Header: <div style={headerColumns} >{getText( "StockMarkdownRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            width: 120,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (d) => d,
                                            Cell: row => {
                                                let element = row.value;
                                                if(element.externalProductId == 0) {
                                                    return (<div style={{...columnBGYellowStyle, padding: 7}}> {"-"} </div>)
                                                }
                                                
                                                return (
                                                    <div style={{...columnBGYellowStyle, height: 35}}>
                                                        <input
                                                            tabIndex={-1}
                                                            className="form-control"
                                                            style={{ width: 100, textAlign: 'center' }}
                                                            type={"number"}
                                                            min={0}
                                                            disabled={this.props.receptionisLoading}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    let stockArr = this.getProductBackStock(element.externalProductId);
                                                                    let value = parseInt(e.currentTarget.value);
                                                                    if ((value || value === 0) && value <= stockArr) {
                                                                        this.props.requestDeleteQuantityRemaining(new Date().getTime(), {
                                                                            productId: element.externalProductId,
                                                                            quantity: value
                                                                        });

                                                                    }
                                                                    e.currentTarget.value = "";
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                )
                                            },
                                        },
                                        {
                                            id: "stockMaxUp",
                                            Header: <div style={headerColumns}>{getText( "StockNumberUpRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 85,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),  
                                            accessor: (x) => this.getProductStockUp(x.externalProductId),
                                            Cell: row => {
                                                return <div style={{...columnBGBlueStyle, padding: 7}}>{row.value !== null ? row.value : "-"} </div>
                                            }
                                        },
                                        {
                                            id: "productReceptionsUp",
                                            Header: <div style={headerColumns}>{getText( "StockBackUpRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 75,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (x) => this.getProductBackStockUp(x.externalProductId),
                                            Cell: row => {
                                                return <div style={{...columnBGBlueStyle, padding: 7}}>{row.value !== null ? row.value : "-"} </div>
                                            }
                                        },
                                        {
                                            id: "actions",
                                            accessor: (d) => d,
                                            filterable: false,
                                            Header: <div>{getText( "StockActions")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 80,
                                            Cell: row => <div style={{ 
                                                width: 20,
                                                height: 20,
                                                placeSelf: "center", 
                                                backgroundColor: "#007AFF",
                                                cursor: "pointer",
                                                marginTop: '2%',
                                                borderRadius: 5, }}
                                                onClick={(e) => {
                                                    this.auxIndexRef = row.index;
                                                    this.props.updateProductLockerId(row.value.productLockerId);
                                                    this.props.openLockerStockModal(row.value.productLockerId);
                                                }}                                        
                                                className="icon-container-dm">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path 
                                                        d="M 11 2 L 11 8 L 5 8 L 5 10 L 11 10 L 11 16 L 13 16 L 13 10 L 19 10 L 19 8 L 13 8 L 13 2 L 11 2 z M 5 18 L 5 20 L 19 20 L 19 18 L 5 18 z"
                                                        fill={ this.isDarkMode() ? "black" : "#FFFFFF" } 
                                                    />
                                                </svg>
                                            </div>
                                        }
                                    ]
                                }
                            ] as Array<LockerStockColumn>}
                            pageSize={Math.max(this.props.stockStatus?.productLockers.length || 0, 20)}
                            showPageSizeOptions={false}
                            showPagination={false}
                            showPaginationBottom={false}
                            data={this.props.stockStatus ? this.props.stockStatus.productLockers : []}>
                        </Table>
                    </div>
                    <DialogModal contentLabel={"Locker stock dialog"}
                                contentStyle={{ 
                                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                                    border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                                    width: "50%", 
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                                title={getText( "NavMenuLockerStock")}
                                isOpen={this.props.LockerStockDialogOpen.isOpen}
                                onRequestClose={() => this.props.closeLockerStockModal()}>
                                <div>
                        {this.props.LockerStockDialogOpen.productLockerId != undefined && <div className="card" style={{
                            ...Styles.cardStyle,
                            padding: 13,
                            marginBottom: 10,
                            height: "100%"
                        }}> 
                            <UpdateProductLockerForm 
                                onSubmit={(values) => this.props.requestUpdateProductLocker(new Date().getTime(), values) as any}/>
                            { this.props.stockStatus.productLockers.find(p => p.productLockerId == this.props.LockerStockDialogOpen.productLockerId)?.externalProductId != 0 &&
                                <div style={{ width: "30%", position:"relative", top: "-70px" }}>
                                    <button className="btn btn-primary"
                                        style= {{ width: "90%", height: 32, marginRight: "5px"}}
                                        disabled={!this.props.LockerStockDialogOpen.productLockerId
                                            || (this.props.productLockerStates[this.props.LockerStockDialogOpen.productLockerId]
                                                && this.props.productLockerStates[this.props.LockerStockDialogOpen.productLockerId].isLoading)}
                                        onClick={(e) => {
                                            let model = {
                                                ...this.props.stockStatus.productLockers.find(p => p.productLockerId == this.props.LockerStockDialogOpen.productLockerId),
                                                externalProductId: undefined
                                            }
                                            this.props.requestUpdateProductLocker(new Date().getTime(), model)
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                        {getText( "PlanogramLockerDeleteProd")}
                                    </button>
                                </div>
                            }
                        </div>}
                        </div>
                    </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.stock,
    productLockerStates: state.planogram.productLockerStates,
    unites: state.seed.seed.units,
    receptionisLoading: state.reception.updateState.isLoading,
    externalProducts: state.seed.seed.externalProducts
} as LockerStockOwnProps),{
    ...StockStore.actionCreators,
    ...ReceptionStore.actionCreators,
    ...PlanogramStore.actionCreators,
} )(LockerStock as any) as any as React.ComponentClass<{}>