import * as Api from '../api/api';
import * as CardStore from '../store/Card';
import * as CashStore from '../store/Cash';
import * as PrintStore from '../store/Print';
import * as RobotStore from '../store/Robot';
import * as TempStore from '../store/Temp';
import * as HomeStore from '../store/Home';
import * as React from 'react';
import Tooltip from "./Tooltip";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import Temperature from './Temperature';
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';
import * as ContactStore from '../store/Contact';
import * as _ from 'lodash';
import * as NeoEventStore from '../store/NeoEvent';
import * as HmiApplicationStore from "../store/HmiApplication";
import { getText } from '../utils/texts';

type PeripheralsProps = PeripheralsOwnProps
    & NeoEventAlarmStore.NeoEventAlarmState
    & NeoEventStore.NeoEventState
    & TempStore.TempState
    & typeof CardStore.actionCreators
    & typeof CashStore.actionCreators
    & typeof PrintStore.actionCreators
    & typeof RobotStore.actionCreators
    & typeof TempStore.actionCreators
    & typeof HomeStore.actionCreators
    & typeof NeoEventAlarmStore.actionCreators
    & typeof ContactStore.actionCreators
    & typeof NeoEventStore.actionCreators
    & typeof HmiApplicationStore.actionCreators
    & typeof TempStore.actionCreators
    ;

interface PeripheralsOwnProps {
    cash: CashStore.CashState;
    card: CardStore.CardState;
    robot: RobotStore.RobotState;
    print: PrintStore.PrintState;
    temp: TempStore.TempState;
    events: Array<Api.NeoEventModel>;
    eventAlarms: Array<Api.NeoEventModel>;
    smartPhoneStatus: Api.SmartPhoneStatusModel;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };    
    deliveryStation: Api.DeliveryStationModel;
    storeType: string;
    lastPayments: Api.LastPaymentsModel;
    hmis: Array<Api.HmiModel>;
}

class Peripherals extends React.Component<PeripheralsProps, {}> {
    interval = null;

    componentDidMount() {
        this.requestData();
        this.interval = setInterval(() => {
            this.requestData();
        }, 1000 * 120);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    requestData(): void {
        this.props.requestCardState(new Date().getTime());
        this.props.requestCashState(new Date().getTime());
        this.props.requestPrintState(new Date().getTime());
        this.props.requestRobotState(new Date().getTime());
        this.props.requestTempTemperature(new Date().getTime());
        this.props.requestTempState(new Date().getTime());
        this.props.requestSmartPhoneStatus(new Date().getTime());
        this.props.requestContacts(new Date().getTime());
        this.props.requestLastPayments(new Date().getTime());
        (this.props.requestNeoEventAlarms(new Date().getTime()) as any as Promise<any>)
            .then(() => this.props.requestAlarmEvents(new Date().getTime()));
        this.props.hmis.forEach(hmi => {
            this.props.requestRequestHmiState(new Date().getTime(), hmi.hmiId);
            this.props.checkAviablePaymentMethods(hmi.hmiId);
        });
    }

    get robotStatus(): Status {
        if (!this.props.robot.state)
            return "Off";

        if (this.props.robot.state.status === "Running") {
            return "Ok";
        }
        if (this.props.robot.state.status == "Ready")
            return "Ok";

        return "Ko";
    }

    get cashCoinStatus(): Status {
        if (!this.props.cash.state)
            return "Off";

        if (this.props.cash.state.status === "Ok") {
            if (this.props.cash.state.coinAcceptorStatus !== "Ok"
                || !this.props.cash.state.coinAcceptorEnabled) {
                return "Ko";
            }

            if (this.props.cash.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.cash.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }


    get cashBillStatus(): Status {
        if (!this.props.cash.state)
            return "Off";

        if (this.props.cash.state.status === "Ok") {
            if (this.props.cash.state.billAcceptorStatus !== "Ok"
                || !this.props.cash.state.billAcceptorEnabled) {
                return "Ko";
            }

            if (this.props.cash.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.cash.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }

    get cardStatus(): Status {
        if (!this.props.card.state)
            return "Off";

        if (this.props.card.state.status === "Ok") {
            if (this.props.card.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.card.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }

    get noContactStatus(): Status {
        if (!this.props.card.state)
            return "Off";

        if (this.props.card.state.noContactStatus === "Ok") {
            if (this.props.card.state.state === "Idle") {
                return "Ok";
            }
            return "Busy";
        }
        if (this.props.card.state.noContactStatus === "Deactivated")
            return "Deactivated";

        return "Ko";
    }

    get printStatus(): Status {
        if (!this.props.print.state)
            return "Off";

        if (this.props.print.state.status === "Ok"
            && this.props.print.state.paperStatus !== "NoPaper") {
            return "Ok";
        }
        if (this.props.print.state.status === "Deactivated")
            return "Deactivated";
        
        if (this.props.print.state.status === "Unavailable")
            return "Ko";

        return "Ko";
    }

    get tempStatus(): Status {
        if (!this.props.temp.state)
            return "Off";

        if (this.props.temp.state.status === "Ok") {
            return "Ok";
        }

        return "Ko";
    }


    get smartPhoneStatus(): Status {
        if (!this.props.smartPhoneStatus)
            return "Off";

        if (this.props.smartPhoneStatus.status === "Ok") {
            return "Ok";
        }

        return "Ko";
    }

    get neoEventsOrdered() {
        return _.sortBy(this.props.events, x => -x.neoEventId);
    }

    statusColor(status: string): string {
        if (status === "Busy")
            return "orange";

        if (status === "Off")
            return "red";

        if (status === "Ko")
            return "red";

        if (status === "Deactivated")
            return "yellow";

        return "green";
    }

    get eventsStatusColor(): string {
        if(this.props.eventAlarms){
            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError")) {
                return 'red'
            }
            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error")) {                
                return 'orange';
            }
        }

        return 'green';
    }

    get cardStatusColor(): string {
        if (!this.props.card.state || this.props.card.state.status === "Deactivated" 
            || this.cardStatus === "Ko")
            return 'red'

        if(this.neoEventsOrdered){
            if(this.props.lastPayments && (this.props.lastPayments.cardPayment !== null || this.props.lastPayments.cardNoContactPayment !== null)){
                if((this.props.lastPayments.cardPayment !== null && this.props.lastPayments.cardNoContactPayment == null) ||
                    (this.props.lastPayments.cardPayment !== null && 
                        this.props.lastPayments.cardPayment.creationDate > this.props.lastPayments.cardNoContactPayment.creationDate)){
                    if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 601)  &&
                        this.props.lastPayments.cardPayment.solvencyValidationDate == null) {
                        return'orange'
                    }
                    if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 606)  &&
                        this.props.lastPayments.cardPayment.solvencyValidationDate == null) {
                        return'orange'
                    }
                    if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 603)  &&
                        this.props.lastPayments.cardPayment.collectiondDate == null) {
                        return 'red'
                    }
                }
                else { //cardNoContactPayment
                    if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 606)  &&
                        this.props.lastPayments.cardNoContactPayment.solvencyValidationDate == null) {
                        return'orange'
                    }
                    if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 607)  &&
                        this.props.lastPayments.cardNoContactPayment.collectiondDate == null) {
                        return 'red'
                    }
                }
            }

            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" && 
                this.props.neoEventCodes[x.neoEventCodeId].source === "Card")) {
                return 'orange'
            }
        }
        return 'green'
    }

    get cashBillStatusColor(): string {
        if (!this.props.cash.state || this.props.cash.state.status === "Deactivated"
            || this.cashBillStatus === "Ko")
            return 'red'

        if(this.neoEventsOrdered){
            if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502) &&
                (this.props.lastPayments && this.props.lastPayments.cashPayment !== null &&
                    this.props.lastPayments.cashPayment.status == "Failed")) {                
                return  'red'
            }
            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" && 
            this.props.neoEventCodes[x.neoEventCodeId].source === "Cash")) {
            return 'orange'
            }
            if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501) &&
                (this.props.lastPayments && this.props.lastPayments.cashPayment !== null &&
                    this.props.lastPayments.cashPayment.solvencyValidationDate == null)) {                
                return 'orange'
            }         
        }

        return 'green'
    }

    get cashCoinStatusColor(): string {
        if (!this.props.cash.state || this.props.cash.state.status === "Deactivated"
            || this.cashCoinStatus === "Ko")
            return 'red'

        if(this.neoEventsOrdered){
            if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502) &&
                (this.props.lastPayments && this.props.lastPayments.cashPayment !== null &&
                    this.props.lastPayments.cashPayment.status == "Failed")) {                
                return  'red'
            }
            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" && 
            this.props.neoEventCodes[x.neoEventCodeId].source === "Cash")) {
            return 'orange'
            }
            if(this.neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501) &&
                (this.props.lastPayments && this.props.lastPayments.cashPayment !== null && 
                    this.props.lastPayments.cashPayment.solvencyValidationDate == null)) {                
                return 'orange'
            }         
        }

        return 'green'
    }

    get printStatusColor(): string {
        if (!this.props.print.state)
            return 'gray' ;

        if(this.props.eventAlarms){
            if(this.props.eventAlarms.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" && 
                this.props.neoEventCodes[x.neoEventCodeId].source === "Printer")) {
                return 'red'
            }               
        }

        if (this.props.print.state.status === "Deactivated")
            return "gray";
        
        if (this.props.print.state.status === "Unavailable")
            return "gray";

        if (this.props.print.state.status === "Ok")
            return "green";

        return 'gray'
    }

    get robotStatusColor(): string {
        if (!this.props.robot.state)
            return  'red' 

        if (this.props.robot.state.status === "Running") {
            return 'green' 
        }
        if (this.props.robot.state.status == "Ready")
            return 'green' 

        return  'red'
    }

    get smartPhoneStatusColor(): string {
        if (!this.props.smartPhoneStatus || this.props.smartPhoneStatus.status === "Ko")
            return  'red'

        if (this.props.smartPhoneStatus.status === "Ok") {
            return 'green'
        }

        return 'orange'
    }

    get isTempWarning() {
        return this.props.deliveryStation
            && this.props.temperatureState.value
            && this.props.deliveryStation.warnTemp < this.props.temperatureState.value;
    }

    get isTempAlert() {
        return this.props.deliveryStation
            && this.props.temperatureState.value
            && this.props.deliveryStation.alertTemp < this.props.temperatureState.value;
    }

    get tempStatusColor(): string {
        if (!this.props.temp.state)
            return  'red'
        if (this.props.temp.state.status === "Ok" && this.props.temperatureState.value) {            
            if (this.isTempAlert && this.props.temperatureState)
                return 'red';
            if (this.isTempWarning && this.props.temperatureState)
                return 'orange' 

            return 'green'
        }        

        return 'orange'
    }

    public render() {
        return (<div style={{
            display: "grid",
            gridTemplateRows: "1fr",
            alignItems: "flex-end"
        }}>
            <div style={{ gridRow: "1 / 2", gridColumn: "1 / 2", padding: '0 20%' }}>
                <PeripheralStatus status={this.robotStatus} version={this.props.robot.state && this.props.robot.state.version}>
                    <svg width="25" height="25" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.05948 0C3.05894 0 3.05833 0 3.0578 0C2.86863 0 2.6907 0.0772115 2.55635 0.217532C2.42052 0.359404 2.3457 0.548308 2.3457 0.74941V11.9867C2.3457 12.3988 2.66713 12.7348 3.06231 12.7359C4.72826 12.74 7.51938 13.1036 9.44487 15.2134V3.45329C9.44487 3.31361 9.4108 3.18238 9.34649 3.07379C7.76613 0.408974 4.7292 0.00408974 3.05948 0Z"
                            fill={this.eventsStatusColor}/>
                        <path d="M17.6557 11.9868V0.74941C17.6557 0.548308 17.5809 0.359404 17.4451 0.217532C17.3107 0.0772115 17.1327 0 16.9437 0C16.9431 0 16.9425 0 16.942 0C15.2723 0.00416026 12.2354 0.409045 10.655 3.07387C10.5906 3.18245 10.5566 3.31368 10.5566 3.45337V15.2134C12.4821 13.1036 15.2733 12.74 16.9392 12.7359C17.3343 12.7348 17.6557 12.3988 17.6557 11.9868Z"
                            fill={this.eventsStatusColor}/>
                        <path d="M19.286 2.59229H18.7671V11.9875C18.7671 13.0396 17.9484 13.8975 16.942 13.9001C15.5289 13.9037 13.199 14.193 11.5488 15.8282C14.4027 15.0966 17.4112 15.5722 19.1258 15.9813C19.3399 16.0324 19.5611 15.9796 19.7326 15.8365C19.9036 15.6937 20.0016 15.4803 20.0016 15.2512V3.34155C20.0017 2.92842 19.6806 2.59229 19.286 2.59229Z"
                            fill={this.eventsStatusColor}/>
                        <path d="M1.23454 11.9875V2.59229H0.715594C0.321095 2.59229 0 2.92842 0 3.34155V15.251C0 15.4801 0.0980524 15.6935 0.268971 15.8363C0.44036 15.9794 0.661382 16.0323 0.875805 15.9811C2.59038 15.5719 5.59896 15.0964 8.45271 15.828C6.80266 14.1928 4.4727 13.9036 3.05963 13.9001C2.05331 13.8975 1.23454 13.0396 1.23454 11.9875Z"
                            fill={this.eventsStatusColor}/>
                    </svg>
                </PeripheralStatus>
            </div>
            <div style={{ gridRow: "1 / 2", gridColumn: "2 / 3", padding: '0 20%' }}>
                <PeripheralStatus status={this.cardStatus} version={this.props.card.state && this.props.card.state.version}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.5 10.3762V9.03395C27.5 7.49641 26.2758 6.25 24.7656 6.25H5.23438C3.72422 6.25 2.5 7.49641 2.5 9.03395V10.3762C2.5 10.5135 2.60933 10.6248 2.74414 10.6248H27.2559C27.3907 10.6248 27.5 10.5135 27.5 10.3762Z" 
                            fill={this.cardStatusColor}/>
                        <path d="M2.5 12.4649V20.9659C2.5 22.5034 3.72422 23.7498 5.23438 23.7498H24.7656C26.2758 23.7498 27.5 22.5034 27.5 20.9659V12.4649C27.5 12.3276 27.3907 12.2163 27.2559 12.2163H2.74414C2.60933 12.2163 2.5 12.3276 2.5 12.4649ZM8.75 18.9773C8.75 19.4166 8.4002 19.7727 7.96875 19.7727H7.1875C6.75605 19.7727 6.40625 19.4166 6.40625 18.9773V18.1819C6.40625 17.7426 6.75605 17.3865 7.1875 17.3865H7.96875C8.4002 17.3865 8.75 17.7426 8.75 18.1819V18.9773Z"
                            fill={this.cardStatusColor}/>
                    </svg>
                </PeripheralStatus>
            </div>  
            {this.props.storeType == "Ximiti" && <>          
                <div style={{ gridRow: "1 / 2", gridColumn: "3 / 4", padding: '0 20%' }}>
                    <PeripheralStatus status={this.cashCoinStatus} version={this.props.cash.state && this.props.cash.state.version}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 3.75C8.78704 3.75 3.75 8.78704 3.75 15C3.75 21.213 8.78704 26.25 15 26.25C21.213 26.25 26.25 21.213 26.25 15C26.25 8.78704 21.213 3.75 15 3.75ZM18.9259 20.6991C18.588 20.8519 18.2361 20.9815 17.875 21.0787C16.5046 21.4583 15.1343 21.4722 13.7685 21.0278C12.1204 20.4954 11.0278 19.3704 10.375 17.7963C10.25 17.4907 10.1667 17.1713 10.0694 16.8519H8.77778C8.56018 16.8519 8.38889 16.6759 8.38889 16.463V15.5926C8.38889 15.375 8.56481 15.2037 8.77778 15.2037H9.84722C9.84722 14.963 9.84722 14.7361 9.84722 14.4954H8.77778C8.56018 14.4954 8.38889 14.3194 8.38889 14.1065V13.2361C8.38889 13.0185 8.56481 12.8472 8.77778 12.8472H10.1389L10.1852 12.713C10.5509 11.5509 11.1806 10.5556 12.1481 9.80556C12.9167 9.20833 13.8056 8.87963 14.7593 8.72685C16.1157 8.50926 17.4306 8.69444 18.7037 9.19444C18.787 9.22685 18.8657 9.26389 18.9444 9.31018C19.25 9.49074 19.3287 9.71759 19.1944 10.0463C19.0509 10.3889 18.9074 10.7361 18.75 11.0741C18.6157 11.3657 18.4306 11.4583 18.1157 11.3889C17.6852 11.2917 17.2639 11.1574 16.8333 11.0694C15.9259 10.8843 15.0231 10.875 14.1389 11.2222C13.3796 11.5231 12.9167 12.1019 12.6019 12.8241C12.5972 12.8333 12.5972 12.8426 12.5972 12.8519H15.2407C15.4583 12.8519 15.6296 13.0278 15.6296 13.2407V14.1111C15.6296 14.3287 15.4537 14.5 15.2407 14.5H12.2222C12.2176 14.7315 12.2222 14.963 12.2222 15.2083H15.2454C15.463 15.2083 15.6343 15.3843 15.6343 15.5972V16.4676C15.6343 16.6852 15.4583 16.8565 15.2454 16.8565H12.4861C12.8194 17.8102 13.3611 18.5463 14.3796 18.8657C15.1944 19.1204 16.0139 19.1065 16.838 18.9352C17.2639 18.8472 17.6806 18.7176 18.1065 18.6204C18.4398 18.5463 18.6204 18.6343 18.7593 18.9398C18.9167 19.2824 19.0648 19.6296 19.2037 19.9815C19.3333 20.2963 19.25 20.5509 18.9259 20.6991Z"
                                fill={this.cashCoinStatusColor}/>
                        </svg>
                    </PeripheralStatus>
                </div>
                <div style={{ gridRow: "1 / 2", gridColumn: "4 / 5", padding: '0 20%' }}>
                    <PeripheralStatus status={this.cashBillStatus} version={this.props.cash.state && this.props.cash.state.version}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.2681 14.3025C18.0177 12.9047 16.961 11.7776 15.6506 11.5105C13.3064 11.0327 11.2789 13.1954 11.7264 15.6963C11.9769 17.0941 13.0335 18.2216 14.3439 18.4883C16.6881 18.966 18.7156 16.8034 18.2681 14.3025ZM14.1052 14.5549H14.5806C14.811 14.5549 14.9973 14.754 14.9973 14.9994C14.9973 15.2447 14.811 15.4438 14.5806 15.4438H14.1052C13.9543 15.4438 13.8485 15.6118 13.9131 15.7576C14.1793 16.359 14.7497 16.7772 15.4139 16.7772C15.6443 16.7772 15.8306 16.9763 15.8306 17.2216C15.8306 17.4669 15.6443 17.666 15.4139 17.666C14.1777 17.666 13.151 16.7029 12.9514 15.4438H12.9139C12.6835 15.4438 12.4972 15.2447 12.4972 14.9994C12.4972 14.754 12.6835 14.5549 12.9139 14.5549H12.9514C13.1506 13.2958 14.1772 12.3327 15.4139 12.3327C15.6443 12.3327 15.8306 12.5318 15.8306 12.7771C15.8306 13.0224 15.6443 13.2215 15.4139 13.2215C14.7497 13.2215 14.1793 13.6398 13.9131 14.2411C13.8485 14.3869 13.9543 14.5549 14.1052 14.5549Z"
                                fill={this.cashBillStatusColor}/>
                            <path d="M24.8291 12.2749C23.6579 12.0381 22.7786 11.1003 22.5566 9.85093C22.5378 9.74515 22.4553 9.6665 22.3545 9.6665H7.64947C7.54864 9.6665 7.46612 9.74515 7.44737 9.85093C7.2253 11.1003 6.3461 12.038 5.17485 12.2749C5.07568 12.2949 5.00195 12.383 5.00195 12.4905V17.5088C5.00195 17.6163 5.07568 17.7043 5.17485 17.7243C6.3461 17.9612 7.2253 18.899 7.44737 20.1484C7.46612 20.2546 7.54864 20.3333 7.64947 20.3333H22.3541C22.4549 20.3333 22.5374 20.2546 22.5562 20.1488C22.7783 18.8995 23.6575 17.9617 24.8287 17.7248C24.9283 17.7048 25.002 17.6168 25.002 17.5092V12.4906C25.002 12.383 24.9283 12.2949 24.8291 12.2749ZM19.1103 15.7527C18.8253 17.5927 17.4345 19.0772 15.7095 19.3816C12.8624 19.8843 10.4233 17.2839 10.8937 14.2465C11.1787 12.4065 12.5695 10.9225 14.2945 10.6176C17.1416 10.1149 19.5807 12.7154 19.1103 15.7527Z"
                                fill={this.cashBillStatusColor}/>
                            <path d="M27.0834 7H2.91665C2.68623 7 2.5 7.19912 2.5 7.44443V22.5556C2.5 22.8009 2.68623 23 2.91665 23H27.0833C27.3138 23 27.5 22.8009 27.5 22.5556V7.44443C27.5 7.19912 27.3138 7 27.0834 7ZM25.8334 18.1111C25.8334 18.3564 25.6472 18.5556 25.4167 18.5556C24.2097 18.5556 23.3334 19.4902 23.3334 20.7778C23.3334 21.0231 23.1472 21.2222 22.9167 21.2222H7.08336C6.85294 21.2222 6.66671 21.0231 6.66671 20.7778C6.66671 19.4902 5.79044 18.5556 4.58335 18.5556C4.35293 18.5556 4.1667 18.3564 4.1667 18.1111V11.8889C4.1667 11.6436 4.35293 11.4445 4.58335 11.4445C5.79044 11.4445 6.66671 10.5098 6.66671 9.22225C6.66671 8.97693 6.85294 8.77782 7.08336 8.77782H22.9167C23.1472 8.77782 23.3334 8.97693 23.3334 9.22225C23.3334 10.5098 24.2097 11.4445 25.4167 11.4445C25.6472 11.4445 25.8334 11.6436 25.8334 11.8889V18.1111Z"
                                fill={this.cashBillStatusColor}/>
                        </svg>
                    </PeripheralStatus>
                </div>
                <div style={{ gridRow: "1 / 2", gridColumn: "5 / 6", padding: '0 20%' }}>
                    <PeripheralStatus status={this.printStatus} version={this.props.print.state && this.props.print.state.version}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.6103 21.8013H11.388C10.9278 21.8013 10.5547 22.1625 10.5547 22.6082C10.5547 23.0539 10.9278 23.4152 11.388 23.4152H18.6103C19.0705 23.4152 19.4436 23.0539 19.4436 22.6082C19.4436 22.1625 19.0705 21.8013 18.6103 21.8013Z"
                                fill={this.printStatusColor}/>
                            <path d="M18.6103 19.2549H11.388C10.9278 19.2549 10.5547 19.6162 10.5547 20.0618C10.5547 20.5075 10.9278 20.8688 11.388 20.8688H18.6103C19.0705 20.8688 19.4436 20.5075 19.4436 20.0618C19.4436 19.6162 19.0705 19.2549 18.6103 19.2549Z"
                                fill={this.printStatusColor}/>
                            <path d="M25.5556 9.22722H22.9041V4.55696C22.9041 4.11133 22.531 3.75 22.0707 3.75H7.9293C7.46909 3.75 7.09595 4.11128 7.09595 4.55696V9.22722H4.44443C3.37227 9.22722 2.5 10.0719 2.5 11.1101V19.2285C2.5 20.2667 3.37227 21.1114 4.44443 21.1114H7.09609V25.443C7.09609 25.8887 7.46919 26.25 7.92944 26.25H22.0706C22.5308 26.25 22.9039 25.8887 22.9039 25.443V21.1114H25.5556C26.6277 21.1114 27.5 20.2667 27.5 19.2285V11.1101C27.5 10.072 26.6277 9.22722 25.5556 9.22722ZM8.7626 5.36392H21.2374V9.22722H8.7626V5.36392ZM21.2372 24.6361H8.76279C8.76279 24.4784 8.76279 18.2293 8.76279 18.0342H21.2373C21.2372 18.2342 21.2372 24.4844 21.2372 24.6361ZM22.0707 13.9656H19.9495C19.4893 13.9656 19.1161 13.6043 19.1161 13.1586C19.1161 12.7129 19.4892 12.3517 19.9495 12.3517H22.0707C22.5309 12.3517 22.9041 12.7129 22.9041 13.1586C22.9041 13.6043 22.531 13.9656 22.0707 13.9656Z"
                                fill={this.printStatusColor}/>
                        </svg>
                    </PeripheralStatus>
                </div>
            </>}
            <div style={{ gridRow: "1 / 2", gridColumn: "6 / 7", padding: '0 20%' }}>
                <PeripheralStatus status={this.robotStatus} version={this.props.robot.state && this.props.robot.state.version}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3217 23.8365C23.3217 20.7394 20.9042 18.2197 17.9327 18.2197C14.9612 18.2197 12.5437 20.7394 12.5437 23.8365V26.0344H8.32617V27.4997H24.7275V26.0344H23.3217V23.8365ZM17.9327 23.8365C17.4151 23.8365 16.9955 23.3992 16.9955 22.8597C16.9955 22.3202 17.4151 21.8829 17.9327 21.8829C18.4503 21.8829 18.8699 22.3202 18.8699 22.8597C18.8699 23.3992 18.4503 23.8365 17.9327 23.8365Z"
                        fill={this.robotStatusColor}/>
                    <path d="M24.7572 9.85098C24.5827 9.46104 24.3266 9.12812 24.0106 8.87074L17.2881 3.12351L17.288 3.12361C16.7616 2.66593 16.096 2.44811 15.4128 2.51046C14.7546 2.57047 14.1563 2.88395 13.7179 3.39485L5.84397 9.81944L5.84444 9.82007C5.33206 10.2214 5 10.86 5 11.578V15.2411H6.40583V16.7063H7.81165V15.2411H9.21748V11.578C9.21748 11.3407 9.1808 11.1124 9.11376 10.8982L14.3713 7.57844L19.3748 11.856L17.6619 16.7613C17.7521 16.7576 17.8426 16.7552 17.9337 16.7552C19.6922 16.7552 21.2969 17.4551 22.5047 18.6017L24.8432 11.905C25.0787 11.2308 25.0481 10.5013 24.7572 9.85098ZM16.5349 5.97149C16.3337 6.2213 16.0513 6.37457 15.7395 6.40299C15.429 6.43121 15.1263 6.33229 14.887 6.12408L14.8835 6.1211C14.6443 5.91153 14.4976 5.61748 14.4704 5.29297C14.4431 4.96806 14.5388 4.65155 14.7401 4.40165C14.9412 4.15184 15.2236 3.99857 15.5354 3.97015C15.5702 3.96698 15.6049 3.96541 15.6396 3.96541C15.9142 3.96541 16.1777 4.06497 16.3906 4.25121C16.6303 4.46083 16.7773 4.75527 16.8046 5.08017C16.8318 5.40508 16.736 5.72163 16.5349 5.97149ZM23.5222 11.4039C23.4151 11.7104 23.2 11.9551 22.9164 12.093C22.6328 12.2308 22.3147 12.2453 22.0206 12.1337C21.4136 11.9034 21.0995 11.2014 21.3204 10.5687C21.4275 10.2622 21.6426 10.0175 21.9262 9.87964C22.0837 9.80313 22.2518 9.7646 22.4206 9.7646C22.5557 9.7646 22.6912 9.78926 22.8219 9.83887C23.116 9.95045 23.3507 10.1747 23.483 10.4702C23.6153 10.7658 23.6292 11.0974 23.5222 11.4039Z"
                        fill={this.robotStatusColor}/>
                </svg>
                </PeripheralStatus>
            </div>
            { this.props.storeType == "Ximiti" && <>
                <div style={{ gridRow: "1 / 2", gridColumn: "7 / 8", padding: '0 20%' }}>
                    <PeripheralStatus status={this.smartPhoneStatus} version={"N/A"}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6154 2.5H10.3846C8.79229 2.5 7.5 3.77271 7.5 5.34089V24.6591C7.5 26.2273 8.79229 27.5 10.3846 27.5H19.6154C21.2077 27.5 22.5 26.2273 22.5 24.6591V5.34089C22.5 3.77271 21.2077 2.5 19.6154 2.5ZM15 26.3637C14.0423 26.3637 13.2693 25.6023 13.2693 24.6591C13.2693 23.7159 14.0423 22.9546 15 22.9546C15.9577 22.9546 16.7308 23.7159 16.7308 24.6591C16.7308 25.6023 15.9577 26.3637 15 26.3637ZM20.1923 21.8182H9.80771V5.90909H20.1923V21.8182Z"
                                fill={this.smartPhoneStatusColor}/>
                        </svg>
                    </PeripheralStatus>
                </div>
                <div style={{ gridRow: "1 / 2", gridColumn: "8 / 9", padding: '0 20%' }}>
                    <PeripheralStatus status={this.tempStatus} version={this.props.temp.state && this.props.temp.state.version}
                        info={<Temperature />}>
                        <svg width="25" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6667 14.0915V4.16665C16.6667 1.86548 14.8012 0 12.5 0C10.1988 0 8.33335 1.86548 8.33335 4.16665V14.0914C7.05469 15.2358 6.25 16.899 6.25 18.75C6.25 22.2018 9.04824 25 12.5 25C15.9518 25 18.75 22.2018 18.75 18.75C18.75 16.899 17.9453 15.2358 16.6667 14.0915ZM12.5 22.9167C10.1988 22.9167 8.33335 21.0512 8.33335 18.75C8.33335 17.547 8.84316 16.4311 9.72275 15.6438L10.4167 15.0227V4.16665C10.4167 3.01606 11.3495 2.0833 12.5 2.0833C13.6506 2.0833 14.5834 3.01602 14.5834 4.16665V15.0227L15.2773 15.6438C16.1569 16.4311 16.6667 17.547 16.6667 18.75C16.6666 21.0512 14.8012 22.9167 12.5 22.9167Z"
                                fill={this.tempStatusColor}/>
                            <path d="M13.5423 8.3335H11.459V20.8335H13.5423V8.3335Z"
                                fill={this.tempStatusColor}/>
                            <path d="M12.5 21.875C14.2259 21.875 15.625 20.4759 15.625 18.75C15.625 17.0241 14.2259 15.625 12.5 15.625C10.7741 15.625 9.375 17.0241 9.375 18.75C9.375 20.4759 10.7741 21.875 12.5 21.875Z"
                                fill={this.tempStatusColor}/>
                        </svg>
                    </PeripheralStatus>
                </div>
            </>}
        </div>);
    }
}

type Status = "Off" | "Ko" | "Ok" | "Busy" | "Deactivated";
type StatusObj = {
    message: string,
    date: Date,
    color: string
}
interface PeripheralStatusProps {
    children: JSX.Element | string;
    version: string;
    status: Status;
    info?: any;
}

class PeripheralStatus extends React.Component<PeripheralStatusProps, {}> {

    get statusText(): string {
        if (this.props.status === "Busy")
            return getText("HomePeropheralsBusy");

        if (this.props.status === "Off")
            return getText("HomePeropheralsOff");

        if (this.props.status === "Ko")
            return getText("HomePeropheralsKo");

        if (this.props.status === "Deactivated")
            return getText("HomePeropheralsDeactivated");

        return getText("HomePeropheralsOk");;
    }

    public render() {
        return (
            <Tooltip placement={"bottom"}
                overlay={<div style={{ fontWeight: "bold" }}>
                    <div>{this.props.info}</div>
                    <div>{this.statusText}</div>
                    <div>V: {this.props.version}</div>
                </div>}>
                <div style={{ flex: "0 0 auto", padding: "0px 4px" }}>
                    {this.props.children}
                </div>
            </Tooltip>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.temp,
    card: state.card,
    cash: state.cash,
    print: state.print,
    robot: state.robot,
    temp: state.temp,
    events: state.home.neoEvents.neoEvents,
    eventAlarms: state.neoEvent.alarmEventsState.neoEvents,
    smartPhoneStatus: state.home.smartPhoneState.smartPhoneStatus,
    neoEventCodes: state.seed.seed.neoEventCodes,
    deliveryStation: state.robot.robotsState.robots.length !== 0
        ? state.robot.robotsState.robots[0].deliveryStations[0]
        : undefined,
    storeType: state.seed.seed.storeAppSettings.typeStore,
    lastPayments: state.home.lastPayments.lastPayments,
    hmis: state.hmiApplication.hmis
} as PeripheralsOwnProps), {
        ...HomeStore.actionCreators,
        ...CashStore.actionCreators,
        ...CardStore.actionCreators,
        ...PrintStore.actionCreators,
        ...RobotStore.actionCreators,
        ...TempStore.actionCreators,
        ...NeoEventAlarmStore.actionCreators,
        ...ContactStore.actionCreators,
        ...HmiApplicationStore.actionCreators,
        ...NeoEventStore.actionCreators
    })(Peripherals as any);