import {addTask} from '../utils/bugFixes';
import {Action, Reducer} from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import {ReceiveEmptyHoppers, ReceiveRechargeHopper} from './Cash';
import {ApplicationState, AppThunkAction} from './';
import {getDefaultHeaders} from '../utils/utils';
import {createSelector} from 'reselect';
import {getNoVatUnitBuyPrice} from '../utils/priceUtils';
import * as _ from 'lodash';

export interface HistoryState {
    codes: Array<number>,
    todayData: {
        needRefresh: boolean;
        isLoading: boolean;
        requestTime?: number;
        dayStoreData?: Api.DayStoreDataModel;
        dayStoreOrders?: Array<Api.StoreOrderModel>;
    };
    todayMoneyStock: {
        needRefresh: boolean;
        isLoading: boolean;
        requestTime?: number;
        dayMoneyStocks: Array<Api.DayMoneyStock>;
    };
    getHistoryModel: Api.GetHistoryModel;
    timeGroupBy: TimeGroupBy;
    historyData: {
        isLoading: boolean;
        requestTime?: number;
        loadedGetHistoryModel?: Api.GetHistoryModel;
        dayStoreDatas: Array<Api.DayStoreDataModel>;
        selectedIndex?: number;
        turnoverV6?: Array<Api.HistoCAModel>;
        requestTimeTV6?: number;
        isLoadingTV6: boolean;
    },
    printReceiptState: {
        isLoading: boolean;
        requestTime?: number;
    },
    printCardReceiptState: {
        isLoading: boolean;
        requestTime?: number;
    },
    receiptState: {
        selectedOrder?: number;
        selectedPaymentId?: number;
        card:{
            receiptContent?: string;
            isLoading: boolean;
            requestTime?: number;
        },
        order:{
            receiptContent?: string;
            isLoading: boolean;
            requestTime?: number;
        },
        isOpen: boolean;
    },
    resetStoredMoneyState: {
        isLoading: boolean;
        requestTime?: number;
    },
    eventsFilter: EventsFilter,
    turnoverFilter: TurnoverFilter,
    salesFilter: SalesFilter,
    viewCardCollection: ViewCardCollection,
    viewRemoteCollection: ViewRemoteCollection
}

export interface ViewCardCollection {
    isOpen: boolean;
    currentCollection: IupCollection;
    currentPayment: Api.StoreOrderPaymentModel;
}

export interface ViewRemoteCollection {
    isOpen: boolean;
    currentRemoteCollection: Api.StoreRemoteCollectionModel;
    currentDataRemote: RemoteCollectionData;
}

export type SalePaymentSmartphone = "Livraison" | "CE";
export type SalePaymentPlatform = "UberEats" | "Deliveroo" | "JustEat"
export interface TurnoverFilter {
    paymentType?: Api.SalePaymentPaymentTypeEnum 
        | Api.SalePaymentModelCardTypeEnum
        | SalePaymentSmartphone
        | SalePaymentPlatform
}

export interface SalesFilter {
    showUnsold?: boolean;
}

export interface EventsFilter {
    /* Minor Events */
    minorEvents: boolean,
    minorInformatique: boolean,
    minorRobot: boolean,
    minorImprimante: boolean,
    minorTPN: boolean,
    minorTPA: boolean,
    /* Major Events */
    majorEvents: boolean,
    majorInformatique: boolean,
    majorRobot: boolean,
    majorMonetique: boolean,
    majorAutomate: boolean,
    /* Events */
    generalEvents: boolean,
    statusEvents: boolean,
    alertEvents: boolean,
    robotEvents: boolean,
    actionExpEvents: boolean,
    actionClientEvents: boolean,
    phoneEvents: boolean,
    TPNEvents: boolean,
    TPAEvents: boolean,
    computerEvents: boolean,
    exploitantEvents: boolean,
    /* Other filters */
    searchText: string,
}           
            
export interface IupCollection {
    transactionType?: string;
    transactionNumber?: string;
    siret?: string;
    remissionNumber?: string;
    currencyNumber?: string;
    transactionAmount?: string;
    acceptorNumber?: string;
    dateTime?: string;
    readingMode?: string;
    cardValidity?: string;
    currencyCode?: string;
    accountNumber?: string;
    currencyPart?: string;
    activityType?: string;
    cvAmount?: string;
    contractNumber?: string;
    cvCode?: string;
    aid?: string;
    cvPart?: string;
    label?: string;
    paymentType?: string;
    locationType?: string;
    applicationType?: string;
    crypto?: string;
    authorizationNumber?: string;
    forceCode?: string;
    estimatedAmount?: string;
    serviceCode?: string;
    acceptorTitle?: string;
    receiptTitle?: string;
    receiptFooter?: string;
}

export type TimeGroupBy = "Day" | "Week" | "Month" | "Year";

interface RequestDayStoreDatas {
    type: "REQUEST_DAYSTOREDATAS";
    payload: { requestTime: number; }
}
interface ReceiveDayStoreDatas {
    type: "RECEIVE_DAYSTOREDATAS";
    payload: {
        requestTime: number;
        getHistoryModel?: Api.GetHistoryModel;
        dayStoreDatas?: Array<Api.DayStoreDataModel>
    }
    error?: any;
}

interface RequestHistoCA {
    type: "REQUEST_HISTO_CAV6";
    payload: { requestTime: number; }
}
interface ReceiveHistoCA {
    type: "RECEIVE_HISTO_CAV6";
    payload: {
        requestTime: number;
        turnoverV6?: Array<Api.HistoCAModel>
    }
    error?: any;
}

interface RequestTodayDayStoreData {
    type: "REQUEST_TODAY_DAYSTOREDATA";
    payload: { requestTime: number; }
}
interface ReceiveTodayDayStoreData {
    type: "RECEIVE_TODAY_DAYSTOREDATA";
    payload: {
        requestTime: number;
        dayStoreData?: Api.DayStoreDataModel;
    }
    error?: any;
}

interface RequestTodayStoreOrders {
    type: "REQUEST_TODAY_STOREORDERS";
    payload: { requestTime: number; }
}
interface ReceiveTodayStoreOrders {
    type: "RECEIVE_TODAY_STOREORDERS";
    payload: {
        requestTime: number;
        todayStoreOrders?: Array<Api.StoreOrderModel>;
    }
    error?: any;
}

interface RequestTodayDayMoneyStock {
    type: "REQUEST_TODAY_DAYMONEYSTOCK";
    payload: { requestTime: number; }
}
interface ReceiveTodayDayMoneyStock {
    type: "RECEIVE_TODAY_DAYMONEYSTOCK";
    payload: {
        requestTime: number;
        dayMoneyStocks?: Array<Api.DayMoneyStock>;
    }
    error?: any;
}

interface UpdateHistoryFromDate {
    type: "UPDATE_HISTORY_FROMDATA";
    payload: { value: Date; }
}
interface UpdateHistoryToDate {
    type: "UPDATE_HISTORY_TODATA";
    payload: { value: Date; }
}
interface UpdateHistoryTimeGroupBy {
    type: "UPDATE_HISTORY_TIMEGROUPBY";
    payload: { value: TimeGroupBy; }
}
interface UpdateHistorySelectedIndex {
    type: "UPDATE_HISTORY_SELECTED_INDEX";
    payload: { value: number; }
}

interface RequestHistoryPrintReceipt {
    type: "REQUEST_HISTORY_PRINT_RECEIPT";
    payload: { requestTime: number; }
}
interface ReceivetHistoryPrintReceipt {
    type: "RECEIVE_HISTORY_PRINT_RECEIPT";
    payload: { requestTime: number; }
    error?: any;
}

interface RequestHistoryPrintCardReceipt {
    type: "REQUEST_HISTORY_PRINT_CARD_RECEIPT";
    payload: { requestTime: number; }
}
interface ReceivetHistoryPrintCardReceipt {
    type: "RECEIVE_HISTORY_PRINT_CARD_RECEIPT";
    payload: { requestTime: number; }
    error?: any;
}

interface HistoryOpenReceipt {
    type: "HISTORY_OPEN_RECEIPT";
    payload: { salePaymentId: number; }
}
interface HistoryCloseReceipt {
    type: "HISTORY_CLOSE_RECEIPT"
}
interface RequestCardReceipt {
    type: "REQUEST_CARD_RECEIPT";
    payload: { requestTime: number; }
}
interface ReceiveCardReceipt {
    type: "RECEIVE_CARD_RECEIPT";
    payload: { requestTime: number; content?: string; }
    error?: any;
}

interface RequestOrderReceipt {
    type: "REQUEST_ORDER_RECEIPT";
    payload: { requestTime: number; }
}
interface ReceiveOrderReceipt {
    type: "RECEIVE_ORDER_RECEIPT";
    payload: { requestTime: number; content?: string; }
    error?: any;
}

//RAZ/Reset cash registry
interface RequestResetStoredMoney {
    type: "REQUEST_RESET_STOREDMONEY";
    payload: { requestTime: number; };
}
interface ReceiveResetStoredMoney {
    type: "RECEIVE_RESET_STOREDMONEY";
    payload: { requestTime: number; moneyReset?: Api.HmiMoneyResetModel };
    error?: any;
}

/*Codes */
interface UpdateCodes {
    type: "UPDATE_CODES";
    payload: { value: Array<number>; }
}

interface UpdateEventsFilter {
    type: "UPDATE_EVENTSFILTER";
    payload: { value: EventsFilter; }
}

interface UpdateTurnoverFilter {
    type: "UPDATE_TURNOVERFILTER";
    payload: { value: TurnoverFilter; }
}
interface UpdateSalesFilter {
    type: "UPDATE_SALESFILTER";
    payload: { value: SalesFilter; }
}

interface OpenViewCardCollection {
    type: "OPEN_VIEW_CARDCOLLECTION";
    payload: { collection: IupCollection, payment: Api.StoreOrderPaymentModel };
}
interface CloseViewCardCollection {
    type: "CLOSE_VIEW_CARDCOLLECTION";
}
interface OpenViewRemoteCollection {
    type: "OPEN_VIEW_REMOTE_COLLECTION";
    payload: { remoteCollection:  Api.StoreRemoteCollectionModel, dataRemote: RemoteCollectionData };
}
interface CloseViewRemoteCollection {
    type: "CLOSE_VIEW_REMOTE_COLLECTION";
}

export type KnownAction = RequestDayStoreDatas
    | ReceiveDayStoreDatas
    | RequestTodayDayStoreData
    | ReceiveTodayDayStoreData
    | UpdateHistoryFromDate
    | UpdateHistoryToDate
    | UpdateHistoryTimeGroupBy
    | UpdateHistorySelectedIndex
    | RequestHistoryPrintReceipt
    | ReceivetHistoryPrintReceipt
    | RequestHistoryPrintCardReceipt
    | ReceivetHistoryPrintCardReceipt
    | RequestTodayDayMoneyStock
    | ReceiveTodayDayMoneyStock
    | HistoryOpenReceipt
    | HistoryCloseReceipt
    | RequestCardReceipt
    | ReceiveCardReceipt
    | RequestResetStoredMoney
    | ReceiveResetStoredMoney
    | ReceiveRechargeHopper
    | ReceiveEmptyHoppers
    | UpdateEventsFilter
    | UpdateCodes
    | UpdateTurnoverFilter
    | UpdateSalesFilter
    | RequestOrderReceipt
    | ReceiveOrderReceipt
    | RequestTodayStoreOrders
    | ReceiveTodayStoreOrders
    | OpenViewCardCollection
    | CloseViewCardCollection
    | OpenViewRemoteCollection
    | CloseViewRemoteCollection
    | RequestHistoCA
    | ReceiveHistoCA;

export const todayOrderMoneyTraffics = (dayStoreData: Api.DayStoreDataModel): Array<Api.StoreMoneyTrafficModel> => {
    return dayStoreData
        ? dayStoreData.storeOrders
            .filter(x => x.storeOrderPayments.length)
            .map(x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0].storeMoneyTraffics)
            .filter(x => x)
            .reduce((a, b) => a.concat(b), [])
        : [];
};

export const todayMoneyTraffics = (dayStoreData: Api.DayStoreDataModel): Array<Api.StoreMoneyTrafficModel> => {
    return dayStoreData
        ? dayStoreData.storeMoneyTraffics
        : [];
};

export const todayPayments = (dayStoreData: Api.DayStoreDataModel): Array<Api.StoreOrderPayment> => {
    return dayStoreData
        ? dayStoreData.storeOrders
            .filter(x => x.storeOrderPayments.length)
            .map(x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0])
        : [];
};

export const todayStoreOrders = (dayStoreData: Api.DayStoreDataModel): Array<Api.StoreOrderModel> => {
    return dayStoreData
        ? dayStoreData.storeOrders
            .filter(x => x.storeOrderPayments.length)
        : [];
};

export const todayStoreOrders24h = (dayStoreOrders:  Array<Api.StoreOrderModel>): Array<Api.StoreOrderModel> => {
    return dayStoreOrders
        ? dayStoreOrders.filter(x => x.storeOrderPayments.length)
        : [];
};

export const requestTodayMoneyStock = (requestTime, dispatch: (a: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.HistoryApi();
    let fetchTask = api.getTodayMoneyStock({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(dayMoneyStocks => {
            dispatch({
                type: "RECEIVE_TODAY_DAYMONEYSTOCK",
                payload: {
                    requestTime: requestTime,
                    dayMoneyStocks: dayMoneyStocks
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_TODAY_DAYMONEYSTOCK",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "REQUEST_TODAY_DAYMONEYSTOCK",
        payload: { requestTime: requestTime }
    });
    addTask(fetchTask);
    return fetchTask;
}

export const requestTodayDayStoreData = (requestTime: number, dispatch: (a: KnownAction) => void, getState : () => ApplicationState): Promise<any>=> {
    let api = new Api.HistoryApi();
    let fetchTask = api.getDayStoreDatas({
        model: {
            fromDate: Moment().startOf("day").toDate(),
            toDate: Moment().endOf("day").toDate(),
        }
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(dayStoreDatas => {
            dispatch({
                type: "RECEIVE_TODAY_DAYSTOREDATA",
                payload: {
                    requestTime: requestTime,
                    dayStoreData: dayStoreDatas[0]
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_TODAY_DAYSTOREDATA",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "REQUEST_TODAY_DAYSTOREDATA",
        payload: { requestTime: requestTime }
    });
    addTask(fetchTask);
    return fetchTask;
}

export const requestTodayStoreOrders = (requestTime: number, dispatch: (a: KnownAction) => void, getState : () => ApplicationState): Promise<any>=> {
    let api = new Api.HistoryApi();
    
    let fetchTask = api.getTodayStoreOrders({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(dayStoreOrders => {
            dispatch({
                type: "RECEIVE_TODAY_STOREORDERS",
                payload: {
                    requestTime: requestTime,
                    todayStoreOrders: dayStoreOrders
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_TODAY_STOREORDERS",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "REQUEST_TODAY_STOREORDERS",
        payload: { requestTime: requestTime }
    });
    addTask(fetchTask);
    return fetchTask;
}

export const actionCreators = {
    requestDayStoreDatas: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let getHistoryModel = getState().history.getHistoryModel;
        let api = new Api.HistoryApi();
        let fetchTask = api.getDayStoreDatas({
            model: getHistoryModel
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(dayStoreDatas => {
                dispatch({
                    type: "RECEIVE_DAYSTOREDATAS",
                    payload: {
                        requestTime: requestTime,
                        getHistoryModel: getHistoryModel,
                        dayStoreDatas: dayStoreDatas
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DAYSTOREDATAS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_DAYSTOREDATAS",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestHistoCAv6: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let getHistoryModel = getState().history.getHistoryModel;
        let api = new Api.HistoryApi();
        let fetchTask = api.getHistosCAv6({
            model: getHistoryModel
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(tv6 => {
                dispatch({
                    type: "RECEIVE_HISTO_CAV6",
                    payload: {
                        requestTime: requestTime,
                        turnoverV6: tv6
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HISTO_CAV6",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_HISTO_CAV6",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestTodayDayStoreData: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestTodayDayStoreData(requestTime, dispatch, getState);
    },
    requestTodayStoreOrders: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestTodayStoreOrders(requestTime, dispatch, getState);
    },
    requestTodayDayMoneyStocks: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestTodayMoneyStock(requestTime, dispatch, getState);
    },
    updateHistoryFromDate: (value: Date) => <UpdateHistoryFromDate>{
        type: "UPDATE_HISTORY_FROMDATA",
        payload: { value: value }
    },
    updateHistoryToDate: (value: Date) => <UpdateHistoryToDate>{
        type: "UPDATE_HISTORY_TODATA",
        payload: { value: value }
    },
    updateHistoryTimeGroupBy: (value: TimeGroupBy) => <UpdateHistoryTimeGroupBy>{
        type: "UPDATE_HISTORY_TIMEGROUPBY",
        payload: { value: value }
    },
    updateHistoryIndex: (value: number) => <UpdateHistorySelectedIndex>{
        type: "UPDATE_HISTORY_SELECTED_INDEX",
        payload: {
            value: value
        }
    },
    requestPrintReceipt: (requestTime: number, orderId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PrintApi();
        let fetchTask = api.printReceiptFromOrderId({
            orderId: orderId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_HISTORY_PRINT_RECEIPT",
                    payload: { requestTime: requestTime }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HISTORY_PRINT_RECEIPT",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_HISTORY_PRINT_RECEIPT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestPrintCardReceipt: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PrintApi();
        let fetchTask = api.printCardReceiptFromSalePaymentId({
            salePaymentId: getState().history.receiptState.selectedPaymentId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_HISTORY_PRINT_CARD_RECEIPT",
                    payload: { requestTime: requestTime }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HISTORY_PRINT_CARD_RECEIPT",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_HISTORY_PRINT_CARD_RECEIPT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    openCardDialog: (paymentId: number) => <HistoryOpenReceipt>{
        type: "HISTORY_OPEN_RECEIPT",
        payload: { salePaymentId: paymentId }
    },
    closeCardDialog: () => <HistoryCloseReceipt>{
        type: "HISTORY_CLOSE_RECEIPT"
    },
    requestCardReceipt: (requestTime: number, salePaymentId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReceiptApi();
        let fetchTask = api.getCardReceiptFromPaymentId({
            salePaymentId: salePaymentId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_CARD_RECEIPT",
                    payload: { requestTime: requestTime, content: result.result }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_RECEIPT",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_RECEIPT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestOrderReceipt: (requestTime: number, orderId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReceiptApi();
        let fetchTask = api.getOrderReceiptFromOrderId({
            orderId: orderId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_ORDER_RECEIPT",
                    payload: { requestTime: requestTime, content: result.result }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ORDER_RECEIPT",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_ORDER_RECEIPT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    //raz
    requestResetStoredMoney: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.resetStoredMoney({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(moneyReset => {
                dispatch({
                    type: "RECEIVE_RESET_STOREDMONEY",
                    payload: {
                        requestTime: requestTime,
                        moneyReset: moneyReset
                    }
                });
                //Rerequest because logic is server side
                return requestTodayMoneyStock(requestTime, dispatch, getState);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_RESET_STOREDMONEY",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_RESET_STOREDMONEY",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    /* codes*/
    updateCodes: (value: Array<number>) => <UpdateCodes>{
        type: "UPDATE_CODES",
        payload: { value: value }
    },
    updateEventsFilter: (value: EventsFilter) => <UpdateEventsFilter>{
        type: "UPDATE_EVENTSFILTER",
        payload: { value: value }
    },
    updateTurnoverFilter: (value: TurnoverFilter) => <UpdateTurnoverFilter>{
        type: "UPDATE_TURNOVERFILTER",
        payload: { value: value }
    },
    updateSalesFilter: (value: SalesFilter) => <UpdateSalesFilter>{
        type: "UPDATE_SALESFILTER",
        payload: { value: value }
    },
    openViewCardCollection: (collection: IupCollection, payment: Api.StoreOrderPaymentModel) => <OpenViewCardCollection>{ 
        type: "OPEN_VIEW_CARDCOLLECTION",
        payload: { collection: collection , payment: payment}
    },
    closeViewCardCollection: () => <CloseViewCardCollection>{ type: "CLOSE_VIEW_CARDCOLLECTION" },
    openViewRemoteCollection: (remoteCollection: Api.StoreRemoteCollectionModel, dataRemote: RemoteCollectionData) => <OpenViewRemoteCollection>{ 
        type: "OPEN_VIEW_REMOTE_COLLECTION",
        payload: { remoteCollection: remoteCollection , dataRemote: dataRemote}
    },
    closeViewRemoteCollection: () => <CloseViewRemoteCollection>{ type: "CLOSE_VIEW_REMOTE_COLLECTION" }
};

const getDayStoreDatas = (state: ApplicationState) => state.history.historyData ? state.history.historyData.dayStoreDatas : [];
const getTimeGroupBy = (state: ApplicationState) => state.history.timeGroupBy;
const getVats = (state: ApplicationState) => state.seed.seed.vats;
const getPaymentType = (state: ApplicationState) => state.history.turnoverFilter.paymentType;
const getPriceNames = (state: ApplicationState) => state.seed.seed.priceNames;
const getProducts = (state: ApplicationState) => state.seed.seed.products;
const getHistoryData = (state: ApplicationState) => state.history.historyData;
const getSelectedDayStoreData = (state: ApplicationState) => (state.history.historyData
    ? state.history.historyData.dayStoreDatas
    : [])[state.history.historyData.selectedIndex || 0];
const getSalesFilter = (state: ApplicationState) => state.history.salesFilter;
const getStockStatus = (state: ApplicationState) => state.stock.stockStatus;
const getStoreType = (state: ApplicationState) => state.seed.seed.storeAppSettings.typeStore;

export interface GroupedStoreData {
    date: Date;
    vatAmounts: Array<{
        paymentType: Api.SalePaymentModelPaymentTypeEnum;
        cardType?: Api.SalePaymentModelCardTypeEnum;
        smpPlatform?: Api.PlatformModel;
        vatId: number;
        amount: number;
        amountExternalPWoTaxes?: number;
    }>;
    storeOrders: Array<Api.StoreOrder>;
    storeHmiMoneyResets: Array<Api.StoreHmiMoneyResetModel>;
    storeDayDataSnapshot: {
        dayMoneyStocks: Array<Api.StoreDayMoneyStockModel>;
    };
    storeMoneyTraffics: Array<Api.StoreMoneyTrafficModel>;
}

export interface ProductSellData {
    productId: number;
    vatId?: number;
    turnover: number;
    buyPrice: number;
    sellPrice?: number;
    amountExternalPWoTaxes? : number;
    appliedTaxes? : number;
    quantityNormal: { byCode: number; normal: number; byPhone: number; byPlatform: number},
    quantityOffer: { byCode: number; normal: number; byPhone: number; byPlatform: number};
    quantityMenu: { byCode: number; normal: number; byPhone: number; byPlatform: number};
}

export interface ProductStockData {
    productId: number;
    stock: number;
    storedStock: number;
}

export const groupedStoreDatas = createSelector(
    getDayStoreDatas,
    getTimeGroupBy,
    getVats,
    getPaymentType,
    getStoreType,
    (dayStoreDatas, timeGroupBy, vats, paymentType, storeType): Array<GroupedStoreData> => {
        return _.map(_.groupBy(dayStoreDatas, x => {
            if (timeGroupBy === "Month") {
                return Moment(x.dayDate).startOf("month")
                    .toDate().getTime().toString();
            } else if (timeGroupBy === "Week") {
                return Moment(x.dayDate).startOf("week")
                    .toDate().getTime().toString();
            } else if (timeGroupBy === "Year") {
                return Moment(x.dayDate).startOf("year")
                    .toDate().getTime().toString();
            }
            return Moment(x.dayDate).startOf("day")
                .toDate().getTime().toString();
        }), (xs, key) => ({
            date: Moment(parseInt(key)).toDate(),
            storeHmiMoneyResets: xs.map(x => x.storeHmiMoneyResets).reduce((a, b) => a.concat(b), []),
            storeDayDataSnapshot: {
                dayMoneyStocks: xs.filter(x => x.storeDayDataSnapshot && x.storeDayDataSnapshot.dayMoneyStocks)[0]
                    ? xs.filter(x => x.storeDayDataSnapshot && x.storeDayDataSnapshot.dayMoneyStocks)[0].storeDayDataSnapshot.dayMoneyStocks
                    : []
            },
            storeOrders: xs
                .map(x => x.storeOrders)
                .reduce((a, b) => a.concat(b), [])
                .filter(x => x.storeOrderPayments.length
                && (!paymentType 
                        || x.storeOrderPayments
                        .some(y => ((y.paymentType === paymentType && x.platform === null)
                            || (y.cardType === paymentType)
                            || (x.platform !== null && x.platform.remoteId === "4" && y.paymentType === "SmartPhone" && paymentType === "Livraison")
                            || (x.platform !== null && x.platform.remoteId !== "4" && y.paymentType === "SmartPhone" && paymentType === "CE")
                            || (x.platform !== null && x.platform.remoteId === "2" && paymentType === "UberEats")
                            || (x.platform !== null && x.platform.remoteId === "5" && paymentType === "Deliveroo")
                            || (x.platform !== null && x.platform.remoteId === "6" && paymentType === "JustEat")
                            )
                            && (y.collectiondDate ? true : false)))),
            storeMoneyTraffics: xs.map(x => x.storeMoneyTraffics).reduce((a, b) => a.concat(b), []),
            vatAmounts: _.orderBy(_.map(_.groupBy(xs
                .map(x => x.storeOrders.filter(y => y.storeOrderPayments.length))
                .reduce((a, b) => a.concat(b), [])
                .filter(x => !paymentType 
                    || x.storeOrderPayments
                        .some(y => ((y.paymentType === paymentType && x.platform === null)
                            || (y.cardType === paymentType)
                            || (x.platform !== null && x.platform.remoteId === "4" && y.paymentType === "SmartPhone" && paymentType === "Livraison")
                            || (x.platform !== null && x.platform.remoteId !== "4" && y.paymentType === "SmartPhone" && paymentType === "CE")
                            || (x.platform !== null && x.platform.remoteId === "2" && paymentType === "UberEats")
                            || (x.platform !== null && x.platform.remoteId === "5" && paymentType === "Deliveroo")
                            || (x.platform !== null && x.platform.remoteId === "6" && paymentType === "JustEat")
                            )
                            && (y.collectiondDate ? true : false)))
                .map(x => {
                    let totalVats = x.storeOrderItemOrders
                        .filter(y => (y.storeOrderItemOrderType === "Product" || y.storeOrderItemOrderType === "ExternalProduct")
                            && !y.storeOrderMenuOrderId)
                        .map(y => {
                            let payment = _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0];

                            return {
                                amount: payment.collection == 0
                                    ? 0
                                    : (storeType === "Ximiti" ? y.price.value : ((y.unitPriceWoTaxes * (1 + y.appliedTaxes)) / 100)) * x.storeOrderProductDeliveries
                                    .filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId
                                        && z.status === "Ok"
                                        && z.stepStatus === "Ok").length,
                                amountExternalPWoTaxes: storeType !== "Ximiti" && (y.unitPriceWoTaxes / 100) * x.storeOrderProductDeliveries
                                    .filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId
                                        && z.status === "Ok"
                                        && z.stepStatus === "Ok").length,
                                vatId: storeType === "Ximiti" ? y.vatId : parseInt(_.findKey(vats, z=>z.value == 10)),
                                paymentType: payment.paymentType,
                                cardType: payment.cardType,
                                smpPlatform: payment.paymentType === "SmartPhone" && x.platform,
                            };
                        }).concat(
                            x.storeOrderItemOrders
                                .filter(y => y.storeOrderItemOrderType === "Product"
                                    && y.storeOrderMenuOrderId)
                                .map(y => {
                                    let payment = _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0];
                                    return {
                                        amount: payment.collection == 0
                                            ? 0
                                            : getPriceFromMenu(y, x)
                                            * x.storeOrderProductDeliveries
                                                .filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId
                                                    && z.status === "Ok"
                                                    && z.stepStatus === "Ok").length,
                                        vatId: y.vatId,
                                        paymentType: payment.paymentType,
                                        cardType: payment.cardType,
                                        smpPlatform: payment.paymentType === "SmartPhone" && x.platform,
                                        amountExternalPWoTaxes: undefined
                                    };
                                })
                        );

                    if(storeType !== "Ximiti"){return totalVats;}

                    let orderTotal = _.sum(totalVats.map(x => x.amount));
                    let roundedTotal = Math.round(orderTotal * 10) / 10;
                    if(orderTotal) {
                        totalVats.forEach(tv => {
                            var totalOfOrder = tv.amount / orderTotal;
                            tv.amount = roundedTotal * totalOfOrder;
                        });
                    }
                    return totalVats;
                })
                .reduce((a, b) => a.concat(b), []),
                x => `v${x.vatId}p${x.paymentType}c${x.cardType}s${x.smpPlatform?.remoteId}}`), (ys) => ({
                    vatId: ys[0].vatId,
                    paymentType: ys[0].paymentType,
                    cardType: ys[0].cardType,
                    amount: Math.round(_.sum(ys.map(y => y.amount)) * 100) / 100,
                    amountExternalPWoTaxes: Math.round(_.sum(ys.map(y => y.amountExternalPWoTaxes)) * 100) / 100,
                    smpPlatform: ys[0].smpPlatform,
                })), x => vats[x.vatId].value)
        }));
    });

export const getPriceFromMenu = (itemOrder: Api.StoreOrderItemOrderModel, storeOrder: Api.StoreOrderModel): number => {
    let menu = storeOrder.storeOrderItemOrders
        .find(x => x.storeOrderItemOrderId === itemOrder.storeOrderMenuOrderId);
    let productsInMenu = storeOrder.storeOrderItemOrders
        .filter(x => x.storeOrderItemOrderType === "Product"
            && x.storeOrderMenuOrderId === menu.storeOrderItemOrderId);
    let totalNoMenu = _.sum(productsInMenu.map(x => x.price.value));
    let itemRatio = itemOrder.price.value / totalNoMenu;

    return menu.price.value * itemRatio;
}

export const iupCollectionFromPayment = (payment: Api.StoreOrderPaymentModel): IupCollection => {
    if(!payment.collectionInfo)
        return {};
    
    let shift = payment.collectionInfo.length == 347 ? 2 : 0;
    let missingVal = payment.collectionInfo.length == 346 
                        || payment.collectionInfo.length == 344 ? 1 : 0;
    
    return {
        acceptorTitle: payment.collectionInfo.slice(0, 50),
        dateTime: `${payment.collectionInfo.slice(50, 56)}-${payment.collectionInfo.slice(56, 62)}`,
        receiptTitle: payment.collectionInfo.slice(62, 122),
        transactionType: payment.collectionInfo.slice(122, 124),
        contractNumber: payment.collectionInfo.slice(124, 131),
        siret: payment.collectionInfo.slice(131, 145),
        activityType: payment.collectionInfo.slice(145, 149),
        paymentType: payment.collectionInfo.slice(149, 151),
        locationType: payment.collectionInfo.slice(151, 159),
        accountNumber: payment.collectionInfo.slice(159, 178),
        applicationType: payment.collectionInfo.slice(178, 182),
        cardValidity: payment.collectionInfo.slice(182, 186 + shift),
        serviceCode: payment.collectionInfo.slice(186 + shift, 189 + shift),
        crypto: payment.collectionInfo.slice(189 + shift, 205 + shift),
        currencyNumber: payment.collectionInfo.slice(205 + shift, 208 + shift),
        acceptorNumber: payment.collectionInfo.slice(208 + shift, 211 + shift),
        transactionNumber: payment.collectionInfo.slice(211 + shift, 217 + shift),
        remissionNumber: payment.collectionInfo.slice(217 + shift, 223 + shift),
        readingMode: payment.collectionInfo.slice(223 + shift, 224 + shift),
        authorizationNumber: payment.collectionInfo.slice(224 + shift, 230 + shift),
        forceCode: missingVal == 1 ? " " : payment.collectionInfo.slice(230 + shift, 231 + shift),
        transactionAmount: payment.collectionInfo.slice(231 + shift - missingVal, 239 + shift - missingVal),
        currencyCode: payment.collectionInfo.slice(239 + shift - missingVal, 242 + shift - missingVal),
        currencyPart: payment.collectionInfo.slice(242 + shift - missingVal, 243 + shift - missingVal),
        cvAmount: payment.collectionInfo.slice(243 + shift - missingVal, 251 + shift - missingVal),
        cvCode: payment.collectionInfo.slice(251 + shift - missingVal, 254 + shift - missingVal),
        cvPart: payment.collectionInfo.slice(254 + shift - missingVal, 255 + shift - missingVal),
        estimatedAmount: payment.collectionInfo.slice(255 + shift - missingVal, 263 + shift - missingVal),
        receiptFooter: payment.collectionInfo.slice(263 + shift - missingVal, 313 + shift - missingVal),
        aid: payment.collectionInfo.slice(313 + shift - missingVal, 329 + shift - missingVal),
        label: payment.collectionInfo.slice(329 + shift - missingVal, 345 + shift - missingVal),
    };
};

export interface RemoteCollectionData {
    title?: string;
    date?: string;
    time?: string;
    contractId?: string;
    siret?: string;
    activity?: string;
    paymentType?: string;
    siteType?: string;
    acceptorNumber?: string;
    responseLabel?: string;
    footer?: string;
    remissions: Array<{
        acquirerNumber?: string;
        id?: string;
        nbTransaction?: string;
        currencyCode?: string;
        amountTotal?: string;
        part?: string;
        nbFailedTransaction?: string;
    }>
}

export const iupRemoteCollectionFromData = (data: string): RemoteCollectionData => {
    let index = 0;
    let dataToParse = data.replace(new RegExp("\r\n", 'g'), "");
    let extractStringPart = (length) => {
        let stringPart = dataToParse.substring(index, index + length);
        index += length;
        return stringPart;
    }

    let remoteCollectionData = {
        title: extractStringPart(50),
        date: extractStringPart(6),
        time: extractStringPart(6),
        contractId: extractStringPart(7),
        siret: extractStringPart(14),
        activity: extractStringPart(4),
        paymentType: extractStringPart(2),
        siteType: extractStringPart(8),
        acceptorNumber: extractStringPart(3),
        responseLabel: extractStringPart(32),
        footer: extractStringPart(50),
        remissions: []
    };
    let nbRem = parseInt(extractStringPart(1));

    for(let nb=0; nb < nbRem; nb++){
        remoteCollectionData.remissions.push({
            acquirerNumber: extractStringPart(6),
            id: extractStringPart(6),
            nbTransaction: extractStringPart(6),
            currencyCode: extractStringPart(3),
            amountTotal: extractStringPart(12),
            part: extractStringPart(1),
            nbFailedTransaction: extractStringPart(6)
        });
    }
    return remoteCollectionData;
}

export const vouchersDiscounts = (storeOrder: Api.StoreOrderModel, collectedFromOrder: number): number => {
    let totalDiscount = 0;
    if(storeOrder.storeVouchersOrder && storeOrder.storeVouchersOrder.length != 0){
        if(storeOrder.storeVouchersOrder[0].amountType == "Flat"){
            return storeOrder.storeVouchersOrder[0].amount;
        }
        else{
            var auxAmount = collectedFromOrder * (storeOrder.storeVouchersOrder[0].amount / 100);
            if(auxAmount > storeOrder.storeVouchersOrder[0].maxAmount){
                totalDiscount = storeOrder.storeVouchersOrder[0].maxAmount;
            }
            else{
                totalDiscount = auxAmount;
            }
        }
    }   

    return totalDiscount;
}

export const totalToCollectFromOrder = (storeOrder: Api.StoreOrderModel , storeType: string): number => {
    let productValues = storeOrder.storeOrderProductDeliveries
        .filter(x => x.status === "Ok"
            && x.stepStatus === "Ok")
        .map(x => {
            let itemOrder = storeOrder.storeOrderItemOrders
                .find(y => y.storeOrderItemOrderId === x.storeOrderProductOrderId);
            if(!itemOrder){
                console.error("Missing item order from delivery");
                return 0;
            }

            if(itemOrder.storeOrderMenuOrderId) {
                return getPriceFromMenu(itemOrder, storeOrder);
            }

            return storeType == "Ximiti" ? itemOrder.price.value : ((itemOrder.unitPriceWoTaxes * (1 + itemOrder.appliedTaxes)) / 100) ;
        });
    let total = _.sum(productValues);
    let roundedTotal = Math.round(total * 10) / 10;
    let newTotal = 0;
    if(total) {
        productValues.forEach(x => {
            let ratio = x / total;
            newTotal += ratio * roundedTotal;
        });
    }
    return storeType == "Ximiti" ? newTotal : (Math.round(total * 100) / 100);
} 

export type RemoteCollectionWithData = { remoteCollection: Api.StoreRemoteCollectionModel, data: RemoteCollectionData };
export const remoteCollections = createSelector(getSelectedDayStoreData, (selectedDayStoreData): Array<RemoteCollectionWithData> => {
    if(!selectedDayStoreData)
        return [];
    
    return selectedDayStoreData.storeRemoteCollections.map(x => ({
        remoteCollection: x,
        data: x.data 
            ? iupRemoteCollectionFromData(x.data) 
            : { remissions: [] }
    }))
});

type ProductSellSource = "Normal" | "Code" | "SmartPhone" | "Platform";
type ProductSellType = "Normal" | "Offer" | "Menu";
export const productSellDatas = createSelector(
    getDayStoreDatas,
    getProducts,
    getVats,
    getPriceNames,
    getSalesFilter,
    getStockStatus,
    getStoreType,
    (dayStoreDatas, products, vats, priceNames, salesFilter, stockStatus, storeType): Array<ProductSellData> => {
        let sellData = _.map(_.groupBy(dayStoreDatas
            .map(x => x.storeOrders)
            .reduce((a, b) => a.concat(b), [])
            .map(x => x.storeOrderItemOrders
                .filter(y => (y.storeOrderItemOrderType === "Product"|| y.storeOrderItemOrderType === "ExternalProduct"))
                .map(y => ({
                    productId: storeType == "Ximiti" ? y.productId : y.externalProductId,
                    quantityDelivered: x.storeOrderProductDeliveries
                        .filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId 
                            && z.status === "Ok" 
                            && z.stepStatus === "Ok").length,
                    vatId: storeType == "Ximiti" ? y.vatId : parseInt(_.findKey(vats, z=>z.value == 10)),
                    buyPrice: x.storeOrderProductDeliveries.filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId 
                            && z.status === "Ok" && z.stepStatus === "Ok")
                            .find(ps => ps.productSupplier && (ps.productId == y.productId || ps.productId == y.externalProductId)) != undefined ?
                                getNoVatUnitBuyPrice(
                                    x.storeOrderProductDeliveries.filter(z => z.storeOrderProductOrderId === y.storeOrderItemOrderId 
                                                    && z.status === "Ok" && z.stepStatus === "Ok")
                                    .find(ps => ps.productSupplier && (ps.productId == y.productId || ps.productId == y.externalProductId)).productSupplier)
                                : undefined,
                    sellPrice: y.storeOrderMenuOrderId
                        ? getPriceFromMenu(y, x)
                        : storeType == "Ximiti" ? y.price.value : ((y.unitPriceWoTaxes * (1 + y.appliedTaxes)) / 100),
                    source: (x.type === "PreOrder"
                        ? (x.platform && x.platform.remoteId !== null && (x.platform.remoteId === "2" || x.platform.remoteId === "5" || x.platform.remoteId === "6") ? "Platform" :"SmartPhone")
                            : "Normal") as ProductSellSource,
                    type: (y.storeOrderMenuOrderId
                        ? "Menu" 
                        : (priceNames[y.price.priceNameId].type === "Offer"
                            ? "Offer"
                            : "Normal")) as ProductSellType,
                    amountExternalPWoTaxes : storeType == "Ximiti" ? undefined : y.unitPriceWoTaxes,
                    appliedTaxes : storeType == "Ximiti" ? undefined : y.appliedTaxes
                })))
            .reduce((a, b) => a.concat(b), []), x => `${x.productId}|${x.vatId}|${x.sellPrice}|${x.buyPrice}`), (xs, key) => ({
                productId: xs[0].productId,
                vatId: xs[0].vatId,
                turnover: _.sum(xs.map(x => x.sellPrice * x.quantityDelivered)),
                sellPrice: xs[0].sellPrice,
                buyPrice: xs[0].buyPrice,
                amountExternalPWoTaxes: _.sum(xs.map(x => x.amountExternalPWoTaxes * x.quantityDelivered)),
                appliedTaxes: xs[0].appliedTaxes,
                quantityMenu: {
                    byCode: _.sum(xs.filter(x => x.type === "Menu" && x.source === "Code").map(x => x.quantityDelivered)),
                    byPhone: _.sum(xs.filter(x => x.type === "Menu" && x.source === "SmartPhone").map(x => x.quantityDelivered)),
                    normal: _.sum(xs.filter(x => x.type === "Menu" && x.source === "Normal").map(x => x.quantityDelivered)),
                    byPlatform: _.sum(xs.filter(x => x.type === "Menu" && x.source === "Platform").map(x => x.quantityDelivered)),
                },
                quantityOffer: {
                    byCode: _.sum(xs.filter(x => x.type === "Offer" && x.source === "Code").map(x => x.quantityDelivered)),
                    byPhone: _.sum(xs.filter(x => x.type === "Offer" && x.source === "SmartPhone").map(x => x.quantityDelivered)),
                    normal: _.sum(xs.filter(x => x.type === "Offer" && x.source === "Normal").map(x => x.quantityDelivered)),
                    byPlatform: _.sum(xs.filter(x => x.type === "Offer" && x.source === "Platform").map(x => x.quantityDelivered)),
                },
                quantityNormal: {
                    byCode: _.sum(xs.filter(x => x.type === "Normal" && x.source === "Code").map(x => x.quantityDelivered)),
                    byPhone: _.sum(xs.filter(x => x.type === "Normal" && x.source === "SmartPhone").map(x => x.quantityDelivered)),
                    normal: _.sum(xs.filter(x => x.type === "Normal" && x.source === "Normal").map(x => x.quantityDelivered)),
                    byPlatform: _.sum(xs.filter(x => x.type === "Normal" && x.source === "Platform").map(x => x.quantityDelivered)),
                }
            } as ProductSellData))
            .filter(x => 
                salesFilter.showUnsold || (x.quantityNormal.normal
                + x.quantityNormal.byCode
                + x.quantityNormal.byPhone
                + x.quantityNormal.byPlatform
                + x.quantityOffer.normal
                + x.quantityOffer.byCode
                + x.quantityOffer.byPhone
                + x.quantityOffer.byPlatform
                + x.quantityMenu.normal
                + x.quantityMenu.byCode
                + x.quantityMenu.byPhone
                + x.quantityMenu.byPlatform) > 0
            );
        
        if(salesFilter.showUnsold 
            && stockStatus
            && stockStatus.planograms.length !== 0) {
            let soldProductId = _.keyBy(sellData, x => x.productId);
            let planoId = _.sortBy(stockStatus.planograms, x => !x.active)[0].planogramId;
            let emptyQuantity = {
                byCode: 0,
                byPhone: 0,
                normal: 0
            };
            sellData = sellData.concat(stockStatus.productRails
                .filter(x => x.planogramId === planoId
                    && !soldProductId[x.productId])
                .map(x => ({
                    productId: x.productId,
                    buyPrice: storeType == "Ximiti" ? products[x.productId].productSuppliers.length !== 0
                            ? getNoVatUnitBuyPrice(
                                _.sortBy(
                                    products[x.productId].productSuppliers
                                    , x => x.supplierMainId !== products[x.productId].defaultSupplierId)[0])
                            : undefined
                        : undefined,
                    sellPrice: undefined,
                    quantityMenu: emptyQuantity,
                    quantityNormal: emptyQuantity,
                    quantityOffer: emptyQuantity,
                    vatId: undefined,
                    turnover: 0
                } as ProductSellData)))
        }
        
        return sellData;
    });

export const productStockDatas = createSelector(
    getSelectedDayStoreData,
    getProducts,
    getHistoryData,
    getStockStatus,
    (dayStoreData, products, historyData, stockStatus): Array<ProductStockData> => {
        if (!historyData 
            || !historyData.dayStoreDatas
            || !historyData.dayStoreDatas[historyData.selectedIndex]
            || !historyData.dayStoreDatas[historyData.selectedIndex].storeDayDataSnapshot
            || !historyData.dayStoreDatas[historyData.selectedIndex].storeDayDataSnapshot.dayProductStocks) {
            return [];
        }
        
        let selectedDayData = historyData.dayStoreDatas[historyData.selectedIndex];

        let presentPlanogramProds =  selectedDayData.storeDayDataSnapshot.dayProductReceptions
        .filter(x => stockStatus.productRails.some(pr => pr.planogramId === _.sortBy(stockStatus.planograms, x => !x.active)[0].planogramId && x.productId === pr.productId));
   
        return _.uniq(selectedDayData.storeDayDataSnapshot.dayProductStocks            
            .concat(presentPlanogramProds)
            //.filter(p => (!state.stock || !state.stock.stockStatus) || state.stock.stockStatus.productRails.some(pr => pr.planogramId === _.sortBy(state.stock.stockStatus.planograms, x => !x.active)[0].planogramId && p.productId === pr.productId))
            .map(x => x.productId))
            .map(x => ({
                productId: x,
                stock: selectedDayData.storeDayDataSnapshot.dayProductStocks
                    .find(y => y.productId === x) != undefined ? 
                        selectedDayData.storeDayDataSnapshot.dayProductStocks.find(y => y.productId === x).quantity : 0,
                storedStock: _.sum(selectedDayData.storeDayDataSnapshot.dayProductReceptions
                    .filter(y => y.productId === x).map(y => y.quantityRemaining))
            } as ProductStockData))
    });

export const totalStocksDatas = createSelector(
    getSelectedDayStoreData,
    getStoreType,
    (dayStoreData, storeType): Array<ProductStockData> => {
        if (!dayStoreData
            || !dayStoreData.storeDayDataSnapshot
            || !dayStoreData.storeDayDataSnapshot.dayProductStocks) {
            return [];
        }

        if(storeType == "Ximiti"){
            return _.uniq(dayStoreData.storeDayDataSnapshot.dayProductStocks            
                .concat(dayStoreData.storeDayDataSnapshot.dayProductReceptions)
                .map(x => x.productId))
                .map(x => ({
                    productId: x,
                    stock: dayStoreData.storeDayDataSnapshot.dayProductStocks
                        .filter(y => y.productId === x).length,
                    storedStock: _.sum(dayStoreData.storeDayDataSnapshot.dayProductReceptions
                        .filter(y => y.productId === x).map(y => y.quantityRemaining))
                } as ProductStockData))
        }
        else{
            return _.uniq(dayStoreData.storeDayDataSnapshot.dayProductStocks            
                .concat(dayStoreData.storeDayDataSnapshot.dayProductReceptions)
                .map(x => x.externalProductId))
                .map(x => ({
                    productId: x,
                    stock: dayStoreData.storeDayDataSnapshot.dayProductStocks
                        .filter(y => y.externalProductId === x).length,
                    storedStock: _.sum(dayStoreData.storeDayDataSnapshot.dayProductReceptions
                        .filter(y => y.externalProductId === x).map(y => y.quantityRemaining))
                } as ProductStockData))
        }
    });

const unloadedState: HistoryState = {
    codes: [],
    getHistoryModel: {
        fromDate: Moment().startOf("month").toDate(),
        toDate: Moment().endOf("day").toDate()
    },
    historyData: {
        isLoading: false,
        isLoadingTV6: false,
        dayStoreDatas: [],
    },
    todayData: {
        needRefresh:false,
        isLoading: false,
    },
    todayMoneyStock: {
        needRefresh: false,
        isLoading: false,
        dayMoneyStocks: []
    },
    timeGroupBy: "Day",
    printReceiptState: {
        isLoading: false
    },
    printCardReceiptState: {
        isLoading: false
    },
    receiptState: {
        isOpen: false,
        card: {
            isLoading: false,
        },
        order: {
            isLoading: false,
        }
    },
    resetStoredMoneyState: {
        isLoading: false
    },
    eventsFilter: {
        /* Minor Events */
        minorEvents: true,
        minorInformatique: true,
        minorRobot: true,
        minorImprimante: true,
        minorTPN: true,
        minorTPA: true,
        /* Major Events */
        majorEvents: true,
        majorInformatique: true,
        majorRobot: true,
        majorMonetique: true,
        majorAutomate: true,
        /* Events */
        generalEvents: true,
        statusEvents: true,
        alertEvents: true,
        robotEvents: true,
        actionExpEvents: true,
        actionClientEvents: true,
        phoneEvents: true,
        TPNEvents: true,
        TPAEvents: true,
        computerEvents: true,
        exploitantEvents: true,
         /* Other filters */
        searchText: ""
    },
    turnoverFilter: {
    },
    salesFilter: {
        showUnsold: false
    },
    viewCardCollection: {
        isOpen: false,
        currentCollection: undefined,
        currentPayment: undefined
    },
    viewRemoteCollection: {
        isOpen: false,
        currentDataRemote: undefined,
        currentRemoteCollection: undefined
    }
};

export const reducer: Reducer<HistoryState> = (state: HistoryState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_DAYSTOREDATAS":
            return {
                ...state,
                historyData: {
                    ...state.historyData,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_DAYSTOREDATAS":
            if (action.payload.requestTime !== state.historyData.requestTime)
                return state;

            return {
                ...state,
                historyData: action.error
                    ? state.historyData
                    : {
                        ...state.historyData,
                        isLoading: false,
                        loadedGetHistoryModel: action.payload.getHistoryModel,
                        dayStoreDatas: action.payload.dayStoreDatas,
                        selectedIndex: 0
                    }
            };
        case "REQUEST_HISTO_CAV6":
            return {
                ...state,
                historyData: {
                    ...state.historyData,
                    isLoadingTV6: true,
                    requestTimeTV6: action.payload.requestTime
                }
            };
        case "RECEIVE_HISTO_CAV6":
            if (action.payload.requestTime !== state.historyData.requestTimeTV6)
                return state;

            return {
                ...state,
                historyData: action.error
                    ? state.historyData
                    : {
                        ...state.historyData,
                        isLoadingTV6: false,
                        turnoverV6: action.payload.turnoverV6
                    }
            };
        case "REQUEST_TODAY_DAYSTOREDATA":
            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    isLoading: true,
                    needRefresh: false,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_TODAY_DAYSTOREDATA":
            if (action.payload.requestTime !== state.todayData.requestTime)
                return state;

            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    dayStoreData: action.error
                        ? state.todayData.dayStoreData
                        : action.payload.dayStoreData
                }
            };
        case "REQUEST_TODAY_DAYMONEYSTOCK":
            return {
                ...state,
                todayMoneyStock: {
                    ...state.todayMoneyStock,
                    isLoading: true,
                    needRefresh:false,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_TODAY_DAYMONEYSTOCK":
            if (action.payload.requestTime !== state.todayMoneyStock.requestTime)
                return state;

            return {
                ...state,
                todayMoneyStock: {
                    ...state.todayMoneyStock,
                    needRefresh:false,
                    dayMoneyStocks: action.error
                        ? state.todayMoneyStock.dayMoneyStocks
                        : action.payload.dayMoneyStocks
                }
            };
        case "UPDATE_HISTORY_FROMDATA":
            return {
                ...state,
                getHistoryModel: {
                    ...state.getHistoryModel,
                    fromDate: action.payload.value
                }
            };
        case "UPDATE_HISTORY_TODATA":
            return {
                ...state,
                getHistoryModel: {
                    ...state.getHistoryModel,
                    toDate: action.payload.value
                }
            };
        case "UPDATE_HISTORY_TIMEGROUPBY":
            return {
                ...state,
                timeGroupBy: action.payload.value
            };
        case "UPDATE_HISTORY_SELECTED_INDEX":
            return {
                ...state,
                historyData: {
                    ...state.historyData,
                    selectedIndex: action.payload.value
                }
            };
        case "REQUEST_HISTORY_PRINT_RECEIPT":
            return {
                ...state,
                printReceiptState: {
                    ...state.printReceiptState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_HISTORY_PRINT_RECEIPT":
            if (action.payload.requestTime !== state.printReceiptState.requestTime)
                return state;

            return {
                ...state,
                printReceiptState: {
                    ...state.printReceiptState,
                    isLoading: false,
                }
            };
        case "REQUEST_HISTORY_PRINT_CARD_RECEIPT":
            return {
                ...state,
                printReceiptCardState: {
                    ...state.printCardReceiptState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_HISTORY_PRINT_CARD_RECEIPT":
            if (action.payload.requestTime !== state.printCardReceiptState.requestTime)
                return state;

            return {
                ...state,
                printReceiptCardState: {
                    ...state.printCardReceiptState,
                    isLoading: false,
                }
            };
        case "HISTORY_OPEN_RECEIPT":
            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    selectedPaymentId: action.payload.salePaymentId,
                    isOpen: true
                }
            };
        case "HISTORY_CLOSE_RECEIPT":
            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    isOpen: false,
                    order: {
                        ...state.receiptState.order,
                        receiptContent: ""
                    },
                    card:{
                        ...state.receiptState.card,
                        receiptContent:""
                    }
                }
            };
        case "REQUEST_CARD_RECEIPT":
            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    card:{
                        ...state.receiptState.card,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CARD_RECEIPT":
            if (action.payload.requestTime !== state.receiptState.card.requestTime)
                return state;

            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    card:{
                        ...state.receiptState.card,
                        isLoading: false,
                        receiptContent: action.error
                            ? ""
                            : action.payload.content
                    }
                }
            };
        case "REQUEST_ORDER_RECEIPT":
            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    order:{
                        ...state.receiptState.order,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ORDER_RECEIPT":
            if (action.payload.requestTime !== state.receiptState.order.requestTime)
                return state;

            return {
                ...state,
                receiptState: {
                    ...state.receiptState,
                    order:{
                        ...state.receiptState.order,
                        isLoading: false,
                        receiptContent: action.error
                            ? ""
                            : action.payload.content
                    }
                }
            };
        case "REQUEST_RESET_STOREDMONEY":
            return {
                ...state,
                resetStoredMoneyState: {
                    ...state.resetStoredMoneyState,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_RESET_STOREDMONEY":
            return {
                ...state,
                resetStoredMoneyState: {
                    ...state.resetStoredMoneyState,
                    isLoading: action.payload.requestTime === action.payload.requestTime
                        ? false
                        : state.resetStoredMoneyState.isLoading
                },
                todayData: {
                    ...state.todayData,
                    needRefresh:true
                }
            };
        case "RECEIVE_TODAY_DAYSTOREDATA":
            if (action.payload.requestTime !== state.todayData.requestTime)
                return state;

            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    dayStoreData: action.error
                        ? state.todayData.dayStoreData
                        : action.payload.dayStoreData
                }
            };
        case "RECEIVE_RECHARGE_HOPPER":
            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    needRefresh:true
                },
                todayMoneyStock: {
                    ...state.todayMoneyStock,
                    needRefresh:true
                }
            };
        case "RECEIVE_EMPTY_HOPPERS":
            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    needRefresh: true
                },
                todayMoneyStock: {
                    ...state.todayMoneyStock,
                    needRefresh: true
                } 
            };
        case "UPDATE_CODES":
            return {
                ...state,
                codes: action.payload.value
            };
        case "UPDATE_EVENTSFILTER":
            return {
                ...state,
                eventsFilter: action.payload.value
            };
        case "UPDATE_TURNOVERFILTER":
            return {
                ...state,
                turnoverFilter: {
                    ...state.turnoverFilter,
                    ...action.payload.value
                }
            };
        case "UPDATE_SALESFILTER":
            return {
                ...state,
                salesFilter: {
                    ...state.salesFilter,
                    ...action.payload.value
                }
            };
        case "REQUEST_TODAY_STOREORDERS":
            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    isLoading: true,
                    needRefresh: false,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_TODAY_STOREORDERS":
            if (action.payload.requestTime !== state.todayData.requestTime)
                return state;

            return {
                ...state,
                todayData: {
                    ...state.todayData,
                    dayStoreOrders: action.error
                        ? state.todayData.dayStoreOrders
                        : action.payload.todayStoreOrders
                }
            };
        case "OPEN_VIEW_CARDCOLLECTION":
            return {
                ...state,
                viewCardCollection: {
                    isOpen: true,
                    currentCollection: action.payload.collection,
                    currentPayment: action.payload.payment
                }
            };
        case "CLOSE_VIEW_CARDCOLLECTION":
            return {
                ...state,
                viewCardCollection: {
                    isOpen: false,
                    currentCollection: unloadedState.viewCardCollection.currentCollection,
                    currentPayment: unloadedState.viewCardCollection.currentPayment
                }
            };
        case "OPEN_VIEW_REMOTE_COLLECTION":
            return {
                ...state,
                viewRemoteCollection: {
                    isOpen: true,
                    currentRemoteCollection: action.payload.remoteCollection,
                    currentDataRemote: action.payload.dataRemote
                }
            };
        case "CLOSE_VIEW_REMOTE_COLLECTION":
            return {
                ...state,
                viewRemoteCollection: {
                    isOpen: false,
                    currentRemoteCollection: unloadedState.viewRemoteCollection.currentRemoteCollection,
                    currentDataRemote: unloadedState.viewRemoteCollection.currentDataRemote
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};