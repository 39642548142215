import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { getPriceFromMenu } from '../store/History';

interface HistoryTurnoverProps {
    isLoading: boolean;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    seed: Api.SeedMngModel;
    timeGroupBy: HistoryStore.TimeGroupBy;
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
}

type TurnoverColumn = ReactTable.Column
    & {
        accessor: (d: HistoryStore.GroupedStoreData) => any;
        style?: React.CSSProperties;
        columns?: Array<TurnoverColumn>
    };

const numberMinWidth: number = 39;
const numberMaxWidth: number = 50;
const textWidth: number = 48;

const columnTTCStyle: React.CSSProperties = { backgroundColor: 'rgba(144, 238, 144, 0.4)' }
const footerTTCStyle: React.CSSProperties = { backgroundColor: 'lightgreen' }

export default class HistoryTurnover extends React.Component<HistoryTurnoverProps, {}> {

    get usedVats(): Array<Api.VatModel> {
        return _.sortBy(_.values(this.props.seed.vats)
            .filter(x => this.props.groupedStoreDatas 
                && this.props.groupedStoreDatas.length !== 0
                && this.props.groupedStoreDatas.some(y => y.vatAmounts.some(z => z.vatId === x.vatId && z.amount !== 0))));
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    calculateTotalMargin(storeOrders: Api.StoreOrderModel[]) : number {
        return _.sum(storeOrders.map(so => _.sum(so.storeOrderProductDeliveries.filter(pd => pd.status === "Ok" || pd.status === "Partial")
            .map(pd => {
                if(this.props.seed.storeAppSettings.typeStore == "Ximiti"){
                    let pvht = 0;
                    let sio = so.storeOrderItemOrders.find(y => y.productId == pd.productId);
                    let pmp = sio.productPmp != undefined ? sio.productPmp : (pd.productSupplier.buyPriceNoVat / pd.productSupplier.packCondition);
                    if(sio.storeOrderMenuOrderId) {
                        pvht = getPriceFromMenu(sio, so) / (1 + (this.props.seed.vats[sio.vatId].value / 100));
                        return (pvht - pmp);
                    }

                    pvht = sio.price.value / (1 + (this.props.seed.vats[sio.vatId].value / 100));
                    return ((pvht - pmp))
                }
                else{
                    return (0);
                }
            }))));
    }

    percentageOf(a: number, b: number): number {
        return (((a - b) / b ) * 100 )
    }
    
    public render() {
        return (
            <Table className="heightHisto"
                style={{margin: 0}}
                loading={this.props.isLoading}
                pageSize={Math.max(this.props.groupedStoreDatas.length, 20)}
                getTheadGroupProps={(state, rowInfo, column)=>{
                    return {
                        style: {backgroundColor: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}
                    }
                }}
                showPageSizeOptions={false}
                showPagination={false}
                showPaginationBottom={false}
                columns={[
                    {
                        Header: <span>&nbsp;</span>,
                        id: 'date',
                        columns: [
                            {
                                Header: <div>{getText( "HistoDate")}</div>,
                                id: 'label',
                                accessor: x => x.date,
                                Cell: row => <div style={{textAlign: 'left'}}>
                                    {this.props.timeGroupBy === "Day" && Moment(row.value).locale(getCurrentLang()).format("dddd DD/MM/YYYY")}
                                    {this.props.timeGroupBy === "Week" && Moment(row.value).locale(getCurrentLang()).format("YYYY") + " S" + Moment(row.value).locale(getCurrentLang()).week()}
                                    {this.props.timeGroupBy === "Month" && Moment(row.value).locale(getCurrentLang()).format("MMMM")}
                                    {this.props.timeGroupBy === "Year" && Moment(row.value).locale(getCurrentLang()).format("YYYY")}
                                </div>,
                                minWidth: 160,
                                Footer: (
                                    <span><strong>{getText( "HistoTotal")}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoNb")}</div>,
                                id: 'Nb',
                                accessor: x => x.storeOrders.length,
                                width: 60,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => x.storeOrders.length)) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                        ]
                    },
                    {
                        Header: <div>{getText( "HistoCA")}</div>,
                        id: 'total',
                        columns: [
                            {
                                Header: <div>{getText( "HistoHT")}</div>,
                                id: 'totalNoVat',
                                accessor: x => this.props.seed.storeAppSettings.typeStore == "Ximiti" ? _.sum(x.vatAmounts
                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))) 
                                    : _.sum(x.vatAmounts.map(y => y.amountExternalPWoTaxes)),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <div>
                                        <div>
                                            <span><strong>{(Math.round(_.sum(_.map(this.props.groupedStoreDatas,  x => 
                                                this.props.seed.storeAppSettings.typeStore == "Ximiti" ?_.sum(x.vatAmounts
                                                .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100))))
                                                    : _.sum(x.vatAmounts.map(y => y.amountExternalPWoTaxes))
                                            )) * 100) / 100).toFixed(2) || "0"}</strong></span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoTTC")}</div>,
                                id: 'totalVat',
                                accessor: x => _.sum(x.vatAmounts
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                <div>
                                    <div style={footerTTCStyle}>
                                            <span><strong>{(Math.round(_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                                .map(y => y.amount)))) * 100) / 100).toFixed(2) || "0"}</strong></span>
                                    </div>
                                </div>
                                )
                            },
                            {
                                Header: <div>{getText("HistoMargin")}</div>,
                                id: 'totalMargin',
                                accessor: x => x,
                                Cell: (row) => {
                                    let aux = this.calculateTotalMargin(row.value.storeOrders);        
                                    let tAmounts = _.sum(row.value.vatAmounts
                                        .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100))));                            
                                    let p = tAmounts ? ((aux / tAmounts) * 100) : 0;
                                    return <div>{(p).toFixed(2)}</div>
                                },
                                Footer: (
                                    <span><strong>{this.props.groupedStoreDatas.length != 0
                                        ? (((_.sum(this.props.groupedStoreDatas
                                                .map(x => x.storeOrders)
                                                .map(x => this.calculateTotalMargin(x))) || 0) / (((_.sum(_.map(this.props.groupedStoreDatas,  x => _.sum(x.vatAmounts
                                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))))) * 100)) /100)) * 100).toFixed(2)
                                        : "0.00"} </strong></span>
                                )
                            }
                        ] as Array<TurnoverColumn>
                    },
                    {
                        Header: <div>{getText( "HistoCashPayments")}</div>,
                        id: 'cash',
                        columns: this.usedVats.map(x => ({
                            id: `cash-${x.vatId}`,
                            Header: <div>{this.props.seed.vats[x.vatId].name}</div>,
                            accessor: y => _.sum(y.vatAmounts
                                .filter(z => z.paymentType === "Cash" && z.vatId === x.vatId)
                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))),
                            Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                            Footer: (
                                <div>
                                    <div>
                                            <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, y => _.sum(y.vatAmounts
                                                .filter(z => z.paymentType === "Cash" && z.vatId === x.vatId)
                                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))))).toFixed(2)}</strong></span>
                                    </div>
                                </div>
                                
                            )
                        } as TurnoverColumn)).concat([
                            {
                                id: 'totalCashNoVat',
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Cash")
                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                        .filter(y => y.paymentType === "Cash")
                                        .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))))).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                id: 'totalCashVat',
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Cash")
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                            .filter(y => y.paymentType === "Cash")
                                            .map(y => y.amount)))).toFixed(2) || "0"}</strong></span>
                                    </div>
                                )
                            }
                        ] as Array<TurnoverColumn>) as Array<TurnoverColumn>
                    },
                    {
                        Header: <div>{getText( "HistoCreditCardPayments")}</div>,
                        id: 'card',
                        columns: this.usedVats.map(x => ({
                            id: `card-${x.vatId}`,
                            Header: <div>{this.props.seed.vats[x.vatId].name}</div>,
                            accessor: y => _.sum(y.vatAmounts
                                .filter(z => z.paymentType === "Card"
                                    && z.cardType === "Normal"
                                    && z.vatId === x.vatId)
                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))),
                            Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                            Footer: (
                                <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, y => _.sum(y.vatAmounts
                                    .filter(z => z.paymentType === "Card"
                                        && z.cardType === "Normal"
                                        && z.vatId === x.vatId)
                                    .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))))).toFixed(2)}</strong></span>
                            )
                        } as TurnoverColumn)).concat([
                            {
                                id: 'totalCardNoVat',
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Card" && y.cardType === "Normal")
                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                        .filter(y => y.paymentType === "Card" && y.cardType === "Normal")
                                        .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))))).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                id: 'totalCardVat',
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Card" && y.cardType === "Normal")
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                            .filter(y => y.paymentType === "Card" && y.cardType === "Normal")
                                            .map(y => y.amount)))).toFixed(2) || "0"}</strong></span>
                                    </div>
                                )
                            }
                        ] as Array<TurnoverColumn>) as Array<TurnoverColumn>
                    },
                    {
                        Header: <div>{getText( "HistoNFCPayments")}</div>,
                        id: 'noContact',
                        columns: this.usedVats.map(x => ({
                            id: `noContact-${x.vatId}`,
                            Header: <div>{this.props.seed.vats[x.vatId].name}</div>,
                            accessor: y => _.sum(y.vatAmounts
                                .filter(z => z.paymentType === "Card"
                                    && z.cardType === "NoContact"
                                    && z.vatId === x.vatId)
                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))),
                            Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                            Footer: (
                                <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, y => _.sum(y.vatAmounts
                                        .filter(z => z.paymentType === "Card"
                                            && z.cardType === "NoContact"
                                            && z.vatId === x.vatId)
                                        .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))))).toFixed(2)}</strong></span>
                            )
                        } as TurnoverColumn)).concat([
                            {
                                id: 'totalNoContactNoVat',
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Card" && y.cardType === "NoContact")
                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                        .filter(y => y.paymentType === "Card" && y.cardType === "NoContact")
                                        .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))))).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                id: 'totalNoContactVat',
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "Card" && y.cardType === "NoContact")
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                            .filter(y => y.paymentType === "Card" && y.cardType === "NoContact")
                                            .map(y => y.amount)))).toFixed(2) || "0"}</strong></span>
                                    </div>
                                )
                            }
                        ] as Array<TurnoverColumn>) as Array<TurnoverColumn>
                    },
                    {
                        Header: <div>{getText( "HistoSmartphonePayments")}</div>,
                        id: 'sm',
                        columns: this.usedVats.map(x => ({
                            id: `sm-${x.vatId}`,
                            Header: <div>{this.props.seed.vats[x.vatId].name}</div>,
                            accessor: y => _.sum(y.vatAmounts
                                .filter(z => z.paymentType === "SmartPhone" && (z.smpPlatform == null ||
                                    (z.smpPlatform !== null && z.smpPlatform.remoteId === "4"))
                                    && z.vatId === x.vatId)
                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))),
                            Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                            //minWidth: numberMinWidth,
                            //maxWidth: numberMaxWidth,
                            Footer: (
                                <span><strong>{
                                    _.sum(_.map(this.props.groupedStoreDatas, y => _.sum(y.vatAmounts
                                        .filter(z => z.paymentType === "SmartPhone" && (z.smpPlatform == null ||
                                            (z.smpPlatform !== null && z.smpPlatform.remoteId === "4"))
                                            && z.vatId === x.vatId)
                                        .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))))).toFixed(2)}</strong></span>
                            )
                        } as TurnoverColumn)).concat([
                            {
                                id: 'totalSmNoVat',
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "SmartPhone" && (y.smpPlatform == null
                                        || (y.smpPlatform !== null && y.smpPlatform.remoteId === "4")))
                                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                //width: textWidth,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                        .filter(y => y.paymentType === "SmartPhone" && (y.smpPlatform == null
                                        ||  (y.smpPlatform !== null && y.smpPlatform.remoteId === "4")))
                                        .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)))))).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                id: 'totalSmVat',
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "SmartPhone" && (y.smpPlatform == null
                                        || y.smpPlatform !== null && y.smpPlatform.remoteId === "4"))
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                //width: 55,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                            .filter(y => y.paymentType === "SmartPhone" && (y.smpPlatform == null
                                                || (y.smpPlatform !== null && y.smpPlatform.remoteId === "4")))
                                            .map(y => y.amount)))).toFixed(2) || "0"}</strong></span>
                                    </div>
                                )
                            }
                        ] as Array<TurnoverColumn>) as Array<TurnoverColumn>
                    },
                    {
                        Header: <div>{getText( "HistoSmartphoneCePayments")}</div>,
                        id: 'smce',
                        columns: this.usedVats.map(x => ({
                            id: `smce-${x.vatId}`,
                            Header: <div>{this.props.seed.vats[x.vatId].name}</div>,
                            accessor: y => this.props.seed.storeAppSettings.typeStore == "Ximiti" ? _.sum(y.vatAmounts
                                .filter(z => z.paymentType === "SmartPhone" && z.smpPlatform !== null && z.smpPlatform.remoteId !== "4"
                                    && z.vatId === x.vatId)
                                .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))) : 0,
                            Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                            //minWidth: numberMinWidth,
                            //maxWidth: numberMaxWidth,
                            Footer: (
                                <span><strong>{
                                    _.sum(_.map(this.props.groupedStoreDatas, y => this.props.seed.storeAppSettings.typeStore == "Ximiti" ? _.sum(y.vatAmounts
                                        .filter(z => z.paymentType === "SmartPhone" && z.smpPlatform !== null && z.smpPlatform.remoteId !== "4"
                                            && z.vatId === x.vatId)
                                        .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100)))) : 0)).toFixed(2)}</strong></span>
                            )
                        } as TurnoverColumn)).concat([
                            {
                                id: 'totalSmCeNoVat',
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "SmartPhone" && y.smpPlatform !== null && y.smpPlatform.remoteId !== "4")
                                    .map(y => this.props.seed.storeAppSettings.typeStore == "Ximiti" ? y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100))
                                        : y.amountExternalPWoTaxes)),
                                Cell: row => <div>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                //width: textWidth,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                        .filter(y => y.paymentType === "SmartPhone" && y.smpPlatform !== null && y.smpPlatform.remoteId !== "4")
                                        .map(y => this.props.seed.storeAppSettings.typeStore == "Ximiti" ? 
                                            y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100)) : y.amountExternalPWoTaxes)
                                        ))).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                id: 'totalSmCeVat',
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                accessor: x => _.sum(x.vatAmounts
                                    .filter(y => y.paymentType === "SmartPhone" && y.smpPlatform !== null && y.smpPlatform.remoteId !== "4")
                                    .map(y => y.amount)),
                                Cell: row => <div style={columnTTCStyle}>{(Math.round(row.value * 100) / 100).toFixed(2)}</div>,
                                //width: 55,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <span><strong>{_.sum(_.map(this.props.groupedStoreDatas, x => _.sum(x.vatAmounts
                                            .filter(y => y.paymentType === "SmartPhone" && y.smpPlatform !== null && y.smpPlatform.remoteId !== "4")
                                            .map(y => y.amount)))).toFixed(2) || "0"}</strong></span>
                                    </div>
                                )
                            }
                        ] as Array<TurnoverColumn>) as Array<TurnoverColumn>
                    }
                ] as Array<TurnoverColumn>}
                data={this.props.groupedStoreDatas ? this.props.groupedStoreDatas : []} />
        );
    }
}