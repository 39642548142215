import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { createSelector } from 'reselect';

export interface ReorderState {
    isLoading: boolean;
    requestTime?: number;
    reorderDayDatas: Array<Api.DayReorderDataModel>;
    productsData: { [storeItemId: number]: ProductData },
    filter: ReorderTableFilter;
}

export interface ProductData {
    storeItemId: number
    daysToProject: number;
    toOrder: number;
}

export interface ReorderTableFilter {
    nbDays: number;
    watendNbDays: number;
    supplierMainId: number;
    coef: number;
    reference: string;
    propositionOnly: boolean;
}

interface ReorderUpdateCoef {
    type: "REORDER_UPDATE_COEF";
    payload: { value: number }
}
interface ReorderUpdateSupplierMainId {
    type: "REORDER_UPDATE_SUPPLIERMAINID";
    payload: { value: number }
}
interface ReorderUpdateReference {
    type: "REORDER_UPDATE_REFERENCE";
    payload: { value: string }
}
interface ReorderUpdatePropositionOnly {
    type: "REORDER_UPDATE_PROPOSITIONONLY";
    payload: { value: boolean }
}

interface ReorderRequestData {
    type: "REORDER_REQUEST_DATA";
    payload: { requestTime: number }
}
interface ReorderReceiveData {
    type: "REORDER_RECEIVE_DATA";
    payload: {
        requestTime: number;
        reorderDayDatas?: Array<Api.DayReorderDataModel>;
    },
    error?: any
}

interface ReorderUpdateFilterNbDays {
    type: "REORDER_UPDATE_FILTER_NBDAYS";
    payload: { value: number }
}

interface ReorderUpdateToOrder {
    type: "REORDER_UPDATE_TOORDER";
    payload: { storeItemId: number; value: number , daysValue: number}
}
interface ReorderUpdateWantedNbDays {
    type: "REORDER_UPDATE_WANTED_NBDAYS";
    payload: { value: number }
}

interface RequestUpdateDaysToProject {
    type: "REQUEST_UPDATE_DAYSTOPROJECT";
    payload: { requestTime: number; };
}

interface ReceiveUpdateDaysToProject {
    type: "RECEIVE_UPDATE_DAYSTOPROJECT";
    payload: { value: Api.UpdateDaysToProjectModel; requestTime: number; };
    error?: any;
}

type KnownAction = ReorderRequestData
    | ReorderReceiveData
    | ReorderUpdateFilterNbDays
    | ReorderUpdateToOrder
    | ReorderUpdateCoef
    | ReorderUpdatePropositionOnly
    | ReorderUpdateReference
    | ReorderUpdateSupplierMainId
    | ReorderUpdateWantedNbDays
    | RequestUpdateDaysToProject
    | ReceiveUpdateDaysToProject
    ;

export interface ReorderData {
    storeItemId: number;
    currentStock: number;
    currentStockLocker: number;
    dayProductData: Array<DayProductData>;
}

interface DayProductData {
    dayDate: Date;
    sellCount: number;
    lostCount: number;
    receptionCount: number;
}

const dayReorderDatasSelector = (state: ApplicationState) => state.reorder.reorderDayDatas;
const stockStatusSelector = (state: ApplicationState) => state.stock.stockStatus;
const productsSelector = (state: ApplicationState) => state.seed.seed.products;
const externalProductsSelector = (state: ApplicationState) => state.seed.seed.externalProducts;
const productsDataSelector = (state: ApplicationState) => state.reorder.productsData;
const reorderFilterSelector = (state: ApplicationState) => state.reorder.filter;
const supplierOrdersSelector = (state: ApplicationState) => state.supplierOrder.supplierOrders;
const storeTypeSelector = (state: ApplicationState) => state.seed.seed.storeAppSettings.typeStore;


export const reorderDataSelector = createSelector(dayReorderDatasSelector, stockStatusSelector, productsSelector, reorderFilterSelector,
    externalProductsSelector, storeTypeSelector,
    (dayResorderDatas, stockStatus, products, filter, externalProducts, storeType) => {

    if(stockStatus == undefined){
        return [];
    }
        
    let planogramId = stockStatus.planograms
            .find(x => x.active).planogramId;

    let productRails=stockStatus.productRails
                .filter(x => x.planogramId === planogramId)
    
    if(storeType == "Ximiti"){
        var productIds = _.uniq(productRails
            .map(x => x.productId)
            .filter(x => x
                && (!filter.supplierMainId || (filter.supplierMainId && getProductSupplier(products[x].storeItemId,products).supplierMainId === filter.supplierMainId))
                && ((!filter.reference || (filter.reference && getProductSupplier(products[x].storeItemId,products).reference === filter.reference))))
            );
                /*.some(y => (!filter.supplierMainId || y.supplierMainId === filter.supplierMainId)
                    && (!filter.reference || y.reference === filter.reference))));*/

        return productIds.map(x => {
            return {
                storeItemId: x,
                currentStock: _.sum(productRails
                    .filter(y => y.productId == x)
                    .map(x => x.productStocks.length))
                    + _.sum(stockStatus
                        .productReceptions
                        .filter(y => y.productId === x)
                        .map(y => y.quantityRemaining)),//Does this need to sum the packets not received?
                dayProductData: (dayResorderDatas || [])
                    .map(y => ({
                        dayDate: y.dayDate,
                        sellCount: _.sum(y.storeOrders
                            .map(z => z.storeOrderItemOrders)
                            .reduce((a, b) => a.concat(b), [])
                            .filter(z => z.productId === x)
                            .map(z => z.quantity)),
                        lostCount: y.storeStockOperations
                            .filter(z => z.productId === x && z.type === "ManualMarkdown")
                            .length,
                        receptionCount: _.sum(y.storeProductReceptions
                            .filter(z => z.productId === x)
                            .map(z => z.quantity))
                    } as DayProductData))
            } as ReorderData;
        });
    }
    else{
        var productIds = _.uniq(productRails
            .map(x => x.externalProductId)
            .filter(x => x
                && (!filter.supplierMainId || (filter.supplierMainId && getExternalProductSupplier(externalProducts[x].storeItemId,externalProducts).supplierMainId === filter.supplierMainId))
                && ((!filter.reference || (filter.reference && getProductSupplier(externalProducts[x].storeItemId,externalProducts).reference === filter.reference))))
            );

        var productIdLockers = _.uniq(stockStatus.productLockers
            .map(x => x.externalProductId)
            .filter(x => x
                && (!filter.supplierMainId || (filter.supplierMainId && getExternalProductSupplier(externalProducts[x].storeItemId,externalProducts).supplierMainId === filter.supplierMainId))
                && ((!filter.reference || (filter.reference && getProductSupplier(externalProducts[x].storeItemId,externalProducts).reference === filter.reference))))
            );
        
        
        return productIds.concat(productIdLockers).map(x => {
            return {
                storeItemId: x,
                currentStock: _.sum(productRails
                    .filter(y => y.externalProductId == x)
                    .map(x => x.productStocks.length))
                    + _.sum(stockStatus
                        .productReceptions
                        .filter(y => y.externalProductId === x)
                        .map(y => y.quantityRemaining)),//Does this need to sum the packets not received?
                currentStockLocker: _.sum(stockStatus.productLockers
                    .filter(y => y.externalProductId == x)
                    .map(x => x.productStocks.length))
                    + _.sum(stockStatus
                        .productReceptions
                        .filter(y => y.externalProductId === x)
                        .map(y => y.quantityRemaining)),
                dayProductData: (dayResorderDatas || [])
                    .map(y => ({
                        dayDate: y.dayDate,
                        sellCount: _.sum(y.storeOrders
                            .map(z => z.storeOrderItemOrders)
                            .reduce((a, b) => a.concat(b), [])
                            .filter(z => z.externalProductId === x)
                            .map(z => z.quantity)),
                        lostCount: y.storeStockOperations
                            .filter(z => z.productId === x && z.type === "ManualMarkdown")
                            .length,
                        receptionCount: _.sum(y.storeProductReceptions
                            .filter(z => z.productId === x)
                            .map(z => z.quantity))
                    } as DayProductData))
            } as ReorderData;
        });
    }
});

export const productsDataCompletedSelector = createSelector(
    productsSelector,
    productsDataSelector,
    reorderDataSelector,
    reorderFilterSelector,
    supplierOrdersSelector,
    (products, productsData, reorderData, filter, supplierOrders) => {
        return _.filter(_.mapValues(_.keyBy(reorderData, x => x.storeItemId),
            (x) => ({
                storeItemId: x.storeItemId,
                daysToProject: getDaysToProject(x.storeItemId, productsData,products),
                toOrder: getToOrder(x, filter, supplierOrders, productsData, products),
                ...productsData[x.storeItemId]
            } as ProductData)), x => !filter.propositionOnly || x.toOrder > 0);
});

export const getDaysToProject = (storeItemId: number, productsData: { [storeItemId: number]: ProductData },
    products: { [id: number]: Api.ProductModel }, watendNbDays?): number => {    
    let productStockDaysToProject = products[storeItemId].stockDaysProjection && 
        products[storeItemId].stockDaysProjection > 0 ? products[storeItemId].stockDaysProjection : undefined; 
    let defValue = watendNbDays ? watendNbDays : 15;    
    return productsData[storeItemId] ? 
            productsData[storeItemId].daysToProject :
                productStockDaysToProject ? 
                productStockDaysToProject : defValue;
};

export const getWantedStock = (d: ReorderData,
    filter,
    nbDays: number,
    productsData: { [storeItemId: number]: ProductData },
    products: { [id: number]: Api.ProductModel }): number => {
    let coef = !isNaN(filter.coef) ? ((filter.coef/100) + 1) : 1;
    let auxVal = d.dayProductData.length - nbDays;
    return Math.round((_.sum(d.dayProductData
        .filter((x, xi) => xi >= auxVal)
        .map(x => x.sellCount)) / nbDays)
        * getDaysToProject(d.storeItemId, productsData, products) * coef);
};

export const getProductSupplier = (storeItemId: number,
    products: { [id: number]: Api.ProductModel }): Api.ProductSupplierModel => {
    return _.sortBy(products[storeItemId]
        .productSuppliers, x => (x.enabled ? 0 : 2)
            + (x.supplierMainId === products[storeItemId].defaultSupplierId ? 0 : 1))[0];
}

export const getExternalProductSupplier = (storeItemId: number,
    products: { [id: number]: Api.ExternalProductModel }): Api.ProductSupplierModel => {
    return _.sortBy(products[storeItemId]
        .productSuppliers, x => (x.enabled ? 0 : 2))[0];
}

export const getToOrder = (d: ReorderData,
    filter,
    supplierOrders: Array<Api.SupplierOrderModel>,
    productsData: { [storeItemId: number]: ProductData },
    products: { [id: number]: Api.ProductModel }): number => {
    return Math.max(0, Math.ceil(
        (getWantedStock(d,
            filter,
            filter.nbDays,
            productsData,
            products) - d.currentStock)
        / getProductSupplier(d.storeItemId, products).packCondition)
        - _.sum(supplierOrders.map(x => x.supplierOrderProductSuppliers)
            .reduce((a, b) => a.concat(b), [])
            .filter(x => x.productId === d.storeItemId).map(x => x.packQuantity)));
};

export const actionCreators = {
    requestReorderData: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReorderApi();
        let fetchTask = api.getReorderData({
            model: {
                fromDate: Moment().add(-90, "days").startOf("day").toDate(),
                toDate: Moment().endOf("day").toDate()
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) }).then(reorderDatas => {
            dispatch({
                type: "REORDER_RECEIVE_DATA",
                payload: { requestTime: requestTime, reorderDayDatas: reorderDatas }
            });
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: "REORDER_RECEIVE_DATA",
                payload: { requestTime: requestTime },
                error: err
            });
        });

        dispatch({
            type: "REORDER_REQUEST_DATA",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    updateFilterNbDays: (value: number) => <ReorderUpdateFilterNbDays>{
        type: "REORDER_UPDATE_FILTER_NBDAYS",
        payload: {
            value: value,
        }
    },
    updateToOrder: (storeItemId: number, value: number, daysValue: number) => <ReorderUpdateToOrder>{
        type: "REORDER_UPDATE_TOORDER",
        payload: {
            storeItemId: storeItemId,
            value: value,
            daysValue: daysValue
        }
    },
    updateCoef: (value: number) => <ReorderUpdateCoef>{
        type: "REORDER_UPDATE_COEF",
        payload: {
            value: value,
        }
    },
    updateFilterReference: (value: string) => <ReorderUpdateReference>{
        type: "REORDER_UPDATE_REFERENCE",
        payload: {
            value: value,
        }
    },
    updatePropositionOnly: (value: boolean) => <ReorderUpdatePropositionOnly>{
        type: "REORDER_UPDATE_PROPOSITIONONLY",
        payload: {
            value: value,
        }
    },
    updateFilterSupplierMainId: (value: number) => <ReorderUpdateSupplierMainId>{
        type: "REORDER_UPDATE_SUPPLIERMAINID",
        payload: {
            value: value,
        }
    },
    updateWantedNbDays: (value: number) => <ReorderUpdateWantedNbDays>{
        type: "REORDER_UPDATE_WANTED_NBDAYS",
        payload: {
            value: value,
        }
    },
    requesUpdateDaysToProject: (value: Api.UpdateDaysToProjectModel, requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReorderApi();
        let fetchTask = api.updateDaysToProject({
            model: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_DAYSTOPROJECT",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_DAYSTOPROJECT",
                    payload: {
                        requestTime: requestTime,
                        value: undefined
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_DAYSTOPROJECT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
};

const unloadedState: ReorderState = {
    isLoading: false,
    reorderDayDatas: [],
    filter: {
        nbDays: 21,
        coef: 5,
        propositionOnly: false,
        watendNbDays: 15,
        supplierMainId: undefined,
        reference: undefined
    },
    productsData: {},
};

export const reducer: Reducer<ReorderState> = (state: ReorderState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REORDER_REQUEST_DATA":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "REORDER_RECEIVE_DATA":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                reorderDayDatas: action.payload.reorderDayDatas,
            };
        case "REORDER_UPDATE_FILTER_NBDAYS":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    nbDays: action.payload.value
                }
            };
        case "REORDER_UPDATE_TOORDER":
            return {
                ...state,
                productsData: {
                    ...state.productsData,
                    [action.payload.storeItemId]: {
                        ...state.productsData[action.payload.storeItemId],
                        toOrder: action.payload.value,
                        daysToProject: action.payload.daysValue
                    }
                }
            };
        case "REORDER_UPDATE_COEF":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    coef: action.payload.value
                }
            };
        case "REORDER_UPDATE_REFERENCE":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    reference: action.payload.value
                }
            };
        case "REORDER_UPDATE_SUPPLIERMAINID":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    supplierMainId: action.payload.value
                }
            };
        case "REORDER_UPDATE_PROPOSITIONONLY":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    propositionOnly: action.payload.value
                }
            };
        case "REORDER_UPDATE_WANTED_NBDAYS":
            return {
                ...state,
                filter: {
                    ...state.filter,
                    watendNbDays: action.payload.value
                }
            }
        case "REQUEST_UPDATE_DAYSTOPROJECT":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_UPDATE_DAYSTOPROJECT":
            return {
                ...state,
                productsData: {
                    ...state.productsData,
                    [action.payload.value.productId]: action.error ? {...state.productsData[action.payload.value.productId]} :
                    {
                        ...state.productsData[action.payload.value.productId],
                        daysToProject: action.payload.value.daysToProject
                    }
                },
                isLoading: false,
                requestTime: action.error
                    ? state.requestTime
                    : action.payload.requestTime
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};