import * as React from "react";
import * as _ from 'lodash';
import * as ReactTable from 'react-table';
import * as Api from '../api/api';
import * as StockStore from "../store/Stock";
import * as TabsStore from "../store/Tabs";
import Table from "./Table";
import { connect } from "react-redux";
import ConnectedTabs from "./ConnectedTabs";
import Confirm from "react-confirm-bootstrap";
import { TabLink, TabContent } from "react-tabs-redux";
import { ApplicationState } from "../store";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { canEditSettings, canUpdate } from "../security/roles";
import { CreateLockerForm, UpdateLockerForm } from "./LockerForm";
import Select from "./Select";

type LockerProps = StockStore.StockState
    & LockerOwnProps
    & typeof StockStore.actionCreators
    & typeof TabsStore.actionCreators
    ;

interface LockerOwnProps {
    stockStatus: Api.StockStatusModel;
    currentUser: Api.CurrentUserManagerModel;  
    disabled: boolean;
}

type LockerColumn = ReactTable.Column
    & { accessor: (d: Api.LockerModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

interface LockerState {
    quantityLockersAuto: number;
    lockerTypeAuto: number;
}

class Locker extends React.Component<LockerProps, LockerState> {

    constructor(props: LockerProps) {
        super(props);
        this.state = {
            quantityLockersAuto: 1,
            lockerTypeAuto: undefined

        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    handleUpdateValQuantityAuto(value: number){
        this.setState({
            quantityLockersAuto: value
        });
    }

    handleUpdateLockerTypeAuto(value: number){
        this.setState({
            lockerTypeAuto: value
        });
    }

    public render() {
        return (
            <div>
                <div className="card" style={{...Styles.cardStyle}}>
                    <Table key={"Lockers"}
                        pageSize={Math.max(13,_.values(this.props.stockStatus ? this.props.stockStatus.lockers : []).length)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        className="shelvingTrayTable"
                        style={{ }}
                        data={_.values(this.props.stockStatus ? this.props.stockStatus.lockers : [])}
                        columns={[
                            {
                                id: "name",
                                Header: getText( "SettingsNameTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.name}</div>
                            },
                            {
                                id: "type",
                                Header: getText( "SettingsTypeTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{this.props.stockStatus.lockerTypes[d.lockerTypeId].name}</div>
                            },
                            {
                                id: "actions",
                                Header: getText( "SettingsActions"),
                                accessor: (d) => d.lockerId,
                                Cell: row => <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5
                                        }}>
                                            <i className="glyphicon glyphicon-pencil icon-dm"
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                                                    opacity: this.props.disabled ? 0.7 : 1,
                                                    margin: "11% 0", 
                                                    cursor: "pointer",
                                                    color: "#FFFFFF" }}
                                                    onClick={(e) => {
                                                        this.props.selectLocker(row.value);
                                                        this.props.selectTab("lockers", "update");
                                                        e.stopPropagation();
                                                    }} />
                                    </div>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        cursor: "pointer",
                                        borderRadius: 5
                                        }}>
                                            <Confirm
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                                    opacity: this.props.disabled ? 0.7 : 1, color: "#FFFFFF", 
                                                    marginTop: '15%',
                                                    marginRight: 1,  }}
                                                onConfirm={() => {
                                                    this.props.requestDeleteLocker(new Date().getTime(), row.value);
                                                    this.props.selectLocker(undefined);
                                                }} 
                                                body={getText("MaintenanceBodyConfirmDelete")}
                                                confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}> 
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                            </Confirm>
                                    </div>
                                </div>
                            }
                        ] as Array<LockerColumn>} />
                </div>
                <div style={{marginTop: '1%'}}>
                    <ConnectedTabs name={"lockers"}>
                        <TabLink style={{width: '50%'}} to="create">
                            <div style={tabHeadStyle}>{getText( "SettingsCreate")}</div>
                        </TabLink>
                        <TabLink style={{width: '50%'}} to="update">
                            <div style={tabHeadStyle}>{getText( "SettingsEdit")}</div>
                        </TabLink>
                        <TabContent for="create">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <div className="row justify-content-md-center" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                                            <div className="col-md-2 shelvingTrayFormCol"></div>
                                            <div className="col-md-2 shelvingTrayFormCol">
                                                <label className="control-label">{getText( "SettingsCreateAuto")}</label>            
                                            </div>
                                            <div className="col-md-2 shelvingTrayFormCol">
                                            <input className="form-control"
                                                style= {{height: 32}}
                                                disabled={this.props.disabled}
                                                type={"number"}
                                                min="1"
                                                name={"quantityLockers"}
                                                value={this.state.quantityLockersAuto}
                                                required
                                                onChange={(e) => this.handleUpdateValQuantityAuto(parseInt(e.target.value) || 1)} />
                                            </div>
                                            <div className="col-md-2 shelvingTrayFormCol">
                                                <Select options={this.props.stockStatus.lockerTypes
                                                        ? _.values(this.props.stockStatus.lockerTypes)
                                                            .map(x => ({ label: x.name, value: x.lockerTypeId }))
                                                        : []}
                                                        value={this.state.lockerTypeAuto}
                                                        onChange={(value) => this.handleUpdateLockerTypeAuto(value)}
                                                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", fontSize: 15}}
                                                />
                                            </div>
                                            <div className="col-md-3 shelvingTrayFormCol">
                                                <button className="btn btn-primary btn-sm"
                                                    style={{ width: "100%", height: 32 }}
                                                    disabled={this.props.disabled || _.values(this.props.stockStatus.lockerTypes).length == 0 || this.state.lockerTypeAuto == undefined}
                                                    onClick={(e) => this.props.requestCreateLockersAuto(new Date().getTime(), this.state.quantityLockersAuto, this.state.lockerTypeAuto)}>{getText("SettingsCreate")}
                                                </button>
                                            </div>
                                        </div>
                                        <CreateLockerForm
                                            onSubmit={(values) => this.props.requestCreateLocker(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                        <TabContent for="update">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.selectedLockerId && this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <UpdateLockerForm
                                            onSubmit={(values) => this.props.requestUpdateLocker(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                    </ConnectedTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.stock,
    stockStatus: state.stock.stockStatus,
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state)
} as LockerOwnProps), {
        ...TabsStore.actionCreators,
        ...StockStore.actionCreators,
    })(Locker as any);