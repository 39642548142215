import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import Planogram from './Planogram';
import LockerPlanogram from './LockerPlanogram';

interface ProductPlacementOwnProps {
    storeType: string;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class ProductPlacement extends React.Component<ProductPlacementOwnProps, {}> {
    public render() {
        return (
            <div>
                <ConnectedTabs name={"ProductPlacement"}>
                    <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                        <span style={{ padding: '2%'}}>
                            {getText( "PlanogramTitle")}
                        </span>
                        {this.props.storeType != "Ximiti" && 
                            <><TabLink to="planogram" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                                <div style={tabHeadStyle}>{getText("PlanogramShelvings")}</div>
                            </TabLink>
                            <TabLink to="lockerPlanogram" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                                <div style={tabHeadStyle}>{getText("PlanogramLockers")}</div>
                            </TabLink>
                        </>}
                        <HelpHeader />
                    </div>
                    {this.props.storeType != "Ximiti" ? <>
                    <TabContent for="planogram">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <Planogram />
                        </div>
                    </TabContent>
                    <TabContent for="lockerPlanogram">
                        <div style={{ margin: '9px 2%'}}>
                            <LockerPlanogram />
                        </div>
                    </TabContent></>
                    :  <Planogram />}
                </ConnectedTabs>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
   storeType: state.seed.seed.storeAppSettings.typeStore
}))(ProductPlacement as any);