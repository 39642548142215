import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getText } from '../utils/texts';

interface HistoryProductSellProps {
    storeType: string;
    isLoading: boolean;
    productSellDatas: Array<HistoryStore.ProductSellData>;
    seed: Api.SeedMngModel;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
}

type ProductSellColumn = ReactTable.Column
    & {
        accessor: (d: HistoryStore.ProductSellData) => any;
        style?: React.CSSProperties;
        columns?: Array<ProductSellColumn>
    };

const numberWidth: number = 70;
export default class HistoryProductSell extends React.Component<HistoryProductSellProps, {}> {
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getQuantityTotal(x: HistoryStore.ProductSellData): number{
        return x.quantityNormal.normal
        + x.quantityNormal.byCode
        + x.quantityNormal.byPhone
        + x.quantityNormal.byPlatform
        + x.quantityOffer.normal
        + x.quantityOffer.byCode
        + x.quantityOffer.byPhone
        + x.quantityOffer.byPlatform
        + x.quantityMenu.normal
        + x.quantityMenu.byCode
        + x.quantityMenu.byPhone
        + x.quantityMenu.byPlatform;
    }

    calculateTotalDelivered(storeOrders: Api.StoreOrder[]) : number {
        return _.sum(storeOrders.map(so => _.sum(so.storeOrderProductDeliveries.filter(pd => pd.status === "Ok" || pd.status === "Partial")
            .map(pd => {
                if (pd.productSupplier) {
                    return pd.productSupplier.buyPriceNoVat / pd.productSupplier.packCondition;
                }
                return 0;
            }))));
    }

    percentageOf(a: number, b: number): number {
       return (((a - b) / a ) * 100)
    }

    get marque(){
        return this.props.seed.storeAppSettings.typeStore == "Ximiti" &&
        this.props.groupedStoreDatas.length != 0
            ? (this.percentageOf(
                _.sum(this.props.groupedStoreDatas
                    .map(x => x.vatAmounts)
                    .reduce((a,b) => a.concat(b), [])
                    .map(y => y.amount / (1 + (this.props.seed.vats[y.vatId].value / 100))))
                , _.sum(this.props.groupedStoreDatas
                    .map(x => x.storeOrders)
                    .map(x => this.calculateTotalDelivered(x)))) || 0).toFixed(2)
            : "0.00"
    }

    public render() {
        return (
            <Table className="heightHisto"
                tableKey={"historyProductSell"}
                loading={this.props.isLoading}
                style={{ textAlign: "center" }}
                pageSize={Math.max(this.props.productSellDatas.length, 20)}
                getTheadGroupProps={(state, rowInfo, column)=>{
                    return {
                        style: {backgroundColor: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}
                    }
                }}
                showPageSizeOptions={false}
                showPagination={false}
                showPaginationBottom={false}
                columns={[
                    {
                        Header: <span>{`${getText( "HistoMarque")}: ${this.marque}`}</span>,
                        id: 'productId',
                        columns: [
                            {
                                Header: <div>{getText( "HistoWord")}</div>,
                                id: 'productId',
                                accessor: x => this.props.storeType == "Ximiti" ? this.props.seed.products[x.productId].name :
                                    this.props.seed.externalProducts[x.productId].name ,
                                minWidth: 300,
                                Footer: (
                                    <span><strong>{getText( "HistoTotal")}</strong></span>
                                )
                            },
                        ]
                    },
                    {
                        Header: <div>{getText( "HistoQty")}</div>,
                        id: 'quantity',
                        columns: [
                            {
                                Header: <div>{getText( "HistoUnit")}</div>,
                                id: 'quantityNormal',
                                accessor: x => x.quantityNormal.normal
                                    + x.quantityNormal.byCode
                                    + x.quantityNormal.byPhone
                                    + x.quantityNormal.byPlatform,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas, x => x.quantityNormal.normal
                                        + x.quantityNormal.byCode
                                        + x.quantityNormal.byPhone
                                        + x.quantityNormal.byPlatform)) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoPromo")}</div>,
                                id: 'quantityOffer',
                                accessor: x => x.quantityOffer.normal
                                    + x.quantityOffer.byCode
                                    + x.quantityOffer.byPhone,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas,  x => x.quantityOffer.normal
                                        + x.quantityOffer.byCode
                                        + x.quantityOffer.byPhone)) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoMenu")}</div>,
                                id: 'quantityMenu',
                                accessor: x => x.quantityMenu.normal
                                    + x.quantityMenu.byCode
                                    + x.quantityMenu.byPhone,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas, x => x.quantityMenu.normal
                                        + x.quantityMenu.byCode
                                        + x.quantityMenu.byPhone)) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoTotal")}</div>,
                                id: 'quantityTotal',
                                accessor: x => this.getQuantityTotal(x),
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas, x => this.getQuantityTotal(x))) || "0"}</strong></span>
                                )
                            },
                        ] as Array<ProductSellColumn>
                    },
                    {
                        Header: <div>{getText( "HistoPrice")}</div>,
                        id: 'price',
                        columns: [
                            {
                                Header: <div>{getText( "HistoPAHT")}</div>,
                                id: 'buyPrice',
                                accessor: x => x.buyPrice
                                    ? x.buyPrice.toFixed(2)
                                    : "N/A",
                                Footer: (
                                    <span style={{fontFamily: '-webkit-pictograph'}}><strong>{_.sum(_.map(this.props.productSellDatas, x => Number(x.buyPrice)
                                        ? (x.buyPrice * this.getQuantityTotal(x))
                                        : 0)).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoPVTTC")}</div>,
                                id: 'sellPrice',
                                accessor: x => x.sellPrice
                                    ? x.sellPrice.toFixed(2)
                                    : "N/A",
                                Footer: (
                                    <span style={{fontFamily: '-webkit-pictograph'}}><strong>{_.sum(_.map(this.props.productSellDatas, x => Number(x.sellPrice)
                                        ? (x.sellPrice * this.getQuantityTotal(x))
                                        : 0)).toFixed(2) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoTVA")}</div>,
                                id: 'vatId',
                                accessor: x => this.props.storeType == "Ximiti" ? 
                                    x.vatId ? this.props.seed.vats[x.vatId].name : ""
                                    : `${x.appliedTaxes * 100} %`,
                            }
                        ] as Array<ProductSellColumn>
                    },
                    {
                        Header: <div>{getText( "HistoCAHT")}</div>,
                        id: 'totalNoVat',
                        columns: [
                            {
                                Header: <div>{getText( "HistoTotal")}</div>,
                                id: 'totalNoVatTotal',
                                accessor: x => x.sellPrice 
                                    ? (this.props.storeType == "Ximiti" ? (Math.round(x.sellPrice *
                                    (x.quantityNormal.normal
                                        + x.quantityNormal.byCode
                                        + x.quantityNormal.byPhone
                                        + x.quantityNormal.byPlatform
                                        + x.quantityOffer.normal
                                        + x.quantityOffer.byCode
                                        + x.quantityOffer.byPhone
                                        + x.quantityMenu.normal
                                        + x.quantityMenu.byCode
                                        + x.quantityMenu.byPhone
                                    ) / (1 + (this.props.seed.vats[x.vatId].value / 100)) * 100) / 100).toFixed(2) : (x.amountExternalPWoTaxes / 100).toFixed(2))
                                    : (0).toFixed(2),
                                Footer: (
                                    <span><strong>{(Math.round(_.sum(_.map(this.props.productSellDatas.filter(x => x.sellPrice !== undefined), x => 
                                        this.props.storeType == "Ximiti" ?(x.sellPrice
                                        * Number(
                                            (x.quantityNormal.normal
                                                + x.quantityNormal.byCode
                                                + x.quantityNormal.byPhone
                                                + x.quantityNormal.byPlatform
                                                + x.quantityOffer.normal
                                                + x.quantityOffer.byCode
                                                + x.quantityOffer.byPhone
                                                + x.quantityMenu.normal
                                                + x.quantityMenu.byCode
                                                + x.quantityMenu.byPhone
                                            )) / (1 + (Number(this.props.seed.vats[x.vatId].value) / 100)))
                                                : (x.amountExternalPWoTaxes / 100)
                                            )) * 100) / 100).toFixed(2) || "0.00"}</strong></span>
                                )
                            }
                        ] as Array<ProductSellColumn>
                    },
                    {
                        Header: <div>{getText( "HistoCATTC")}</div>,
                        id: 'totalVat',
                        columns: [
                            {
                                Header: <div>{getText( "HistoTotal")}</div>,
                                id: 'totalVatTotal',
                                accessor: x => x.sellPrice ? (Math.round(x.sellPrice *
                                    (x.quantityNormal.normal
                                        + x.quantityNormal.byCode
                                        + x.quantityNormal.byPhone
                                        + x.quantityNormal.byPlatform
                                        + x.quantityOffer.normal
                                        + x.quantityOffer.byCode
                                        + x.quantityOffer.byPhone
                                        + x.quantityMenu.normal
                                        + x.quantityMenu.byCode
                                        + x.quantityMenu.byPhone
                                        ) * 100) / 100).toFixed(2) : (0).toFixed(2),
                                Footer: (
                                    <span><strong>{(Math.round(_.sum(_.map(this.props.productSellDatas.filter(x => x.sellPrice !== undefined), x => (x.sellPrice * Number(
                                        (
                                            x.quantityNormal.normal
                                            + x.quantityNormal.byCode
                                            + x.quantityNormal.byPhone
                                            + x.quantityNormal.byPlatform
                                            + x.quantityOffer.normal
                                            + x.quantityOffer.byCode
                                            + x.quantityOffer.byPhone
                                            + x.quantityMenu.normal
                                            + x.quantityMenu.byCode
                                            + x.quantityMenu.byPhone
                                        ))))) * 100) / 100).toFixed(2) || "0"}</strong></span>
                                )
                            }
                        ] as Array<ProductSellColumn>
                    },
                    {
                        Header: <div>{getText( "HistoChoice")}</div>,
                        id: 'source',
                        columns: [
                            {
                                Header: <div>{getText( "HistoShelv")}</div>,
                                id: 'sourceNormal',
                                accessor: x => x.quantityNormal.normal
                                    + x.quantityMenu.normal
                                    + x.quantityOffer.normal,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas,  x => x.quantityNormal.normal
                                        + x.quantityMenu.normal
                                        + x.quantityOffer.normal)) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoSmart")}</div>,
                                id: 'sourceSmartPhone',
                                accessor: x => x.quantityNormal.byPhone
                                    + x.quantityMenu.byPhone
                                    + x.quantityOffer.byPhone,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas,  x => x.quantityNormal.byPhone
                                        + x.quantityMenu.byPhone
                                        + x.quantityOffer.byPhone)) || "0"}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText("HistoCE")}</div>,
                                id: 'CE',
                                accessor: x => x.quantityNormal.byPlatform,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productSellDatas,  x => x.quantityOffer.byPlatform)) || "0"}</strong></span>
                                )
                            },
                        ] as Array<ProductSellColumn>
                    }
                ] as Array<ProductSellColumn>}

                data={this.props.storeType == "Ximiti" ? this.props.productSellDatas.sort((a, b) => this.props.seed.products[a.productId].name.localeCompare(this.props.seed.products[b.productId].name))
                    : this.props.productSellDatas.sort((a, b) => this.props.seed.externalProducts[a.productId].name.localeCompare(this.props.seed.externalProducts[b.productId].name))} />
            );
    }
}