import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Api from '../api/api';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';
import * as PlanogramStore from "../store/Planogram";
import * as StockStore from "../store/Stock";
import * as Styles from '../styles/baseStyle';
import { CSSProperties } from 'react';
import Select from "./Select";
import LockerPlanogramGrid from './LockerPlanogramGrid';
import { SubmitButton } from './SubmitButton';
import Confirm from "react-confirm-bootstrap";
import { calculateLockerHeight, calculateLockerWidth } from '../utils/utils';
import { UpdateProductLockerForm } from './ProductLockerForm';
import DialogModal from './DialogModal';

type LockerPlanogramProps = PlanogramStore.PlanogramState
    & LockerPlanogramOwnProps
    & typeof PlanogramStore.actionCreators;

interface LockerPlanogramOwnProps {
    stock: StockStore.StockState;
    disabled: boolean;
    productLocker: Api.ProductLockerModel;
    externalProducts: { [key: string]: Api.ExternalProductModel; };
}

const labelStyle: CSSProperties = {
    fontWeight: "bold",
    display: "contents",
    paddingRight: 5
};

const fieldStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

const PLANO_HEIGHT = 24;
const PLANO_WIDTH = 102;
const PLANO_SQUARE_SIZE_MM = 100;

const sensList: Array<Api.ProductLockerModelSensEnum> = [
    "Vertical", "Horizontal"
];

interface LockerPlanogramState {
    openDialogMsg: boolean;
    msgDialog: string;
}

class LockerPlanogram extends React.Component<LockerPlanogramProps, LockerPlanogramState> {

    constructor(props: LockerPlanogramProps) {
        super(props);
        this.state = {
            openDialogMsg: false,
            msgDialog: getText( "PlanogramLockerDialogMsg")
        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get startRowAnimation() {
        return 30 - (4 - 1
            + Math.ceil(calculateLockerHeight(
                this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                this.props.createProductLockerState.sens) / PLANO_SQUARE_SIZE_MM));
    }

    handleChangeLocker(lockerId: number, productLocker?: Api.ProductLockerModel){
        this.props.updateLockerId(lockerId);
        this.props.updateLockerTypeId(this.props.stock.stockStatus.lockers[lockerId].lockerTypeId);

        if(productLocker != undefined){
            this.props.updateCreateLockerSens(
                sensList[sensList.indexOf(productLocker.sens)]);
        }
        else{
            this.props.updateCreateLockerSens(sensList[0]);
        }
    }

    get lockerPreview(): PlanogramStore.LockerGrid {
        return {
            startRow: this.props.createProductLockerState.position.row,
            endRow: this.props.createProductLockerState.position.row - 1
                + Math.ceil(calculateLockerHeight(
                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                    this.props.createProductLockerState.sens) / PLANO_SQUARE_SIZE_MM),
            startCol: this.props.createProductLockerState.position.col,
            endCol: this.props.createProductLockerState.position.col - 1
                + Math.ceil(calculateLockerWidth(
                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                    this.props.createProductLockerState.sens) / PLANO_SQUARE_SIZE_MM),
        };
    }

    lockerPlanoMsgClose(){
        this.setState({
            openDialogMsg: false
        });
    }

    validationLocker(lockerId: number) {
        return _.values(this.props.stock.stockStatus.productLockers)
             .some(x => x.lockerId === lockerId)
    }

    public render() {
        if(!this.props.stock.stockStatus)
            return <div></div>;

        return ( 
        <div className="gridContainerLockerPlano">
            <div className="lockerPlanoItem1 card" style={{
                ...Styles.cardStyle,
                padding: "3px 13px",
                marginBottom: 10,
                height: "100%",
                display: "flex",
                overflowX: "auto"
            }}>
                <div>
                    <LockerPlanogramGrid 
                        planoType={"Normal"}
                        planoHeight={PLANO_HEIGHT}
                        planoWidth={PLANO_WIDTH}
                        productLockerId={this.props.productLockerId}
                        lockerGrids = {_.sortBy(this.props.stock.stockStatus.productLockers
                            .map(x => {
                                return {
                                    startRow: x.startRow,
                                    endRow: x.endRow,
                                    startCol: x.startCol,
                                    endCol: x.endCol,
                                    productLocker: x
                                };
                            }).filter(x => x), x => `${x.startRow}|${x.startCol}`)}
                        lockerPreview={this.props.createProductLockerState.isCreating
                            && this.props.createProductLockerState.lockerId
                            && this.lockerPreview}
                        externalProds = {this.props.externalProducts}
                        ipAddresses = {this.props.stock.stockStatus.lockerAddresses}
                        locks={this.props.stock.stockStatus.locks}
                        onMouseEnterRail={(row, col) => this.props.updateCreatePositionLockerPlano(row, col)}
                        onSelectCreatePosition={(preview) => this.props.requestCreateProductLocker(new Date().getTime(), preview)}
                        onSelectProductLocker={(productLocker) => this.props.updateProductLockerId(productLocker.productLockerId)} 
                        updateCreateLockerId={(id, productLocker) => this.handleChangeLocker(id, productLocker)}/>
                </div>
            </div>
            <div className="lockerPlanoItem2"> 
                <div className="card" style={{
                    ...Styles.cardStyle,
                    padding: 15,
                    marginBottom: 10,
                    height: "100%"
                }}> 
                    <div className="row">
                        <div className="col-md-6">
                            <div style={{ margin: "0px 10px" }}>
                                <div style={{minWidth: 125, ...labelStyle }}>
                                    {getText("SettingsLocker")} :
                                </div>
                                <div className="input-planogram">
                                    <Select options={this.props.stock.stockStatus
                                        ? _.values(this.props.stock.stockStatus.lockers)
                                            .map(x => ({ label: x.name, value: x.lockerId }))
                                        : []}
                                    style={{
                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                    }}
                                    value={this.props.createProductLockerState.lockerId}
                                    onChange={(lockerid) => {
                                        if(this.validationLocker(lockerid)){
                                            this.setState({
                                                openDialogMsg: true
                                            });
                                        }
                                        else{
                                            this.handleChangeLocker(lockerid)
                                        }
                                    }} />
                                </div>
                            </div>
                            {this.props.createProductLockerState.lockerId && <div style={{ marginTop: 26 }}>
                                <div style={{ display: "flex", marginTop: 5, marginBottom: 10 }}>
                                    <b> {getText( "PlanogramProductPosition")} </b>
                                    <div style={{
                                        pointerEvents: this.props.disabled ? "none" : "initial",
                                        opacity: this.props.disabled ? 0.7 : 1, display: "flex", flexDirection: "row", alignItems: "center"
                                    }}>
                                        <i style={{ paddingLeft: 5,
                                            paddingBottom: 3, display: "block", cursor: "pointer"
                                        }}
                                            onClick={(e) => {
                                                if(this.props.disabled)
                                                    return;
                                                
                                                this.props.updateCreateLockerSens(
                                                    sensList[(sensList.indexOf(this.props.createProductLockerState.sens) + 1) % sensList.length]);
                                                e.preventDefault();                                            
                                            }}
                                            className="glyphicon glyphicon-repeat"></i>
                                        <span style={{ paddingLeft: 3 }}>({getText( "PlanogramDirection")} {this.props.createProductLockerState.sens})</span>
                                        <i style={{ flex: "0 0 auto", paddingLeft: 5 }}></i>
                                    </div>                                
                                </div>
                                <div>
                                    {!this.props.createProductLockerState.isCreating && <SubmitButton
                                        style = {{ marginLeft: 0, height: 32 }}
                                        disabled={this.props.createProductLockerState.isLoading || this.props.disabled}
                                        disablingMessages={[]}
                                        onClick={() => {
                                            if(this.validationLocker(this.props.createProductLockerState.lockerId)){
                                                this.setState({
                                                    openDialogMsg: true
                                                });
                                            }
                                            else{
                                                this.props.updateCreatingLockersPlano(true);
                                            }
                                        }}>{getText( "PlanogramAddButton")}</SubmitButton>}
                                    {this.props.createProductLockerState.isCreating && <button className="btn btn-secondary"
                                        disabled={this.props.createProductLockerState.isLoading}
                                        onClick={(e) => {
                                            this.props.updateCreatingLockersPlano(false);
                                            e.preventDefault();
                                        }}
                                        style={{ height: 32 }}>{getText( "PlanogramCancel")}</button>}
                                </div>
                            </div>}
                        </div>
                        <div className="col-md-6">
                            {this.props.createProductLockerState.lockerId && <div style={{ display: "flex", marginTop: 5, marginBottom: 10 }}>
                                <div style={{
                                    height: "150px",
                                    width: "150px",
                                    display: "grid",
                                    gridTemplateRows: "repeat(30, 1fr)",
                                    gridTemplateColumns: "repeat(26, 1fr)",
                                    alignSelf: "center",
                                }}>
                                    {Array.from(Array(30).keys()).map((i) => (
                                        Array.from(Array(26).keys()).map((j) => (
                                            <div key={`${(i + 1)}${(j + 1)}`}
                                                style={{
                                                    gridRow: (i + 1) + " / " + Math.ceil(i + 2),
                                                    gridColumn: (j + 1) + " / " + Math.ceil(j + 2),
                                                    backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                    border: "1px solid rgba(60, 68, 114, 0.25)"
                                                }}>
                                            </div>
                                        ))
                                    ))}
                                    <div
                                        style={{
                                            gridRow: this.startRowAnimation + " / " +  ((this.startRowAnimation + 1) - 1
                                                + Math.ceil(calculateLockerHeight(
                                                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                                                    this.props.createProductLockerState.sens) / PLANO_SQUARE_SIZE_MM)),
                                            gridColumn: 2 + " / " + (2
                                                + Math.ceil((calculateLockerWidth(
                                                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                                                    this.props.createProductLockerState.sens)) / PLANO_SQUARE_SIZE_MM)),
                                            backgroundColor: "red",
                                            opacity: 0.65,
                                            border: "2px solid rgba(60, 68, 114, 0.15)"
                                        }}>
                                    </div>
                                    <div style={{
                                        gridRow: 27 + " / " + 29,
                                        gridColumn: 1 + " / " + 27,
                                        backgroundColor: "yellow",
                                        opacity: 0.65,
                                        border: "2px solid rgba(60, 68, 114, 0.15)"
                                    }}>
                                    </div>
                                </div>
                                <div style={{marginLeft: 10}}>
                                    <div style={{ ...fieldStyle }}>
                                        <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramHeight")} :</div>
                                        <div style={{ flex: 1, marginLeft: 5 }}>{calculateLockerHeight(
                                                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                                                    this.props.createProductLockerState.sens)}</div>
                                    </div>
                                    <div style={{ ...fieldStyle }}>
                                        <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramWidth")} :</div>
                                        <div style={{ flex: 1, marginLeft: 5 }}>{calculateLockerWidth(
                                                    this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId],
                                                    this.props.createProductLockerState.sens)}</div>
                                    </div>
                                    <div style={{ ...fieldStyle }}>
                                        <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramDepth")} :</div>
                                        <div style={{ flex: 1, marginLeft: 5 }}>{this.props.stock.stockStatus.lockerTypes[this.props.createProductLockerState.lockerTypeId].depth}</div>
                                    </div>
                                    <div style={{ flex: 1, marginTop: 6}}>
                                        {(this.props.productLocker)
                                            && (this.props.productLocker.lockerId === this.props.createProductLockerState.lockerId) 
                                            && <Confirm
                                            style= {{ width: "90%", height: 32}}
                                            onConfirm={() => {
                                                let response = this.props.requestDeleteProductLocker(new Date().getTime(), this.props.productLockerId, this.props.productLocker.productStocks.length);
                                                    if (response === null) {
                                                        {
                                                            alert(getText("PlanogramLockerProductAlert"));
                                                        }
                                                    }
                                                }}
                                                body={getText("MaintenanceBodyConfirmDelete")}
                                                confirmText={getText("MaintenanceConfirm")}
                                                title={getText("MaintenanceConfirmDeleteTitle")}>
                                                <button className="btn btn-primary"
                                                    disabled={!this.props.productLockerId
                                                        || (this.props.productLockerStates[this.props.productLockerId]
                                                           && this.props.productLockerStates[this.props.productLockerId].isLoading)
                                                        ||this.props.disabled}>
                                                    {getText( "PlanogramRemove")}
                                                </button>
                                        </Confirm>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="lockerPlanoItem3"> 
                {(this.props.productLocker)
                && (this.props.productLocker.lockerId === this.props.createProductLockerState.lockerId) 
                &&<div className="card" style={{
                    ...Styles.cardStyle,
                    padding: 13,
                    marginBottom: 10,
                    height: "100%"
                }}> 
                   <UpdateProductLockerForm 
                        onSubmit={(values) => this.props.requestUpdateProductLocker(new Date().getTime(), values) as any}/>
                    { this.props.productLocker.externalProductId != 0 &&
                        <div style={{ width: "30%", position:"relative", top: "-70px" }}>
                            <button className="btn btn-primary"
                                style= {{ width: "90%", height: 32, marginRight: "5px"}}
                                disabled={!this.props.productLockerId
                                    || (this.props.productLockerStates[this.props.productLockerId]
                                        && this.props.productLockerStates[this.props.productLockerId].isLoading)
                                    ||this.props.disabled}
                                onClick={(e) => {
                                    let model = {
                                        ...this.props.productLocker,
                                        externalProductId: undefined
                                    }
                                    this.props.requestUpdateProductLocker(new Date().getTime(), model)
                                    e.preventDefault();
                                }}>
                                {getText( "PlanogramLockerDeleteProd")}
                            </button>
                        </div>
                    }
                </div>}
            </div>
            <DialogModal isOpen={this.state.openDialogMsg}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);",
                        width: 650
                    }}
                    title={getText( "PlanogramGridDialogTitle")}
                    contentLabel={getText( "PlanogramGridDialogTitle")}
                    onRequestClose={() => this.lockerPlanoMsgClose()}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {this.state.msgDialog}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.lockerPlanoMsgClose()}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
        </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.planogram,
    stock: state.stock,
    disabled: !canUpdate(state),
    productLocker: PlanogramStore.productLockerSelector(state),
    externalProducts: state.seed.seed.externalProducts
} as LockerPlanogramOwnProps), {
        ...PlanogramStore.actionCreators,
    })(LockerPlanogram as any);