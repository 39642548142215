import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as ReduxForm from "redux-form";
import * as FormField from './FormField';
import { CSSProperties } from 'react';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';
import { locksAvailable, productsLockersPlanoAvailable, productLockerSelector } from "../store/Planogram";
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";
import * as StockStore from "../store/Stock";


type ProductLockerFormProps = InjectedFormProps<{}, {}, {}>
    & ProductLockerFormOwnProps
    & ProductLockerFormExternalProps;

interface ProductLockerFormOwnProps {
    stock: StockStore.StockState;
    text: string;
    disabled: boolean;
    productLockerId: number;
    lockerAddressId: number;
    externalProductId: number;
    productsAvailable: Array<Api.ExternalProductModel>;
    lockIdUpdated: number;
}

interface ProductLockerFormExternalProps {
    onSubmit: (values: Api.ProductLockerModel) => Promise<any>
}

const labelStyle: CSSProperties = {
    fontWeight: "bold",
    display: "contents",
    paddingRight: 5
};

const fieldStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

const customFilter = createFilter({ ignoreAccents: true });

class ProductLockerForm extends React.Component<ProductLockerFormProps, {}> {
    constructor(props: ProductLockerFormProps) {
        super(props);
        this.validationProduct = this.validationProduct.bind(this);
        this.validationLock = this.validationLock.bind(this);
        this.state = {
        };
    }

    validationProduct(externalProductId) {
        var lockerP = this.props.stock.stockStatus.productLockers.find(x => x.productLockerId === this.props.productLockerId);
        var lockerType = this.props.stock.stockStatus.lockerTypes[this.props.stock.stockStatus.lockers[lockerP.lockerId].lockerTypeId];
        var product = this.props.productsAvailable.find(x => x.storeItemId == externalProductId);
        return ((lockerType.height * lockerType.width *  lockerType.depth) < 
                    (product.height * product.width * product.depth))
                ? getText( "PlanogramLockerProdValidation")
                : undefined
    }

    validationLock(lockId) {
        var locks = locksAvailable(this.props.lockerAddressId, this.props.stock.stockStatus.productLockers, this.props.stock.stockStatus.locks);
        return (!locks.some(x => x == lockId) && lockId != this.props.lockIdUpdated
                ? getText( "PlanogramLockerLockValidation")
                : undefined)
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical borderSelectors" style={{ maxWidth: '100%' }}>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "PlanogramLockerIP")} :</label>
                    <ReduxForm.Field
                        name="lockerAddressId"
                        validate={[FormField.required]}
                        component={FormField.getSelectField(
                            _.values(this.props.stock.stockStatus.lockerAddresses).map(x => ({
                                label: `${x.name}-${x.ipAddress} `,
                                value: x.lockerAddressId
                            })))} />
                </div>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "PlanogramLockerLockNum")} :</label>
                    {this.props.lockerAddressId != 0 ?
                    <ReduxForm.Field
                        name="lockId"
                        validate={[FormField.required, this.validationLock]}
                        component={FormField.getSelectField(
                            _.values(this.props.stock.stockStatus.locks).map(x => ({
                                label: x.lockNumber.toString(),
                                value: x.lockId
                            })))} />
                    :  <div style={{fontSize: "smaller"}}>{getText("PlanogramLockerValidationIP")}</div>
                    }
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>   
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "PlanogramProduct")}:</label>
                    <ReduxForm.Field
                        name="externalProductId"
                        validate={[FormField.required, this.validationProduct]}
                        component={FormField.getSelectField( 
                            _.sortBy(this.props.productsAvailable, x => x.name)
                            .map(p => ({ label: p.name, value: p.storeItemId })))} /> 
                </div>
                <div className="col-md-6 form-group">
                    <div style={{marginLeft: 10}}>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramHeight")} :</div>
                            <div style={{ flex: 1, marginLeft: 5 }}>{this.props.externalProductId ? this.props.productsAvailable.find(x => x.storeItemId == this.props.externalProductId).height : ""}</div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramWidth")} :</div>
                            <div style={{ flex: 1, marginLeft: 5 }}>{this.props.externalProductId ? this.props.productsAvailable.find(x => x.storeItemId == this.props.externalProductId).width : ""}</div>
                        </div>
                        <div style={{ ...fieldStyle }}>
                            <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramDepth")} :</div>
                            <div style={{ flex: 1, marginLeft: 5 }}>{this.props.externalProductId ? this.props.productsAvailable.find(x => x.storeItemId == this.props.externalProductId).depth : ""}</div>
                        </div>
                    </div>
                </div> 
            </div> 
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>   
                <div className="col-md-6 form-group"></div>
                <div className="col-md-6 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

let form = reduxForm({
    form: 'updateProductLocker',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ProductLockerForm as any) as any;
const updateSelector = formValueSelector('updateProductLocker');

export const UpdateProductLockerForm = connect((state: ApplicationState) => {
    return {
        stock: state.stock,
        text: getText( "SettingsSave"),
        initialValues: productLockerSelector(state),
        disabled: !canUpdate(state),
        productLockerId: state.planogram.productLockerId,
        lockerAddressId: updateSelector(state, "lockerAddressId"),
        externalProductId: updateSelector(state, "externalProductId"),
        productsAvailable: productsLockersPlanoAvailable(state),
        lockIdUpdated: productLockerSelector(state).lockId
    } as ProductLockerFormOwnProps
}, null)(form as any) as any as React.ComponentClass<ProductLockerFormExternalProps>

