import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as HomeStore from "../store/Home";
import * as StockStore from "../store/Stock";
import * as CashStore from "../store/Cash";
import * as CardStore from '../store/Card';
import * as PrintStore from '../store/Print';
import * as RobotStore from '../store/Robot';
import * as TempStore from '../store/Temp';
import * as ReactTable from "react-table";
import * as HistoryStore from "../store/History";
import Image from "./Image";
import Table from "./Table";
import { NeoEventSource } from '../utils/utils';
import { CSSProperties } from 'react';
import Tooltip from "./Tooltip";
import NeoEventFilterForm from './NeoEventFilterForm';
import { getText } from '../utils/texts';
import Graph from './Graph';
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';
import * as moment from 'moment';
import { canUpdate } from '../security/roles';
import { Translation } from 'react-i18next';

type  HomeProps= HomeOwnProps
    & HomeStore.HomeState
    & typeof HistoryStore.actionCreators
    & typeof HomeStore.actionCreators
    & typeof CashStore.actionCreators
    & typeof StockStore.actionCreators
    & typeof HistoryStore.actionCreators
    & typeof CardStore.actionCreators
    & typeof PrintStore.actionCreators
    & typeof RobotStore.actionCreators
    & typeof TempStore.actionCreators
    & typeof NeoEventAlarmStore.actionCreators
    ;

interface HomeOwnProps {
    seed: Api.SeedMngModel;
    cash: CashStore.CashState;
    stock: StockStore.StockState;
    products: { [id: number]: Api.ProductModel };
    moneys: { [id: number]: Api.MoneyModel };
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
    dayStoreData: Api.DayStoreDataModel;
    dayMoneyStocks: Array<Api.DayMoneyStock>;
    currentUser: Api.CurrentUserManagerModel;
    card: CardStore.CardState;
    robot: RobotStore.RobotState;
    print: PrintStore.PrintState;
    temp: TempStore.TempState;
    smartPhoneStatus: Api.SmartPhoneStatusModel;
    deliveryStation: Api.DeliveryStationModel;
    disabled: boolean;
    storeType: string;
    paymentGraph: { graphData: [][], graphLabels: Array<LabelGraphObj> },
    totals: { totalTurnover: number, totalCashRegister: number };
    neoEventsFiltered: Array<Api.NeoEventModel>;
    clientsGraph: {graphData: (string | number)[][], graphMax: number},
    productsGraph: {graphData: (string | number)[][], graphMax: number},
}

type StoreEventColumn = ReactTable.Column
    & { accessor: (d: Api.NeoEventModel) => any; }

type EmptyRailColumn = ReactTable.Column
    & { accessor: (d: Api.ProductRailModel) => any; }

const ContentCardStyle: CSSProperties = { margin: '10px 0' };
const InfoCardStyle: CSSProperties = { padding: '10px 0', display: 'flex', justifyContent: 'space-evenly' };
const stockInfoFieldStyle: CSSProperties = { display: "flex", justifyContent: 'space-between'};
const stockInfoLabelStyle: CSSProperties = { display: 'block' , margin: 'auto 0', fontSize: 13};
const stockInfoValueStyle: CSSProperties = {
    border: '1px solid rgba(59, 67, 112, 0.25)',
    padding: '5px 0',
    marginRight: '3%',
    borderRadius: '4px',
    width: '100px',
    textAlign: 'center'
 };
const minUpdateInterval = 6000;

const nonRecycledImage = require('../assets/images/non_recycled.png') as any;

type Status = "Off" | "Ko" | "Ok" | "Busy" | "Deactivated";


type StatusObj = {
    message: string,
    date: Moment.Moment,
    color: string
}

type LabelGraphObj = {
    id: string,
    valueSum: number,
    labelText: string,
    colorRGB: string,
    isSideLeft: boolean,
    graphIndex: number
}

class Home extends React.Component<HomeProps, {}> {
    get productRails() {
        let activePlanogram = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0];
        return this.props.stock.stockStatus.productRails
            .filter(x => activePlanogram && x.planogramId === activePlanogram.planogramId);
    }

    componentDidMount() {
        this.props.updateNeoEventFilter({fromDate: Moment().startOf('day').toDate()});
        this.props.homeSetLastUpdate(new Date().getTime());
        this.props.requestNeoEvents(new Date().getTime());
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    cachedTranslations = {};
    setCorrectValues(t, neoEventCodeId, params, message, neoEventId) {
        let result;

        if (this.cachedTranslations[neoEventId]) {
            result = this.cachedTranslations[neoEventId];
        }
        let eventCode = this.props.neoEventCodes[neoEventCodeId] && this.props.neoEventCodes[neoEventCodeId].code;
        if (!result && !eventCode) {
            result = message;
        }

        if (!result && params != null) {
            try {
                let parsedParams = JSON.parse(params);
                result = t(eventCode, parsedParams);
            }
            catch (e) {

            }
        }

        if (!result) {
            let forcedLanguage = { lng: 'fr' };
            result = t(eventCode, forcedLanguage) == message ? t(eventCode) : message;
        }
        this.cachedTranslations[neoEventId] = result;
        return result;
    }

    labelHasOpacity(value: number) {
        if(value <= 0) {
            return true;
        }
        return false;
    }

    isRecycled = (index: number): Boolean => {
        if (this.props.cash.hoppers.hoppers) {
            let arr = [];
            let obj = this.props.cash.hoppers.hoppers;
            Object.keys(obj).map(function (key) {
                arr.push(obj[key])
                return arr;
            });
            if (arr.some(x => x.moneyId === index)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    

    get coinsNotRecycled () {
        let cnr = 0;
        this.moneys.filter(x => x.type === "Coin").map((money, index) => {
            cnr = cnr + this.getCoinQuantity(money)
        });
        return cnr;
    }

    get billsNotRecycled () {
        let bnr = 0;
        this.moneys.filter(x => x.type === "Bill").map((money, index) => {
            bnr = bnr + this.getCoinQuantity(money)
        });
        return bnr;
    }
    getCoinQuantity(money: Api.MoneyModel): number {
        return _.sum(this.todayMoneyStocks
            .filter(x => x.moneyId == money.moneyId && !x.hmiMoneyHopperId)
            .map(x => x.quantity))
    }
    hopperCoinImage(lowAlert: any, lowWarning: any, highAlert: any, highWarning: any, quantity): any {
        let hopperImg;
        if(quantity <= lowAlert) {
            hopperImg = require('../assets/images/coin_hopper_low.png') as any;
        }
        else if(quantity > lowAlert && quantity <= lowWarning) {
            hopperImg = require('../assets/images/coin_hopper_low.png') as any;
        }
        else if(quantity > lowWarning && quantity <= highAlert) {
            hopperImg = require('../assets/images/coin_hopper_ok.png') as any; //status ok
        }
        else if(quantity > highAlert && quantity <= highWarning) {
            hopperImg = require('../assets/images/coin_hopper_high.png') as any;
        }
        else {
            hopperImg = require('../assets/images/coin_hopper_high.png') as any;
        }
        return hopperImg;
    }

    hopperBanknoteImage(lowAlert: any, lowWarning: any, highAlert: any, highWarning: any, quantity): any {
        let hopperImg;
        if(quantity <= lowAlert) {
            hopperImg = require('../assets/images/banknote_hopper_ok.png') as any;
        }
        else if(quantity > lowAlert && quantity <= lowWarning) {
            hopperImg = require('../assets/images/banknote_hopper_ok.png') as any;
        }
        else if(quantity > lowWarning && quantity <= highAlert) {
            hopperImg = require('../assets/images/banknote_hopper_ok.png') as any; //status ok
        }
        else if(quantity > highAlert && quantity <= highWarning) {
            hopperImg = require('../assets/images/banknote_hopper_ok.png') as any;
        }
        else {
            hopperImg = require('../assets/images/banknote_hopper_ok.png') as any;
        }
        return hopperImg;
    }

    getCodeLabel(type: string) {
        if(type===NeoEventSource[0]) {
            return getText("HistoState");
        }        
        if(type===NeoEventSource[1]) {
            return getText("HistoExploitant");
        }        
        if(type===NeoEventSource[2]) {
            return getText("HistoClient");
        }
        if(type===NeoEventSource[3]) {
            return getText("HistoRobot");
        }
        if(type===NeoEventSource[4]) {
            return getText("HistoOrdinateur");
        }
        if(type===NeoEventSource[5]) {
            return getText("HistoTPN");
        }
        if(type===NeoEventSource[6]) {
            return getText("HistoTPA");
        }
        if(type===NeoEventSource[7]) {
            return getText("HistoPrinter");
        }
        if(type===NeoEventSource[8]) {
            return getText("HistoSmart");
        }
        return getText("HistoCode");
    }
    
    //maybe avoid getter for potential high computing stuff for semantic reason, easier to optimize later when we see function we think it takes cpu but getter not really
    //Pretty meh here but it is called a lot, if we have 2k events it might take a few ms
    neoEventsOrdered() {
        return _.sortBy(_.values(this.props.alarmEventsState.neoEvents), x => -x.neoEventId)
    }

    get hasMinorEvent(): boolean {
        return this.props.neoEvents.neoEvents
            && this.props.neoEvents.neoEvents.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error");
    }

    get hasMajorEvent(): boolean {
        return this.props.neoEvents.neoEvents
            && this.props.neoEvents.neoEvents.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError");
    }
    

    get todayMoneyTraffics(): Array<Api.StoreMoneyTrafficModel> {
        return HistoryStore.todayMoneyTraffics(this.props.dayStoreData);
    }

    get moneys(): Array<Api.MoneyModel> {
        return _.sortBy(_.values(this.props.seed.moneys), x => x.value);
    }
    

    get todayStoreOrders(): Array<Api.StoreOrderModel> {
        return HistoryStore.todayStoreOrders(this.props.dayStoreData);
    }
    
    get todayMoneyStocks(): Array<Api.StoreDayMoneyStockModel> {
        if (this.props.dayMoneyStocks) {
            return this.props.dayMoneyStocks;
        }

        //This comes from datasnapshoot: data saved every 24 hours from a worker task which might not happen on dev env
        if (this.props.dayStoreData
            && this.props.dayStoreData.storeDayDataSnapshot
            && this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks
            && this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks.length) {
            return this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks;
        }

        return [];
    }

    get eventsStatus(): StatusObj {
        let neoEventsOrdered = this.neoEventsOrdered();
        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError").creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError").neoEventCodeId].name,
                    date: date,
                    color: 'red'
                }
            }
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error").creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error").neoEventCodeId].name,
                    date: date,
                    color: 'orange'
                }
            }
        }

        return {
            message: "",
            date: Moment().utc(),
            color: 'green'
        };
    }

    get cardStatusLabel(): Status {
        if (!this.props.card.state)
            return "Off";

        if (this.props.card.state.status === "Ok") {
            if (this.props.card.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.card.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }

    get cardStatus(): StatusObj {
        if (!this.props.card.state)
            return {
                message: "Off",
                date: Moment().utc(),
                color: 'red'
            };

        let neoEventsOrdered = this.neoEventsOrdered();
        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                this.props.neoEventCodes[x.neoEventCodeId].source === "Card")) {
                var date = Moment(neoEventsOrdered.find(x =>
                    this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                    this.props.neoEventCodes[x.neoEventCodeId].source === "Card").creationDate);
                return {
                    message: neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Card").message,
                    date: date,
                    color: 'orange'
                }
            }
        }

        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 603) && this.cardStatusLabel == "Ko") {
                var date  =Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 603).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 603).neoEventCodeId].name,
                    date: date,
                    color: 'red'
                };
            }
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 601) && this.cardStatusLabel == "Ko") {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 601).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 601).neoEventCodeId].name,
                    date: date,
                    color: 'green'
                };
            }
        }

        return {
            message: "",
            date: Moment().utc(),
            color: 'green'
        };
    }

    get cashCoinStatus(): Status {
        if (!this.props.cash.state)
            return "Off";

        if (this.props.cash.state.status === "Ok") {
            if (this.props.cash.state.coinAcceptorStatus !== "Ok"
                || !this.props.cash.state.coinAcceptorEnabled) {
                return "Ko";
            }

            if (this.props.cash.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.cash.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }


    get cashBillStatus(): Status {
        if (!this.props.cash.state)
            return "Off";

        if (this.props.cash.state.status === "Ok") {
            if (this.props.cash.state.billAcceptorStatus !== "Ok"
                || !this.props.cash.state.billAcceptorEnabled) {
                return "Ko";
            }

            if (this.props.cash.state.state === "Idle") {
                return "Ok";
            }
            //stay green always
            return "Ok";
        }
        if (this.props.cash.state.status === "Deactivated")
            return "Deactivated";

        return "Ko";
    }

    get coinsCashStatus(): StatusObj {
        if (!this.props.cash.state)
            return {message: "Off", date: Moment().utc(), color: 'red' };

        let neoEventsOrdered = this.neoEventsOrdered();
        
        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                this.props.neoEventCodes[x.neoEventCodeId].source === "Cash" && this.props.neoEventCodes[x.neoEventCodeId].code !== 821)) {
                var date = Moment(neoEventsOrdered.find(x =>
                    this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                    this.props.neoEventCodes[x.neoEventCodeId].source === "Cash").creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Cash" && this.props.neoEventCodes[x.neoEventCodeId].code !== 821).neoEventCodeId].name,
                    date: date,
                    color: 'orange'
                }
            }
        }

        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502) && (this.cashBillStatus == "Ko" || this.cashCoinStatus == "Ko")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502).neoEventCodeId].name,
                    date: date,
                    color: 'red'
                }
            }

            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501) && (this.cashBillStatus == "Ko" || this.cashCoinStatus == "Ko")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501).neoEventCodeId].name,
                    date: date,
                    color: 'orange'
                }
            }
        }

        return {
            message: "",
            date: Moment().utc(),
            color: 'green'
        };
    }

    get billsCashStatus(): StatusObj {
        if (!this.props.cash.state)
            return {message: "Off", date: Moment().utc(), color: 'red' };

        let neoEventsOrdered = this.neoEventsOrdered();
        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                this.props.neoEventCodes[x.neoEventCodeId].source === "Cash" && this.props.neoEventCodes[x.neoEventCodeId].code !== 822)) {
                var date = Moment(neoEventsOrdered.find(x =>
                    this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                    this.props.neoEventCodes[x.neoEventCodeId].source === "Cash").creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Cash" && this.props.neoEventCodes[x.neoEventCodeId].code !== 822).neoEventCodeId].name,
                    date: date,
                    color: 'orange'
                }
            }
        }

        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502) && (this.cashBillStatus == "Ko" || this.cashCoinStatus == "Ko")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 502).neoEventCodeId].name,
                    date: date,
                    color: 'red'
                }
            }

            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501) && (this.cashBillStatus == "Ko" || this.cashCoinStatus == "Ko")) {
                var date = Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501).creationDate);
                return {
                    message: this.props.neoEventCodes[neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].code == 501).neoEventCodeId].name,
                    date: date,
                    color: 'orange'
                }
            }
        }

        return {
            message: "",
            date: Moment().utc(),
            color: 'green'
        };
    }

    get noContactStatus(): StatusObj {
        if (!this.props.card.state)
            return {message: "Off", date: Moment().utc(), color: 'red' };

        if (this.props.card.state.noContactStatus === "Ok") {
            if (this.props.card.state.state === "Idle") {
                return {message: "", date: Moment().utc(), color: 'green'};
            }
            return {message: "Busy", date: Moment().utc(), color: 'orange' };
        }
        if (this.props.card.state.noContactStatus === "Deactivated")
            return {message: "Deactivated", date: Moment().utc(), color: 'red' };

        return {message: "Ko", date: Moment().utc(), color: 'orange' };
    }

    get printStatus(): StatusObj {
        if (!this.props.print.state)
            return {message: "Off", date: Moment().utc(), color: 'red' };

        let neoEventsOrdered = this.neoEventsOrdered();
        if(neoEventsOrdered){
            if(neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                this.props.neoEventCodes[x.neoEventCodeId].source === "Printer")) {
                var date = Moment(neoEventsOrdered.find(x =>
                    this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                    this.props.neoEventCodes[x.neoEventCodeId].source === "Printer").creationDate);
                return {
                    message: neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Printer").message,
                    date: date,
                    color: 'red'
                }
            }
        }

        if (this.props.print.state.status === "Deactivated")
            return {message: "Off", date: Moment().utc(), color: 'red' };

        if (this.props.print.state.status === "Unavailable")
            return {message: "Busy", date: Moment().utc(), color: 'orange' };

        if (this.props.print.state.status === "Ok")
            return { message: "", date: Moment().utc(), color: 'green' };

        return {
            message: "Ko",
            date: Moment().utc(),
            color: 'red'
        };
    }

    get robotStatus(): StatusObj {
        let neoEventsOrdered = this.neoEventsOrdered();
        
        if(neoEventsOrdered) {
            if (!this.props.robot.state)
                return {
                    message: neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").message 
                        : "Off",
                    date: neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").creationDate) 
                        : Moment().utc(),
                    color: 'red' };

            if (this.props.robot.state.status === "Running") {
                return {
                    message: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").message 
                        : "",
                    date: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").creationDate) 
                        : Moment().utc(),
                    color: 'green' };
            }
            if (this.props.robot.state.status == "Ready")
                return {
                    message: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").message 
                        : "",
                    date: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                        ? Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").creationDate) 
                        : Moment().utc(),
                    color: 'green' };

            return {
                message: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                    ? neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").message 
                    : "Ko",
                date: neoEventsOrdered.some(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot") 
                    ? Moment(neoEventsOrdered.find(x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&  this.props.neoEventCodes[x.neoEventCodeId].source === "Robot").creationDate) 
                    : Moment().utc(),
                color: 'red' };
        }
        return {
            message: "",
            date: Moment().utc(),
            color: 'green'
        };
    }

    get smartPhoneStatus(): StatusObj {
        if (!this.props.smartPhoneStatus)
            return {message: "Off", date: Moment().utc(), color: 'red' };

        if (this.props.smartPhoneStatus.status === "Ok") {
            return {message: "", date: Moment().utc(), color: 'green'};
        }

        return {message: "Ko", date: Moment().utc(), color: 'orange' };
    }

    get isTempWarning() {
        return this.props.deliveryStation
            && this.props.temp.temperatureState
            && this.props.temp.temperatureState.value
            && this.props.deliveryStation.warnTemp < this.props.temp.temperatureState.value;
    }

    get isTempAlert() {
        return this.props.deliveryStation
            && this.props.temp.temperatureState
            && this.props.temp.temperatureState.value
            && this.props.deliveryStation.alertTemp < this.props.temp.temperatureState.value;
    }

    get tempStatus(): StatusObj {
        if (!this.props.temp.state)
            return {message: "Off", date: Moment().utc(), color: 'red' };
        if (this.props.temp.state.status === "Ok" && this.props.temp.temperatureState && this.props.temp.temperatureState.value) {
            if (this.isTempAlert && this.props.temp.temperatureState)
                return { message: this.props.temp.temperatureState.value.toFixed(2) + " C°" || "", date: Moment().utc(), color: 'red' };
            if (this.isTempWarning && this.props.temp.temperatureState)
                return { message: this.props.temp.temperatureState.value.toFixed(2) + " C°" || "", date: Moment().utc(), color: 'orange' };

            return { message: this.props.temp.temperatureState ? this.props.temp.temperatureState.value.toFixed(2) + " C°" : "", date: Moment().utc(), color: 'green' };
        }

        return {message: "Ko", date: Moment().utc(), color: 'orange' };
    }

    isToday(someDate: Moment.Moment) {
        return Moment(someDate).isSame(Moment(), 'day');
    }

    getEventLabelColor(d: Api.NeoEventModel) {
        let neoEvent = this.props.neoEventCodes[d.neoEventCodeId]
        let color = this.isDarkMode() ? 'white' : '#3B4370';
        let isKO = d.message.toUpperCase().includes("KO");

        switch (neoEvent.code) {
            case 828:
                color = neoEvent.type == "FatalError" ? 'red' : 'orange';
                break;
            case 831:
                color = neoEvent.type == "FatalError" ? 'red' : 'orange';
                break;
            case 501:
                color = isKO ? 'red' : color;
                break;
            case 502:
                color = isKO ? 'red' : color;
                break;
            case 601:
                color = isKO ? 'red' : color;
                break;
            case 606:
                color = isKO ? 'red' : color;
                break;
            default:
                break;
        }
        if(neoEvent.code >= 900 && neoEvent.code <= 999) {
            color = 'red';
        }  
        return color;
    }

    getLabel( x: LabelGraphObj, index: number){
        return <li key={index}>
                    <span  style={{
                        display: "inline-block",
                        opacity: this.labelHasOpacity(x.valueSum) ? 0.6 : 1 }}>
                        <div style= {{ width: 10, height: 10, borderRadius: "50%",  margin: "0px 5px 0px 0px", background: x.colorRGB, display: "inherit"}}></div>
                        {x.labelText}: {x.valueSum.toFixed(2)}{"\u20AC"}
                    </span>
                </li>
    }

    get totalClientsToday(): number {
        let storeOrders : Array<Api.StoreOrderModel> = this.props.seed.storeAppSettings.typeStore == "Ximiti" 
        ? this.todayStoreOrders.filter(x => x.status == "Delivered")
        : this.todayStoreOrders.filter(x => x.type == "PreOrder" && x.status == "Delivered");

        return storeOrders.length;
    }

    public render() {
        let activePlanogram = this.props.stock.stockStatus
            && this.props.stock.stockStatus.planograms.find(x => x.active);
        let totalRails = activePlanogram
            ? _.sum(this.props.stock.stockStatus.shelvingTrayPlacements
            .filter(x => x.planogramId === activePlanogram.planogramId)
            .map(x => this.props.stock.stockStatus.shelvingTrays[x.shelvingTrayId].railEnd
                    - (this.props.stock.stockStatus.shelvingTrays[x.shelvingTrayId].railStart - 1)))
            : 0;
        return <div style={{
            display: "grid",
            gridGap: 20,
            padding: "0px 1%",
            height: "100%",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridTemplateRows: "1fr 1fr",
        }}>
            { this.props.storeType == "Ximiti" ?
            <div style={{ display: "contents"}}>
            <div>
                <div className="card" style={{ ...Styles.cardStyle }}>
                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                        <div style={{ ...Styles.titleCard }}>{getText("HomeStoreState")}</div>
                    </div>
                    <div style={{...ContentCardStyle, paddingLeft: '3%'}}>
                        <div style={{ fontSize: 16 }}>{getText("HomeCashLevel")}</div>
                        <div style={InfoCardStyle}>
                            {this.props.cash.hoppers.hoppers
                                .filter(x => this.props.moneys[x.moneyId].type === "Coin")
                                .map(hopper => <div key={hopper.hmiMoneyHopperId} style={{ display: 'grid', textAlign: 'center', paddingLeft: '3%', placeItems: "center" }}>
                                    {hopper.quantity}
                                    <img src={this.hopperCoinImage(hopper.lowQuantityAlert, hopper.lowQuantity, hopper.highQuantityAlert, hopper.highQuantity, hopper.quantity)} alt="menu" />
                                    {hopper.money.value.toFixed(2)} {"\u20AC"}
                                </div>)}
                            <div style={{ display: 'grid', textAlign: 'center', paddingLeft: '3%', placeItems: "center" }}>
                                {this.coinsNotRecycled}
                                <img src={nonRecycledImage} alt="menu" />
                                {getText("HomeNotRecycled")}
                            </div>
                        </div>
                    </div>
                    <div style={{...ContentCardStyle, paddingLeft: '3%'}}>
                        <div style={{ fontSize: 16 }}>{getText("HomeTicketsLevel")}</div>
                        <div style={InfoCardStyle}>
                            {this.props.cash.hoppers.hoppers
                                .filter(x => this.props.moneys[x.moneyId].type === "Bill")
                                .map(hopper => <div key={hopper.hmiMoneyHopperId} style={{ display: 'grid', textAlign: 'center', paddingLeft: '3%', placeItems: "center" }}>
                                    {hopper.quantity}
                                    <img src={this.hopperBanknoteImage(hopper.lowQuantityAlert, hopper.lowQuantity, hopper.highQuantityAlert, hopper.highQuantity, hopper.quantity)} alt="menu" />
                                    {hopper.money.value.toFixed(2)} {"\u20AC"}
                                </div>)}
                            <div style={{ display: 'grid', textAlign: 'center', paddingLeft: '3%', placeItems: "center" }}>
                                {this.billsNotRecycled}
                                <img src={nonRecycledImage} alt="menu" />
                                {getText("HomeNotRecycled")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ ...Styles.cardStyle, paddingBottom: 10 }}>
                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                        <div style={{ ...Styles.titleCard }}>{getText( "HomeStockState")}</div>
                    </div>
                    <div style={ContentCardStyle}>
                        {
                            this.props.stock.stockStatus &&
                            <div style={{paddingLeft: '3%'}}>
                                <div style={stockInfoFieldStyle}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeProductsNumber")}</div>
                                    <div style={stockInfoValueStyle}>
                                        {_.uniq(this.productRails
                                        .filter(x => x.productStocks.length !== 0)
                                            .map(x => x.productId)).length}
                                        {" / "}
                                        {this.productRails.filter((e, i) => this.productRails.findIndex(a => a.productId === e.productId) === i).length}
                                    </div>
                                </div>
                                <div style={{...stockInfoFieldStyle, padding: '1% 0'}}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeEmptyBaysPerc")}</div>
                                    <div style={stockInfoValueStyle}>
                                        {this.productRails
                                            .filter(x => x.productStocks.length === 0).length}
                                        {" / "}
                                        {this.productRails.length}
                                    </div>
                                </div>
                                <div style={stockInfoFieldStyle}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeFreeBands")}</div>
                                    {activePlanogram && <div style={stockInfoValueStyle}>
                                        {totalRails - _.sum(this.productRails.map(x => x.f - x.d + 1))}
                                        {" / "}
                                        {totalRails}
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="card" style={{
               ...Styles.cardStyle,
               padding: 10
            }}>
                <div style={{display: "flex", alignItems: 'center'}}>
                    <Tooltip overlay={<div>{this.props.totals.totalTurnover.toFixed(2) !== this.props.totals.totalCashRegister.toFixed(2)
                        ? getText("HomeCashRegisterValidationTrue")
                        : getText("HomeCashRegisterValidationFalse")}</div>}>
                        <div style={{
                            display: "inline-block",
                            margin: '2%',
                            width: 10, height: 10,
                            borderRadius: 10,
                            backgroundColor: this.props.totals.totalTurnover.toFixed(2) !== this.props.totals.totalCashRegister.toFixed(2)
                                ? "red"
                                : "green"
                        }}></div>
                    </Tooltip>
                    <div style={{...Styles.titleCard}}>{getText("HomeCash")}</div>
                </div>
                <div style={{display: 'block', fontSize: 15, textAlign: "end", textDecoration:"underline"}}>{`${getText("HomeComboChartTitleClients")}: 
                        ${this.totalClientsToday}`}
                    </div>
                <div style={{height: 300, color: "#3B4370", fontSize: 9, display: 'flex', justifyContent: 'center'}}>
                    <Graph
                        width={'400px'}
                        height={'300px'}
                        chartType="PieChart"
                        data={this.props.paymentGraph.graphData}
                        options={{
                            title: '',
                            legend: 'none',
                            backgroundColor: 'transparent'
                        }}
                    />
                </div>
                <div style={{ textAlign: "center" }}>
                    <ul className="chart-ul">
                        {
                            this.props.paymentGraph.graphLabels.map((x, index) => {
                                return this.getLabel(x, index)
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="card" style={Styles.cardStyle}>
                <div style={{
                    padding: 10,
                    display: "grid"
                }}>
                    <div style={{...Styles.titleCard}}>{getText("HomeComboChartTitleClients")}</div>
                    <Graph
                        width={'100%'}
                        height={'100%'}
                        chartType="ComboChart"
                        data={this.props.clientsGraph.graphData}
                        options={{
                            title: "",
                            titlePosition: 'none',
                            legend: 'none',
                            seriesType: 'bars',
                            series: { 1: { type: "line" } },
                            backgroundColor: 'transparent',
                            chartArea: {width: '90%'},
                            vAxis: {
                                format: "#",
                                viewWindowMode: 'explicit',
                                viewWindow: {min: 0, max: this.props.clientsGraph.graphMax }
                            }
                        }}
                    />
                    <div style={{...Styles.titleCard}}>{getText("HomeComboChartTitleProducts")}</div>
                    <Graph
                        width={'100%'}
                        height={'100%'}
                        chartType="ComboChart"
                        data={this.props.productsGraph.graphData}
                        options={{
                            title: "",
                            titlePosition: 'none',
                            legend: 'none',
                            seriesType: 'bars',
                            series: { 1: { type: "line" } },
                            backgroundColor: 'transparent',
                            chartArea: {width: '90%'},
                            vAxis: {
                                format: "#",
                                viewWindowMode: 'explicit',
                                viewWindow: {min: 0, max: this.props.productsGraph.graphMax}
                            }
                        }}
                    />
                </div>
            </div>
            </div>
            :
            <div style={{ display: "contents"}}>
            <div className="card" style={{
               ...Styles.cardStyle,
               padding: 10
            }}>
                <div style={{...Styles.titleCard, marginBottom: "1.5em"}}>{getText("HomeComboChartTitleClients")}</div>
                <Graph
                    width={'100%'}
                    height={'100%'}
                    chartType="ComboChart"
                    data={this.props.clientsGraph.graphData}
                    options={{
                        title: "",
                        titlePosition: 'none',
                        legend: 'none',
                        seriesType: 'bars',
                        series: { 1: { type: "line" } },
                        backgroundColor: 'transparent',
                        chartArea: {width: '90%'},
                        vAxis: {
                            format: "#",
                            viewWindowMode: 'explicit',
                            viewWindow: {min: 0, max: this.props.clientsGraph.graphMax}
                          }
                    }}
                />
            </div>
            <div className="card" style={{
               ...Styles.cardStyle,
               padding: 10
            }}>
                <div style={{...Styles.titleCard, marginBottom: "1.5em"}}>{getText("HomeComboChartTitleProducts")}</div>
                <Graph
                    width={'100%'}
                    height={'100%'}
                    chartType="ComboChart"
                    data={this.props.productsGraph.graphData}
                    options={{
                        title: "",
                        titlePosition: 'none',
                        legend: 'none',
                        seriesType: 'bars',
                        series: { 1: { type: "line" } },
                        backgroundColor: 'transparent',
                        chartArea: {width: '90%'},
                        vAxis: {
                            format: "#",
                            viewWindowMode: 'explicit',
                            viewWindow: {min: 0, max: this.props.productsGraph.graphMax}
                          }
                    }}
                />
            </div>
            <div>
                <div className="card" style={{ ...Styles.cardStyle }}>
                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                        <div style={{ ...Styles.titleCard }}>{getText("HomeDefaults")}</div>
                    </div>
                    <div style={{...ContentCardStyle, paddingLeft: '3%'}}>
                        <div style={{margin: '10px 5px'}}>
                            <div style={{...InfoCardStyle, borderBottom: '1px solid rgba(59, 67, 112, 0.08)'}}>
                                <svg style={{margin: "auto 0"}} width="30" height="30" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.05948 0C3.05894 0 3.05833 0 3.0578 0C2.86863 0 2.6907 0.0772115 2.55635 0.217532C2.42052 0.359404 2.3457 0.548308 2.3457 0.74941V11.9867C2.3457 12.3988 2.66713 12.7348 3.06231 12.7359C4.72826 12.74 7.51938 13.1036 9.44487 15.2134V3.45329C9.44487 3.31361 9.4108 3.18238 9.34649 3.07379C7.76613 0.408974 4.7292 0.00408974 3.05948 0Z" fill={this.eventsStatus.color}/>
                                    <path d="M17.6557 11.9868V0.74941C17.6557 0.548308 17.5809 0.359404 17.4451 0.217532C17.3107 0.0772115 17.1327 0 16.9437 0C16.9431 0 16.9425 0 16.942 0C15.2723 0.00416026 12.2354 0.409045 10.655 3.07387C10.5906 3.18245 10.5566 3.31368 10.5566 3.45337V15.2134C12.4821 13.1036 15.2733 12.74 16.9392 12.7359C17.3343 12.7348 17.6557 12.3988 17.6557 11.9868Z" fill={this.eventsStatus.color}/>
                                    <path d="M19.286 2.59229H18.7671V11.9875C18.7671 13.0396 17.9484 13.8975 16.942 13.9001C15.5289 13.9037 13.199 14.193 11.5488 15.8282C14.4027 15.0966 17.4112 15.5722 19.1258 15.9813C19.3399 16.0324 19.5611 15.9796 19.7326 15.8365C19.9036 15.6937 20.0016 15.4803 20.0016 15.2512V3.34155C20.0017 2.92842 19.6806 2.59229 19.286 2.59229Z" fill={this.eventsStatus.color}/>
                                    <path d="M1.23454 11.9875V2.59229H0.715594C0.321095 2.59229 0 2.92842 0 3.34155V15.251C0 15.4801 0.0980524 15.6935 0.268971 15.8363C0.44036 15.9794 0.661382 16.0323 0.875805 15.9811C2.59038 15.5719 5.59896 15.0964 8.45271 15.828C6.80266 14.1928 4.4727 13.9036 3.05963 13.9001C2.05331 13.8975 1.23454 13.0396 1.23454 11.9875Z" fill={this.eventsStatus.color}/>
                                </svg>
                                <div style={{margin: 'auto'}}> { this.isToday(Moment(this.eventsStatus.date)) ?
                                    Moment(this.eventsStatus.date).add(-new Date().getTimezoneOffset(), "minutes").format("HH:mm:ss") :
                                    Moment(this.eventsStatus.date).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY")} </div>
                                <div style={{margin: 'auto'}}>
                                    <Tooltip overlay={<div>
                                            {this.eventsStatus.message}
                                        </div>}
                                        placement={"top"}>
                                        <div className="truncate-long-texts" >{this.eventsStatus.message} </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div style={{...InfoCardStyle, borderBottom: '1px solid rgba(59, 67, 112, 0.08)'}}>
                                <svg style={{margin: "auto 0"}} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.3217 23.8365C23.3217 20.7394 20.9042 18.2197 17.9327 18.2197C14.9612 18.2197 12.5437 20.7394 12.5437 23.8365V26.0344H8.32617V27.4997H24.7275V26.0344H23.3217V23.8365ZM17.9327 23.8365C17.4151 23.8365 16.9955 23.3992 16.9955 22.8597C16.9955 22.3202 17.4151 21.8829 17.9327 21.8829C18.4503 21.8829 18.8699 22.3202 18.8699 22.8597C18.8699 23.3992 18.4503 23.8365 17.9327 23.8365Z"
                                        fill={this.robotStatus.color}/>
                                    <path d="M24.7572 9.85098C24.5827 9.46104 24.3266 9.12812 24.0106 8.87074L17.2881 3.12351L17.288 3.12361C16.7616 2.66593 16.096 2.44811 15.4128 2.51046C14.7546 2.57047 14.1563 2.88395 13.7179 3.39485L5.84397 9.81944L5.84444 9.82007C5.33206 10.2214 5 10.86 5 11.578V15.2411H6.40583V16.7063H7.81165V15.2411H9.21748V11.578C9.21748 11.3407 9.1808 11.1124 9.11376 10.8982L14.3713 7.57844L19.3748 11.856L17.6619 16.7613C17.7521 16.7576 17.8426 16.7552 17.9337 16.7552C19.6922 16.7552 21.2969 17.4551 22.5047 18.6017L24.8432 11.905C25.0787 11.2308 25.0481 10.5013 24.7572 9.85098ZM16.5349 5.97149C16.3337 6.2213 16.0513 6.37457 15.7395 6.40299C15.429 6.43121 15.1263 6.33229 14.887 6.12408L14.8835 6.1211C14.6443 5.91153 14.4976 5.61748 14.4704 5.29297C14.4431 4.96806 14.5388 4.65155 14.7401 4.40165C14.9412 4.15184 15.2236 3.99857 15.5354 3.97015C15.5702 3.96698 15.6049 3.96541 15.6396 3.96541C15.9142 3.96541 16.1777 4.06497 16.3906 4.25121C16.6303 4.46083 16.7773 4.75527 16.8046 5.08017C16.8318 5.40508 16.736 5.72163 16.5349 5.97149ZM23.5222 11.4039C23.4151 11.7104 23.2 11.9551 22.9164 12.093C22.6328 12.2308 22.3147 12.2453 22.0206 12.1337C21.4136 11.9034 21.0995 11.2014 21.3204 10.5687C21.4275 10.2622 21.6426 10.0175 21.9262 9.87964C22.0837 9.80313 22.2518 9.7646 22.4206 9.7646C22.5557 9.7646 22.6912 9.78926 22.8219 9.83887C23.116 9.95045 23.3507 10.1747 23.483 10.4702C23.6153 10.7658 23.6292 11.0974 23.5222 11.4039Z"
                                        fill={this.robotStatus.color}/>
                                </svg>
                                <div style={{margin: 'auto'}}> { this.isToday(this.robotStatus.date) ?
                                    Moment(this.robotStatus.date).add(-new Date().getTimezoneOffset(), "minutes").format("HH:mm:ss") :
                                    Moment(this.robotStatus.date).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY")} </div>
                                <div style={{margin: 'auto'}}>
                                    <Tooltip overlay={<div>
                                        {this.robotStatus.message}
                                        </div>}
                                        placement={"top"}>
                                        <div className="truncate-long-texts" >{this.robotStatus.message}   </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ ...Styles.cardStyle, paddingBottom: 10 }}>
                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                        <div style={{ ...Styles.titleCard }}>{getText( "HomeStockState")}</div>
                    </div>
                    <div style={ContentCardStyle}>
                        {
                            this.props.stock.stockStatus &&
                            <div style={{paddingLeft: '3%'}}>
                                <div style={stockInfoFieldStyle}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeProductsNumber")}</div>
                                    <div style={stockInfoValueStyle}>
                                        {_.uniq(this.productRails
                                        .filter(x => x.productStocks.length !== 0)
                                            .map(x => x.externalProductId)).length}
                                        {" / "}
                                        {this.productRails.filter((e, i) => this.productRails.findIndex(a => a.externalProductId === e.externalProductId) === i).length}
                                    </div>
                                </div>
                                <div style={{...stockInfoFieldStyle, padding: '1% 0'}}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeEmptyBaysPerc")}</div>
                                    <div style={stockInfoValueStyle}>
                                        {this.productRails
                                            .filter(x => x.productStocks.length === 0).length}
                                        {" / "}
                                        {this.productRails.length}
                                    </div>
                                </div>
                                <div style={stockInfoFieldStyle}>
                                    <div style={stockInfoLabelStyle}>{getText( "HomeFreeBands")}</div>
                                    {activePlanogram && <div style={stockInfoValueStyle}>
                                        {totalRails - _.sum(this.productRails.map(x => x.f - x.d + 1))}
                                        {" / "}
                                        {totalRails}
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </div>
            }
            <div className="card" style={{...Styles.cardStyle,
                padding: '1%',
                marginBottom: '1%',
                gridColumn: "1 / 4",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    padding: '1%'
                }}>
                    <div style={{fontSize: 24, marginRight: 10}}> {getText( "HomeEvents")}</div>
                    <NeoEventFilterForm onSubmit={(values) => {
                        this.props.updateNeoEventFilter(values);
                        return this.props.requestNeoEvents(new Date().getTime());
                    }} />
                    {/* <div style={{
                        flex: "0 0 auto",
                        margin: "0px 6px" }}>
                        <button className="btn btn-primary btn-sm"
                            disabled={this.props.neoEvents.downloadState.isLoading || this.isDisable()}
                            onClick={(e) => this.props.requestDownloadNeoEvents(new Date().getTime())}
                            style={{}}>
                            T&eacute;l&eacute;charger
                        </button>
                    </div> */}
                </div>
                <div style={{
                    pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1,
                    padding: '0 1%'
                }}>
                    <div style={{width: "100%" }}>
                        <Table
                                className="home-events-table"
                                style={{margin: 0, marginTop: "1%"}}
                                columns={[
                                    {
                                        id: "creationDate",
                                        accessor: (d) => d,
                                        Header: <div style={{}}>{getText( "HomeHour")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 150,
                                        Cell: row => <div style={{
                                            color: this.getEventLabelColor(row.value)
                                        }}>
                                            {Moment(row.value.creationDate).format("DD/MM/YYYY HH:mm:ss")}
                                        </div>
                                    },
                                    {
                                        id: "source",
                                        accessor: (d) => d,
                                        Header: <div>{getText( "HomeType")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 70,
                                        Cell: row => <div style={{
                                            color: this.getEventLabelColor(row.value)
                                        }}>
                                            {this.getCodeLabel(this.props.neoEventCodes[row.value.neoEventCodeId].source)}
                                        </div>
                                    },
                                    {
                                        id: "code",
                                        accessor: (d) => d,
                                        Header: <div>{getText( "HomeCode")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 52,
                                        Cell: row =>
                                            <Tooltip overlay={<div>
                                                {this.props.neoEventCodes[row.value.neoEventCodeId].name}
                                            </div>}
                                                placement={"right"}>
                                                <div style={{
                                                    color: this.getEventLabelColor(row.value)
                                                }}>{this.props.neoEventCodes[row.value.neoEventCodeId].code}</div>
                                            </Tooltip>
                                    },
                                    {
                                        id: "message",
                                        accessor: (d) => d,
                                        style: {
                                            height: '100%'
                                        },
                                        Header: <div>{getText( "HomeEvent")}</div>,
                                        Cell: row => <div style={{
                                            textAlign: 'left',
                                            marginLeft: '1%',
                                            whiteSpace: 'pre-wrap',
                                            color: this.getEventLabelColor(row.value)
                                        }}> 
                                            <Translation>
                                                {
                                                    (t, { i18n }) => this.setCorrectValues(t, row.value.neoEventCodeId, row.value.paramsMessage, row.value.message, row.value.neoEventId || row.value.storeNeoEventId || row.value.creationDate || row.value.eventDate) 
                                                }
                                            </Translation>
                                        </div>
                                    }
                                ] as Array<StoreEventColumn>}
                                pageSize={Math.max(this.props.neoEventsFiltered.length,8)}
                                showPagination={false}
                                showPaginationBottom={false}
                                data={this.props.neoEventsFiltered}>
                            </Table>
                        </div>
                </div>
            </div>
        </div>;
    }
}

interface ModuleProps {
    title: string;
    imageSrc: string;
    to: string;
}

class Module extends React.Component<ModuleProps, {}> {
    public render() {
        return (
            <NavLink exact to={this.props.to}
                style={{
                    display: "block",
                    width: "100%", height: "100%",
                }}>
                <Image fit={"contain"} style={{}} src={this.props.imageSrc} />
            </NavLink>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.home,
    ...state.history,
    cash: state.cash,
    stock: state.stock,
    products: state.seed.seed.products,
    moneys: state.seed.seed.moneys,
    neoEventCodes: state.seed.seed.neoEventCodes,
    dayStoreData: state.history.todayData.dayStoreData,
    dayMoneyStocks: state.history.todayMoneyStock.dayMoneyStocks || [],
    seed: state.seed.seed,
    currentUser: state.account.currentUser,
    card: state.card,
    print: state.print,
    robot: state.robot,
    temp: state.temp,
    smartPhoneStatus: state.home.smartPhoneState.smartPhoneStatus,
    deliveryStation: state.robot.robotsState.robots.length !== 0
        ? state.robot.robotsState.robots[0].deliveryStations[0]
        : undefined,
    disabled: !canUpdate(state),
    storeType: state.seed.seed.storeAppSettings.typeStore,
    paymentGraph: HomeStore.paymentGraphSelector(state) as any,
    totals: HomeStore.totalSelector(state),
    neoEventsFiltered: HomeStore.homeNeoEventsSelector(state),
    clientsGraph: HomeStore.clientsGraphSelector(state),
    productsGraph: HomeStore.productsGraphSelector(state)
} as HomeOwnProps), {
        ...HomeStore.actionCreators,
        ...StockStore.actionCreators,
        ...CashStore.actionCreators,
        ...HistoryStore.actionCreators,
        ...CardStore.actionCreators,
        ...PrintStore.actionCreators,
        ...RobotStore.actionCreators,
        ...TempStore.actionCreators,
        ...NeoEventAlarmStore.actionCreators
    })(Home as any) as any as React.ComponentClass<any>;