import * as React from "react";
import * as _ from 'lodash';
import * as ReactTable from 'react-table';
import * as Api from '../api/api';
import * as StockStore from "../store/Stock";
import * as TabsStore from "../store/Tabs";
import Table from "./Table";
import { connect } from "react-redux";
import ConnectedTabs from "./ConnectedTabs";
import Confirm from "react-confirm-bootstrap";
import { TabLink, TabContent } from "react-tabs-redux";
import { ApplicationState } from "../store";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { canEditSettings, canUpdate } from "../security/roles";
import { CreateLockerTypeForm, UpdateLockerTypeForm } from "./LockerTypeForm";

type LockerTypeProps = StockStore.StockState
    & LockerTypeOwnProps
    & typeof StockStore.actionCreators
    & typeof TabsStore.actionCreators
    ;

interface LockerTypeOwnProps {
    stockStatus: Api.StockStatusModel;
    currentUser: Api.CurrentUserManagerModel;  
    disabled: boolean;
}

type LockerTypeColumn = ReactTable.Column
    & { accessor: (d: Api.LockerTypeModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class LockerType extends React.Component<LockerTypeProps, {}> {

    public render() {
        return (
            <div>
                <div className="card" style={{...Styles.cardStyle}}>
                    <Table key={"LockerTypes"}
                        pageSize={Math.max(13,_.values(this.props.stockStatus ? this.props.stockStatus.lockerTypes : []).length)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        className="shelvingTrayTable"
                        style={{ }}
                        data={_.values(this.props.stockStatus ? this.props.stockStatus.lockerTypes : [])}
                        columns={[
                            {
                                id: "name",
                                Header: getText( "SettingsNameTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.name}</div>
                            },
                            {
                                id: "width",
                                Header: getText( "SettingsLockerWidthTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.width}</div>
                            },
                            {
                                id: "height",
                                Header: getText( "SettingsLockerHeightTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.height}</div>
                            },
                            {
                                id: "depth",
                                Header: getText( "SettingsLockerDepthTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.depth}</div>
                            },
                            {
                                id: "actions",
                                Header: getText( "SettingsActions"),
                                accessor: (d) => d.lockerTypeId,
                                Cell: row => <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5
                                        }}>
                                            <i className="glyphicon glyphicon-pencil icon-dm"
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                                                    opacity: this.props.disabled ? 0.7 : 1,
                                                    margin: "11% 0", 
                                                    cursor: "pointer",
                                                    color: "#FFFFFF" }}
                                                    onClick={(e) => {
                                                        this.props.selectLockerType(row.value);
                                                        this.props.selectTab("Lockertypes", "update");
                                                        e.stopPropagation();
                                                    }} />
                                    </div>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        cursor: "pointer",
                                        borderRadius: 5
                                        }}>
                                            <Confirm
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                                    opacity: this.props.disabled ? 0.7 : 1, color: "#FFFFFF", 
                                                    marginTop: '15%',
                                                    marginRight: 1,  }}
                                                onConfirm={() => {
                                                    this.props.requestDeleteLockerType(new Date().getTime(), row.value);
                                                    this.props.selectLockerType(undefined);
                                                }}
                                                body={getText("MaintenanceBodyConfirmDelete")}
                                                confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                            </Confirm>
                                    </div>
                                </div>
                            }
                        ] as Array<LockerTypeColumn>} />
                </div>
                <div style={{marginTop: '1%'}}>
                    <ConnectedTabs name={"Lockertypes"}>
                        <TabLink style={{width: '50%'}} to="create">
                            <div style={tabHeadStyle}>{getText( "SettingsCreate")}</div>
                        </TabLink>
                        <TabLink style={{width: '50%'}} to="update">
                            <div style={tabHeadStyle}>{getText( "SettingsEdit")}</div>
                        </TabLink>
                        <TabContent for="create">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <CreateLockerTypeForm
                                            onSubmit={(values) => this.props.requestCreateLockerType(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                        <TabContent for="update">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.selectedLockerTypeId && this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <UpdateLockerTypeForm
                                            onSubmit={(values) => this.props.requestUpdateLockerType(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                    </ConnectedTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.stock,
    stockStatus: state.stock.stockStatus,
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state)
} as LockerTypeOwnProps), {
        ...TabsStore.actionCreators,
        ...StockStore.actionCreators,
    })(LockerType as any);