import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as ReduxForm from "redux-form";
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type LockerFormProps = InjectedFormProps<{}, {}, {}>
    & LockerFormOwnProps
    & LockerFormExternalProps;

interface LockerFormOwnProps {
    text: string;
    disabled: boolean;
    lockers: { [key: string]: Api.LockerModel; };
    nameUpdated?: string;
    lockersType: Array<Api.LockerTypeModel>;
}

interface LockerFormExternalProps {
    onSubmit: (values: Api.LockerModel) => Promise<any>
}

class LockerForm extends React.Component<LockerFormProps, {}> {
    constructor(props: LockerFormProps) {
        super(props);
        this.validationLocker = this.validationLocker.bind(this);
    }

    validationLocker(value) {
        return _.values(this.props.lockers)
             .some(x =>(this.props.nameUpdated == undefined && x.name === value) || 
               (this.props.nameUpdated != value && x.name === value))
                ? getText( "SettingsNameExist")
                : undefined
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical borderSelectors" style={{ maxWidth: '50%' }}>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsNameTable")}</label>
                    <Field name="name"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required, this.validationLocker]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsTypeTable")}</label>
                    <ReduxForm.Field 
                            style={{height: 35}}
                            name={'lockerTypeId'}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(
                                this.props.lockersType
                                    .map(x => ({
                                        label: x.name,
                                        value: x.lockerTypeId
                                    })))} 
                        />
                </div> 
                <div className="col-md-12 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createLocker'));

let createForm = reduxForm({
    form: 'createLocker',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(LockerForm as any) as any;
const createSelector = formValueSelector('createLocker');

export const CreateLockerForm = connect((state: ApplicationState) => {
    return {
        text: getText("SettingsCreate"),
        initialValues: null,
        disabled: !canUpdate(state),
        lockers: state.stock.stockStatus.lockers,
        lockersType: _.values(state.stock.stockStatus.lockerTypes)
    } as LockerFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<LockerFormExternalProps>


let form = reduxForm({
    form: 'updateLocker',
    enableReinitialize: true,
    destroyOnUnmount: true
})(LockerForm as any) as any;
const updateSelector = formValueSelector('updateLocker');

export const UpdateLockerForm = connect((state: ApplicationState) => {
    return {
        text: getText( "SettingsSave"),
        initialValues: state.stock.stockStatus.lockers[state.stock.selectedLockerId],
        disabled: !canUpdate(state),
        lockers: state.stock.stockStatus.lockers,
        lockersType: _.values(state.stock.stockStatus.lockerTypes),
        nameUpdated: state.stock.stockStatus.lockers[state.stock.selectedLockerId].name
    } as LockerFormOwnProps
}, null)(form as any) as any as React.ComponentClass<LockerFormExternalProps>

