import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as ReduxForm from "redux-form";
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type LockerTypeFormProps = InjectedFormProps<{}, {}, {}>
    & LockerTypeFormOwnProps
    & LockerTypeFormExternalProps;

interface LockerTypeFormOwnProps {
    text: string;
    disabled: boolean;
    lockerTypes: { [key: string]: Api.LockerTypeModel; };
    nameUpdated?: string;
}

interface LockerTypeFormExternalProps {
    onSubmit: (values: Api.LockerTypeModel) => Promise<any>
}

class LockerTypeForm extends React.Component<LockerTypeFormProps, {}> {
    constructor(props: LockerTypeFormProps) {
        super(props);
        this.validationLockerType = this.validationLockerType.bind(this);
    }

    validationLockerType(value) {
        return _.values(this.props.lockerTypes)
             .some(x =>(this.props.nameUpdated == undefined && x.name === value) || 
               (this.props.nameUpdated != value && x.name === value))
                ? getText( "SettingsNameExist")
                : undefined
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical borderSelectors" style={{ maxWidth: '100%' }}>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsNameTable")}</label>
                    <Field name="name"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required, this.validationLockerType]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsLockerWidthTable")}</label>
                    <Field name="width"
                        className="form-control"
                        type="number"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>   
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsLockerHeightTable")}</label>
                    <Field name="height"
                        className="form-control"
                        type="number"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsLockerDepthTable")}</label>
                    <Field name="depth"
                        className="form-control"
                        type="number"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div> 
            </div> 
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>   
                <div className="col-md-6 form-group"></div>
                <div className="col-md-6 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createLockerType'));

let createForm = reduxForm({
    form: 'createLockerType',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(LockerTypeForm as any) as any;
const createSelector = formValueSelector('createLockerType');

export const CreateLockerTypeForm = connect((state: ApplicationState) => {
    return {
        text: getText("SettingsCreate"),
        initialValues: null,
        disabled: !canUpdate(state),
        lockerTypes: state.stock.stockStatus.lockerTypes
    } as LockerTypeFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<LockerTypeFormExternalProps>


let form = reduxForm({
    form: 'updateLockerType',
    enableReinitialize: true,
    destroyOnUnmount: true
})(LockerTypeForm as any) as any;
const updateSelector = formValueSelector('updateLockerType');

export const UpdateLockerTypeForm = connect((state: ApplicationState) => {
    return {
        text: getText( "SettingsSave"),
        initialValues: state.stock.stockStatus.lockerTypes[state.stock.selectedLockerTypeId],
        disabled: !canUpdate(state),
        lockerTypes: state.stock.stockStatus.lockerTypes,
        nameUpdated: state.stock.stockStatus.lockerTypes[state.stock.selectedLockerTypeId].name
    } as LockerTypeFormOwnProps
}, null)(form as any) as any as React.ComponentClass<LockerTypeFormExternalProps>

