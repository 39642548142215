import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationFR from '../eventLanguages/textEventFR.json';
import translationEN from '../eventLanguages/textEventEN.json';
import translationNL from '../eventLanguages/textEventNL.json';

const resources = {
    fr: {
        translation: translationFR
    },
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // if you're using a language detector, do not define the lng option
    //keySeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;